/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credentials-google': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M31.4 13.1H16.3v6.2h8.6c-.4 2-1.5 3.7-3.2 4.8-1.4 1-3.3 1.5-5.4 1.5-4.2 0-7.7-2.8-9-6.6-.3-1-.5-2-.5-3 0-1.1.2-2.1.5-3 1.3-3.8 4.8-6.6 9-6.6 2.3 0 4.5.8 6.1 2.4L27 4.2C24.3 1.6 20.6 0 16.3 0 10.1 0 4.7 3.6 2 8.8.9 11 .3 13.4.3 16s.6 5 1.7 7.2c2.6 5.2 8 8.8 14.3 8.8 4.3 0 7.9-1.4 10.6-3.9 3-2.8 4.8-6.9 4.8-11.8 0-1.1-.1-2.2-.3-3.2z" fill-rule="evenodd" clip-rule="evenodd" _fill="#fff"/>'
  }
})
