import {
  BACKEND,
  withAuthorization
} from 'src/remotes'

export default () => ({
  namespaced: true,
  state () {
    return {
    }
  },
  actions: {
    async checkTx ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post('/poli/tx/check', {id}, withAuthorization(token))
      return data
    }
  }
})
