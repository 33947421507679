import moment from 'moment'
import { debounce } from 'lodash'
import pluralize from 'pluralize'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Users'
  },
  components: {
    ...components
  },
  computed: {
    ...mapState({
      chatUsers: state => state.chat.users
    }),
    ...mapGetters({
      users: 'chatUsers/items',
      isLoaded: 'chatUsers/isLoaded',
      isLoading: 'chatUsers/isLoading',
      activePage: 'chatUsers/activePage',
      numberOfElements: 'chatUsers/numberOfElements',
      totalPages: 'chatUsers/totalPages'
    }),
    items () {
      const activePage = this.activePage
      const numberOfElements = this.numberOfElements
      return this.users.map((user, index) => ({
        index: (activePage - 1) * numberOfElements + index + 1,
        user
      }))
    }
  },
  data () {
    return {
      filters: {
        name: ''
      },
      visibleCount: 8
    }
  },
  async created () {
    await this.loadChatUsers()
  },
  methods: {
    pluralize,
    momentUtc: moment.utc,
    ...mapActions({
      openModal: 'modals/open',
      loadChatUsers: 'chatUsers/loadMore',
      banUser: 'chatUsers/ban',
      unbanUser: 'chatUsers/unban'
    }),
    toCustomer (id) {
      this.$router.push({ path: `/private/admin/customers/i/${id}` })
    },
    async handleSearch (q) {
      this.filters.name = q
      await this.loadChatUsers({
        reset: true,
        filters: this.filters
      })
    },
    handleSearchDebounced: debounce(
      function (value) {
        return value ? this.handleSearch(value) : this.loadChatUsers()
      },
      500
    ),
    async handleActivePage (value) {
      this.loadChatUsers({
        page: {
          filters: this.filters,
          activePage: value,
          numberOfElements: this.numberOfElements
        }
      })
    },
    async handleBan (item) {
      await this.banUser(item.user)
    },
    async handleUnban (item) {
      await this.unbanUser(item.user)
    }
  }
}
