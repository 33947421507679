import {mapActions} from 'vuex'
import * as components from 'src/components'
import {required} from 'vuelidate/lib/validators'
import { TYPES } from 'src/remotes'
import {OperationCanceledError} from 'src/errors'

export default {
  components: {
    ...components
  },
  props: {
  },
  data () {
    return {
      isAddInProgress: false,
      filters: TYPES,
      form: {
        name: '',
        type: null,
        description: '',
        address: null
      }
    }
  },
  computed: {
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
    },
    typeErrors () {
      if (this.$v.form.type.$dirty) {
        if (!this.$v.form.type.required) {
          return [
            'Type is a required field'
          ]
        }
      }
    },
    descriptionErrors () {
      if (this.$v.form.description.$dirty) {
        if (!this.$v.form.description.required) {
          return [
            'Description is a required field'
          ]
        }
      }
    },
    addressErrors () {
      if (this.$v.form.address.$dirty) {
        if (!this.$v.form.address.required) {
          return [
            'Address is a required field'
          ]
        }
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      type: {
        required
      },
      description: {
        required
      },
      address: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createAddress: 'customAddresses/createAddress'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createAddress(this.form)
          await this.handleToast({
            message: 'Address added'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot add address'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
