import Vue from 'vue'
import SvgIcon from 'vue-svgicon'
import { InlineSvgPlugin } from 'vue-inline-svg'
import lax from 'lax.js'
import 'src/icons'

export default async () => {
  Vue.use(SvgIcon, {
    defaultWidth: '1rem',
    defaultHeight: '1rem'
  })

  Vue.use(InlineSvgPlugin)

  if (process.client) {
    const SmoothScroll = require('smoothscroll-polyfill')
    SmoothScroll.polyfill()

    lax.setup({
      breakpoints: { small: 0, large: 600 }
    })

    const updateLax = () => {
      lax.update(window.scrollY)
      window.requestAnimationFrame(updateLax)
    }
    window.requestAnimationFrame(updateLax)
  }
}
