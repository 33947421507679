/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  's-icon': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M14.066 11.934h-2.134a1.067 1.067 0 01-1.066-1.066h-.001l.001-.002A1.064 1.064 0 0111.932 9.8V9.8 9.8h2.134a1.065 1.065 0 011.066 1.067h2.134c0-1.767-1.433-3.2-3.2-3.202V5.534h-2.134v2.134-.002a3.2 3.2 0 00-3.2 3.2v.002c0 1.766 1.434 3.2 3.2 3.2h2.134a1.066 1.066 0 011.066 1.066h-.002a1.065 1.065 0 01-1.066 1.067h-2.132a1.064 1.064 0 01-1.066-1.067H8.732c0 1.767 1.434 3.2 3.2 3.2v2.134h2.134v-2.134a3.2 3.2 0 000-6.4z" _fill="#FF7715"/><path pid="1" d="M25.177 11.493l-1.745-1.746c-.415-.415-.755-1.236-.755-1.821v-2.47a2.14 2.14 0 00-2.133-2.133h-2.47c-.587 0-1.406-.34-1.82-.754L14.506.823a2.137 2.137 0 00-3.016 0L9.745 2.57c-.415.414-1.235.754-1.82.754h-2.47a2.14 2.14 0 00-2.133 2.133v2.468c0 .586-.34 1.406-.754 1.82L.822 11.494c-.83.829-.83 2.186 0 3.016l1.746 1.746c.415.414.754 1.236.754 1.82v2.47a2.14 2.14 0 002.133 2.133h2.47c.586 0 1.405.34 1.82.754l1.746 1.746a2.14 2.14 0 003.016 0l1.746-1.746c.415-.415 1.235-.754 1.821-.754h2.47a2.14 2.14 0 002.133-2.133v-2.47c0-.587.34-1.406.755-1.82l1.745-1.746a2.14 2.14 0 000-3.016zm-3.254 3.254c-.812.81-1.38 2.181-1.38 3.329v2.469h-2.47c-1.147 0-2.517.566-3.329 1.379L13 23.669l-1.746-1.745c-.812-.813-2.181-1.38-3.329-1.38h-2.47v-2.468c0-1.148-.566-2.517-1.378-3.33l-1.747-1.745 1.747-1.747c.813-.811 1.379-2.18 1.379-3.33V5.458h2.469c1.148 0 2.517-.568 3.33-1.38l1.745-1.746 1.746 1.746c.811.812 2.181 1.38 3.33 1.38h2.47v2.47c0 1.147.566 2.516 1.378 3.329l1.746 1.746-1.746 1.745z" _fill="#FF7715"/>'
  }
})
