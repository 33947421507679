import { mapActions } from 'vuex'

export default {
  props: {
    sessionId: String
  },
  async mounted () {
    this.subscription = Object.freeze({
      sessionId: this.sessionId,
      listener: await this.subscribe({
        sessionId: this.sessionId
      })
    })
  },
  async destroyed () {
    if (this.subscription) {
      await this.unsubscribe(this.subscription)
      this.subscription = null
    }
  },
  render () {
    if (this.$slots.default) {
      if (this.$slots.default.length === 1) {
        return this.$slots.default[0]
      } else if (this.$slots.default.length > 1) {
        throw new Error('DepositSubscription component may contain max one child')
      }
    }
    return null
  },
  methods: {
    ...mapActions({
      subscribe: 'purchases/subscribe',
      unsubscribe: 'purchases/unsubscribe'
    })
  }
}
