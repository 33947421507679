import moment from 'moment'
import { mapGetters } from 'vuex'
import * as components from 'src/components'
import { ProfileIdentityModel } from 'src/models'

export default {
  components: {
    ...components
  },
  props: {
    withdrawals: Array,
    isMobile: Boolean
  },
  computed: {
    ...mapGetters({
      getScoutTransactionReference: 'scout/getScoutTransactionReference',
      hasWithdrawalsActionAccess: 'grants/hasWithdrawalsActionAccess'
    }),
    items () {
      return this.withdrawals.map((withdrawal, index) => ({
        index,
        withdrawal,
        moment: moment(withdrawal.createdAt),
        txLink: this.getScoutTransactionReference(withdrawal.token, withdrawal.tx, withdrawal.network),
        identities: (withdrawal.identities || []).map(
          id => new ProfileIdentityModel({
            type: 'passport',
            id
          })
        )
      }))
    }
  },
  methods: {
    moment,
    getFee (operation) {
      return operation.realCommissionAmount || operation.commissionAmount
    }
  }
}
