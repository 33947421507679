import { render, staticRenderFns } from "./SectionWithdrawalDiscount.htm?vue&type=template&id=3fea6015&scoped=true&external"
import script from "./SectionWithdrawalDiscount.js?vue&type=script&lang=js&external"
export * from "./SectionWithdrawalDiscount.js?vue&type=script&lang=js&external"
import style0 from "./SectionWithdrawalDiscount.scss?vue&type=style&index=0&id=3fea6015&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fea6015",
  null
  
)

export default component.exports