import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  currency: Joi.string().required(),
  countDeposits: Joi.number(),
  countWithdraws: Joi.number(),
  depositsVolume: Joi.string().required(),
  withdrawsVolume: Joi.string().required()
})

export default class PlasmaFinancialStatItemModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new PlasmaFinancialStatItemModel(data, { stripUnknown: true })
  }
}
