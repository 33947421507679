import { PLASMA, withAuthorization } from 'src/remotes'
import {LoadableListModel, BundlesModel} from 'src/models'

export const BUNDLE_LOADING = 'bundles/loading'
export const BUNDLE_LOADED = 'bundles/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      bundles: new LoadableListModel(BundlesModel)
    }
  },
  mutations: {
    [BUNDLE_LOADING]: (state) => {
      state.bundles = state.bundles.loading()
    },
    [BUNDLE_LOADED]: (state, { bundles }) => {
      state.bundles = state.bundles.loaded(...bundles)
    }
  },
  getters: {
    isLoading: state => state.bundles.isLoading,
    isLoaded: state => state.bundles.isLoaded,
    bundles: state => state.bundles.value
  },
  actions: {
    async loadBundle ({ state, commit, dispatch }) {
      commit(BUNDLE_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('/bundle/list/', withAuthorization(token))
      const bundles = data.map(BundlesModel.fromJson)
      commit(BUNDLE_LOADED, { bundles })
    },
    async editBundle ({ state, commit, dispatch }, { ...params }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const request = {
        ...params
      }
      await PLASMA.post('/bundle/update', request, withAuthorization(token))
      await dispatch('loadBundle')
    },
    async createBundle ({ state, commit, dispatch }, { ...params }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const request = {
        ...params
      }
      await PLASMA.post('/bundle/create', request, withAuthorization(token))
      await dispatch('loadBundle')
    },
    async deleteBundle ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PLASMA.post(`/bundle/delete/${id}`, null, withAuthorization(token))
      await dispatch('loadBundle')
    }
  }
})
