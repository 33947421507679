// import uniqid from 'uniqid'
import AbstractDevice from '../AbstractDevice'
import { SignerMemoryModel } from 'src/models'

const LOCK = 'MemoryDevice'

export default class MemoryDevice extends AbstractDevice {
  get name () {
    return 'memory'
  }

  get title () {
    return 'Memory Device'
  }

  async init () {
    this.emit('connected')
    // eslint-disable-next-line
    console.log('MemoryDevice initialized', this)
  }

  async decrypt ({ encrypted, password }) {
    return SignerMemoryModel.decrypt({ encrypted, password })
  }

  getLock () {
    return LOCK
  }
}
