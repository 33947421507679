import {mapActions} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isAddInProgress: false,
      form: {
        count: null
      }
    }
  },
  validations: {
    form: {
      count: {
        required
      }
    }
  },
  computed: {
    countErrors () {
      if (this.$v.form.count.$dirty) {
        if (!this.$v.form.count.required) {
          return [
            'Count is a required field'
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleError: 'interaction/error',
      createAccounts: 'monoova/createAccounts'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createAccounts({
            count: this.form.count
          })
          this.handleClose()
        } catch (e) {
          await this.handleError({
            error: e,
            message: 'Cannot create accounts'
          })
        } finally {
          this.isAddInProgress = false
        }
      })
    }
  }
}
