import BigNumber from 'bignumber.js'
import AbstractSocketDAO from './AbstractSocketDAO'
import PlasmaChannel from './PlasmaChannel'

export default class CurrencyDAO extends AbstractSocketDAO {
  constructor ({ currency }) {
    super()
    // this.setMaxListeners(100)
    this.currency = currency
    this.addressSubscriptions = {}
  }

  // eslint-disable-next-line no-unused-vars
  connect (client, api, options) {
    if (this.isConnected) {
      this.disconnect()
    }
    this.client = client
    this.api = api

    for (const subscription of Object.values(this.addressSubscriptions)) {
      const { address, counter } = subscription
      const channel = new PlasmaChannel(this.client, `^/balance/${address}/${this.currency.address}$`, null)
        .on('message', message => this.handleState(message))
        .on('subscribed', () => this.handleSubscribed({ address }))
      subscription.channel = channel
      // eslint-disable-next-line
      console.info(`[CurrencyDAO] Subscription to [address,token] = [${address},${this.currency.address}] restored with ${counter} watcher(s) `)
    }
  }

  subscribeAddress (address) {
    address = address.toLowerCase()
    let subscription = this.addressSubscriptions[address]
    if (!subscription) {
      const channel = new PlasmaChannel(this.client, `^/balance/${address}/${this.currency.address}$`, null)
        .on('message', message => this.handleState(message))
        .on('subscribed', () => this.handleSubscribed({ address }))
      subscription = this.addressSubscriptions[address] = {
        address,
        channel,
        counter: 1
      }
    } else {
      subscription.counter++
    }
    return this
  }

  unsubscribeAddress (address) {
    address = address.toLowerCase()
    let subscription = this.addressSubscriptions[address]
    if (!subscription) {
      // eslint-disable-next-line
      console.warn('[CurrencyDAO] Attempt to unsubscribe on invalid address')
    } else {
      subscription.counter--
      if (subscription.channel) {
        subscription.channel.removeAllListeners()
        subscription.channel.close()
        subscription.channel = null
      }
      if (subscription.counter === 0) {
        delete this.addressSubscriptions[address]
      }
    }
    return this
  }

  disconnect () {
    if (this.isConnected) {
      // Stop subscriptions but leave metadata
      for (const subscription of Object.values(this.addressSubscriptions)) {
        subscription.channel.removeAllListeners()
        subscription.channel.close()
        subscription.channel = null
      }

      this.client = null
      this.api = null
    }
  }

  handleEvent ({ event, payload }) {
    // console.log('CurrencyDAO Event ->', { event, payload })
    setImmediate(() => {
      this.emit(event.type, { event, payload })
    })
  }

  handleState ({ state, payload }) {
    // console.log('CurrencyDAO State ->', { state, payload })
    setImmediate(() => {
      this.emit(state.type, { state, payload })
    })
  }

  handleSubscribed ({ address }) {
    setImmediate(() => {
      this.emit('@_SUBSCRIBED', { address })
    })
  }

  addDecimals (amount) {
    return amount == null
      ? null
      : amount.multipliedBy(new BigNumber(10).pow(this.currency.tradeDecimals))
  }

  removeDecimals (value) {
    return value == null
      ? null
      : new BigNumber(value).div(new BigNumber(10).pow(this.currency.tradeDecimals))
  }

  formatValueString (symbol, value, isSigned) {
    const scale = [
      { power: 0, suffix: ' wei' },
      { power: 3, suffix: ' Kwei' },
      { power: 6, suffix: ' Mwei' },
      { power: 9, suffix: ' Gwei' }
    ]
    const abs = value.abs()
    const sign = value.isLessThan(0)
      ? '-'
      : value.isGreaterThan(0)
        ? '+'
        : ''
    if (abs.isGreaterThan(0)) {
      for (const { power, suffix } of scale) {
        if (this.currency.decimals >= power + 6 && abs.isLessThan(new BigNumber(10).pow(power + 3))) {
          return (isSigned && sign)
            ? `${sign} ${abs.dividedBy(new BigNumber(10).pow(power)).toString()}${suffix} ${symbol}`
            : `${abs.toString()}${suffix} ${symbol}`
        }
      }
    }
    return (isSigned && sign)
      ? `${sign} ${this.removeDecimals(abs).toString()} ${symbol}`
      : `${this.removeDecimals(abs).toString()} ${symbol}`
  }

  get isDepositSupported () {
    return false
  }

  get isWithdrawSupported () {
    return false
  }

  get isConnected () {
    return false
  }

  get isTradeSupported () {
    return false
  }

  get isTransferSupported () {
    return false
  }

  get isApproveSupported () {
    return false
  }

  get isAutoAllowance () {
    return false
  }

  async getBalance (address) {
    const { data } = await this.api.get(`/balance/${address.toLowerCase()}/${this.currency.address.toLowerCase()}`)
    const { confirmedDelta, unconfirmedDelta, lockedBalance } = data
    return { balance: new BigNumber(confirmedDelta).plus(new BigNumber(unconfirmedDelta)), lockedBalance: new BigNumber(lockedBalance) }
  }

  async getAllBalances (address) {
    const { data: balances } = await this.api.get(`/balance/${address.toLowerCase()}/all`)

    const result = []

    for (const balance of balances) {
      const { address, token, confirmedDelta, unconfirmedDelta, lockedBalance } = balance
      result.push({
        address,
        token,
        balance: new BigNumber(confirmedDelta).plus(new BigNumber(unconfirmedDelta)),
        lockedBalance: new BigNumber(lockedBalance)
      })
    }

    return result
  }

  async createTransferTx (sender, recipient, amount) {
    const value = BigNumber.isBigNumber(amount)
      ? amount.toString(10)
      : amount
    const { data } = await this.api.post(`/transfer/prepare`, {
      from: sender.toLowerCase(),
      principal: sender.toLowerCase(),
      to: recipient.toLowerCase(),
      token: this.currency.address.toLowerCase(),
      value
    })
    return data
  }

  async dispatchTransferTx (principalSignature, transfer, code) {
    const { data } = await this.api.post(`/transfer/perform`, {
      principalSignature,
      transfer,
      code
    })
    return data
  }

  async createWithdrawTx ({ sender, destination, amount, setupId }) {
    const value = BigNumber.isBigNumber(amount)
      ? amount.toString(10)
      : amount

    const { data } = await this.api.post(`/withdraw/prepare`, {
      principal: sender.toLowerCase(),
      account: sender.toLowerCase(),
      token: this.currency.address.toLowerCase(),
      value,
      destination,
      metaInfo: setupId
    })
    return data
  }

  async dispatchWithdrawTx ({ withdrawal, principalSignature, code }) {
    const { data } = await this.api.post(`/withdraw/perform`, {
      principalSignature,
      withdrawal,
      code
    })
    return data
  }
}
