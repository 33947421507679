// import BigNumber from 'bignumber.js'
import EthereumTx from '@ethereumjs/tx'
import hdkey from 'ethereumjs-wallet/dist/hdkey'
import ethUtil from 'ethereumjs-util'
import { omitBy, isNil } from 'lodash'
import Web3Utils from 'web3-utils'
import TrezorConnect from 'trezor-connect'
import AbstractDevice from '../AbstractDevice'

const DEFAULT_PATH = "m/44'/60'/0'/0"
const DEFAULT_PATH_FACTORY = (index) => `${DEFAULT_PATH}/${index}`

const LOCK = 'TrezorDevice'

export default class TrezorDevice extends AbstractDevice {
  constructor (manifest) {
    super()
    TrezorConnect.manifest(manifest)
  }

  get name () {
    return 'trezor'
  }

  get title () {
    return 'Trezor Device'
  }

  async init () {
    const response = await TrezorConnect.getPublicKey({
      path: DEFAULT_PATH,
      coin: 'eth'
    })
    if (response.success) {
      const payload = response.payload
      const { xpub } = payload
      this.xpubkey = xpub
      const wallet = hdkey.fromExtendedKey(this.xpubkey).getWallet()
      this.emit('connected')
      return {
        path: DEFAULT_PATH,
        address: `0x${wallet.getAddress().toString('hex')}`,
        publicKey: `0x${wallet.getPublicKey().toString('hex')}`
      }
    } else {
      // eslint-disable-next-line
      console.warn(`TrezorDevice error`, response)
      throw new Error(response.payload)
    }
  }

  get isConnected () {
    return !!this.xpubkey
  }

  async getAddressInfoList (from: Number = 0, limit: Number = 5): String {
    if (this.isConnected) {
      const hdKey = hdkey.fromExtendedKey(this.xpubkey)
      return Array.from({ length: limit }).map((element, index) => {
        const wallet = hdKey.deriveChild(from + index).getWallet()
        return {
          path: DEFAULT_PATH_FACTORY(index),
          address: `0x${wallet.getAddress().toString('hex')}`,
          publicKey: `0x${wallet.getPublicKey().toString('hex')}`
        }
      })
    }
    return []
  }

  async signTransaction (path, txData) {
    const params = {
      nonce: `0x${Web3Utils.toBN(txData.nonce || 0).toString('hex')}`,
      chainId: parseInt(txData.chainId, 10),
      gasPrice: txData.gasPrice == null // nil check
        ? null
        : `0x${Web3Utils.toBN(txData.gasPrice).toString('hex')}`,
      gas: txData.gas == null // nil check
        ? null
        : `0x${Web3Utils.toBN(txData.gas).toString('hex')}`,
      to: txData.to,
      value: txData.value == null // nil check
        ? null
        : `0x${Web3Utils.toBN(txData.value).toString('hex')}`,
      data: txData.data
    }

    const tx = new EthereumTx({
      ...omitBy(params, isNil)
    })

    const transaction = {
      to: `0x${tx.to.toString('hex')}`,
      value: `0x${tx.value.toString('hex')}`,
      gasPrice: `0x${tx.gasPrice.toString('hex')}`,
      gasLimit: `0x${tx.gasLimit.toString('hex')}`,
      nonce: `0x${tx.nonce.toString('hex')}`,
      data: `0x${tx.data.toString('hex')}`,
      chainId: tx.getChainId()
    }

    const chainId = txData.chainId
    const response = await TrezorConnect.ethereumSignTransaction({
      path: path,
      transaction
    })

    if (response.success) {
      // Store signature in transaction
      tx.v = response.payload.v
      tx.r = response.payload.r
      tx.s = response.payload.s

      // EIP155: v should be chain_id * 2 + {35, 36}
      const signedChainId = Math.floor((tx.v[0] - 35) / 2)
      if (signedChainId !== chainId) {
        throw new Error('Invalid signature received.')
      }

      // Return the signed raw transaction
      const rawTx = '0x' + tx.serialize().toString('hex')
      return {
        rawTransaction: rawTx
      }
    } else {
      // eslint-disable-next-line
      console.warn(`TrezorDevice error`, response)
      throw new Error(response.payload)
    }
  }

  /**
   * Data should be plaint text string
   */
  async signData (path, data) {
    const response = await TrezorConnect.ethereumSignMessage({
      path: path,
      message: data,
      hex: true
    })

    if (response.success) {
      const payload = response.payload

      let signature = payload.signature
      if (signature.startsWith('0x')) {
        signature = signature.slice(2)
      }

      const serializedSignature = `0x${signature}`

      const fromRpcSig = ethUtil.fromRpcSig(serializedSignature)

      const vHex = Number(fromRpcSig.v).toString(16)
      const r = ethUtil.toBuffer(fromRpcSig.r).toString('hex')
      const s = ethUtil.toBuffer(fromRpcSig.s).toString('hex')

      return {
        signature: serializedSignature,
        format: 'trezor',
        r: `0x${r}`,
        s: `0x${s}`,
        v: `0x${vHex}`
      }
    }
  }

  getLock () {
    return LOCK
  }
}
