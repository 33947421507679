import { mapActions } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'

export default {
  metaInfo: {
    title: 'Balances'
  },
  components: {
    ...components,
    ...micros
  },
  methods: {
    ...mapActions({
      downloadSystemBalances: 'system/downloadSystemBalances'
    }),
    async handleDownload () {
      await this.downloadSystemBalances()
    }
  }
}
