import { mapGetters } from 'vuex'
import { formatNumber } from 'accounting'
import moment from 'moment'

import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    plasmaWithdrawals: Array,
    isMobile: Boolean
  },
  computed: {
    ...mapGetters({
      getCurrency: 'currencies/getCurrency'
    }),
    items () {
      return this.plasmaWithdrawals.map(plasmaWithdrawal => {
        const currency = this.getCurrency(plasmaWithdrawal.token)
        const formattedValue = {
          value: currency.dao.removeDecimals(plasmaWithdrawal.value),
          decimals: currency.currency.displayDecimals
        }
        return {
          plasmaWithdrawal,
          formattedValue,
          currencyDecimals: currency.dao.currency.decimals
        }
      })
    }
  },
  methods: {
    moment,
    formatNumber
  }
}
