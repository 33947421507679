import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { isGreaterThanOrEqual, isLessThanOrEqual } from 'src/validations'

export default {
  metaInfo: {
    title: 'Create Custom Fee'
  },
  components: {
    ...components,
    ...micros
  },
  data () {
    return {
      isBusy: false,
      isMobile: window.innerWidth <= 768,
      form: {
        address: '',
        market: null,
        feeCurrency: null,
        makerFee: '',
        takerFee: '',
        expiredAt: null
      }
    }
  },
  validations: {
    form: {
      address: {
        required
      },
      market: {
        required
      },
      feeCurrency: {
        required
      },
      makerFee: {
        validCheck (value) {
          if (value == null || value === '') {
            return true
          }
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      takerFee: {
        validCheck (value) {
          if (value == null || value === '') {
            return true
          }
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getMarketBySymbol: 'markets/getMarketBySymbol',
      markets: 'markets/markets',
      getCurrency: 'currencies/getCurrency'
    }),
    marketItems () {
      return this.markets.value.map(el => ({
        name: el.name,
        value: el.symbol
      }))
    },
    currencyItems () {
      const res = []
      if (this.form.market) {
        const market = this.getMarketBySymbol(this.form.market.value)
        const feeCurrency = this.getCurrency(market.feeTokenAddress)
        res.push({
          name: feeCurrency.currency.name,
          value: feeCurrency.currency.symbol
        })

        if (market.alternativeTokenAddress) {
          const altFeeCurrency = this.getCurrency(market.alternativeTokenAddress)
          res.push({
            name: altFeeCurrency.currency.name,
            value: altFeeCurrency.currency.symbol
          })
        }
      }
      return res
    },
    addressErrors () {
      if (this.$v.form.address.$dirty) {
        if (!this.$v.form.address.required) {
          return [
            'Address is a required field'
          ]
        }
      }
    },
    marketErrors () {
      if (this.$v.form.market.$dirty) {
        if (!this.$v.form.market.required) {
          return [
            'Market is a required field'
          ]
        }
      }
    },
    feeCurrencyErrors () {
      if (this.$v.form.feeCurrency.$dirty) {
        if (!this.$v.form.feeCurrency.required) {
          return [
            'Currency symbol is a required field'
          ]
        }
      }
    },
    takerFeeErrors () {
      if (this.$v.form.takerFee.$dirty) {
        if (!this.$v.form.takerFee.validCheck) {
          return [
            'Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    },
    makerFeeErrors () {
      if (this.$v.form.makerFee.$dirty) {
        if (!this.$v.form.makerFee.validCheck) {
          return [
            'Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    }
  },
  async created () {
    if (this.$route.query.wallet) {
      this.form.address = this.$route.query.wallet
    }
    addEventListener('resize', () => {
      this.isMobile = innerWidth <= 768
    })
  },
  beforeDestroy () {
    removeEventListener('resize', () => {})
  },
  methods: {
    moment,
    ...mapActions({
      createCustomFee: 'customFee/createCustomFee',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error'
    }),
    async handleSubmit () {
      this.isBusy = true
      setImmediate(async () => {
        try {
          await this.createCustomFee({
            ...this.form,
            address: (this.form.address).toLowerCase(),
            marketSymbol: this.form.market.value,
            feeCurrencySymbol: this.form.feeCurrency.value,
            expiredAt: this.form.expiredAt && this.moment(this.form.expiredAt).utc().toISOString()
          })
          await this.handleToast({
            message: 'Custom Fee created'
          })
          if (this.$route.query.user) {
            await this.$router.push({ path: `/private/admin/customers/i/${this.$route.query.user}/custom-fee` })
          } else {
            await this.$router.push({ path: '/private/system/custom-fee/' })
          }
        } catch (e) {
          await this.handleError({
            error: e,
            message: 'Something wrong'
          })
        } finally {
          this.isBusy = false
        }
      })
    }
  }
}
