import { mapActions, mapState, mapGetters } from 'vuex'
import * as partials from 'src/partials'
import * as components from 'src/components'
import AddTemplateModal from 'src/modals/lib/AddTemplateModal/AddTemplateModal.vue'

export default {
  metaInfo: {
    title: 'Kyc Templates'
  },
  components: {
    ...components,
    ...partials
  },
  async created () {
    await this.getTemplates()
  },
  computed: {
    ...mapState({
      templatesIsLoading: state => state.customers.templatesIsLoading,
      templatesItems: state => state.customers.templates
    }),
    ...mapGetters({
      hasKycActionsAccess: 'grants/hasKycActionsAccess'
    })
  },
  methods: {
    ...mapActions({
      getTemplates: 'customers/getTemplates',
      delete: 'customers/deleteTemplate',
      openModal: 'modals/open'
    }),
    addTemplate () {
      this.openModal({
        factory: () => AddTemplateModal,
        data: {}
      })
    },
    deleteTemplate (item) {
      this.delete({ id: item.id })
    },
    openDetailModal (item) {
      this.openModal({
        factory: () => AddTemplateModal,
        data: {
          id: item.id,
          propTitle: item.name,
          propMessage: item.text
        }
      })
    }
  }
}
