import { has, get } from 'lodash'

export default () => ({
  computed: {
    getRouteProperty () {
      const matched = this.$route.matched.slice().reverse()
      return (propertyPath, defaultValue = undefined) => {
        const route = matched.find(
          route => has(route, propertyPath)
        )
        if (route != null) {
          return get(route, propertyPath)
        }
        return defaultValue
      }
    }
  }
})
