import * as modulesClient from 'src/store/client'
import * as modulesServer from 'src/store/server'

export default async ({ store, router }) => {
  if (process.client) {
    for (const [key, factory] of Object.entries(modulesClient)) {
      await store.registerModule(key, factory({ store, router }))
    }
  } else {
    for (const [key, factory] of Object.entries(modulesServer)) {
      await store.registerModule(key, factory({ store, router }))
    }
  }
}
