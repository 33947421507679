/* eslint-disable */
require('./admin')
require('./arrow-left')
require('./arrow-right')
require('./australia')
require('./close')
require('./contact-mail')
require('./contact-phone')
require('./contact-skype')
require('./contact-telegram')
require('./convert-icon')
require('./credentials-email')
require('./credentials-facebook')
require('./credentials-google')
require('./credentials-login')
require('./credentials-phone')
require('./credentials-telegram')
require('./dollar')
require('./eye')
require('./fb')
require('./github')
require('./globus')
require('./graph')
require('./mail')
require('./medium')
require('./pen')
require('./planet')
require('./reddit')
require('./s-icon')
require('./server')
require('./social-facebook')
require('./social-google')
require('./social-profile')
require('./stack-coins-icon')
require('./telegram')
require('./tower')
require('./twitter')
require('./user')
require('./user2')
require('./wallet')
require('./youtube')
