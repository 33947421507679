import { mapGetters } from 'vuex'
import * as components from 'src/components'
import SectionLayout from '../SectionLayout/SectionLayout.vue'
import { PlasmaPocketModel, EthereumPocketModel } from 'src/models'
import TokenStatsPartial from '../TokenStatsPartial/TokenStatsPartial.vue'

export default {
  components: {
    ...components,
    SectionLayout,
    TokenStatsPartial
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      getCurrency: 'currencies/getCurrency',
      getFiatCurrencyBySymbol: 'profileRegistry/getFiatCurrencyBySymbol',
      getToken: 'tokens/getToken',
      isCurrenciesLoaded: 'currencies/isLoaded',
      isTokensLoaded: 'tokens/isLoaded',
      isProfileRegistryLoaded: 'profileRegistry/isLoaded'
    }),
    monitors () {
      const isCurrenciesLoaded = this.isCurrenciesLoaded
      const isTokensLoaded = this.isTokensLoaded
      const isProfileRegistryLoaded = this.isProfileRegistryLoaded
      return (!isCurrenciesLoaded || !isTokensLoaded || !isProfileRegistryLoaded)
        ? []
        : this.config.transparency.currencies
          .map(c => {
            const currency = this.getCurrency(c.tokenAddress)
            const token = this.getToken(c.tokenAddress)
            const fiatCurrency = this.getFiatCurrencyBySymbol(c.fiatSymbol)
            return (currency == null || token == null || fiatCurrency == null) ? null : {
              ...c,
              token,
              fiatCurrency,
              plasmaPocket: new PlasmaPocketModel({
                address: c.relayAddress,
                currency
              }),
              ethereumPocket: new EthereumPocketModel({
                address: c.relayAddress,
                token
              })
            }
          })
          .filter(monitor => monitor != null)
    }
  },
  data () {
    return {
      breadcrumbs: [
        { text: 'Home', disabled: false, href: '/' },
        { text: 'Transparency', disabled: true, href: '' }
      ]
    }
  }
}
