import createPersistedState from 'vuex-persistedstate'
import createI18n from 'src/i18n'
import { WalletClientModel } from 'src/models'

import {
  privateRouter,
  callbackRouter,
  authHookFactory
} from 'src/router'

export default async ({ router, store }) => {
  try {
    if (process.client) {
      createPersistedState({
        key: 'persistedAudtLocalState',
        storage: window.localStorage,
        paths: [
          'i18n.localeDefault',
          'auth'
        ]
      })(store)

      createPersistedState({
        key: 'persistedLocalState',
        storage: window.localStorage,
        paths: [
          'wallets.clientWallets'
        ],
        setState (name, state, storage) {
          return storage.setItem(name, JSON.stringify({
            wallets: {
              clientWallets: state.wallets.clientWallets
            }
          }))
        },
        getState (name, storage) {
          const clientWallets = []
          try {
            if (name in storage) {
              const tree = JSON.parse(storage[name])
              if (tree && tree.wallets && tree.wallets.clientWallets) {
                for (const data of tree.wallets.clientWallets) {
                  try {
                    clientWallets.push(WalletClientModel.fromJson(data))
                  } catch (e) {
                    // eslint-disable-next-line
                    console.warn('Cannot parse wallet client data', data, e)
                  }
                }
              }
            }
          } catch (e) {
            // eslint-disable-next-line
            console.warn('Cannot parse wallets', e)
          }
          return {
            wallets: {
              clientWallets
            }
          }
        }
      })(store)

      createPersistedState({
        key: 'settingsLocalState',
        storage: window.localStorage,
        paths: [
          'passport.token',
          'passport.refreshToken',
          'passport.expirationTime',
          'cookies',
          'notifySettings',
          'profileNotifications.switched',
          'profileNotifications.readDate'
        ]
      })(store)

      try {
        await store.dispatch('passport/recover')
      } catch (e) {
        // ignore
      }
    }

    await store.dispatch('i18n/initialize', createI18n())

    const authHook = authHookFactory(store)
    router.addRoutes([
      ...privateRouter({ store }),
      ...callbackRouter({ store })
    ])

    router.beforeEach(async (to, from, next) => {
      // await store.dispatch('splash/clear')
      await authHook(to, from, next)
    })

    if (process.client) {
      router.afterEach((to) => {
        if (to.matched.some(record => record.meta.toTop)) {
          document.body.scrollTop = 0 // For Safari
          document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        }
      })
    }
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
    throw e
  }
}
