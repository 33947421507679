import { mapGetters, mapActions } from 'vuex'

import VerificationPhone from './VerificationPhone/VerificationPhone.vue'
import VerificationEmail from './VerificationEmail/VerificationEmail.vue'

export default {
  components: {
    VerificationPhone,
    VerificationEmail
  },
  async created () {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
  },
  methods: {
    ...mapActions({
    }),
    changeStep () {
      this.$emit('changeStep', 1)
    }
  }
}
