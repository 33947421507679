/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact-skype': {
    width: 16,
    height: 16,
    viewBox: '0 0 329.978 329.978',
    data: '<path pid="0" d="M309.644 192.877a147.95 147.95 0 002.648-27.888c0-81.353-65.954-147.302-147.304-147.302-9.537 0-18.858.917-27.891 2.649-32.52-28.348-81.894-27.063-112.86 3.902-30.967 30.964-32.253 80.341-3.902 112.863a148.025 148.025 0 00-2.648 27.888c0 81.354 65.953 147.303 147.302 147.303 9.538 0 18.86-.917 27.894-2.649 32.518 28.348 81.893 27.063 112.859-3.902 30.963-30.965 32.251-80.342 3.902-112.864zm-141.849 62.838c-43.627 0-79.982-19.5-79.982-43.298 0-10.575 5.946-20.163 19.495-20.163 20.826 0 22.811 29.746 58.511 29.746 17.182 0 28.091-7.602 28.091-17.515 0-12.231-10.576-14.213-27.762-18.509l-28.424-6.939c-28.099-6.943-49.911-18.51-49.911-50.902 0-39.332 38.998-53.873 72.381-53.873 36.691 0 73.708 14.541 73.708 36.684 0 11.236-7.608 21.158-20.163 21.158-18.841 0-19.507-22.146-49.907-22.146-16.855 0-27.762 4.626-27.762 14.874 0 11.236 10.907 13.877 25.784 17.185l20.156 4.629c27.428 6.275 60.156 17.845 60.156 51.564 0 39.325-38.016 57.505-74.371 57.505z"/>'
  }
})
