import moment from 'moment'
import { mask } from 'vue-the-mask'

export default {
  props: {
    value: [String, Date],
    text: Boolean,
    filled: Boolean,
    solo: Boolean,
    label: String,
    placeholder: String,
    errorMessages: Array,
    prependIcon: String,
    appendIcon: String,
    hideDetails: Boolean,
    clearable: Boolean,
    rounded: Boolean,
    mask: {
      type: String,
      default: '##.##.####'
    },
    inputMask: {
      type: String,
      default: 'DD.MM.YYYY'
    },
    valueMask: {
      type: String,
      default: 'YYYY-MM-DD'
    }
  },
  directives: {
    mask
  },
  data () {
    const value = this.value
    const m = (value == null || value === '')
      ? null
      : moment(value, this.inputMask)
    return {
      isMenuVisible: false,
      currentValue: (m == null || !m.isValid()) ? null : m.format(this.valueMask),
      formattedValue: (m == null || !m.isValid()) ? null : m.format(this.inputMask)
    }
  },
  watch: {
    currentValue (value) {
      const m = value == null
        ? null
        : moment.utc(value, this.valueMask)
      const v = (m == null || !m.isValid())
        ? null
        : m.format(this.inputMask)
      this.formattedValue = v
      this.$emit('input', value)
    }
  },
  methods: {
    handleInput () {
      this.isMenuVisible = false
    },
    handleBlur () {
      const value = this.formattedValue
      const m = value == null
        ? null
        : moment.utc(value, this.inputMask)
      const v = (m == null || !m.isValid())
        ? null
        : m.format(this.valueMask)
      this.currentValue = v
    },
    handleClick () {
      this.isMenuVisible = true
    },
    handleClear () {
      this.currentValue = null
    }
  }
}
