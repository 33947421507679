import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import SectionGeneral from './SectionGeneral/SectionGeneral.vue'
import SectionWallets from './SectionWallets/SectionWallets.vue'
import SectionDeposits from './SectionDeposits/SectionDeposits.vue'
import SectionWithdrawals from './SectionWithdrawals/SectionWithdrawals.vue'
import SectionCredentials from './SectionCredentials/SectionCredentials.vue'
import SectionPlasmaWithdrawals from './SectionPlasmaWithdrawals/SectionPlasmaWithdrawals.vue'
import SectionPlasmaDeposits from './SectionPlasmaDeposits/SectionPlasmaDeposits.vue'
import SectionDirectTransfer from './SectionDirectTransfer/SectionDirectTransfer.vue'
import SectionAccountOperations from './SectionAccountOperations/SectionAccountOperations.vue'
import BasicInformation from './BasicInformation/BasicInformation.vue'
import TwoFaSection from './TwoFaSection/TwoFaSection.vue'
import DepositsAddresses from './DepositsAddresses/DepositsAddresses.vue'
import SectionWithdrawalDiscount from './SectionWithdrawalDiscount/SectionWithdrawalDiscount.vue'

export default {
  metaInfo: {
    title: 'Profile Details'
  },
  components: {
    ...components,
    SectionGeneral,
    SectionWallets,
    SectionDeposits,
    SectionWithdrawals,
    SectionCredentials,
    SectionPlasmaWithdrawals,
    SectionPlasmaDeposits,
    SectionDirectTransfer,
    SectionAccountOperations,
    BasicInformation,
    TwoFaSection,
    DepositsAddresses,
    SectionWithdrawalDiscount
  },
  data () {
    return {
      deposits: {
        isLoaded: false,
        isLoading: false,
        items: []
      },
      withdrawals: {
        isLoaded: false,
        isLoading: false,
        items: []
      },
      plasmaWithdrawals: {
        isLoaded: false,
        isLoading: false,
        items: []
      },
      plasmaDeposits: {
        isLoaded: false,
        isLoading: false,
        items: []
      },
      directTransfers: {
        isLoaded: false,
        isLoading: false,
        items: [],
        wallet: ''
      },
      selectedWallet: null,
      isMobile: window.innerWidth <= 1200
    }
  },
  computed: {
    ...mapState({
      profileId: state => state.route.params.id
    }),
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData',
      profile: 'myProfileKyc/profile',
      hasGrant: 'passport/hasGrant',
      depositAddresses: 'manageDepositsAddresses/items',
      hasDepositsViewAccess: 'grants/hasDepositsViewAccess',
      hasWithdrawalsViewAccess: 'grants/hasWithdrawalsViewAccess',
      hasAccountOperationsAccess: 'grants/hasAccountOperationsAccess',
      hasTransfersViewAccess: 'grants/hasTransfersViewAccess',
      hasDepositsAddressesViewAccess: 'grants/hasDepositsAddressesViewAccess'
    }),
    verificators () {
      return this.getProfileData(this.profileId).value.verificators.length > 0
    },
    profileData () {
      return this.getProfileData(this.profileId)
    },
    details () {
      return this.profileData.value.profile
    },
    wallets () {
      return this.profileData.value.addresses || []
    },
    activeWalletAddress () {
      if (this.selectedWallet) {
        return this.selectedWallet.address
      }
      if (this.wallets.length > 0) {
        return this.wallets[0].address
      }
      return null
    },
    addresses () {
      const addresses = this.wallets
        .filter(address => address.blockchain === 'ETH')
        .map(address => address.address.toLowerCase())
      return Array.from(new Set(addresses))
    }
  },
  async created () {
    addEventListener('resize', this.resizeEventListener)
  },
  mounted () {
    const addresses = this.addresses

    if (this.hasDepositsViewAccess) {
      this.refreshDeposits({ addresses })
      this.refreshPlasmaDeposits()
    }
    if (this.hasWithdrawalsViewAccess) {
      this.refreshWithdrawals({ addresses })
      this.refreshPlasmaWithdrawals()
    }
    if (this.hasTransfersViewAccess) {
      this.refreshDirectTransfer()
    }
    if (this.hasDepositsAddressesViewAccess) {
      this.refreshDepositsAddresses()
    }
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  watch: {
    profile: {
      immediate: true,
      handler () {
        this.refreshProfile()
      }
    },
    activeWalletAddress: {
      handler () {
        if (this.hasWithdrawalsViewAccess) {
          this.refreshPlasmaWithdrawals()
        }
        if (this.hasDepositsViewAccess) {
          this.refreshPlasmaDeposits()
        }
        if (this.hasTransfersViewAccess) {
          this.refreshDirectTransfer()
        }
        if (this.hasDepositsAddressesViewAccess) {
          this.refreshDepositsAddresses()
        }
      },
      immediate: true
    }
  },
  methods: {
    moment,
    momentUtc: moment.utc,
    ...mapActions({
      loadDepositAddresses: 'manageDepositsAddresses/loadDepositAddresses',
      loadProfileData: 'customers/loadKYCProfileData',
      fetchTypedDeposits: 'purchases/fetchTyped',
      fetchTypedWithdrawals: 'redemptions/fetchTyped',
      fetchPlasmaWithdrawals: 'redemptions/fetchPlasmaWithdrawals',
      fetchPlasmaDeposits: 'purchases/fetchPlasmaDeposits',
      fetchDirectTransfer: 'purchases/fetchDirectTransfer',
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    handleRefreshDeposits () {
      this.refreshDeposits({ addresses: this.addresses })
    },
    handleRefreshWithdrawals () {
      this.refreshWithdrawals({ addresses: this.addresses })
    },
    walletChanged (wallet) {
      this.selectedWallet = wallet
    },
    async refreshProfile () {
      return this.loadProfileData({
        id: this.details.id
      })
    },
    async refreshDeposits ({ addresses }) {
      try {
        this.deposits.isLoading = true
        const deposits = await this.fetchTypedDeposits({
          filters: {
            addresses
          }
        })
        Object.assign(this.deposits, {
          isLoaded: true,
          items: deposits
        })
      } finally {
        Object.assign(this.deposits, {
          isLoading: false
        })
      }
    },
    async refreshWithdrawals ({ addresses }) {
      try {
        this.withdrawals.isLoading = true
        const withdrawals = await this.fetchTypedWithdrawals({
          filters: {
            addresses
          }
        })
        Object.assign(this.withdrawals, {
          isLoaded: true,
          items: withdrawals
        })
      } finally {
        Object.assign(this.withdrawals, {
          isLoading: false
        })
      }
    },
    async refreshPlasmaWithdrawals () {
      if (!this.activeWalletAddress) {
        return
      }
      if (this.hasWithdrawalsViewAccess) {
        try {
          this.plasmaWithdrawals.isLoading = true
          const plasmaWithdrawals = await this.fetchPlasmaWithdrawals({
            address: this.activeWalletAddress
          })
          Object.assign(this.plasmaWithdrawals, {
            isLoaded: true,
            items: plasmaWithdrawals
          })
        } finally {
          Object.assign(this.plasmaWithdrawals, {
            isLoading: false
          })
        }
      }
    },
    async refreshDepositsAddresses () {
      if (!this.activeWalletAddress) {
        return
      }
      if (this.hasDepositsAddressesViewAccess) {
        try {
          await this.loadDepositAddresses({
            filters: {
              account: this.activeWalletAddress
            }
          })
        } finally {
        }
      }
    },
    async refreshPlasmaDeposits () {
      if (!this.activeWalletAddress) {
        return
      }
      if (this.hasDepositsViewAccess) {
        try {
          this.plasmaDeposits.isLoading = true
          const plasmaDeposits = await this.fetchPlasmaDeposits({
            address: this.activeWalletAddress
          })
          Object.assign(this.plasmaDeposits, {
            isLoaded: true,
            items: plasmaDeposits
          })
        } finally {
          Object.assign(this.plasmaDeposits, {
            isLoading: false
          })
        }
      }
    },
    async refreshDirectTransfer () {
      if (!this.activeWalletAddress) {
        return
      }
      if (this.hasTransfersViewAccess) {
        try {
          this.directTransfers.isLoading = true
          const directTransfers = await this.fetchDirectTransfer({
            address: this.activeWalletAddress
          })
          Object.assign(this.directTransfers, {
            isLoaded: true,
            items: directTransfers,
            wallet: this.activeWalletAddress
          })
        } finally {
          Object.assign(this.directTransfers, {
            isLoading: false
          })
        }
      }
    }
  }
}
