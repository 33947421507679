import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  name: Joi.string().required(),
  feeTokenAddress: Joi.string().required(),
  makerFee: Joi.string().required(),
  takerFee: Joi.string().required(),
  alternativeFeeTokenAddress: Joi.string().allow([null]),
  makerAltFee: Joi.string().allow([null]),
  takerAltFee: Joi.string().allow([null]),
  quantityIncrement: Joi.string().required(),
  tickSize: Joi.string().required(),
  baseMinSize: Joi.string().required(),
  quoteMinSize: Joi.string().required(),
  locked: Joi.boolean().default(false),
  promotional: Joi.boolean().default(false),
  showOnLanding: Joi.boolean(),
  sortOrder: Joi.number().required(),
  defaultSlippage: Joi.number().allow([null])
})

export default class UpdateMarketRequest extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  static fromJs (data) {
    return new UpdateMarketRequest(data, { stripUnknown: true })
  }
}
