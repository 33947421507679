import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  revenue: Joi.object().keys({
    claimedAmounts: Joi.number().allow(null),
    totalAmounts: Joi.number().allow(null)
  }),
  friends: Joi.object().keys({
    directFriends: Joi.number().allow(null),
    referralFriends: Joi.number().allow(null)
  })
})

export default class ReferralData extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new ReferralData(
      { ...data },
      { stripUnknown: true }
    )
  }
}
