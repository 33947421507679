import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.number(),
  type: Joi.string().allow(['', null]),
  callbackUrl: Joi.string().allow(['', null]),
  status: Joi.string().allow(['', null]),
  lastUpdated: Joi.string().allow(['', null]),
  createdDate: Joi.string().allow(['', null])
})

export default class MonoovaSubscriptionModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new MonoovaSubscriptionModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
