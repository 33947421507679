import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object().keys({
  id: Joi.string().allow([null, '']),
  type: Joi.string().allow(['', null]),
  timestamp: Joi.number().required(),
  domain: Joi.string().allow([null, '']),
  ssoUserId: Joi.string().allow([null, '']),
  device: Joi.object().keys({
    browser: Joi.string().allow([null, '']),
    os: Joi.string().allow([null, '']),
    ip: Joi.string().allow([null, ''])
  }),
  payload: Joi.object()
})

export default class UserActivityModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new UserActivityModel({
      ...data
    }, { stripUnknown: true })
  }
}
