import EventEmitter from 'events'
import uniqid from 'uniqid'

export default class Channel extends EventEmitter {
  constructor (client, stream) {
    super()
    this.client = client
    this.stream = stream
    this.subscribeRequestId = uniqid()

    const handleSubscribed = m => {
      if (m.type === 'subscribed' && m.requestId === this.subscribeRequestId) {
        client.removeListener('message', handleSubscribed)
        this.subscriptionId = m.subscriptionId
      }
    }

    const handleMessage = m => {
      if (m.type === 'message' && m.subscriptionId === this.subscriptionId) {
        this.emit('message', m.payload)
      }
    }

    client.on('message', handleSubscribed)
    client.on('message', handleMessage)

    this.client.send({
      type: 'subscribe',
      stream,
      requestId: this.subscribeRequestId
    })
  }

  send (payload) {
    this.client.send({
      type: 'message',
      stream: this.stream,
      payload,
      requestId: uniqid(),
      subscriptionId: this.subscriptionId
    })
  }

  close () {
    try {
      this.client.send({
        type: 'unsubscribe',
        stream: this.stream,
        requestId: this.subscribeRequestId,
        subscriptionId: this.subscriptionId
      })
      this.subscriptionId = null
      this.client = null
    } catch (e) {
      // ignore
    }
  }
}
