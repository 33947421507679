/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 2.667C2.667 2.667.667 8 .667 8s2 5.333 7.333 5.333S15.333 8 15.333 8s-2-5.333-7.333-5.333zm0 2a3.333 3.333 0 110 6.666 3.333 3.333 0 010-6.666zM8 6a2 2 0 100 4 2 2 0 000-4z" _fill="#12143B" opacity=".2"/>'
  }
})
