import { render, staticRenderFns } from "./TwofaPartial.htm?vue&type=template&id=81f61af2&scoped=true&external"
import script from "./TwofaPartial.js?vue&type=script&lang=js&external"
export * from "./TwofaPartial.js?vue&type=script&lang=js&external"
import style0 from "./TwofaPartial.scss?vue&type=style&index=0&id=81f61af2&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81f61af2",
  null
  
)

export default component.exports