/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'graph': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M1 1v38h38" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M1 12.753h38l-2.413-2.375M39 12.753l-2.412 2.375" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M1 26.111l6.202-2.581 4.135-7.745h3.898l1.254-8.683 5.185-4.225 3.733 19.245 4.537 3.99 2.034 7.744-2.737-1.027M31.979 33.855l1.877-1.877" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
