import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.number().required(),
  fee: Joi.string().allow([null, '']),
  feeToken: Joi.string().allow([null, '']),
  makerOrTaker: Joi.string().allow([null, '']),
  makerOrderId: Joi.string().allow([null, '']),
  takerOrderId: Joi.string().allow([null, '']),
  price: Joi.string().required(),
  quantity: Joi.string().required(),
  side: Joi.string().allow(['BUY', 'SELL']).required(),
  symbol: Joi.string().required(),
  timestamp: Joi.string().required()
})

export default class PlasmaTradeModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new PlasmaTradeModel({
      ...data
    }, { stripUnknown: true })
  }
}
