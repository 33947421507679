import Joi from 'joi'
import { get } from 'lodash'
import { AbstractModel, ImageModel, AttachmentModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  ssoUserId: Joi.string().allow([null, '']).optional(),
  lastResidenceCheckDate: Joi.string().allow([null, '']),
  phone: Joi.object().keys({
    phone: Joi.string(),
    status: Joi.string().required(),
    createdAt: Joi.date().required()
  }),
  email: Joi.object().keys({
    email: Joi.string(),
    status: Joi.string().required(),
    createdAt: Joi.date().required()
  }),
  personal: Joi.object().keys({
    firstName: Joi.string(),
    middleName: Joi.string().allow([null, '']),
    lastName: Joi.string(),
    birthDate: Joi.date(),
    photo: Joi.object().type(ImageModel).allow(null),
    createdAt: Joi.date().required(),
    status: Joi.string().required()
  }),
  customComment: Joi.string().allow([null, '']),
  address: Joi.object().keys({
    country: Joi.string(),
    state: Joi.string().allow([null, '']),
    city: Joi.string(),
    zip: Joi.string(),
    addressLine1: Joi.string(),
    addressLine2: Joi.string().allow([null, '']),
    createdAt: Joi.date().required(),

    status: Joi.string().required(),
    rejectComment: Joi.string().allow([null, '']),
    complicateComment: Joi.string().allow([null, ''])
  }),
  documents: Joi.object().keys({
    selfie: Joi.object().type(AttachmentModel).allow(null),
    attachments: Joi.array().items(Joi.object().keys({
      type: Joi.string(),
      file: Joi.object().type(AttachmentModel)
    })),
    createdAt: Joi.date().required(),

    status: Joi.string().required(),
    complicateComment: Joi.string().allow([null, '']),
    rejectComment: Joi.string().allow([null, ''])
  }),
  rapidID: Joi.array().items(Joi.object().keys({
    phone: Joi.string(),
    status: Joi.string(),
    createdAt: Joi.date(),
    expiredAt: Joi.date(),
    firstName: Joi.string().allow([null, '']),
    lastName: Joi.string().allow([null, '']),
    middleName: Joi.string().allow([null, ''])
  })),
  signatures: Joi.array().items(Joi.object()).allow(null),
  twofa: Joi.object().allow(null)
})

export default class CustomerKycDetails extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get kycLevel () {
    let level = 0

    if (this.phone && this.phone.status === 'ACCEPTED' &&
      this.email && this.email.status === 'ACCEPTED') {
      level = 1
    }
    if (this.personal && this.personal.status === 'ACCEPTED') {
      level = 2
    }
    if (this.address && this.address.status === 'ACCEPTED') {
      level = 3
    }
    if (this.documents && this.documents.status === 'ACCEPTED') {
      level = 4
    }

    if (this.isRapidIDComplete) {
      level = 4
    }
    return level
  }

  get isRapidIDComplete () {
    if (!this.rapidID) {
      return false
    }
    return this.rapidID.find(e => e.status === 'ACCEPTED') != null
  }

  get acceptedRapidIDInfo () {
    if (!this.isRapidIDComplete) {
      return null
    }

    return this.rapidID.find(e => e.status === 'ACCEPTED')
  }

  get activeRapidIdRequest () {
    const now = new Date()

    const activeRapidIdRequests = this.rapidID
      .filter(e => e.status === 'PENDING')
      .filter(e => e.expiredAt > now)

    activeRapidIdRequests.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

    return activeRapidIdRequests.length > 0 ? activeRapidIdRequests[0] : null
  }

  get lastNotActiveRapidIdRequest () {
    if (this.activeRapidIdRequest || this.isRapidIDComplete) {
      return null
    }

    const rapidIdRequests = this.rapidID
      .filter(e => e.status !== 'PENDING' && e.status !== 'RESET')

    rapidIdRequests.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    return rapidIdRequests.length > 0 ? rapidIdRequests[0] : null
  }

  isRapidIDPossible (allowedPhonePrefixes = []) {
    if (this.kycLevel !== 1) {
      return false
    }

    let isPhoneValid = false
    for (const prefix of allowedPhonePrefixes) {
      isPhoneValid |= this.phone.phone.startsWith(prefix)
    }

    return isPhoneValid
  }

  get fullName () {
    if (this.isRapidIDComplete) {
      return `${this.acceptedRapidIDInfo.firstName} ${this.acceptedRapidIDInfo.lastName}`
    }

    if (this.kycLevel >= 2) {
      return `${this.personal.firstName} ${this.personal.lastName}`
    }
    return null
  }

  get kycEmail () {
    if (this.kycLevel >= 1) {
      return this.email.email
    }
    return null
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    const attachments = get(data, 'documents.attachments', [])
    return new CustomerKycDetails({
      ...data,
      documents: data.documents && {
        ...data.documents,
        attachments: attachments.map(el => ({
          ...el,
          file: AttachmentModel.fromJson(el.file)
        })),
        selfie: data.documents && data.documents.selfie && AttachmentModel.fromJson(data.documents.selfie)
      },
      signatures: data.signatures && data.signatures.map(e => ({
        type: e.type,
        value: e.value
      })),
      twofa: data.twofa
    }, { stripUnknown: true })
  }
}
