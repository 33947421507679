import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import { getData } from 'iso-3166-1-alpha-2'

export default {
  metaInfo: {
    title: 'Verification'
  },
  props: {
    submitTitle: String
  },
  components: {
    ...components,
    VuetifyGoogleAutocomplete: () => import('vuetify-google-autocomplete')
  },
  data () {
    return {
      isBusy: false,
      form: {
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: ''
      },
      agreement: false,
      address: null
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      addressGetter: 'myProfileKyc/address',
      levels: 'myProfileKyc/levels'
    }),
    countryOptions () {
      return Object.values(getData())
    },
    countryOptionsData () {
      return getData()
    },
    countryErrors () {
      if (this.$v.form.country.$dirty) {
        if (!this.$v.form.country.required) {
          return [
            'Country is a required field'
          ]
        }
      }
      return []
    },
    stateErrors () {
      return []
    },
    cityErrors () {
      if (this.$v.form.city.$dirty) {
        if (!this.$v.form.city.required) {
          return [
            'City is a required field'
          ]
        }
      }
      return []
    },
    zipErrors () {
      if (this.$v.form.zip.$dirty) {
        if (!this.$v.form.zip.required) {
          return [
            'Postcode is a required field'
          ]
        }
        if (!this.$v.form.zip.lengthCheck) {
          return [
            'Postcode 4 digit number min'
          ]
        }
      }
      return []
    },
    addressLine1Errors () {
      if (this.$v.form.addressLine1.$dirty) {
        if (!this.$v.form.addressLine1.required) {
          return [
            'Address Line 1 is a required field'
          ]
        }
      }
      return []
    },
    passportErrors () {
      if (this.$v.form.passport.$dirty) {
        if (!this.$v.form.passport.required) {
          return [
            'Passport ID is a required field'
          ]
        }
      }
      return []
    }
  },
  validations: {
    form: {
      country: { required },
      state: {},
      city: { required },
      zip: {
        required,
        lengthCheck (value) {
          return value.length >= 4
        }
      },
      addressLine1: { required },
      addressLine2: {}
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler () {
        this.handleReset()
        this.$v.form.$reset()
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadProfile: 'myProfileKyc/loadProfile',
      updateAddress: 'myProfileKyc/updateAddress'
    }),
    getAddressData (addressData, placeResultData) {
      const shortCountryName = placeResultData.address_components.find(el => el.types.find(el => el === 'country')).short_name
      let countryName = this.countryOptionsData[shortCountryName]
      this.address = addressData
      this.form = {
        country: countryName,
        state: addressData.administrative_area_level_1,
        city: addressData.locality,
        zip: addressData.postal_code,
        addressLine1: addressData.name
      }
    },
    async handleSubmit () {
      this.isBusy = true
      try {
        if (this.agreement) {
          await this.updateAddress({
            ...this.form
          })
        }
      } finally {
        this.isBusy = false
      }
    },
    handleReset () {
      Object.assign(
        this.form,
        !this.profile.address
          ? {
            country: '',
            state: '',
            city: '',
            zip: '',
            addressLine1: '',
            addressLine2: ''
          }
          : {
            country: this.profile.address.country,
            state: this.profile.address.state,
            city: this.profile.address.city,
            zip: this.profile.address.zip,
            addressLine1: this.profile.address.addressLine1,
            addressLine2: this.profile.address.addressLine2
          })
    }
  },
  mounted () {
  }
}
