import {mapActions, mapGetters, mapState} from 'vuex'
import * as components from 'src/components'
import * as partials from 'src/partials'

export default {
  components: {
    ...components,
    ...partials
  },
  computed: {
    ...mapState({
      currencySymbol: state => state.route.params.symbol
    }),
    ...mapGetters({
      bundles: 'bundle/bundles',
      isLoading: 'bundle/isLoading'
    }),
    currentBundle () {
      return this.bundles.find(bundle => bundle.baseCurrencySymbol === this.currencySymbol)
    }
  },
  methods: {
    ...mapActions({
      loadBundle: 'bundle/loadBundle',
      handleToast: 'interaction/toast'
    })
  },
  async created () {
    await this.loadBundle()
  }
}
