import { mapActions } from 'vuex'
import { OperationCanceledError } from 'src/errors'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    message: String,
    submitTitle: String,
    resetTitle: String,
    handler: Function,
    monoovaAccountId: String
  },
  data () {
    return {
      isLoading: false,
      bsb: null,
      accountName: '',
      accountNumber: null
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleError: 'interaction/error',
      addMonoovaWhitelist: 'monoova/addMonoovaWhitelist'
    }),
    async handleSubmit () {
      this.isLoading = true
      setImmediate(async () => {
        try {
          await this.addMonoovaWhitelist({
            accountId: this.monoovaAccountId,
            bsb: this.bsb,
            accountName: this.accountName,
            accountNumber: this.accountNumber
          })
          await this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isLoading = false
        }
      })
    }
  }
}
