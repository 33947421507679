import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  type: Joi.string().valid('FixedCommission'),
  value: Joi.string().required()
})

export default class FixedCommissionModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new FixedCommissionModel({
      type: 'FixedCommission',
      value: data.value
    })
  }
}
