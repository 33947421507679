import { mapActions } from 'vuex'
import { BlockedAddressOperationType } from 'src/dictionaries'
export default {
  props: {
    walletAddress: String
  },
  data () {
    return {
      items: [],
      isLoading: false,
      index: -1
    }
  },
  async mounted () {
    const operationDetails = await Promise.all(
      Object.values(BlockedAddressOperationType)
        .map(async e => {
          const status = await this.loadOperationTypeStatus(e)
          return {
            ...e,
            ...status
          }
        })
    )
    this.items = operationDetails
  },
  methods: {
    ...mapActions({
      fetchPlasmaAddressOperationStatus: 'addressOperations/fetchPlasmaAddressOperationStatus',
      fetchBackendAddressOperationStatus: 'addressOperations/fetchBackendAddressOperationStatus',
      blockPlasmaAddressOperation: 'addressOperations/blockPlasmaAddressOperation',
      unblockPlasmaAddressOperation: 'addressOperations/unblockPlasmaAddressOperation',
      blockExchangeAddressOperation: 'addressOperations/blockExchangeAddressOperation',
      unblockExchangeAddressOperation: 'addressOperations/unblockExchangeAddressOperation'
    }),
    async loadOperationTypeStatus (blockedAddressOperationType) {
      if (blockedAddressOperationType.service === 'PLASMA') {
        return this.fetchPlasmaAddressOperationStatus({
          address: this.walletAddress,
          operationType: blockedAddressOperationType.value
        })
      } else if (blockedAddressOperationType.service === 'EXCHANGE') {
        return this.fetchBackendAddressOperationStatus({
          address: this.walletAddress,
          operationType: blockedAddressOperationType.value
        })
      }
    },
    async handleBlock (item) {
      try {
        this.isLoading = true
        let res
        if (item.service === 'PLASMA') {
          res = await this.blockPlasmaAddressOperation({
            address: this.walletAddress,
            operationType: item.value
          })
        } else if (item.service === 'EXCHANGE') {
          res = await this.blockExchangeAddressOperation({
            address: this.walletAddress,
            operationType: item.value
          })
        }
        const found = this.items.find(e => e.value === item.value)
        found.allow = res.allow
      } finally {
        this.isLoading = false
      }
    },
    async handleUnblock (item) {
      try {
        this.isLoading = true
        let res
        if (item.service === 'PLASMA') {
          res = await this.unblockPlasmaAddressOperation({
            address: this.walletAddress,
            operationType: item.value
          })
        } else if (item.service === 'EXCHANGE') {
          res = await this.unblockExchangeAddressOperation({
            address: this.walletAddress,
            operationType: item.value
          })
        }
        const found = this.items.find(e => e.value === item.value)
        found.allow = res.allow
      } finally {
        this.isLoading = false
      }
    }
  }
}
