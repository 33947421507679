import fileSaver from 'file-saver'
import { ImageModel, AttachmentModel } from 'src/models'
import { PROFILE, PASSPORT, withAuthorization } from 'src/remotes'

export default () => ({
  namespaced: true,
  state: {},
  actions: {
    async uploadImage ({ dispatch }, { image }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const formData = new FormData()
      formData.append('image', image, image.name)
      const { data } = await PROFILE.post('media/image/upload', formData, withAuthorization(token))
      return new ImageModel(data)
    },
    async uploadFile ({ dispatch }, { file }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const formData = new FormData()
      formData.append('file', file, file.name)
      const { data } = await PROFILE.post('media/file/upload', formData, withAuthorization(token))
      return AttachmentModel.fromJson(data)
    },
    async openFile ({ dispatch }, { id, name }) {
      const token = await dispatch('passport/requireToken', null, { root: true })

      const request = {
        open: 1
      }

      const pathname = new URL(PROFILE.defaults.baseURL).pathname
      const urlSuffix = `/media/file/${id}/manager`

      const acknowledgeRequest = {
        scope: 'audt.to',
        grants: null,
        method: 'GET',
        baseURL: null,
        path: pathname + urlSuffix,
        query: request
      }

      const { data: { ackToken } } = await PASSPORT.post('/api/v1/security/acknowledge', acknowledgeRequest, withAuthorization(token))

      const url = `${PROFILE.defaults.baseURL}/media/file/${id}/manager?open=1&ackToken=${ackToken}`
      window.open(url, '_blank')
    },
    async registrateFile ({ dispatch }, { id }) {
      const ackToken = await dispatch('passport/requireAckToken', {}, { root: true })
      const url = `${PROFILE.defaults.baseURL}/media/file/${id}?open=1&ackToken=${ackToken}`
      return url
    },
    async downloadFile ({ dispatch }, { id, name }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const response = await PROFILE.get(`media/file/${id}`, withAuthorization(token, {
        responseType: 'arraybuffer'
      }))
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      fileSaver.saveAs(blob, name)
    },
    async downloadResourceAsFile ({ dispatch }, { remote, name, path, params }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const searchParams = params == null
        ? null
        : new URLSearchParams(params)
      const resource = searchParams == null
        ? path
        : `${path}?${searchParams.toString()}`
      const response = await remote.get(resource, withAuthorization(token, {
        responseType: 'arraybuffer'
      }))
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      fileSaver.saveAs(blob, name)
    }
  }
})
