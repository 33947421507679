/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'australia': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M7.015 27.63c3.318-.01 4.07-2.782 7.82-3.76.723-.187 3.139-.713 5.526.189 3.26 1.231 2.932 3.816 6.071 5.545 2.256 1.24 5.63 1.673 8.195.432 3.665-1.786 4.737-6.57 4.276-9.915-.545-3.985-3.139-4.981-6.033-10.686a33.072 33.072 0 01-2.914-8.355 51.1 51.1 0 00-1.72 4.37 47.07 47.07 0 00-.733 2.34A37.458 37.458 0 0123.2 4.342c-2.33-2.161-3.195-3.496-4.38-3.327-1.419.207-1.438 2.303-3.514 2.895-1.175.329-1.542-.226-2.585 0-2.18.47-2.077 3.224-4.239 5.855-2.847 3.459-6.014 2.322-7.133 5.113-.752 1.889-.244 4.746 1.25 6.607 1.062 1.315 2.594 2.114 2.434 3.411-.103.855-.864 1.25-.667 1.814.244.686 1.663.93 2.65.921zM28.34 34.087c.253-.14.986.968 2.471 1.062 1.579.094 2.453-1.053 2.951-.808.808.395-.216 4.116-1.908 4.276-1.917.17-3.994-4.267-3.515-4.53z" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
