import moment from 'moment'
import BigNumber from 'bignumber.js'
import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import { ProfileIdentityModel } from 'src/models'
import { PurchasesDetailsModal, LogsModal } from 'src/modals'
import { NumberUtils } from 'src/utils'

export default {
  metaInfo: {
    title: 'Purchases'
  },
  components: {
    ...components,
    ...micros
  },
  computed: {
    ...mapGetters({
      purchases: 'purchases/items',
      isLoaded: 'purchases/isLoaded',
      isLoading: 'purchases/isLoading',
      actualDate: 'purchases/actualDate',
      updatesCount: 'purchases/updatesCount',
      activePage: 'purchases/activePage',
      numberOfElements: 'purchases/numberOfElements',
      totalPages: 'purchases/totalPages',
      currencies: 'currencies/currencies',
      getFiatCurrencyById: 'currencies/getFiatCurrencyById',
      getScoutTransactionReference: 'scout/getScoutTransactionReference',
      hasDepositsViewAccess: 'grants/hasDepositsViewAccess',
      hasProfilesViewAccess: 'grants/hasProfilesViewAccess',
      hasDepositsActionAccess: 'grants/hasDepositsActionAccess'
    }),
    stateOptions () {
      return [
        { value: 'PENDING', label: 'Pending' },
        { value: 'FAILED', label: 'Failed' },
        { value: 'ACCEPTED', label: 'Accepted' },
        { value: 'CANCELLED', label: 'Cancelled' },
        { value: 'REJECTED', label: 'Rejected' },
        { value: 'CONFIRMED', label: 'Confirmed' },
        { value: 'COMPLETE', label: 'Complete' }
      ]
    },
    typeOptions () {
      return [
        { value: 'DepositExternal', label: 'Crypto' },
        { value: 'DepositFaucet', label: 'Faucet' },
        { value: 'DepositManual', label: 'Manual' },
        { value: 'AdvCashDepositRequest', label: 'AdvCash' },
        { value: 'GeoPayDepositRequest', label: 'GeoPay' },
        { value: 'MonoovaDepositOperation', label: 'Monoova' },
        { value: 'DepositItez', label: 'Itez' },
        { value: 'DepositGamePadBot', label: 'GamePadBot' },
        { value: 'ApiDeposit', label: 'ApiDeposit' },
        { value: 'PlasmaTransferInboundOperation', label: 'Internal deposit' }
      ]
    },
    symbolOptions () {
      const currencies = this.currencies
      return !currencies.isLoaded
        ? []
        : this.currencies.value.map(currency => ({
          value: currency.currency.symbol,
          label: currency.currency.name
        }))
    },
    items () {
      return this.purchases.map((purchase, index) => ({
        index,
        purchase,
        title: this.calcTitle(purchase),
        txLink: this.getScoutTransactionReference(purchase.token, purchase.tx, purchase.network),
        identities: (purchase.identities || []).map(
          id => new ProfileIdentityModel({
            type: 'passport',
            id
          })
        )
      }))
    },
    passportIds () {
      const set = new Set()
      const items = this.items || []
      for (const { purchase } of items) {
        for (const passportId of (purchase.identities || [])) {
          set.add(passportId)
        }
      }
      return [...set.values()]
    }
  },
  watch: {
    passportIds: {
      immediate: true,
      handler () {
        if (!this.hasProfilesViewAccess) {
          return
        }

        this.reloadIdentities({
          identities: this.passportIds.map(
            id => new ProfileIdentityModel({
              type: 'passport',
              id
            })
          )
        })
      }
    }
  },
  data () {
    return {
      filters: {
        state: arrayOfQueryParam(this.$route.query.state) || [],
        type: arrayOfQueryParam(this.$route.query.type) || [],
        symbol: arrayOfQueryParam(this.$route.query.symbol) || []
      },
      visibleCount: 6,
      isTablet: window.innerWidth <= 1000
    }
  },
  async created () {
    if (!this.hasDepositsViewAccess) {
      return
    }

    await this.init()
    await this.handleRefresh()
  },
  async beforeRouteUpdate (to, from, next) {
    if (!this.hasDepositsViewAccess) {
      return
    }

    Object.assign(this.filters, {
      state: arrayOfQueryParam(to.query.state) || [],
      type: arrayOfQueryParam(to.query.type) || [],
      symbol: arrayOfQueryParam(to.query.symbol) || []
    })
    await this.handleRefresh() // just trigger, do not await
    next()
  },
  methods: {
    moment,
    ...mapActions({
      loadMorePurchases: 'purchases/loadMore',
      reloadIdentities: 'identities/reload',
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast'
    }),
    async init () {
      await this.handleState('PENDING')
      this.filters = {
        state: ['PENDING'] || arrayOfQueryParam(this.$route.query.state)
      }
    },
    calcTitle (purchase) {
      switch (purchase.type || '') {
        case 'DEPOSIT_GEO_PAY': return 'GeoPay'
        case 'DEPOSIT_EXTERNAL': return 'Crypto'
        case 'DEPOSIT_ADV_CASH': return 'AdvCash'
        case 'DEPOSIT_MONOOVA': return 'Monoova'
        case 'DEPOSIT_MANUAL': return 'Manual'
        case 'DEPOSIT_ITEZ': return 'Itez'
        case 'API_DEPOSIT': return 'ApiDeposit'
        case 'INTERNAL_DEPOSIT': return 'Internal deposit'
        default: return purchase.type
      }
    },
    getFiatCurrencyTitle (item) {
      const purchase = item.purchase
      if (!purchase.fiatCurrencyId) {
        return null
      }
      const fiatCurrency = this.getFiatCurrencyById(purchase.fiatCurrencyId)
      if (!fiatCurrency) {
        return null
      }
      return fiatCurrency
    },
    getFiatAmountString (item) {
      const purchase = item.purchase
      const fiatCurrency = this.getFiatCurrencyTitle(item)
      if (!purchase.exchangeRate || !fiatCurrency) {
        return null
      }
      const fiatAmount = new BigNumber(this.getAmount(purchase)).times(purchase.exchangeRate).toFixed(2)
      return `(${fiatAmount} ${fiatCurrency.title})`
    },
    async handleState (state) {
      this.filters.state = state
      this.$router.replace({ // triggers update on this component (see beforeRouteUpdate)
        query: {
          state: state === '*'
            ? undefined
            : state,
          type: this.filters.type,
          symbol: this.filters.symbol
        }
      })
    },
    async handleType (type) {
      this.filters.type = type
      this.$router.replace({ // triggers update on this component (see beforeRouteUpdate)
        query: {
          state: this.filters.state,
          type: type === '*'
            ? undefined
            : type,
          symbol: this.filters.symbol
        }
      })
    },
    async handleSymbol (symbol) {
      this.filters.symbol = symbol
      this.$router.replace({ // triggers update on this component (see beforeRouteUpdate)
        query: {
          state: this.filters.state,
          type: this.filters.type,
          symbol: symbol === '*'
            ? undefined
            : symbol
        }
      })
    },
    async handleRefresh () {
      await this.loadMorePurchases({
        reset: true,
        filters: this.filters
      })
    },
    async handleLoadMore () {
      await this.loadMorePurchases({
        filters: this.filters
      })
    },
    async handleActivePage (value) {
      this.loadMorePurchases({
        filters: this.filters,
        page: {
          activePage: value,
          numberOfElements: this.numberOfElements
        }
      })
    },
    async handleDetails (item) {
      this.openModal({
        factory: () => PurchasesDetailsModal,
        data: {
          item
        }
      })
    },
    getAmount (operation) {
      if (operation.realAmount) {
        return NumberUtils.getNumber(new BigNumber(operation.realAmount).plus(operation.realCommissionAmount))
      } else {
        return NumberUtils.getNumber(new BigNumber(operation.amount).plus(operation.commissionAmount))
      }
    },
    showCrystalData (item) {
      const rows = JSON.stringify(item.purchase.crystalData, null, 2).split('\n')
      this.openModal({
        factory: () => LogsModal,
        data: {
          rows: rows
        }
      })
    }
  }
}

function arrayOfQueryParam (value) {
  return value == null
    ? null
    : (Array.isArray(value) ? value : [value])
}
