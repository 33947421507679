import Joi from 'joi'
import BigNumber from 'bignumber.js'
import { omitBy, isUndefined } from 'lodash'
import { AbstractModel } from 'src/models/core'
import TokenDAOModel from './TokenDAOModel'

export const schemaFactory = () => ({
  token: Joi.object().type(TokenDAOModel).required(),
  isLoading: Joi.boolean().required(),
  isLoaded: Joi.boolean().required(),
  totalSupply: Joi.object().type(BigNumber).allow(null)
})

export default class EthereumStatsModel extends AbstractModel {
  constructor (data, options) {
    super(Object.assign({
      isLoading: true,
      isLoaded: false,
      totalSupply: null
    }, data), schemaFactory(), options)
  }

  get totalSupplyAmount () {
    return this.token.dao.removeDecimals(this.totalSupply)
  }

  loaded ({ totalSupply }) {
    return Object.assign(this, omitBy({
      isLoaded: true,
      isLoading: false,
      totalSupply
    }, isUndefined))
  }

  loading () {
    return Object.assign(this, {
      isLoading: true
    })
  }
}
