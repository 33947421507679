import { LaxMixinFactory } from 'src/mixins'

export default {
  mixins: [
    LaxMixinFactory(function () {
      const { top } = this.$refs.blocks.getBoundingClientRect()
      const { scrollY } = window
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    })
  ],
  data () {
    return {
      startScrollOffset: 0,
      endScrollOffset: 0,
      titles: [
        {
          id: 1,
          class: 'second-title',
          animateClass: 'slideInDown',
          text: 'Stablecoin linked to Australian dollar',
          offset: 10,
          start: 0,
          end: 120,
          distance: -440,
          tag: 'h1'
        },
        {
          id: 2,
          class: 'first-title',
          animateClass: 'bounceIn',
          text: 'Introducing AUD Token',
          offset: 0,
          start: 0,
          end: 130,
          distance: -120,
          tag: 'h2'
        },
        {
          id: 3,
          class: 'third-title',
          animateClass: 'slideInDown',
          text: 'The stability and trust of traditional currency with the openness and freedom of cryptocurrency',
          offset: 3,
          start: 0,
          end: 497,
          distance: -500,
          tag: 'h3'
        }
      ]
    }
  },
  computed: {
    items () {
      return this.titles.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${item.start} 1, ${item.end + item.offset} 0`,
          dataLaxTranslateY: `${item.start} 0, ${item.end + item.offset * 10} ${item.distance}px`
        })
      )
    }
  }
}
