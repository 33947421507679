import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  accountName: Joi.string().required(),
  accountNumber: Joi.string().required(),
  bankName: Joi.string().required(),
  bsb: Joi.string().required(),
  payId: Joi.string().allow([null, '']),
  project: Joi.string().required(),
  id: Joi.string().required()
})

export default class BankModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }

    return new BankModel({
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      bankName: data.bankName,
      bsb: data.bsb,
      payId: data.payId,
      project: data.project,
      id: data.id
    })
  }
}
