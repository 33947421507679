import {mapActions, mapGetters, mapState} from 'vuex'
import * as directives from 'src/directives'
import * as components from 'src/components'

import VerificationLevel1 from './VerificationLevel1/VerificationLevel1.vue'
import VerificationLevel2 from './VerificationLevel2/VerificationLevel2.vue'
import VerificationLevel3 from './VerificationLevel3/VerificationLevel3.vue'
import VerificationLevel4 from './VerificationLevel4/VerificationLevel4.vue'
import PlatformSelection from './PlatformSelection/PlatformSelection.vue'
import VerificationRapidId from './VerificationRapidId/VerificationRapidId.vue'
import VerificationProgress from './VerificationProgress/VerificationProgress.vue'

export default {
  metaInfo: {
    title: 'Verification'
  },
  directives: {
    ...directives
  },
  components: {
    ...components,
    VerificationLevel1,
    VerificationLevel2,
    VerificationLevel3,
    VerificationLevel4,
    VerificationProgress,
    PlatformSelection,
    VerificationRapidId
  },
  async created () {
    await this.updateSteps()

    if (this.profile && this.profile.kycLevel < 4) {
      this.goStep(this.profile.kycLevel)
    } else {
      this.goStep(3)
    }
  },
  data () {
    return {
      currentTab: 0,
      mobileMenuToggle: false,
      isTxFlowStart: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      profileTab: 'myProfileKyc/tab',
      isLoading: 'myProfileKyc/isLoading',
      config: 'config/config'
    }),
    ...mapState({
      levels: state => state.myProfileKyc.levels
    }),
    tabData () {
      const levels = this.levels

      return [
        {
          id: 1,
          disabled: false,
          title: 'Phone and Email',
          desc: [
            {text: 'Up to 1 BTC daily'}
          ],
          status: levels.firstLevel
        },
        {
          id: 2,
          disabled: levels.firstLevel !== 'validated' && !this.profile.isRapidIDComplete,
          title: 'Personal Info',
          desc: [
            {
              text: 'Up to 5 BTC daily'
            }
          ],
          status: levels.level2
        },
        {
          id: 3,
          disabled: levels.level2 !== 'validated' && !this.profile.isRapidIDComplete,
          title: 'Residential Address',
          desc: [
            {
              text: 'Up to 10 BTC daily'
            }
          ],
          status: levels.level3
        },
        {
          id: 4,
          disabled: levels.level3 !== 'validated' && !this.profile.isRapidIDComplete,
          title: 'Documents',
          desc: [
            {
              text: 'Up to 50 BTC daily'
            },
            {
              text: 'Up to $20 000 daily'
            }
          ],
          status: levels.level4
        }
      ]
    },
    isRapidIdEnabled () {
      return this.config.rapidID && this.config.rapidID.enabled
    },
    getProgressValue () {
      if (this.profile.isRapidIDComplete) {
        return this.profile.kycLevel
      }

      if (this.isTxFlowStart && this.currentTab === 1) {
        return this.currentTab
      }

      return this.profile && this.profile.kycLevel
    }
  },
  methods: {
    ...mapActions({
      updateSteps: 'myProfileKyc/updateSteps',
      loadProfile: 'myProfileKyc/loadProfile'
    }),
    handleTxFlow () {
      this.isTxFlowStart = true
      this.currentTab = 1
    },
    goStep (idx) {
      if (this.isRapidIdEnabled && !this.isTxFlowStart && idx === 1) {
        if (this.profile.isRapidIDPossible(this.config.rapidID.allowedPrefixes)) {
          this.currentTab = 1.5
          return
        }
      }

      if (!this.tabData[idx].disabled) {
        this.currentTab = idx
      }
    }
  }
}
