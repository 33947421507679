import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { get as getProperty, findKey } from 'lodash'
import * as components from 'src/components'
import uniqid from 'uniqid'

// eslint-disable-next-line
const DOC_TYPES = {
  'Passport': 'PASSPORT',
  'Address': 'PROOF_OF_ADDRESS',
  'Driver`s license': 'DRIVER_LICENSE',
  'Identity card': 'IDENTITY_CARD'
}

export default {
  components: {
    ...components
  },
  props: {
    submitTitle: String
  },
  async created () {
  },
  data () {
    return {
      isBusy: false,
      documents: [],
      addressDocuments: [],
      form: {
        attachments: []
      },
      documentType: 'Passport',
      selfieType: 'Passport',
      avatarLoaded: true,
      idLoaded: true,
      addressLoaded: true,
      imageCapture: null,
      track: null,
      avatar: null,
      cameraOn: false,
      photoTaked: false,
      canvas: document.querySelector('canvas'),
      image: document.querySelector('#snap'),
      video: null,
      activeUpload: '',
      avatarEmpty: false,
      documentsEmpty: false,
      addressEmpty: false,
      docTypes: [
        'Passport',
        'Driver`s license',
        'Identity card'
      ]
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      profileDocs: 'myProfileKyc/documents',
      levels: 'myProfileKyc/levels'
    }),
    isConfirmed () {
      if (this.profile && this.profile.documents) {
        if (this.profile.documents.status !== 'REJECTED') {
          return true
        }
      } else {
        return false
      }
    },
    avatarErrors () {
      if (this.$v.avatar.$error) {
        return 'Selfie required'
      }
    },
    documentsErrors () {
      if (this.$v.documents.$error) {
        return 'Documents required'
      }
    },
    addressDocErrors () {
      if (this.$v.addressDocuments.$error) {
        return 'Address documents required'
      }
    }
  },
  watch: {
    profile: {
      handler () {
        this.fillForm()
      }
    }
  },
  validations: {
    documents: { required },
    addressDocuments: { required },
    avatar: { required }
  },
  methods: {
    ...mapActions({
      uploadFile: 'media/uploadFile',
      openFile: 'media/openFile',
      updateDocuments: 'myProfileKyc/updateDocuments'
    }),
    handleUpload (e) {
      if (!e.target.id) {
        this.activeUpload = e.target.parentElement.id
      } else {
        this.activeUpload = e.target.id
      }
      this.$refs['uploader-input'].click()
    },
    handleDownload (attachment) {
      this.openFile({
        id: attachment.id,
        name: attachment.name
      })
    },
    fillForm () {
      this.avatar = getProperty(this.profile, 'documents.selfie', [])
      this.documents = getProperty(this.profile, 'documents.attachments', []).filter(el => el.type === 'PASSPORT' || el.type === 'DRIVER_LICENSE' || el.type === 'IDENTITY_CARD').slice(0, 2)
      this.addressDocuments = getProperty(this.profile, 'documents.attachments', []).filter(el => el.type === 'PROOF_OF_ADDRESS').slice(0, 2)
      this.form.attachments = this.form.attachments.concat(this.documents, this.addressDocuments).map(el => ({
        type: el.type,
        fileId: el.file ? el.file.id : ''
      }))
      this.documentType = findKey(DOC_TYPES, e => e === getProperty(this.profile, 'documents.attachments[0].type', 'PASSPORT'))
    },
    async handleChange (e) {
      switch (this.activeUpload) {
        case 'avatar':
          this.avatarLoaded = false
          let file = e.target.files[0]
          this.avatar = await this.uploadFile({ file })
          this.avatarLoaded = true
          this.$refs['uploader-input'].value = ''
          return
        case 'doc':
          this.idLoaded = false
          let fileDoc = await this.uploadFile({ file: e.target.files[0] })
          this.idLoaded = true
          this.documents.push({
            file: fileDoc,
            type: DOC_TYPES[this.documentType]
          })
          this.$refs['uploader-input'].value = ''
          return
        case 'address':
          this.addressLoaded = false
          let fileAddress = await this.uploadFile({ file: e.target.files[0] })
          this.addressLoaded = true
          this.addressDocuments.push({
            file: fileAddress,
            type: DOC_TYPES['Address']
          })
          this.$refs['uploader-input'].value = ''
      }
    },
    deleteAvatar () {
      this.avatar = null
    },
    deleteDoc (id) {
      let docIndex = this.documents.findIndex(el => el.file.id === id)
      this.documents.splice(docIndex, 1)
    },
    deleteAddressDoc (id) {
      let docIndex = this.addressDocuments.findIndex(el => el.file.id === id)
      this.addressDocuments.splice(docIndex, 1)
    },
    hasGetUserMedia () {
      return !!(navigator.mediaDevices &&
        navigator.mediaDevices.getUserMedia)
    },
    updateCam (stream) {
      document.querySelector('video').src = stream.url
    },
    async selfie (type) {
      this.stopCamera()
      this.selfieType = type
      this.cameraOn = true
      this.photoTaked = false
      if (this.hasGetUserMedia()) {
        const constraints = {
          video: { width: { min: 300 }, height: { min: 300 } }
        }
        const img = document.querySelector('#screenshot img')
        const canvas = document.createElement('canvas')
        img.classList.remove('index')
        canvas.classList.remove('index')

        const video = document.querySelector('#screenshot video')

        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints)
          this.track = stream
          video.srcObject = stream
          this.video = video
        } catch (e) {
          this.cameraOn = false
          this.photoTaked = true
        }
      } else {
        alert('getUserMedia() is not supported by your browser')
      }
    },
    async onTakePhotoButtonClick () {
      this.photoTaked = true
      const video = document.querySelector('#screenshot video')
      const img = document.querySelector('#screenshot img')
      const canvas = document.createElement('canvas')

      img.removeAttribute('src')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas.getContext('2d').drawImage(video, 0, 0)
      // Other browsers will fall back to image/png
      img.src = canvas.toDataURL('image/png')
      canvas.classList.add('index')
      img.classList.add('index')

      this.track.getTracks().forEach(function (track) {
        track.stop()
      })

      let blob = this.dataURLToBlob(img.src)
      if (this.selfieType === 'avatar') {
        let file = this.toFile(blob, 'selfie')
        this.avatarLoaded = false
        this.avatar = await this.uploadFile({ file })
        this.avatarLoaded = true
      } else if (this.selfieType === 'addressDoc') {
        this.addressLoaded = false
        let file = this.toFile(blob, 'addressDoc')
        let doc = await this.uploadFile({ file })
        this.addressLoaded = true
        if (this.addressDocuments.length < 2) {
          this.addressDocuments.push({
            type: DOC_TYPES['Address'],
            file: doc
          })
        }
      } else {
        this.idLoaded = false
        let file = this.toFile(blob, DOC_TYPES[this.documentType])
        let doc = await this.uploadFile({ file })
        this.idLoaded = true
        if (this.documents.length < 2) {
          this.documents.push({
            type: DOC_TYPES[this.documentType],
            file: doc
          })
        }
      }
    },
    stopCamera () {
      const img = document.querySelector('#screenshot img')
      img.removeAttribute('src')

      if (this.track) {
        this.track.getTracks().forEach(function (track) {
          track.stop()
        })
      }

      this.cameraOn = false
      this.photoTaked = false
    },
    dataURLToBlob (dataURL) {
      let BASE64_MARKER = ';base64,'
      if (dataURL.indexOf(BASE64_MARKER) === -1) {
        let parts = dataURL.split(',')
        let contentType = parts[0].split(':')[1]
        let raw = decodeURIComponent(parts[1])

        return new Blob([raw], {type: contentType})
      }

      let parts = dataURL.split(BASE64_MARKER)
      let contentType = parts[0].split(':')[1]
      let raw = window.atob(parts[1])
      let rawLength = raw.length

      let uInt8Array = new Uint8Array(rawLength)

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      return new Blob([uInt8Array], {type: contentType})
    },
    toFile (blob, name = 'selfie') {
      blob.lastModifiedDate = new Date()
      blob.name = `${name}-${uniqid()}.png`
      return blob
    },
    async handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.form.attachments = this.documents.concat(this.addressDocuments)
        let data = {
          selfie: this.avatar.id,
          attachments: this.form.attachments.map(el => ({
            fileId: el.file.id,
            type: el.type
          }))
        }
        this.updateDocuments(data)
        this.stopCamera()
      }
    }
  }
}
