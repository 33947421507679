import { mapState } from 'vuex'
import * as components from 'src/components'
import * as partials from 'src/partials'

export default {
  components: {
    ...components,
    ...partials
  },
  computed: {
    ...mapState({
      currencySymbol: state => state.route.params.currency
    })
  }
}
