import {mapActions, mapGetters} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isAddInProgress: false,
      form: {
        symbol: '',
        title: '',
        rateServiceSymbol: '',
        icon: ''
      }
    }
  },
  validations: {
    form: {
      symbol: {
        required,
        symbolCheck (value) {
          return !this.fiatCurrencies.value.map(e => e.symbol).includes(value)
        }
      },
      title: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      fiatCurrencies: 'currencies/fiatCurrencies'
    }),
    symbolErrors () {
      if (this.$v.form.symbol.$dirty) {
        if (!this.$v.form.symbol.required) {
          return [
            'Symbol is a required field'
          ]
        }
        if (!this.$v.form.symbol.symbolCheck) {
          return [
            'This symbol already exists'
          ]
        }
      }
      return []
    },
    titleErrors () {
      if (this.$v.form.title.$dirty) {
        if (!this.$v.form.title.required) {
          return [
            'Title is a required field'
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createFiatCurrency: 'currencies/createFiatCurrency'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createFiatCurrency({
            ...this.form
          })
          await this.handleToast({
            message: 'Currency added'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot add currency'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
