import { mapActions, mapGetters, mapState } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import BasicMonoovaInformation from './BasicMonoovaInformation/BasicMonoovaInformation.vue'
import MonoovaWhitelist from './MonoovaWhitelist/MonoovaWhitelist.vue'
import MonoovaBank from './MonoovaBank/MonoovaBank.vue'

export default {
  metaInfo: {
    title: 'Monoova'
  },
  components: {
    ...components,
    ...micros,
    BasicMonoovaInformation,
    MonoovaWhitelist,
    MonoovaBank
  },
  data () {
    return {
      filters: {
        address: null
      },
      isMonoovaAccountLoading: false,
      isMonoovaWhitelistLoading: false,
      isMobile: window.innerWidth <= 1024
    }
  },
  async created () {
    await this.init()

    if (this.hasProfilesViewAccess) {
      await this.loadAccountData()
      await this.loadWhitelistData()
    }
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  computed: {
    ...mapState({
      profileId: state => state.route.params.id
    }),
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData',
      monoovaAccount: 'monoova/account',
      hasProfilesViewAccess: 'grants/hasProfilesViewAccess'
    }),
    monoovaAccountId () {
      const monoova = this.monoovaAccount && this.monoovaAccount.value
      return monoova && monoova.id
    },
    ssoUserId () {
      return this.profileData.value.profile.ssoUserId
    },
    profileData () {
      return this.getProfileData(this.profileId)
    },
    wallets () {
      return this.profileData.value.addresses.map(e => e.address) || []
    }
  },
  methods: {
    ...mapActions({
      loadMonoovaWhitelist: 'monoova/loadMonoovaWhitelist',
      loadAccount: 'monoova/loadAccount',
      assignAccount: 'monoova/assignAccount',
      handleConfirmation: 'interaction/confirmation'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1024
    },
    async init () {
      this.filters = {
        address: this.$route.query.address || this.wallets[0]
      }
    },
    async loadAccountData () {
      this.isMonoovaAccountLoading = true
      try {
        await this.loadAccount({
          id: this.ssoUserId,
          plasmaAddress: this.filters.address
        })
      } finally {
        this.isMonoovaAccountLoading = false
      }
    },
    async loadWhitelistData () {
      this.isMonoovaWhitelistLoading = true
      try {
        if (this.monoovaAccountId) {
          await this.loadMonoovaWhitelist({
            accountId: this.monoovaAccountId
          })
        }
      } finally {
        this.isMonoovaWhitelistLoading = false
      }
    },
    async changeAddress (address) {
      await this.loadAccount({
        id: this.ssoUserId,
        plasmaAddress: address
      })
      await this.loadWhitelistData()
    },
    async handleCreate () {
      await this.handleConfirmation({
        handler: async () => {
          await this.assignAccount({
            ssoUserId: this.ssoUserId,
            address: this.filters.address
          })

          await this.loadAccount({
            id: this.ssoUserId,
            plasmaAddress: this.filters.address
          })
          await this.loadWhitelistData()
        }
      })
    }
  }
}
