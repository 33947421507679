import {mapActions, mapGetters} from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    name: String,
    displayName: String,
    description: String
  },
  data () {
    return {
      form: {
        name: '',
        displayName: '',
        description: ''
      }
    }
  },
  created () {
    this.init()
  },
  validations: {
    form: {
      name: {
        required
      },
      displayName: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({}),
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    displayNameErrors () {
      if (this.$v.form.displayName.$dirty) {
        if (!this.$v.form.displayName.required) {
          return [
            'Display Name is a required field'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      addChannel: 'chatChannels/add',
      editChannel: 'chatChannels/update'
    }),
    init () {
      if (this.name) {
        this.form.name = this.name
      }
      if (this.displayName) {
        this.form.displayName = this.displayName
      }
      if (this.description) {
        this.form.description = this.description
      }
    },
    async handleSubmit () {
      if (this.name || this.displayName || this.description) {
        await this.editChannel({
          name: this.form.name,
          displayName: this.form.displayName,
          description: this.form.description
        })
      } else {
        await this.addChannel({
          name: this.form.name,
          displayName: this.form.displayName,
          description: this.form.description
        })
      }
      this.handleClose()
    }
  }
}
