import * as partials from 'src/partials'

export default {
  head: {
    title: 'AUDT stablecoin - digital Australian dollar on the Blockchain'
  },
  components: {
    ...partials
  }
}
