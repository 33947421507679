import { mnemonicToSeedSync } from 'bip39'
import { privateToPublic } from 'ethereumjs-util'
import HDKey from 'ethereumjs-wallet/dist/hdkey'

export const toPrivateKeyHex = ({ privateKey, mnemonic, seed }) => {
  // eslint-disable-next-line
  // console.log('toPrivateKeyHex', { privateKey, mnemonic, seed })
  if (privateKey) {
    return privateKey.indexOf('0x') === 0
      ? privateKey
      : `0x${privateKey}`
  }
  if (seed) {
    const hdWallet = HDKey.fromMasterSeed(seed)
    const w = hdWallet.derivePath(`m/44'/60'/0'/0/0`).getWallet()
    return `0x${w.getPrivateKey().toString('hex')}`
  }
  if (mnemonic) {
    const seed = mnemonicToSeedSync(mnemonic)
    const hdWallet = HDKey.fromMasterSeed(seed)
    const w = hdWallet.derivePath(`m/44'/60'/0'/0/0`).getWallet()
    return `0x${w.getPrivateKey().toString('hex')}`
  }
}

export const privateToPublicKeyHex = privateKey => {
  return `0x${privateToPublic(Buffer.from(privateKey.substring(2), 'hex')).toString('hex')}`
}
