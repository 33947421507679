import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import * as components from 'src/components'
import * as micros from 'src/micros'
import { omitBy, isEmpty } from 'lodash'

export default {
  metaInfo: {
    title: 'Operation'
  },
  components: {
    ...components,
    ...micros
  },
  data () {
    return {
      filters: {
        user: '',
        type: [],
        offset: null,
        limit: null
      }
    }
  },
  computed: {
    ...mapGetters({
      operation: 'system/operation'
    }),
    items () {
      return this.operation
    }
  },
  async created () {
    await this.getOperation()
  },
  methods: {
    moment,
    ...mapActions({
      getOperation: 'system/getOperation'
    }),
    async handleGetOperation () {
      await this.getOperation({
        filters: omitBy(this.filters, isEmpty)
      })
    }
  }
}
