import { mapActions } from 'vuex'
import * as components from 'src/components'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    ...components
  },
  props: {
    ssoUserId: String
  },
  data () {
    return {
      form: {
        discount: null
      },
      isLoading: false
    }
  },
  computed: {
    discountErrors () {
      if (this.$v.form.discount.$dirty) {
        if (!this.$v.form.discount.required) {
          return 'Discount is a required field'
        }
      }
    }
  },
  validations: {
    form: {
      discount: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      addWithdrawalDiscount: 'withdrawalDiscount/addWithdrawalDiscount'
    }),
    async handleSubmit () {
      try {
        this.isLoading = true
        await this.addWithdrawalDiscount({
          discount: Number(this.form.discount),
          ssoUserId: this.ssoUserId
        })
      } finally {
        this.isLoading = false
        this.handleClose()
      }
    },
    close () {
      this.handleClose()
    }
  }
}
