import { isAddress } from 'web3-utils'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isBusy: false,
      form: {
        name: '',
        address: ''
      }
    }
  },
  validations: {
    form: {
      name: { required },
      address: {
        required,
        valid () {
          return isAddress(this.form.address)
        }
      }
    }
  },
  computed: {
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    addressErrors () {
      if (this.$v.form.address.$dirty) {
        if (!this.$v.form.address.required) {
          return [
            'Address is a required field'
          ]
        }
        if (!this.$v.form.address.valid) {
          return [
            'Invalid address'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createWallet: 'wallets/createWallet'
    }),
    handleSubmit () {
      if (this.isBusy) {
        return
      }
      this.isBusy = true
      setImmediate(async () => {
        try {
          const address = (this.form.address.indexOf('0x') === 0)
            ? this.form.address
            : `0x${this.form.address}`
          await this.createWallet({
            type: 'READONLY',
            name: this.form.name,
            options: {
              address: address.toLowerCase()
            }
          })
          await this.handleToast({
            message: 'Wallet added'
          })
          await this.handleClose()
        } catch (e) {
          await this.handleError({
            message: 'Cannot add wallet',
            error: e
          })
        } finally {
          this.isBusy = false
        }
      })
    }
  }
}
