import Joi from 'joi'
import BigNumber from 'bignumber.js'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  reference: Joi.string(),
  idempotencyKey: Joi.string().allow([null, '']),
  identities: Joi.array().items(
    Joi.string()
  ),
  createdAt: Joi.date(),
  type: Joi.string(),
  state: Joi.string(),
  processState: Joi.string().allow([null, '']),
  token: Joi.string(),
  address: Joi.string(),

  amount: Joi.object().type(BigNumber),
  commissionAmount: Joi.object().type(BigNumber).allow(null),
  realAmount: Joi.object().type(BigNumber).allow(null),
  realCommissionAmount: Joi.object().type(BigNumber).allow(null),

  exchangeRate: Joi.string().allow([null, '']),
  realExchangeRate: Joi.string().allow([null, '']),

  fiatCurrencyId: Joi.string().allow([null, '']),

  symbol: Joi.string(),
  tx: Joi.string().allow([null]),

  depositAddress: Joi.string().allow([null]),

  network: Joi.string().allow([null, '']),
  transferHash: Joi.string().allow([null, '']),

  // type specific field
  transactionId: Joi.string().allow([null, '']),

  crystalData: Joi.object()
})

export default class DepositModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.id
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }

    return new DepositModel({
      id: data.id,
      reference: data.reference,
      idempotencyKey: data.idempotencyKey,
      identities: data.identities,
      createdAt: data.createdAt == null ? null : new Date(data.createdAt),
      type: data.type,
      state: data.state,
      processState: data.processState,
      token: data.token,
      address: data.address,
      amount: data.amount == null ? null : new BigNumber(data.amount),
      commissionAmount: data.commissionAmount == null ? null : new BigNumber(data.commissionAmount),
      realAmount: data.realAmount == null ? null : new BigNumber(data.realAmount),
      realCommissionAmount: data.realCommissionAmount == null ? null : new BigNumber(data.realCommissionAmount),
      symbol: data.symbol,
      tx: data.tx,

      fiatCurrencyId: data.fiatCurrencyId,
      exchangeRate: data.exchangeRate,
      realExchangeRate: data.realExchangeRate,

      depositAddress: data.depositAddress,

      network: data.network,

      transferHash: data.transferHash,

      // type specific field
      transactionId: data.transactionId,

      crystalData: data.crystalData
    })
  }
}
