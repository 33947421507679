import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.number(),
  name: Joi.string().allow([null, '']),
  network: Joi.string().allow([null, '']),
  status: Joi.string().allow([null, '']),
  type: Joi.string().allow([null, '']),
  address: Joi.string().allow([null, '']),
  pubKey: Joi.string().allow([null, '']),
  blockchain: Joi.string().allow([null, '']),
  default: Joi.boolean()
})

export default class CustomerWalletModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new CustomerWalletModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
