import Joi from 'joi'
import AbstractModel from './AbstractModel'

export const schemaFactory = (type) => ({
  value: Joi.object().type(type).required()
})

export default class HolderModel extends AbstractModel {
  constructor (type, data, options) {
    super(data, schemaFactory(type), options)
    Object.freeze(this)
  }
}
