import moment from 'moment'
import assert from 'assert'
import AsyncLock from 'async-lock'
import { OperationCanceledError } from 'src/errors'
import { EnterCodeModal } from 'src/modals'
import { PassportUserModel } from 'src/models'

import { PASSPORT, withAuthorization, withAuthorizationAndVerification } from 'src/remotes'
import { REGISTRY } from 'src/services'

const MIN_SECONDS_BEFORE_REFRESH = 20
const MIN_SECONDS_BEFORE_WARN = 40

const LOCK_REQUIRE_TOKEN = 'requireTokenLock'
const requireTokenLock = new AsyncLock({ domainReentrant: true })

export const PASSPORT_UPDATE = 'update'
export const SIGNOUT = 'signout'

export default () => ({
  namespaced: true,
  state () {
    return {
      user: null, // PassportUserModel
      token: null,
      refreshToken: null,
      expirationTime: null,
      grants: null
    }
  },
  mutations: {
    [PASSPORT_UPDATE] (state, { user, token, refreshToken, grants, expirationTime }) {
      if (user != null) {
        state.user = user
      }
      if (token != null) {
        state.token = token
      }
      if (refreshToken != null) {
        state.refreshToken = refreshToken
      }
      if (expirationTime != null) {
        state.expirationTime = expirationTime
      }
      if (grants != null) {
        state.grants = grants
      }
    },
    [SIGNOUT] (state) {
      state.user = null
      state.token = null
      state.refreshToken = null
      state.expirationTime = null
      state.grants = null
    }
  },
  getters: {
    user: state => state.user,
    grants: state => state.grants,
    hasGrant: state => (...options) => {
      if (options.length === 0) {
        return true
      }
      if (state.grants == null) {
        return false
      }
      for (const option of options) {
        for (const grant of state.grants) {
          const optionURL = new URL(option)
          const grantURL = new URL(grant)

          const optionParams = Array.from(optionURL.searchParams)
          const grantParams = Array.from(grantURL.searchParams)

          grantURL.search = null
          optionURL.search = null

          if (optionURL.toString() !== grantURL.toString()) continue

          if (optionParams.length !== 1) continue
          if (grantParams.length !== 1) continue
          if (optionParams[0][0] !== grantParams[0][0]) continue
          return true
        }
      }
      return false
    }
  },
  actions: {
    async requireToken ({ dispatch }) {
      return requireTokenLock.acquire(
        LOCK_REQUIRE_TOKEN,
        async () => {
          const token = await dispatch('accessToken')
          if (token == null) {
            // eslint-disable-next-line
            console.info('[passport] No token found.')
            await dispatch('signout')
            await dispatch('navigation/push', {
              path: '/'
            }, { root: true })
            throw new Error('No token found')
          }
          return token
        }
      )
    },
    async accessToken ({ state, dispatch }) {
      try {
        assert(state.token != null, 'No token provided')
        assert(state.refreshToken != null, 'No refreshToken provided')
        assert(state.expirationTime != null, 'No expirationTime provided')

        const now = moment()

        const diff = moment.unix(state.expirationTime).diff(now, 'seconds')

        if (diff > MIN_SECONDS_BEFORE_REFRESH) {
          if (diff <= MIN_SECONDS_BEFORE_WARN) {
            // eslint-disable-next-line
            console.info(`[passport] Token will expire in ${diff} second(s)`)
          }
          return state.token
        } else {
          // eslint-disable-next-line
          console.info('[passport] Token has expired. Attempt to refresh token.')
        }

        const { data } = await PASSPORT.post('/api/v1/security/refresh', null, withAuthorization(state.refreshToken))

        const {
          user,
          token,
          refreshToken
        } = data

        await dispatch('session', {
          token,
          refreshToken,
          user: PassportUserModel.fromJson(user)
        })
        await dispatch('loadGrants')

        // eslint-disable-next-line
        console.info(`[passport] Token has been refreshed.`)

        return token
      } catch (e) {
        // eslint-disable-next-line
        console.info('[passport] No token found.', e)

        return null
      }
    },
    async exchangeGrantCode ({ rootState, rootGetters, dispatch }, { code }) {
      const authConfig = rootGetters['config/config'].auth
      const { data } = await PASSPORT.post('/api/v1/auth/token', {
        clientId: authConfig.clientId,
        grantCode: code,
        codeVerifier: rootState.auth.oauthCodeVerifier
      })

      const { token, refreshToken, user } = data
      await dispatch('session', { token, refreshToken, user: PassportUserModel.fromJson(user) })
      await dispatch('loadGrants')
    },
    async session ({ commit, dispatch }, { token, refreshToken, user }) {
      const { expirationTime } = await dispatch('decodeToken', { token })
      commit(PASSPORT_UPDATE, {
        user,
        token,
        refreshToken,
        expirationTime
      })
    },
    async loadGrants ({ commit, dispatch }) {
      const token = await dispatch('accessToken')
      const { data } = await PASSPORT.get('/api/v1/security/me/grants', withAuthorization(token))
      commit(PASSPORT_UPDATE, {
        grants: data
      })
    },
    async decodeToken ({ rootGetters }, { token }) {
      const pubKey = rootGetters['config/jwtPubKey']
      const authConfig = rootGetters['config/config'].auth
      const passportClient = REGISTRY.getService('passportClient')
      if (token == null || pubKey == null || passportClient == null) {
        return null
      }
      const data = await passportClient.decodeAndVerifyToken({
        token,
        pubKey,
        clientId: authConfig.clientId
      })

      const { expirationTime } = data
      return { expirationTime }
    },
    async recover ({ commit, dispatch }) {
      const token = await dispatch('accessToken')
      if (token != null) {
        try {
          const [{data}] = await Promise.all([
            PASSPORT.get('/api/v1/security/me', withAuthorization(token)),
            dispatch('loadGrants')
          ])
          commit(PASSPORT_UPDATE, {
            user: PassportUserModel.fromJson(data)
          })
          return
        } catch (e) {
          // eslint-disable-next-line
          console.info('[passport] Cannot restore session', e)
        }
      }

      await dispatch('signout')
    },
    async fetch (_, { token }) {
      const { data } = await PASSPORT.get('/api/v1/security/me', withAuthorization(token))
      return PassportUserModel.fromJson(data)
    },
    async requireAckToken ({ dispatch }, { grants, method, baseURL, path, query, body } = {}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const r = {
        scope: 'audt.to',
        method,
        path,
        query
      }
      const { data: { ackToken } } = await PASSPORT.post('/api/v1/security/acknowledge', r, withAuthorization(token))
      return ackToken
    },
    async withCode ({ dispatch }, { onComplete }) {
      const { result } = await dispatch('requestCode', { onComplete })
      return result
    },
    async ensureCode ({ dispatch }, { onComplete }) {
      await dispatch('modals/close')
      return new Promise((resolve, reject) => {
        dispatch('modals/open', {
          factory: () => EnterCodeModal,
          data: {
            onComplete: async ({ code }) => {
              if (onComplete) {
                const result = await onComplete({ code })
                resolve({ code, result })
              } else {
                resolve({ code })
              }
            },
            onCancel: () => {
              reject(new OperationCanceledError('Cancelled by user'))
            }
          }
        }, { root: true })
      })
    },
    async requestCode ({ state, dispatch }, { onComplete }) {
      if (state.user.hasVerificator()) {
        return dispatch('ensureCode', { onComplete })
      }
      return {
        code: null,
        result: onComplete({ code: null })
      }
    },
    async signout ({ commit }) {
      commit(SIGNOUT, {
        user: null,
        token: null,
        refreshToken: null,
        expirationTime: null,
        grants: null
      })
    },
    async enable2FA ({ state, dispatch }, {secret, code}) {
      const accessToken = await dispatch('requireToken')
      const request = {
        secret,
        otp: code
      }
      const { data } = await PASSPORT.post('/api/v1/security/me/verificators/app', request, withAuthorization(accessToken))
      const { user, token, refreshToken } = data
      await dispatch('session', {token, refreshToken, user: PassportUserModel.fromJson(user)})
      return user
    },
    async disable2FA ({ state, dispatch }, { code }) {
      const accessToken = await dispatch('requireToken')
      const { data } = await PASSPORT.delete('/api/v1/security/me/verificators', withAuthorizationAndVerification(accessToken, code))
      const { user, token, refreshToken } = data
      await dispatch('session', { token, refreshToken, user: PassportUserModel.fromJson(user) })
      return user
    }
  }
})
