import * as components from 'src/components'
import SectionLayout from '../SectionLayout/SectionLayout.vue'

export default {
  components: {
    ...components,
    SectionLayout
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/'
      },
      {
        text: 'Fees',
        disabled: true,
        href: ''
      }
    ],
    data: [
      {
        id: 1,
        title: 'Fee per Fiat Deposit',
        content: [
          {
            id: 11,
            subtitle: 'POLi',
            list: [
              {
                text: 'Minimum Fee $1.00 or 1% of the deposit amount'
              },
              {
                text: 'Maximum Fee $5.00'
              }
            ]
          },
          {
            id: 12,
            subtitle: 'Bank Transfer',
            list: [
              {
                text: 'Minimum Fee $1.00 or 0.1% of the deposit amount'
              },
              {
                text: 'Maximum Fee $1000'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        title: 'Fee per Fiat Withdrawal',
        content: [
          {
            id: 13,
            subtitle: 'Bank Transfer',
            list: [
              {
                text: '0.1% of the withdrawal amount'
              }
            ]
          }
        ]
      }
    ]
  })
}
