import { LoadableMapModel, EthereumBalanceModel } from 'src/models'

const POCKET_BALANCE_LOADED = 'ethereumBalances/loaded'
const POCKET_BALANCE_LOADING = 'ethereumBalances/loading'
const BALANCES_RESET = 'ethereumBalances/reset'

const initialState = () => ({
  table: new LoadableMapModel(EthereumBalanceModel)
})

export default () => ({
  namespaced: true,
  state: initialState(),
  mutations: {
    [POCKET_BALANCE_LOADED]: (state, { pocket, balance }) => {
      const model = state.table.get(pocket.key) || new EthereumBalanceModel({ pocket })
      state.table = state.table.put(pocket.key, model.loaded(balance))
    },
    [POCKET_BALANCE_LOADING]: (state, { pocket }) => {
      const model = state.table.get(pocket.key) || new EthereumBalanceModel({ pocket })
      state.table = state.table.put(pocket.key, model.loading())
    },
    [BALANCES_RESET]: (state) => {
      Object.assign(state, initialState())
    }
  },
  getters: {
    balance: state => pocket => state.table.get(pocket.key) || new EthereumBalanceModel({ pocket })
  },
  actions: {
    async update ({ commit }, { pocket }) {
      commit(POCKET_BALANCE_LOADING, { pocket })
      const balance = await pocket.token.dao.getBalance(pocket.address)
      commit(POCKET_BALANCE_LOADED, { pocket, balance })
    },
    async subscribe ({ dispatch }, { pocket }) {
      const listener = function (data) {
        if ((data.from && data.from.toLowerCase() === pocket.address.toLowerCase()) ||
          (data.to && data.to.toLowerCase() === pocket.address.toLowerCase())) {
          dispatch('update', { pocket })
        }
      }
      pocket.token.dao.on('transfer', listener)
      pocket.token.dao.on('deposit', listener)
      pocket.token.dao.on('withdrawal', listener)
      pocket.token.dao.on('mint', listener)
      pocket.token.dao.on('withdrawal-burn', listener)
      dispatch('update', { pocket })
      return listener
    },
    async unsubscribe (_, { pocket, listener }) {
      pocket.token.dao.removeListener('transfer', listener)
      pocket.token.dao.removeListener('deposit', listener)
      pocket.token.dao.removeListener('withdrawal', listener)
      pocket.token.dao.removeListener('mint', listener)
      pocket.token.dao.removeListener('withdrawal-burn', listener)
    }
  }
})
