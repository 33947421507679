import {mapActions, mapGetters} from 'vuex'
import {MarketEdit} from 'src/modals'
import {OperationCanceledError} from 'src/errors'

export default {
  props: {
    market: Object
  },
  computed: {
    ...mapGetters({
      getCurrency: 'currencies/getCurrency'
    })
  },
  data () {
    return {
      isDeleteInProgress: false
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      handleClose: 'modals/close',
      deleteMarket: 'markets/deleteMarket'
    }),
    async handleEdit (market) {
      this.openModal({
        factory: () => MarketEdit,
        data: {
          market
        }
      })
    },
    async handleDelete (symbol) {
      this.isDeleteInProgress = true
      setImmediate(async () => {
        try {
          await this.deleteMarket({
            symbol: symbol
          })
          await this.handleToast({
            message: 'Market deleted'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot delete market'
            })
          }
        } finally {
          this.isDeleteInProgress = false
        }
      })
    }
  }
}
