import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  destination: Joi.string().allow([null, '']),
  destinationExtra: Joi.string().allow([null, '']),
  from: Joi.string().allow([null, '']),
  metaInfo: Joi.string().allow([null, '']),
  reason: Joi.string().allow([null, '']),
  timestamp: Joi.string().required(),
  token: Joi.string().allow([null, '']),
  value: Joi.string().allow([null, ''])
})

export default class WithdrawalPlasmaModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new WithdrawalPlasmaModel(data, { stripUnknown: true })
  }
}
