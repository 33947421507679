import Proxy from './Proxy.vue'

export default {
  props: {
    toast: Object
  },
  components: {
    Proxy
  },
  data () {
    return {
      isOpen: false
    }
  },
  mounted () {
    setImmediate(() => {
      this.isOpen = true
    })
  },
  watch: {
    isOpen () {
      if (!this.isOpen) {
        setTimeout(() => {
          this.$store.dispatch('toasts/close', this.toast)
        }, 500)
      }
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
    }
  }
}
