import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import { ProfileIdentityModel } from 'src/models'
import moment from 'moment'

export default {
  metaInfo: {
    title: 'Partners Programs'
  },
  components: {
    ...components,
    ...micros
  },
  computed: {
    ...mapGetters({
      isLoaded: 'partnersPrograms/isLoaded',
      isLoading: 'partnersPrograms/isLoading',
      activePage: 'partnersPrograms/activePage',
      limit: 'partnersPrograms/limit',
      count: 'partnersPrograms/count',
      campaigns: 'partnersPrograms/campaigns',
      totalPages: 'partnersPrograms/totalPages',
      getCampaignData: 'partnersPrograms/getCampaignData',
      hasProfilesViewAccess: 'grants/hasProfilesViewAccess'
    }),
    items () {
      return this.campaigns.map((campaigns, index) => ({
        index,
        campaigns,
        open: this.opened[campaigns.id],
        identity: campaigns.userId == null
          ? null
          : new ProfileIdentityModel({
            type: 'passport',
            id: campaigns.userId
          })
      }))
    },
    passportIds () {
      const set = new Set()
      const items = this.items || []
      for (const { identity } of items) {
        set.add(identity.id)
      }
      return [...set.values()]
    }
  },
  async created () {
    await this.loadCampaigns()
    addEventListener('resize', () => {
      this.isMobile = innerWidth <= 768
    })
  },
  beforeDestroy () {
    removeEventListener('resize', () => {})
  },
  watch: {
    passportIds: {
      immediate: true,
      handler () {
        if (!this.hasProfilesViewAccess) {
          return
        }

        this.reloadIdentities({
          identities: this.passportIds.map(
            id => new ProfileIdentityModel({
              type: 'passport',
              id
            })
          )
        })
      }
    }
  },
  data () {
    return {
      visibleCount: 10,
      opened: {},
      sort: {
        col: '',
        desc: true
      },
      isMobile: window.innerWidth <= 768
    }
  },
  methods: {
    moment,
    ...mapActions({
      reloadIdentities: 'identities/reload',
      loadCampaigns: 'partnersPrograms/loadCampaigns',
      loadMoreCampaigns: 'partnersPrograms/loadMore',
      deactivateCampaign: 'partnersPrograms/deactivateCampaign',
      prolongCampaign: 'partnersPrograms/prolongCampaign',
      activateCampaign: 'partnersPrograms/activateCampaign'
    }),
    async handleToggle (item) {
      if (this.opened[item.campaigns.id]) {
        const newOpened = {
          ...this.opened
        }
        delete newOpened[item.campaigns.id]
        this.opened = newOpened
      } else {
        this.opened = {
          ...this.opened,
          [item.campaigns.id]: true
        }
      }
    },
    isOpen (item) {
      return this.opened.has(item.campaigns.id)
    },
    async handleRefresh () {
      await this.loadCampaigns({
        reset: true
      })
    },
    async handleActivePage (value) {
      this.loadMoreCampaigns({
        page: {
          activePage: value,
          count: this.count
        }
      })
    },
    async handleDeactivate (item) {
      await this.deactivateCampaign({
        id: item.campaigns.id
      })
      await this.handleRefresh()
    },
    async handleProlong (item) {
      await this.prolongCampaign({
        id: item.campaigns.id
      })
      await this.handleRefresh()
    },
    async handleActivate (item) {
      await this.activateCampaign({
        id: item.campaigns.id
      })
      await this.handleRefresh()
    }
  }
}
