export default {
  notifications: {
    availables: {
      admin: {
        purchases: 'purchase-admin',
        withdrawal: 'withdraw-admin',
        profiles: 'profile-update-admin',
        dailyStats: 'daily-stats-admin'
      },
      user: {
        // Password: 'passwordChange',
        // 'Sign In': 'signIn',
        'Redemption Approved': 'redemptionApproved',
        'Redemption Rejected': 'redemptionRejected',
        'Purchase Approved': 'purchaseApproved',
        'Purchase Rejected': 'purchaseRejected',
        'Profile Update Approved': 'profileUpdateApproved',
        'Profile Update Rejected': 'profileUpdateRejected',
        Purchase: 'purchase'
        // Redemption: 'redemption'
      }
    },
    availableProviders: ['websocket'],
    availableNotificationsPageProviders: ['email']
  },
  passport: {
    domain: {
      audt: 'audt.to'
    }
  }
}
