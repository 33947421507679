import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  totalCount: Joi.number(),
  countByKycLevels: Joi.array().items(Joi.object().keys({
    kycType: Joi.string().required(),
    count: Joi.number()
  })),
  countByBirthYear: Joi.array().items(Joi.object().keys({
    year: Joi.number(),
    count: Joi.number()
  })),
  countByCountry: Joi.array().items(Joi.object().keys({
    country: Joi.string().allow([null, '']),
    count: Joi.number()
  }))
})

export default class UsersStatsModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new UsersStatsModel(data, { stripUnknown: true })
  }
}
