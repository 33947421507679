// import assert from 'assert'
import { generateMnemonic } from 'bip39'
import Accounts from 'web3-eth-accounts'
import { WalletEntryModel, WalletClientModel, WalletServerModel, SignerMemoryModel } from 'src/models'
import { toPrivateKeyHex } from 'src/utils'
import { REGISTRY } from 'src/services'
import { SIGNER, withAuthorization, withAuthorizationAndVerification } from 'src/remotes'

export const WALLETS_SERVER_CREATE = 'wallets/server/create'
export const WALLETS_SERVER_REMOVE = 'wallets/server/remove'
export const WALLETS_SERVER_REPLACE_ALL = 'wallets/server/replace-all'

export const WALLETS_CLIENT_CREATE = 'wallets/client/create'
export const WALLETS_CLIENT_REMOVE = 'wallets/client/remove'
export const WALLETS_CLIENT_REPLACE_ALL = 'wallets/client/replace-all'

export default () => ({
  namespaced: true,
  state () {
    return {
      serverWallets: [],
      clientWallets: []
    }
  },
  mutations: {
    [WALLETS_SERVER_CREATE] (state, walletServer: WalletServerModel) {
      state.serverWallets.push(walletServer)
    },
    [WALLETS_CLIENT_CREATE] (state, walletClient: WalletClientModel) {
      state.clientWallets.push(walletClient)
    },
    [WALLETS_SERVER_REMOVE] (state, id: Number) {
      const index = state.serverWallets.findIndex(entry => entry.id === id)
      if (index !== -1) {
        state.serverWallets.splice(index, 1)
      }
    },
    [WALLETS_CLIENT_REMOVE] (state, id: Number) {
      const index = state.clientWallets.findIndex(entry => entry.id === id)
      if (index !== -1) {
        state.clientWallets.splice(index, 1)
      }
    },
    [WALLETS_SERVER_REPLACE_ALL] (state, wallets) {
      state.serverWallets = wallets || []
    },
    [WALLETS_CLIENT_REPLACE_ALL] (state, wallets) {
      state.serverWallets = wallets || []
    }
  },
  getters: {
    getClientWallet: ({ clientWallets }) => id => {
      const clientWallet = clientWallets.find(cw => cw.id === id)
      return clientWallet == null
        ? null
        : clientWallet
    },
    getServerWallet: ({ serverWallets }) => id => {
      const serverWallet = serverWallets.find(sw => sw.id === id)
      return serverWallet == null
        ? null
        : serverWallet
    },
    // getClientsWallets: {},
    entries: ({ serverWallets, clientWallets }, { getClientWallet }) => serverWallets.map(
      serverWallet => {
        const clientWalletData = clientWallets.find(cw => cw.id === serverWallet.id)
        return new WalletEntryModel({
          serverWallet,
          clientWallet: clientWalletData == null
            ? null
            : getClientWallet(serverWallet.id)
        })
      }
    )
  },
  actions: {
    async ensureWallet ({ dispatch }) {
      return dispatch('createWallet', {
        type: 'SERVER',
        name: 'My private key 1',
        options: {
          mnemonic: generateMnemonic()
        }
      })
    },
    async removeWallet ({ commit, dispatch }, entry) {
      await dispatch('removeServerEntry', entry.serverWallet)
      commit(WALLETS_SERVER_REMOVE, entry.serverWallet.id)
      if (entry.clientWallet != null) {
        commit(WALLETS_CLIENT_REMOVE, entry.clientWallet.id)
      }
    },
    async createWallet ({ dispatch }, { type, name, options }) {
      switch (type) {
        case 'SERVER': {
          const { address, seed, privateKey, mnemonic } = options
          return dispatch('createServerWallet', { name, address, seed, privateKey, mnemonic })
        }
        case 'MEMORY': {
          const { password, seed, privateKey, mnemonic } = options
          return dispatch('createMemoryWallet', { name, password, seed, privateKey, mnemonic })
        }
        case 'PLUGIN': {
          const { address, subtype } = options
          return dispatch('createPluginWallet', { name, address, subtype })
        }
        case 'DEVICE': {
          const { address, publicKey, derivationPath, subtype } = options
          return dispatch('createDeviceWallet', { name, subtype, address, publicKey, derivationPath })
        }
        case 'READONLY': {
          const { address } = options
          return dispatch('createReadonlyWallet', { name, address })
        }
      }
    },
    async createServerEntry ({ dispatch }, { type, subtype, address, pubKey, prvKey, derivePath, meta }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await SIGNER.post('wallet', {
            blockchain: 'ETH',
            network: 'MAINNET',
            type,
            subtype,
            address,
            pubKey,
            prvKey,
            derivePath,
            meta
          }, withAuthorizationAndVerification(token, code))

          return WalletServerModel.fromJson(data)
        }
      }, { root: true })
      return result
    },
    async removeServerEntry ({ dispatch }, serverWallet) {
      await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          await SIGNER.delete(`wallet/${serverWallet.id}`, withAuthorizationAndVerification(token, code))
        }
      }, { root: true })
    },
    async createMemoryWallet ({ commit, dispatch }, { name, password, seed, privateKey, mnemonic }) {
      const signer = await SignerMemoryModel.create({ seed, privateKey, mnemonic })
      const encrypted = await signer.encrypt(password)
      const serverWallet = await dispatch('createServerEntry', {
        type: 'MEMORY',
        address: signer.getAddress().toLowerCase(),
        pubKey: signer.getPublicKey(),
        meta: {
          name
        }
      })
      const clientWallet = WalletClientModel.fromJson({
        id: serverWallet.id,
        encrypted
      })
      commit(WALLETS_SERVER_CREATE, serverWallet)
      commit(WALLETS_CLIENT_CREATE, clientWallet)
      return new WalletEntryModel({
        serverWallet,
        clientWallet
      })
    },
    async createServerWallet ({ commit, dispatch }, { name, seed, privateKey, mnemonic }) {
      // const plugin = REGISTRY.getPlugin('server')
      // const signer = await device.create({ token })
      // const encrypted = await signer.encrypt(password)
      const prvKey = toPrivateKeyHex({ seed, privateKey, mnemonic })
      const serverWallet = await dispatch('createServerEntry', {
        type: 'SERVER',
        prvKey: prvKey.toLowerCase(),
        meta: {
          name
        }
      })
      commit(WALLETS_SERVER_CREATE, serverWallet)
      return new WalletEntryModel({
        serverWallet,
        clientWallet: null
      })
    },
    async createReadonlyWallet ({ commit, dispatch }, { name, address }) {
      const serverWallet = await dispatch('createServerEntry', {
        type: 'READONLY',
        address: address.toLowerCase(),
        meta: {
          name
        }
      })
      commit(WALLETS_SERVER_CREATE, serverWallet)
      return new WalletEntryModel({
        serverWallet,
        clientWallet: null
      })
    },
    async createPluginWallet ({ commit, dispatch }, { name, address, subtype }) {
      const serverWallet = await dispatch('createServerEntry', {
        type: 'PLUGIN',
        subtype,
        address: address.toLowerCase(),
        meta: {
          name
        }
      })
      commit(WALLETS_SERVER_CREATE, serverWallet)
      return new WalletEntryModel({
        serverWallet,
        clientWallet: null
      })
    },
    async createDeviceWallet ({ commit, dispatch }, { name, subtype, address, publicKey, derivationPath }) {
      const serverWallet = await dispatch('createServerEntry', {
        type: 'DEVICE',
        subtype,
        address: address == null ? undefined : address.toLowerCase(),
        pubKey: publicKey,
        derivePath: derivationPath,
        meta: {
          name
        }
      })
      commit(WALLETS_SERVER_CREATE, serverWallet)
      return new WalletEntryModel({
        serverWallet,
        clientWallet: null
      })
    },
    async fetchWallets ({ dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await SIGNER.get('wallet', withAuthorization(token))
      return data
    },
    async loadWallets ({ commit, dispatch, getters }) {
      const { getClientWallet, requestToken, requestPassword } = getters

      const accounts = await dispatch('fetchWallets')

      const serverPlugin = REGISTRY.getPlugin('server')
      const memoryDevice = REGISTRY.getDevice('memory')

      // TODO @ipavlenko: Add "plugin type" to the SIGNER REST service and use it to properly instantiate signer
      const serverWallets = await Promise.all(
        accounts.filter(account => account.blockchain === 'ETH').map(
          async account => {
            try {
              const serverWallet = WalletServerModel.fromJson(account)
              const entry = new WalletEntryModel({
                serverWallet,
                clientWallet: getClientWallet(serverWallet.id)
              })
              switch (entry.serverWallet.type) {
                case 'SERVER': {
                  await serverPlugin.register(entry, {
                    requestToken: async () => requestToken(entry)
                  })
                  break
                }
                case 'MEMORY': {
                  await memoryDevice.register(entry, {
                    requestPassword: async () => requestPassword(entry)
                  })
                  break
                }
                case 'DEVICE': {
                  // ignore, no device, no plugin here
                  break
                }
                case 'PLUGIN': {
                  // ignore, no device, no plugin here
                  break
                }
                case 'READONLY': {
                  // ignore, no device, no plugin here
                  break
                }
                default: {
                  // eslint-disable-next-line
                  console.warn(`Unsuported wallet type: '${entry.type}'`)
                }
              }
              return serverWallet
            } catch (e) {
              // eslint-disable-next-line
              console.error(`Broken wallet: '${account.id}'`, account, e)
            }
          }
        )
      )

      commit(WALLETS_SERVER_REPLACE_ALL, serverWallets.filter(entry => entry != null))
    },
    async uploadV3 (_, { file }) {
      const value = {
        name: file.name.toLowerCase().endsWith('.wlt')
          ? file.name.substring(0, file.name.length - 4)
          : file.name
      }

      const content = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('load', e => {
          resolve(e.target.result)
        })
        reader.addEventListener('error', () => {
          reject(new Error('Upload error'))
        })
        reader.readAsText(file)
      })

      value.content = content

      const parsed = await new Promise((resolve, reject) => {
        try {
          resolve(JSON.parse(content))
        } catch (e) {
          reject(new Error('Illegal format'))
        }
      })

      const encrypted = Array.isArray(parsed)
        ? parsed
        : [parsed]

      value.encrypted = encrypted.map(account => {
        if (!account.id || !account.address || (!account.crypto && !account.Crypto) || !account.version || account.version !== 3) {
          throw new Error('Illegal format')
        }
        return Object.freeze({
          id: account.id,
          crypto: account.crypto || account.Crypto,
          version: account.version,
          address: account.address
        })
      })
      return Object.freeze(value)
    },
    async decryptV3 (_, { encrypted, password }) {
      const accounts = new Accounts()
      const decrypted = accounts.wallet.decrypt(encrypted, password)
      return {
        privateKey: decrypted[0].privateKey,
        address: decrypted[0].address
      }
    },
    async fetchClientWallets ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await SIGNER.get('manage/addresses', withAuthorization(token, {
        params: {
          id
        }
      }))
      return data
    }
  }
})
