import { mapGetters } from 'vuex'

export default {
  props: {
    seconds: {
      type: Number,
      default: 45
    }
  },
  data () {
    return {
      start: new Date().getTime() + this.seconds * 1000,
      min: '',
      sec: ''
    }
  },
  methods: {
    calcTime (dist) {
      this.min = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
      this.sec = Math.floor((dist % (1000 * 60)) / 1000)
    },
    timerCount (start) {
      let now = new Date().getTime()
      let distance = start - now
      this.calcTime(distance)
    },
    timerFinished () {
      this.$emit('timerFinished')
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  mounted () {
    this.timerCount(this.start)
    this.interval = setInterval(() => {
      this.timerCount(this.start)
      if (this.sec <= 0) {
        clearInterval(this.interval)
        this.timerFinished()
      }
    }, 1000)
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
