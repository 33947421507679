/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<path pid="0" opacity=".4" d="M11.128 9.52l8.32 8.36-1.12 1.08-8.32-8.32-8.32 8.32-1.12-1.08 8.32-8.36L.568 1.2 1.688.08l8.32 8.32 8.32-8.32 1.12 1.12-8.32 8.32z" _fill="#12143C"/>'
  }
})
