import _ from 'lodash'
import { LoadableModel, NotificationItem, ToggleNotificationSettingModel, Constants } from 'src/models'
import { PROFILE, withAuthorization } from 'src/remotes'

export const NOTIFICATIONS_LOADING = 'passportNotifications/loading'
export const NOTIFICATIONS_LOADED = 'passportNotifications/loaded'
export const NOTIFICATIONS_TOGGLE = 'passportNotifications/toggle'
export const SWITCH = 'passportNotifications/switch'
export const REMEMBER_READ_DATE = 'passportNotifications/readDate'

export default ({ safeExec }) => ({
  namespaced: true,
  state: {
    availables: Constants.notifications.availables.user,
    availablesAdmin: Constants.notifications.availables.admin,
    availableProviders: Constants.notifications.availableProviders,
    table: {},
    loading: false,
    loaded: false,
    switched: false,
    readDate: null // дата последнего прочтения активностей
  },
  getters: {
    switched: state => state.switched,
    readDate: state => state.readDate
  },
  mutations: {
    [NOTIFICATIONS_LOADING]: (state) => {
      state.loading = true
      state.loaded = false
    },
    [NOTIFICATIONS_LOADED]: (state, { notifications }) => {
      state.loading = false
      state.loaded = true
      state.table = notifications.reduce(
        (table, notification) => {
          _.set(
            table,
            `${notification.name}.${notification.provider}`,
            new LoadableModel(NotificationItem).loaded(notification)
          )
          return table
        },
        {}
      )
    },
    [NOTIFICATIONS_TOGGLE] (state, { name, provider, value, start }) {
      const newItem = new NotificationItem({
        name,
        domain: Constants.passport.domain.audt,
        provider,
        value
      })
      const loadableModel = start
        ? new LoadableModel(NotificationItem).loading(newItem)
        : new LoadableModel(NotificationItem).loaded(newItem)
      const newTablePart = _.set({}, `${name}.${provider}`, loadableModel)
      const newTable = _.merge({}, state.table, newTablePart)

      state.table = newTable
    },
    [SWITCH] (state) {
      state.switched = !state.switched
    },
    [REMEMBER_READ_DATE] (state, date) {
      state.readDate = date
    }
  },
  actions: {
    async fetchNotifications ({ dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('/security/me/profile/notifications', withAuthorization(token, {
        params: {
          domain: Constants.passport.domain.audt,
          provider: 'WEBSOCKET'
        }
      }))
      return data.map(NotificationItem.fromJs)
    },
    async loadNotifications ({ commit, dispatch }) {
      commit(NOTIFICATIONS_LOADING)
      const notifications = await dispatch('fetchNotifications')
      commit(NOTIFICATIONS_LOADED, {
        notifications
      })
    },
    async toggleNotification ({ commit, dispatch }, { name, provider, value }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      try {
        commit(NOTIFICATIONS_TOGGLE, { name, provider, value, start: true })
        await PROFILE.post('/security/me/profile/notifications/toggle',
          ToggleNotificationSettingModel.fromJson({
            name,
            provider,
            value
          }),
          withAuthorization(token)
        )
      } finally {
        commit(NOTIFICATIONS_TOGGLE, { name, provider, value, start: false })
      }
    },
    switchNotification ({ commit }) {
      commit(SWITCH)
    },
    rememberReadDate ({ commit }, date) {
      commit(REMEMBER_READ_DATE, date)
    }
  }
})
