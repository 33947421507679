import moment from 'moment'

const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_TIME = '00:00:00'

export default {
  name: 'date-time-picker',
  props: {
    prependIcon: String,
    appendIcon: String,
    clearable: Boolean,
    disabled: Boolean,
    filled: Boolean,
    hideDetails: Boolean,
    rounded: Boolean,
    label: String,
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY HH:mm:ss'
    },
    value: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      display: false,
      activeTab: 0
    }
  },
  computed: {
    date () {
      return this.value && moment(this.value).format('YYYY-MM-DD')
    },
    time () {
      return this.value && moment(this.value).format('HH:mm:ss')
    },
    selectedDatetime () {
      return this.date && this.time && moment(`${this.date} ${this.time}`).toDate()
    },
    formattedDatetime () {
      if (this.selectedDatetime) {
        return moment(this.selectedDatetime).format(this.format)
      }
      return ''
    }
  },
  methods: {
    onDateInput (value) {
      this.activeTab = 1

      const time = this.time || DEFAULT_TIME
      const newValue = moment(`${value} ${time}`).toDate()

      this.$emit('input', newValue)
    },
    onInputTime (value) {
      const date = this.date || moment().format('YYYY-MM-DD')

      const newValue = moment(`${date} ${value}`).toDate()

      this.$emit('input', newValue)
    },
    okHandler () {
      this.resetPicker()
      this.$emit('input', this.selectedDatetime)
    },
    clearHandler () {
      this.resetPicker()
      this.$emit('input', null)
    },
    resetPicker () {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    }
  }
}
