import { render, staticRenderFns } from "./AddChatChannel.htm?vue&type=template&id=28bc0671&scoped=true&external"
import script from "./AddChatChannel.js?vue&type=script&lang=js&external"
export * from "./AddChatChannel.js?vue&type=script&lang=js&external"
import style0 from "./AddChatChannel.scss?vue&type=style&index=0&id=28bc0671&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bc0671",
  null
  
)

export default component.exports