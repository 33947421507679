/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-facebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a10 10 0 100 20 10 10 0 000-20zm5 13.889A1.11 1.11 0 0115.889 17H13.9v-3.869h1.314l.2-1.514h-1.5v-.964c0-.438.122-.734.75-.734h.793V8.568c-.386-.04-.774-.059-1.162-.058a1.819 1.819 0 00-1.942 1.995v1.114h-1.3v1.514h1.3V17H8.111A1.11 1.11 0 017 15.889V8.111A1.111 1.111 0 018.111 7h7.778A1.111 1.111 0 0117 8.111v7.778z"/>'
  }
})
