import Joi from 'joi'
import BigNumber from 'bignumber.js'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  reference: Joi.string(),
  idempotencyKey: Joi.string().allow([null, '']),
  identities: Joi.array().items(
    Joi.string()
  ),
  createdAt: Joi.date(),
  type: Joi.string(),
  state: Joi.string(),
  token: Joi.string(),
  address: Joi.string(),

  advCashRecipientEmail: Joi.string().allow([null, '']),
  advCashTransactionId: Joi.string().allow([null, '']),

  geoPayUuid: Joi.string().allow([null, '']),
  geoPayReceiverHashId: Joi.string().allow([null, '']),

  monoovaTransactionId: Joi.string().allow([null, '']),
  accountName: Joi.string().allow([null, '']),
  accountNumber: Joi.string().allow([null, '']),
  accountType: Joi.string().allow([null, '']),
  bsb: Joi.string().allow([null, '']),
  payId: Joi.string().allow([null, '']),
  disbursementMethod: Joi.string().allow([null, '']),
  payIdType: Joi.string().allow([null, '']),

  amount: Joi.object().type(BigNumber),
  commissionAmount: Joi.object().type(BigNumber).allow(null),
  realAmount: Joi.object().type(BigNumber).allow(null),
  realCommissionAmount: Joi.object().type(BigNumber).allow(null),

  bank: Joi.object().keys({
    id: Joi.string().allow([null, '']),
    createdAt: Joi.string().allow([null, '']),
    name: Joi.string().allow([null, '']),
    payId: Joi.string().allow([null, '']),
    type: Joi.string().allow([null, '']),
    currency: Joi.string().allow([null, '']),
    accountNumber: Joi.string().allow([null, '']),
    bnb: Joi.string().allow([null, ''])
  }).allow(null),
  symbol: Joi.string(),
  tx: Joi.string().allow([null, '']),
  result: Joi.string().allow([null, '']),

  // type specific fields
  autoWithdrawDecision: Joi.string().allow([null, '']),
  destination: Joi.string().allow([null, '']),
  destinationExtra: Joi.string().allow([null, '']),
  processState: Joi.string().allow([null, '']),
  network: Joi.string().allow([null, '']),
  transferHash: Joi.string().allow([null, '']),

  crystalData: Joi.object()
})

export default class WithdrawalModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.id
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new WithdrawalModel({
      id: data.id,
      reference: data.reference,
      idempotencyKey: data.idempotencyKey,
      identities: data.identities,
      createdAt: data.createdAt == null ? null : new Date(data.createdAt),
      type: data.type,
      state: data.state,
      token: data.token,
      address: data.address,
      advCashRecipientEmail: data.advCashRecipientEmail,
      advCashTransactionId: data.advCashTransactionId,
      monoovaTransactionId: data.monoovaTransactionId,
      amount: data.amount == null ? null : new BigNumber(data.amount),
      commissionAmount: data.commissionAmount == null ? null : new BigNumber(data.commissionAmount),
      realAmount: data.realAmount == null ? null : new BigNumber(data.realAmount),
      realCommissionAmount: data.realCommissionAmount == null ? null : new BigNumber(data.realCommissionAmount),
      bank: data.bank,
      symbol: data.symbol,
      result: data.result,
      tx: data.withdrawHash,
      destination: data.destination,
      destinationExtra: data.destinationExtra,
      network: data.network,
      processState: data.processState,
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      accountType: data.accountType,
      bsb: data.bsb,
      payId: data.payId,
      disbursementMethod: data.disbursementMethod,
      payIdType: data.payIdType,
      autoWithdrawDecision: data.autoWithdrawDecision,
      transferHash: data.transferHash,
      crystalData: data.crystalData
    })
  }
}
