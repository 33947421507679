export const NOTIFICATIONS_CREATE = 'notifications/create'
export const NOTIFICATIONS_COUNTS_UNREAD = 'notifications/counts/unread'
export const NOTIFICATIONS_TOGGLE = 'notifications/toggle'

export default ({ safeExec }) => ({
  namespaced: true,
  state: {
    notifications: [],
    unreadCount: 0,
    isOpenNotifications: false
  },
  mutations: {
    [NOTIFICATIONS_CREATE] (state, notification) {
      state.notifications = [ notification, ...state.notifications ]
    },
    [NOTIFICATIONS_COUNTS_UNREAD] (state, count) {
      state.unreadCount = count
    },
    [NOTIFICATIONS_TOGGLE] (state, isOpenNotifications) {
      if (isOpenNotifications == null) { // nil check
        isOpenNotifications = !state.isOpenNotifications
      }
      state.isOpenNotifications = isOpenNotifications
      if (state.isOpenNotifications) {
        state.unreadCount = 0
      }
    }
  },
  getters: {
    isOpenNotifications (state) {
      return state.isOpenNotifications
    },
    unreadCount (state) {
      return state.unreadCount
    },
    pending (state, getters, rootState, rootGetters) {
      // const transactions = rootGetters['ethereum/pending']
      // const signer = rootGetters['wallets/signer']
      return []
      // return transactions.map(entry => {
      //   const context = {
      //     address: signer.getAddress(),
      //     agents: [],
      //     getters: rootGetters
      //   }
      //
      //   const desc = describeTx({ tx: entry.tx }, context)
      //
      //   return new NotificationModel({
      //     key: entry.key,
      //     type: 'tx',
      //     name: desc.name,
      //     date: new Date(),
      //     icon: 'tx',
      //     title: desc.title,
      //     message: desc.address,
      //     amountTitle: 'Amount',
      //     isAmountSigned: true,
      //     amount: desc.amount && desc.amount.negative(),
      //     target: null,
      //     timestamp: new Date()
      //   })
      // })
    },
    history (state) {
      return state.notifications.filter(notification => !notification.isPending)
    }
  },
  actions: {
    async toggleNotifications ({ state, commit }, isOpenNotifications) {
      commit(NOTIFICATIONS_TOGGLE, isOpenNotifications)
    },
    async flushUnread ({ state, commit }) {
      commit(NOTIFICATIONS_COUNTS_UNREAD, 0)
    },
    async pushEventEntries ({ state, commit }, { entries }) {
      if (!state.isOpenNotifications) {
        commit(NOTIFICATIONS_COUNTS_UNREAD, state.unreadCount + entries.length)
      }
    },
    async pushTxEntries ({ state, commit }, { entries }) {
      if (!state.isOpenNotifications) {
        commit(NOTIFICATIONS_COUNTS_UNREAD, state.unreadCount + entries.length)
      }
    }
  }
})
