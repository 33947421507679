import moment from 'moment'
import BigNumber from 'bignumber.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Profile Trade History'
  },
  components: {
    ...components
  },
  data () {
    return {
      trades: {
        isLoaded: false,
        isLoading: false,
        page: 1,
        size: 100,
        count: null,
        items: []
      },
      filters: {
        address: null,
        market: null,
        side: '*',
        from: null,
        to: null
      }
    }
  },
  computed: {
    ...mapState({
      profileId: state => state.route.params.id
    }),
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData',
      getMarketBySymbol: 'markets/getMarketBySymbol',
      getCurrency: 'currencies/getCurrency',
      markets: 'markets/markets'
    }),
    sideOptions () {
      return [
        { value: '*', label: 'Both Sides' },
        { value: 'SELL', label: 'Sell' },
        { value: 'BUY', label: 'Buy' }
      ]
    },
    marketOptions () {
      return [
        ...this.markets.value.map(market => ({
          value: market.symbol,
          label: market.name
        }))
      ]
    },
    profileData () {
      return this.getProfileData(this.profileId)
    },
    details () {
      return this.profileData.value.profile
    },
    wallets () {
      return this.profileData.value.addresses || []
    },
    addressOptions () {
      const addresses = this.wallets
        .filter(address => address.blockchain === 'ETH')
        .map(address => address.address.toLowerCase())
      return Array.from(new Set(addresses))
    },
    totalPages () {
      const size = this.trades.size
      const count = this.trades.count
      const m = count % size
      const d = parseInt(count / size)
      return d + (m === 0 ? 0 : 1)
    },
    items () {
      const page = this.trades.page
      const size = this.trades.size
      return this.trades.items.map((trade, index) => {
        const market = this.getMarketBySymbol(trade.symbol)
        const feeCurrency = this.getCurrency(trade.feeToken)
        return {
          index: 1 + index + (page - 1) * size,
          moment: moment.utc(trade.timestamp).local(),
          market,
          trade,
          volume: new BigNumber(trade.quantity).toFixed(market.quantityIncrementDP),
          amount: new BigNumber(trade.quantity).times(trade.price).toFixed(market.tickSizeQuantityIncrementCompositionDP),
          price: new BigNumber(trade.price).toFixed(market.tickSizeDP),
          fee: new BigNumber(trade.fee).toFixed(feeCurrency != null ? feeCurrency.displayDecimals * 2 : 4)
        }
      })
    }
  },
  created () {
    this.filters.address = this.addressOptions[0]
    this.filters.market = this.marketOptions[0]
  },
  mounted () {
    this.loadMoreTrades({
      filters: this.filters
    })
  },
  methods: {
    ...mapActions({
      fetchTrades: 'plasmaTrades/fetchTypedPage'
    }),
    // async refreshTrades ( {})
    async loadMoreTrades ({ page = 1, size = 100, filters }) {
      if (!this.trades.isLoading) {
        Object.assign(this.trades, {
          page,
          size,
          isLoading: true
        })
        setImmediate(async () => {
          try {
            const from = this.filters.from == null
              ? undefined
              : moment(this.filters.from).startOf('day').toISOString()
            const till = this.filters.to == null
              ? undefined
              : moment(this.filters.to).endOf('day').toISOString()

            const { count, items } = await this.fetchTrades({
              page: page - 1,
              size,
              filters: {
                from,
                till,
                address: this.filters.address,
                side: this.filters.side === '*' ? undefined : this.filters.side,
                symbol: this.filters.market.value
              }
            })
            Object.assign(this.trades, {
              isLoaded: true,
              count,
              items
            })
          } finally {
            Object.assign(this.trades, {
              isLoading: false
            })
          }
        })
      }
    },
    async handleActivePage (value) {
      this.loadMoreTrades({
        filters: this.filters,
        page: value
      })
    },
    async handleAddress (address) {
      if (address.length === 0) return
      this.filters.address = address
      await this.loadMoreTrades({
        filters: this.filters
      })
    },
    async handleMarket (market) {
      this.filters.market = this.marketOptions.find(e => e.value === market)
      await this.loadMoreTrades({
        filters: this.filters
      })
    },
    async handleSide (side) {
      this.filters.side = side
      await this.loadMoreTrades({
        filters: this.filters
      })
    },
    async handleFrom (from) {
      this.filters.from = from
      await this.loadMoreTrades({
        filters: this.filters
      })
    },
    async handleTo (to) {
      this.filters.to = to
      await this.loadMoreTrades({
        filters: this.filters
      })
    }
  }
}
