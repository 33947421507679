import { omitBy, isNil } from 'lodash'
import { DepositModel } from 'src/models'
import { BACKEND, withAuthorization } from 'src/remotes'

export const PURCHASES_LOADING = 'purchases/loading'
export const PURCHASES_LOADED = 'purchases/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      actualDate: null,
      activePage: 1,
      numberOfElements: 10, // page size
      limit: 100,
      items: [],
      count: null,
      filters: {
        state: null
      }
    }
  },
  mutations: {
    [PURCHASES_LOADING]: (state) => {
      state.isLoading = true
    },
    [PURCHASES_LOADED]: (state, { actualDate, count, items, activePage, numberOfElements }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        actualDate,
        activePage,
        numberOfElements,
        count,
        items
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    items: state => state.items,
    totalPages (state) {
      const count = state.count
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async fetch ({ dispatch }, { actualDate, offset, limit, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { state, type, symbol } = filters
      const { data } = await BACKEND.get('me/deposits', withAuthorization(token, {
        params: {
          state: (state == null || state === '*' || state.length === 0)
            ? null
            : arrayOfQueryParam(state),
          type: (type == null || type === '*' || type.length === 0)
            ? null
            : arrayOfQueryParam(type),
          symbol: (symbol == null || symbol === '*' || symbol.length === 0)
            ? null
            : arrayOfQueryParam(symbol),
          actualDate,
          offset,
          limit
        }
      }))
      return data
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      }
    ) {
      if (!state.isLoading) {
        commit(PURCHASES_LOADING)
        const { actualDate, items, count } = await dispatch('fetch', {
          actualDate: !reset && state.isLoaded
            ? state.actualDate
            : null,
          filters,
          offset: (page.activePage - 1) * page.numberOfElements,
          limit: page.numberOfElements
        })
        commit(PURCHASES_LOADED, {
          actualDate,
          activePage: page.activePage,
          numberOfElements: page.numberOfElements,
          count,
          items: items.map(DepositModel.fromJson)
        })
      }
    },
    async createPurchase ({ state, commit, dispatch }, { currency, amount, wallet, pluginId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post('me/deposits', {
        symbol: currency.symbol,
        address: wallet.address,
        pluginId: pluginId,
        amount: amount
      }, withAuthorization(token))
      const deposit = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: [deposit, ...state.items]
      })
      return deposit
    },
    async cancelPurchase ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`me/deposits/i/${id}/cancel`, null, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : DepositModel.fromJson(purchase)
        )
      })
      return purchase
    },
    async discoverPurchase ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`me/deposits/i/${id}`, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : DepositModel.fromJson(purchase)
        )
      })
      return purchase
    },
    async discoverPurchaseByReference ({ state, commit, dispatch }, { reference }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`me/deposits/r/${reference}`, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : DepositModel.fromJson(purchase)
        )
      })
      return purchase
    }
  }
})

function arrayOfQueryParam (value) {
  return value == null
    ? null
    : (Array.isArray(value) ? value : [value])
}
