import { LoadableListModel, BoTradesModel } from 'src/models'
import {PLASMA, withAuthorization} from 'src/remotes'

export const BO_TRADES_LOADING = 'trades/loading'
export const BO_TRADES_LOADED = 'trades/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      pageSize: 50, // elements per page
      items: new LoadableListModel(BoTradesModel),
      filters: {},
      pageNumber: 0
    }
  },
  mutations: {
    [BO_TRADES_LOADING]: (state) => {
      state.items = state.items.loading()
    },
    [BO_TRADES_LOADED]: (state, { items, pageNumber }) => {
      state.items = state.items.loaded(...items)
      state.pageNumber = pageNumber
    }
  },
  getters: {
    pageSize: state => state.pageSize,
    items: state => state.items,
    pageNumber: state => state.pageNumber
  },
  actions: {
    async fetchTrades ({ state, commit, dispatch }, { filters = {}, pageSize, pageNumber } = { filters: {}, pageSize: state.pageSize }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('backoffice/history/trades', withAuthorization(token, {
        params: {
          ...filters,
          pageSize,
          pageNumber
        }
      }))
      return data
    },
    async loadTrades ({ state, commit, dispatch }, { filters, pageNumber }) {
      commit(BO_TRADES_LOADING)
      const { boTradesModelList } = await dispatch('fetchTrades', {
        filters,
        pageSize: state.pageSize,
        pageNumber
      })
      commit(BO_TRADES_LOADED, {
        items: boTradesModelList.map(BoTradesModel.fromJson),
        pageNumber
      })
    }
  }
})
