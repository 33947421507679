export function makeValidationErrors (prop, conditions) {
  if (prop.$dirty) {
    for (const [check, value] of Object.entries(conditions)) {
      if (!prop[check]) {
        return [
          (value instanceof Function)
            ? value()
            : value
        ]
      }
    }
  }
  return []
}
