import {mapGetters, mapActions, mapState} from 'vuex'
import * as components from 'src/components'
import moment from 'moment'
import { omit } from 'lodash'

export default {
  metaInfo: {
    title: 'Activity'
  },
  components: {
    ...components
  },
  data () {
    return {
      visibleCount: 10,
      filters: {
        from: null,
        to: null
      },
      isMobile: window.innerWidth <= 1200
    }
  },
  computed: {
    ...mapState({
      profileId: state => state.route.params.id,
      items: state => state.activity.items
    }),
    ...mapGetters({
      totalPages: 'activity/totalPages',
      activePage: 'activity/page',
      hasUserActivityViewAccess: 'grants/hasUserActivityViewAccess'
    })
  },
  async created () {
    await this.clearActivity()
    if (this.hasUserActivityViewAccess) {
      await this.loadUserActivity({
        userId: this.profileId
      })
    }
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    moment,
    ...mapActions({
      loadUserActivity: 'activity/loadUserActivity',
      clearActivity: 'activity/clearActivity'
    }),
    createStringFromPayLoad (payload) {
      return JSON.stringify(omit(payload, ['device']), undefined, 3)
    },
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    async handleRefresh () {
      await this.loadUserActivity({
        userId: this.profileId,
        from: null,
        to: null,
        page: 1
      })
    },
    async handleFilter () {
      await this.loadUserActivity({
        from: moment(this.filters.from).utc().toISOString(),
        to: moment(this.filters.to).utc().toISOString(),
        userId: this.profileId
      })
    },
    async handleActivePage (value) {
      await this.loadUserActivity({
        from: this.filters.from && this.moment(this.filters.from).utc().toISOString(),
        to: this.filters.to && this.moment(this.filters.to).utc().toISOString(),
        userId: this.profileId,
        page: value
      })
    }
  }
}
