import { render, staticRenderFns } from "./ManageDepositsAddressesPage.htm?vue&type=template&id=a2e086e4&scoped=true&external"
import script from "./ManageDepositsAddressesPage.js?vue&type=script&lang=js&external"
export * from "./ManageDepositsAddressesPage.js?vue&type=script&lang=js&external"
import style0 from "./ManageDepositsAddressesPage.scss?vue&type=style&index=0&id=a2e086e4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2e086e4",
  null
  
)

export default component.exports