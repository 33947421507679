/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medium': {
    width: 35,
    height: 29,
    viewBox: '0 0 35 29',
    data: '<path pid="0" d="M0 0h35.003v28.772H0V0zm9.253 9.74v8.738a1 1 0 01-.27.867l-2.089 2.519v.332h5.924v-.331l-2.09-2.52a1.046 1.046 0 01-.286-.867V10.92l5.199 11.276h.603L20.71 10.92v8.987c0 .24 0 .286-.157.443l-1.607 1.55v.332h7.8V21.9l-1.55-1.513a.463.463 0 01-.178-.443V8.827a.457.457 0 01.177-.443l1.587-1.514V6.54h-5.497l-3.918 9.715-4.457-9.715H7.144v.331l1.857 2.224a.764.764 0 01.25.646h.002z" _fill="#12143C"/>'
  }
})
