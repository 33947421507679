import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import {MarketAdd, MarketEdit} from 'src/modals'
import MarketPartial from 'src/partials/private/MarketPartial/MarketPartial.vue'
import {OperationCanceledError} from 'src/errors'

export default {
  metaInfo: {
    title: 'Marketplace'
  },
  components: {
    ...components,
    MarketPartial
  },
  data () {
    return {
      searchQuery: null,
      trade: [
        {
          name: 'unlocked',
          value: false,
          key: 0
        },
        {
          name: 'locked',
          value: true,
          key: 1
        }
      ],
      promotionalValues: [
        {
          name: 'no',
          value: false,
          key: 0
        },
        {
          name: 'yes',
          value: true,
          key: 1
        }
      ],
      showOnLandingValues: [
        {
          name: 'no',
          value: false,
          key: 0
        },
        {
          name: 'yes',
          value: true,
          key: 1
        }
      ],
      isMobile: window.innerWidth <= 1200
    }
  },
  computed: {
    ...mapGetters({
      markets: 'markets/markets',
      getCurrency: 'currencies/getCurrency'
    }),
    resultQuery () {
      if (this.searchQuery) {
        return this.markets.value.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(v => item.symbol.toLowerCase().includes(v))
        })
      } else {
        return this.markets.value
      }
    }
  },
  async created () {
    await this.loadMarkets()
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      loadMarkets: 'markets/loadMarkets',
      deleteMarket: 'markets/deleteMarket'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    async handleAdd () {
      this.openModal({
        factory: () => MarketAdd
      })
    },
    async handleEdit (market) {
      this.openModal({
        factory: () => MarketEdit,
        data: {
          market
        }
      })
    },
    async handleDelete (symbol) {
      this.isDeleteInProgress = true
      setImmediate(async () => {
        try {
          await this.deleteMarket({
            symbol: symbol
          })
          await this.handleToast({
            message: 'Market deleted'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot delete market'
            })
          }
        } finally {
          this.isDeleteInProgress = false
        }
      })
    }
  }
}
