export default (store) => async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.passport.user) {
      return next({
        path: '/'
      })
    }
  }

  return next()
}
