import {mapActions} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import * as components from 'src/components'
export default {
  components: {
    ...components
  },
  props: {
    id: String,
    propTitle: String,
    propMessage: String
  },
  data () {
    return {
      form: {
        title: '',
        message: ''
      }
    }
  },
  created () {
    this.fillForm()
  },
  computed: {
    titleErrors () {
      if (this.$v.form.title.$dirty) {
        if (!this.$v.form.title.required) {
          return [
            'Title is a required field'
          ]
        }
      }
      return []
    },
    messageErrors () {
      if (this.$v.form.message.$dirty) {
        if (!this.$v.form.message.required) {
          return [
            'Message is a required field'
          ]
        }
      }
      return []
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      message: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createTemplate: 'customers/createTemplate',
      editTemplate: 'customers/editTemplate'
    }),
    fillForm () {
      if (!!this.propTitle && !!this.propMessage) {
        this.form.title = this.propTitle
        this.form.message = this.propMessage
      }
    },
    async handleSubmit () {
      if (this.id) {
        this.editTemplate({
          id: this.id,
          title: this.form.title,
          text: this.form.message
        })
      } else {
        this.createTemplate({
          title: this.form.title,
          text: this.form.message
        })
      }
      this.handleClose()
    }
  }
}
