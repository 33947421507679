import { mapActions } from 'vuex'
import * as components from 'src/components'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    ...components
  },
  props: {
    ssoUserId: String,
    handler: Function
  },
  data () {
    return {
      form: {
        message: null
      },
      isLoading: false
    }
  },
  computed: {
    messageErrors () {
      if (this.$v.form.message.$dirty) {
        if (!this.$v.form.message.required) {
          return 'Lock reason is a required field'
        }
      }
    }
  },
  validations: {
    form: {
      message: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      lockedUser: 'users/lockedUser',
      handleToast: 'interaction/toast'
    }),
    async handleSubmit () {
      setImmediate(async () => {
        try {
          this.isLoading = true
          if (this.handler) {
            await this.lockedUser({
              ssoUserId: this.ssoUserId,
              lockReason: this.form.message,
              isLocked: true
            })
            await this.handler()
          }
        } catch (e) {
          await this.handleToast({
            message: 'User not locked'
          })
        } finally {
          this.isLoading = false
          await this.handleToast({
            message: 'User updated'
          })
          await this.handleClose()
        }
      })
    },
    close () {
      this.handleClose()
    }
  }
}
