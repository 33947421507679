import { mapGetters, mapActions, mapState } from 'vuex'
import * as micros from 'src/micros'
import * as components from 'src/components'
import { NavigationMixinFactory } from 'src/mixins'

export default {
  mixins: [
    NavigationMixinFactory()
  ],
  head: {
    title: 'Customer Portal',
    titleTemplate: '%s | Customer Portal',
    htmlAttrs: {
      lang: 'en'
    },
    link: [
      { rel: 'icon', href: '/assets/favicon.ico' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap&subset=latin-ext' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Material+Icons' }
    ],
    meta: [
      { charset: 'utf-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
    ]
  },
  components: {
    ...micros,
    ...components
  },
  computed: {
    ...mapState({
      serverWallets: state => state.wallets.serverWallets
    }),
    ...mapGetters({
      isProfileRegistryLoaded: 'profileRegistry/isLoaded',
      isCurrenciesLoaded: 'currencies/isLoaded',
      isTokensLoaded: 'tokens/isLoaded',
      profileSessionId: 'network/profileSessionId',
      exchangeSessionId: 'network/exchangeSessionId',
      passport: 'passport/user',
      config: 'config/config'
    }),
    isLoaded () {
      const isSelfLoaded = this.isSelfLoaded
      const isCurrenciesLoaded = this.isCurrenciesLoaded
      const isTokensLoaded = this.isTokensLoaded
      const isProfileRegistryLoaded = this.isProfileRegistryLoaded
      return isSelfLoaded && isCurrenciesLoaded && isTokensLoaded && isProfileRegistryLoaded
    },
    pagesOrigin () {
      return this.config.pages.url
    },
    title () {
      return this.$t(this.getRouteProperty('meta.title'))
    }
  },
  data () {
    return {
      isSelfLoaded: false
    }
  },
  async mounted () {
    await Promise.all([
      this.initDevices(),
      this.initPlugins(),
      this.loadProfile()
      // this.loadActivities()
    ])
    await Promise.all([
      this.loadWallets()
    ])
    await Promise.all([
      this.ensureActiveWallet()
    ])
    this.isSelfLoaded = true
  },
  methods: {
    ...mapActions({
      initDevices: 'devices/init',
      initPlugins: 'plugins/init',
      loadWallets: 'wallets/loadWallets',
      loadProfile: 'myProfileKyc/loadProfile',
      ensureWallet: 'wallets/ensureWallet',
      loadActivity: 'myActivities/loadActivity'
    }),
    async ensureActiveWallet () {
      if (this.serverWallets.length === 0) {
        await this.ensureWallet()
      }
    },
    async loadActivities () {
      let localStorage = JSON.parse(window.localStorage.settingsLocalState).profileNotifications
      const params = {
        to: localStorage.switched ? localStorage.readDate : null
      }
      await this.loadActivity(params)
    }
  }
}
