import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object({
  address: Joi.string().allow(null),
  symbol: Joi.string(),
  decimals: Joi.number().min(1).required(),
  contract: Joi.string().allow([null, ''])
})

export default class TokenModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.address || '0x00'
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new TokenModel({
      ...data
    })
  }
}
