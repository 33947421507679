import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  metaInfo: {
    title: 'Verification'
  },
  props: {
    submitTitle: String
  },
  components: {
    ...components
  },
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        birthDate: new Date()
      },
      isBusy: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      personal: 'myProfileKyc/personal',
      levels: 'myProfileKyc/levels'
    }),
    firstNameErrors () {
      if (this.$v.form.firstName.$dirty) {
        if (!this.$v.form.firstName.required) {
          return [
            'First Name is a required field'
          ]
        }
      }
      return []
    },
    lastNameErrors () {
      if (this.$v.form.lastName.$dirty) {
        if (!this.$v.form.lastName.required) {
          return [
            'Last Name is a required field'
          ]
        }
      }
      return []
    },
    birthDateErrors () {
      if (this.$v.form.birthDate.$dirty) {
        if (!this.$v.form.birthDate.required) {
          return [
            'Birth Date is a required field'
          ]
        }
        if (!this.$v.form.birthDate.isBeforeNow) {
          return [
            'Birth Date should be in the past'
          ]
        }
      }
      return []
    },
    isConfirmed () {
      if (this.profile && this.profile.personal) {
        if (this.profile.personal.status === 'ACCEPTED') {
          return true
        }
      } else {
        return false
      }
    }
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      birthDate: {
        required,
        isBeforeNow (value) {
          return moment(value).isBefore(moment())
        }
      }
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler () {
        this.handleReset()
        this.$v.form.$reset()
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      updatePersonal: 'myProfileKyc/updatePersonal'
    }),
    async handleSubmit () {
      this.isBusy = true
      try {
        await this.updatePersonal({
          ...this.form
        })
      } finally {
        this.isBusy = false
      }
    },
    handleReset () {
      Object.assign(
        this.form,
        !this.profile.personal
          ? {
            firstName: '',
            lastName: '',
            birthDate: ''
          }
          : {
            firstName: this.profile.personal.firstName,
            lastName: this.profile.personal.lastName,
            birthDate: this.profile.personal.birthDate
          })
    }
  },
  mounted () {
  }
}
