import Joi from 'joi'
import PluginModel from './PluginModel'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'
import { WithdrawalNetwork } from 'src/dictionaries'

const schemaFactory = () => ({
  id: Joi.string().required(),
  type: Joi.string().valid('CryptoDepositPlugin'),
  minKycLevel: Joi.number().min(0).max(4).required(),
  minAmount: Joi.string().allow([null, '']),
  // TODO: after prod will be updated, remove null from possible values and mark field required
  network: Joi.string().allow([...WithdrawalNetwork.values(), null]),
  blockchainDecimals: Joi.number().allow(null),
  link: Joi.string().allow([null, '']),
  // TODO: add required after prod will be updated
  depositAddressType: Joi.string().allow([null, '']),
  crystalCurrency: Joi.string().allow([null, '']),
  crystalTokenId: Joi.string().allow([null, '']),
  commission: Joi.alternatives().try(
    Joi.object().type(FixedCommissionModel),
    Joi.object().type(PercentCommissionModel),
    Joi.object().type(DefaultCommissionModel)
  ).allow(null)
})

export default class CryptoDepositPluginModel extends PluginModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new CryptoDepositPluginModel({
      id: data.id,
      type: 'CryptoDepositPlugin',
      minKycLevel: Number(data.minKycLevel),
      minAmount: data.minAmount,
      network: data.network,
      blockchainDecimals: data.blockchainDecimals,
      depositAddressType: data.depositAddressType,
      link: data.link,
      crystalTokenId: data.crystalTokenId,
      crystalCurrency: data.crystalCurrency,
      commission: this.prepareCommission(data.commission)
    })
  }
}
