import {omitBy, isNil, minBy} from 'lodash'
import {PROFILE, withAuthorization} from 'src/remotes'
import {REGISTRY} from 'src/services'
import {ActivitiesSubscribeRequest, ActivitiesUnsubscribeRequest, Constants} from 'src/models'

export const ACTIVITY_LOADING = 'activity/loading'
export const ACTIVITY_LOADED = 'activity/loaded'
export const ACTIVITY_PUSHED = 'activity/pushed'
export const ACTIVITY_RECORD_ADDED = 'activity/record/added'
export const ACTIVITY_UPDATES_AFFECTED = 'activity/updates/affected'
export const RESET_COUNT = 'activity/updates/count'
export const CHANGE_SUBSCRIBE_LISTENER = 'activity/updates/changeListener'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      activity: [],
      updatesCount: 0,
      subscribeListener: null,
      cursor: null
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    activity: state => state.activity,
    updatesCount: state => state.updatesCount,
    cursor: state => state.cursor
  },
  mutations: {
    [ACTIVITY_LOADING]: (state) => {
      state.isLoading = true
    },
    [ACTIVITY_LOADED]: (state, { activity, updatesCount, cursor }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        activity,
        updatesCount,
        cursor
      }, isNil))
    },
    [ACTIVITY_PUSHED]: (state, { activity, cursor }) => {
      let newActivity = state.activity.concat(activity)
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        activity: newActivity,
        cursor
      }, isNil))
    },
    [ACTIVITY_RECORD_ADDED]: (state, activity) => {
      state.activity.unshift(activity)
    },
    [ACTIVITY_UPDATES_AFFECTED]: (state, updatesDiff = +1) => {
      state.updatesCount += updatesDiff
    },
    [RESET_COUNT]: (state, newCoumt) => {
      state.updatesCount = newCoumt
    },
    [CHANGE_SUBSCRIBE_LISTENER]: (state, listener) => {
      state.subscribeListener = listener
    }
  },
  actions: {
    async fetchActivity ({ dispatch }, params) {
      const token = await dispatch('passport/requireToken', null, {root: true})
      const { data } = await PROFILE.get(`/security/me/activity/history`, withAuthorization(token, {
        params: {
          ...params,
          types: Object.values(Constants.notifications.availables.user)
        }
      }))
      return data
    },
    async loadActivity ({ state, commit, dispatch }, params) {
      if (!state.isLoading) {
        commit(ACTIVITY_LOADING)
        const dateStep = JSON.parse(window.localStorage.settingsLocalState).profileNotifications.readDate || null
        const activity = await dispatch('fetchActivity', { countFrom: dateStep, to: params.to })
        const cursor = minBy(activity.items, e => e.timestamp)
        commit(ACTIVITY_LOADED, { activity: activity.items, updatesCount: activity.count, cursor })
      }
    },
    async loadMoreActivity ({ state, commit, dispatch }) {
      if (!state.isLoading) {
        commit(ACTIVITY_LOADING)
        const activity = await dispatch('fetchActivity', { to: state.cursor.timestamp, id: state.cursor.id })
        const cursor = minBy(activity.items, e => e.timestamp)
        commit(ACTIVITY_PUSHED, { activity: activity.items, updatesCount: activity.count, cursor })
      }
    },
    async subscribe ({commit, dispatch}, { sessionId }) {
      const token = await dispatch('passport/requireToken', null, {root: true})
      const listener = async m => {
        if (m.type === 'create' && m.channel === 'activity') {
          commit(ACTIVITY_UPDATES_AFFECTED, +1)
          commit(ACTIVITY_RECORD_ADDED, m.payload)
        }
      }

      commit(CHANGE_SUBSCRIBE_LISTENER, listener)

      const activitySocketClient = REGISTRY.getService('profileSocketClient')
      activitySocketClient
        .on('message', listener)
        .ensure(new ActivitiesSubscribeRequest({
          requestId: `profile-session-${sessionId}`,
          payload: {
            token
          }
        }))
      return listener
    },
    async unsubscribe ({ state, commit }, subscription) {
      const profileSocketClient = REGISTRY.getService('profileSocketClient')
      profileSocketClient
        .send(new ActivitiesUnsubscribeRequest({
          requestId: `profile-session-${subscription.sessionId}`
        }))
        .removeListener('message', subscription.listener)
      commit(RESET_COUNT, +0)
    }
  }
})
