import Joi from 'joi'
import BigNumber from 'bignumber.js'
import { AbstractModel } from 'src/models/core'
import CurrencyDAOModel from './CurrencyDAOModel'

export const schemaFactory = () => ({
  currency: Joi.object().type(CurrencyDAOModel.model).required(),
  value: Joi.object().type(BigNumber).allow(null)
})

export default class PlasmaAmountModel extends AbstractModel {
  constructor (data, options) {
    super(Object.assign({
      value: null
    }, data), schemaFactory(), options)
    Object.freeze(this)
  }

  get amount () {
    return this.currency.dao.removeDecimals(this.value)
  }

  formatString (isSigned = true) {
    return this.currency.dao.formatValueString(this.currency.currency.symbol, this.value, isSigned)
  }
}
