import { NavigationMixinFactory } from 'src/mixins'

export default {
  mixins: [
    NavigationMixinFactory()
  ],
  computed: {
    title () {
      return this.getRouteProperty('meta.title')
    }
  }
}
