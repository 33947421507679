import BigNumber from 'bignumber.js'

export default (value, comparison = 0, number = 'positive') => {
  if (value != null) {
    let numb = new BigNumber(value)
    if (number === 'positive') {
      if (numb.isPositive()) return numb.isGreaterThan(comparison)
    } else if (number === 'negative') {
      if (numb.isNegative()) return numb.isGreaterThan(comparison)
    }
  }
  return false
}
