import * as components from 'src/components'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    ...components
  },
  data () {
    return {
      filters: {
        from: moment().subtract(14, 'days').startOf('day').toDate(),
        to: moment().subtract(1, 'days').endOf('day').toDate()
      },
      isExporting: false,
      isMobile: window.innerWidth <= 1200
    }
  },
  computed: {
    ...mapGetters({
      marketStats: 'stats/marketStats'
    })
  },
  async created () {
    addEventListener('resize', this.resizeEventListener)

    await this.reloadData()
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    ...mapActions({
      loadMarketStats: 'stats/loadMarketStats',
      export: 'stats/exportMarketStats'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    handleFrom (value) {
      this.filters.from = moment(value, 'YYYY-MM-DD').startOf('day').toDate()
      this.reloadData()
    },
    handleTo (value) {
      this.filters.to = moment(value, 'YYYY-MM-DD').endOf('day').toDate()
      this.reloadData()
    },
    async reloadData () {
      await this.loadMarketStats({
        from: this.filters.from,
        to: this.filters.to
      })
    },
    extractFromDate (date) {
      return moment.utc(date).startOf('day').format('YYYY-MM-DD')
    },
    extractToDate (date) {
      return moment.utc(date).endOf('day').format('YYYY-MM-DD')
    },
    async handleExport () {
      this.isExporting = true
      setImmediate(async () => {
        try {
          const { exportLink } = await this.export({
            from: this.filters.from,
            to: this.filters.to
          })

          this.createExportLinkAndClick({
            href: exportLink
          })
        } finally {
          this.isExporting = false
        }
      })
    },
    createExportLinkAndClick ({ href }) {
      const el = document.createElement('a')
      el.setAttribute('href', href)
      el.setAttribute('download', true)
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
    }
  }
}
