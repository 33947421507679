import { mapActions } from 'vuex'
import { OperationCanceledError } from 'src/errors'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    ssoUserId: String,
    verificator: String
  },
  data () {
    return {
      isConfirmLoading: false,
      email: null
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleError: 'interaction/error',
      reSand2faEmail: 'customers/reSand2faEmail'
    }),
    async handleSubmit () {
      this.isConfirmLoading = true
      setImmediate(async () => {
        try {
          await this.reSand2faEmail({
            ssoUserId: this.ssoUserId,
            verificator: this.verificator,
            email: this.email
          })
          await this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isConfirmLoading = false
        }
      })
    }
  }
}
