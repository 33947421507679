import uniqid from 'uniqid'
import {mapActions, mapGetters} from 'vuex'
import set from 'lodash/set'

export default {
  props: {
    tagsList: Array
  },
  computed: {
    ...mapGetters({
    })
  },
  data () {
    return {
      tagText: []
    }
  },
  methods: {
    ...mapActions({
    }),
    handleTagAdd () {
      this.$emit('tagAdd', {
        id: uniqid(),
        property: '',
        content: ''
      })
    },
    updateTagField (tag, field, value) {
      this.tagsList.find((e) => {
        if (e.id === tag.id) {
          set(tag, field, value)
        }
      })
      this.$emit('updateTagField', tag)
    },
    handleTagRemove (tag) {
      this.$emit('tagRemove', tag.id)
    }
  }
}
