import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  type: Joi.string().required(),
  timestamp: Joi.number().required(),
  domain: Joi.string().allow([null, '']),
  userId: Joi.string().allow([null, '']),
  ssoUserId: Joi.string().allow([null, '']),
  device: Joi.object().keys({
    browser: Joi.string().allow([null, '']),
    os: Joi.string().allow([null, '']),
    ip: Joi.string().allow([null, ''])
  })
})

export default class ActivityEntryModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new ActivityEntryModel(data, { stripUnknown: true })
  }
}
