import * as xrlpCodec from 'xrpl-tagged-address-codec'

export default () => ({
  namespaced: true,
  getters: {
    getScoutTransactionReference: (_1, _2, _3, rootGetters) => (token, txid, network) => {
      if (token == null || txid == null || network == null) {
        return null
      }

      const config = rootGetters['config/config']
      let cfg = config.txLinks[network]

      let result = null
      if (cfg) {
        result = cfg.url.format({ txHash: txid })
      }
      return result
    },
    getScoutAddressReference: (_1, _2, _3, rootGetters) => (depositAddressType, address) => {
      if (depositAddressType == null || address == null) {
        return null
      }

      const config = rootGetters['config/config']
      const { currencyByType } = config.depsoitAddressess

      const currency = rootGetters['currencies/getCurrencyBySymbol'](currencyByType[depositAddressType])

      let blockchairSuffix = currency.currency.blockchairAddressReferenceSuffix
      const convertedAddress = convertAddressIfNeeded(address, currency.currency)

      const response = {
        address,
        depositAddressType,
        url: `https://blockchair.com/${blockchairSuffix}/${convertedAddress}`
      }

      return response
    }
  }
})

function convertAddressIfNeeded (address, currency) {
  if (currency.symbol === 'XRP') {
    if (address.toLowerCase().startsWith('x')) {
      const decoded = xrlpCodec.Decode(address)
      return decoded.account
    }
  }
  return address
}
