import { ErrorModal, ConfirmationModal, PromptModal } from 'src/modals'
import { EventToast } from 'src/toasts'

export default () => ({
  namespaced: true,
  actions: {
    async error ({ dispatch }, { title, message, error, verificationLink, closeTitle }) {
      let msg
      if (message) {
        msg = message
      } else if (error.response) {
        msg = JSON.stringify(error.response.data)
      } else {
        msg = error.message
      }
      return dispatch('modals/open', {
        factory: () => ErrorModal,
        data: {
          title,
          message: msg,
          error,
          verificationLink,
          closeTitle
        }
      }, { root: true })
    },
    async confirmation ({ dispatch }, { title, message, submitTitle, resetTitle, handler }) {
      return dispatch('modals/open', {
        factory: () => ConfirmationModal,
        data: {
          title,
          message,
          submitTitle,
          resetTitle,
          handler
        }
      }, { root: true })
    },
    async prompt ({ dispatch }, { title, message, submitTitle, resetTitle, label, handler }) {
      return dispatch('modals/open', {
        factory: () => PromptModal,
        data: {
          title,
          label,
          message,
          submitTitle,
          resetTitle,
          handler
        }
      }, { root: true })
    },
    async toast ({ dispatch }, { type = 'INFO', message, closeTitle, timeout } = { type: 'INFO' }) {
      return dispatch('toasts/open', {
        factory: () => EventToast,
        timeout,
        data: {
          type,
          message,
          closeTitle
        }
      }, { root: true })
    }
  }
})
