import Joi from 'joi'
import PluginModel from './PluginModel'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'

const schemaFactory = () => ({
  type: Joi.string().valid('FaucetDepositPlugin'),
  minKycLevel: Joi.number().min(0).max(4).required(),
  minAmount: Joi.string().allow([null, '']),
  commission: Joi.alternatives().try(
    Joi.object().type(FixedCommissionModel),
    Joi.object().type(PercentCommissionModel),
    Joi.object().type(DefaultCommissionModel)
  ).allow(null)
})

export default class FaucetDepositPluginModel extends PluginModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new FaucetDepositPluginModel({
      type: 'FaucetDepositPlugin',
      minKycLevel: Number(data.minKycLevel),
      minAmount: data.minAmount,
      commission: this.prepareCommission(data.commission)
    })
  }
}
