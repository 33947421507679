import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'
import * as micros from 'src/micros'
import { makeValidationErrors } from 'src/utils'
// , isValidPhoneNumber, getRelevantPhoneExample
export default {
  components: {
    ...components,
    ...micros
  },
  props: {
  },
  async created () {
    if (this.phone) {
      this.form.phone = this.phone.phone
    } else {
      this.form.phone = ''
    }
  },
  data () {
    return {
      form: {
        phone: ''
      },
      phoneConfirm: '',
      phoneValidatePending: true,
      codeConfirm: null,
      timerEnd: false,
      timerReset: true
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      phone: 'myProfileKyc/phone',
      levels: 'myProfileKyc/levels'
    }),
    phoneErrors () {
      return makeValidationErrors(this.$v.form.phone, {
        required: 'Phone is a required field',
        phoneNumber: 'Must match the pattern: +XXX...'
      })
    },
    phoneConfirmErrors () {
      if (this.codeConfirm === false) {
        return 'Wrong Confirm Code'
      }
    },
    isConfirmed () {
      if (this.profile && this.profile.phone) {
        if (this.profile.phone.status === 'ACCEPTED') {
          return true
        }
      } else {
        return false
      }
    }
  },
  validations: {
    form: {
      phone: {
        required,
        phoneNumber: value => /^\+\d+$/.test(value)
      }
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler () {
        this.handleReset()
        this.$v.form.$reset()
      }
    }
  },
  methods: {
    ...mapActions({
      updatePhone: 'myProfileKyc/updatePhone',
      validatePhone: 'myProfileKyc/validatePhone',
      phoneResendConfirm: 'myProfileKyc/resendPhoneConfirmation'
    }),
    async handleCodeInput (value) {
      if (value.length === 5) {
        const response = await this.validatePhone({
          code: this.phoneConfirm
        })
        this.codeConfirm = response.isConfirmed
        this.$nextTick(() => {
          if (this.phone && this.phone.status === 'ACCEPTED') {
            this.$emit('changeStep', 1)
          }
        })
      }
    },
    async handleReset () {
      if (this.phone) {
        this.form.phone = this.phone.phone
      } else {
        this.form.phone = ''
      }
    },
    async handleSubmit () {
      this.phoneValidatePending = false
      setImmediate(async () => {
        await this.updatePhone({
          ...this.form
        })
      })
    },
    async handleResendPhone () {
      this.phoneValidatePending = false
      this.timerReset = false
      setTimeout(() => {
        this.timerReset = true
      }, 50)
      setImmediate(async () => {
        await this.phoneResendConfirm({
          ...this.form
        })
      })
    },
    changeNumber () {
      this.phoneValidatePending = true
      this.timerEnd = false
    }
  }
}
