import moment from 'moment'
import BigNumber from 'bignumber.js'
import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'

export default {
  metaInfo: {
    title: 'Purchases'
  },
  components: {
    ...components,
    ...micros
  },
  computed: {
    ...mapGetters({
      purchases: 'myPurchases/items',
      isLoaded: 'myPurchases/isLoaded',
      isLoading: 'myPurchases/isLoading',
      actualDate: 'myPurchases/actualDate',
      activePage: 'myPurchases/activePage',
      numberOfElements: 'myPurchases/numberOfElements',
      totalPages: 'myPurchases/totalPages',
      getCurrencyBySymbol: 'currencies/getCurrencyBySymbol',
      exchangeCurrency: 'general/exchangeCurrency'
    }),
    states () {
      return [
        { value: '*', label: 'All states' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'CANCELLED', label: 'Cancelled' },
        { value: 'ACCEPTED', label: 'Accepted' },
        { value: 'REJECTED', label: 'Rejected' },
        { value: 'CONFIRMED', label: 'Confirmed' },
        { value: 'COMPLETE', label: 'Complete' }
      ]
    },
    items () {
      return this.purchases.map((purchase, index) => ({
        index,
        purchase,
        currency: this.getCurrencyBySymbol(purchase.symbol)
      }))
    },
    groups () {
      const grouped = this.items
        .reduce((target, item) => {
          const m = moment(item.purchase.createdAt).startOf('day')
          const key = m.format('YYYY-MM-DD')
          if (!(key in target)) {
            target[key] = {
              key,
              moment: m,
              items: []
            }
          }
          target[key].items.push(item)
          return target
        }, Object.create(null))
      return Object
        .values(grouped)
        .sort((item1, item2) => {
          return item2.moment.diff(item1.moment)
        })
    }
  },
  data () {
    return {
      filters: {
        state: '*' // Show all by default
      },
      visibleCount: 8,
      commission: { percent: 0, min: 0, max: 0 }
    }
  },
  created () {
    this.handleRefresh()
  },
  methods: {
    momentUtc: moment.utc,
    ...mapActions({
      loadMorePurchases: 'myPurchases/loadMore',
      cancelPurchase: 'myPurchases/cancelPurchase',
      openModal: 'modals/open',
      handleError: 'interaction/error',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast'
    }),
    async handleState (state) {
      this.filters.state = state
      await this.loadMorePurchases({
        reset: true,
        filters: this.filters
      })
    },
    async handleRefresh () {
      await this.loadMorePurchases({
        reset: true,
        filters: this.filters
      })
    },
    async handleLoadMore () {
      await this.loadMorePurchases({
        filters: this.filters
      })
    },
    async handleCancel ({ purchase }) {
      await this.handleConfirmation({
        handler: async () => {
          await this.cancelPurchase({
            id: purchase.id
          })
          await this.handleToast({
            message: 'Purchase cancelled'
          })
        }
      })
    },
    async handleActivePage (value) {
      this.loadMorePurchases({
        filters: this.filters,
        page: {
          activePage: value,
          numberOfElements: this.numberOfElements
        }
      })
    },
    getAmount (operation) {
      if (operation.realAmount) {
        return new BigNumber(operation.realAmount)
          .plus(operation.realCommissionAmount)
      } else {
        return new BigNumber(operation.amount)
          .plus(operation.commissionAmount)
      }
    }
  }
}
