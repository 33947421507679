import Web3 from 'web3'
import { decodeSignature } from 'eth-lib/lib/account'
import AbstractDevice from '../AbstractDevice'
// import { WalletEntryModel } from 'src/models'

const LOCK = 'MetamaskPlugin'

export default class MetamaskPlugin extends AbstractDevice {
  get name () {
    return 'metamask'
  }

  get title () {
    return 'Metamask Plugin'
  }

  get isConnected () {
    return !!this.web3
  }

  async init () {
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    } else if (window.web3) {
      this.web3 = new Web3(window.web3.currentProvider)
    }

    this.emit('connected')

    // eslint-disable-next-line
    console.log('MetamaskPlugin initialized')
  }

  async getAddressInfoList () {
    const accounts = await this.web3.eth.getAccounts()
    return accounts.map(address => ({
      address
    }))
  }

  // eslint-disable-next-line
  async signTransaction (address, { gas, gasPrice, ...txData }) { // do not pass gas & gasPrice to sendTransaction of the Metamask plugin
    const signed = await this.web3.eth.sendTransaction({
      ...txData,
      from: address
    })
    return signed
  }

  async signData (address, data) {
    const signature = await this.web3.eth.personal.sign(data, address)

    const [v, r, s] = decodeSignature(signature)
    return {
      signature,
      v,
      r,
      s
    }
  }

  async withSigner (entry, callable) {
    return this._safeExec(
      async () => {
        return this._withSigner(entry, callable)
      }
    )
  }

  getLock () {
    return LOCK
  }
}
