import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  name: Joi.string().allow([null, '']),
  isLocked: Joi.boolean(),
  createdAt: Joi.date().allow(null),
  updatedAt: Joi.date().allow(null),
  credentials: Joi.array().allow(null),
  checks: Joi.array().allow(null),
  socials: Joi.array().allow(null),
  grants: Joi.array().allow(null),
  referralLinks: Joi.array().allow(null),
  verificators: Joi.array().allow(null)
})

export default class UserDetails extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new UserDetails(
      { ...data },
      { stripUnknown: true }
    )
  }
}
