import { render, staticRenderFns } from "./CurrencyPage.htm?vue&type=template&id=6c445706&scoped=true&external"
import script from "./CurrencyPage.js?vue&type=script&lang=js&external"
export * from "./CurrencyPage.js?vue&type=script&lang=js&external"
import style0 from "./CurrencyPage.scss?vue&type=style&index=0&id=6c445706&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c445706",
  null
  
)

export default component.exports