import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  market: Joi.string().required(),
  tradeVolume: Joi.string().required(),
  buyVolume: Joi.string().required(),
  sellVolume: Joi.string().required(),
  countBuyOrders: Joi.number().required(),
  countSellOrders: Joi.number().required(),
  totalFeePayedVolume: Joi.array().items(Joi.object().keys({
    currency: Joi.string().required(),
    volume: Joi.string().required()
  }))
})

export default class MarketStatsItemModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new MarketStatsItemModel(data, { stripUnknown: true })
  }
}
