import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { makeValidationErrors } from 'src/utils'
import * as micros from 'src/micros'

export default {
  components: {
    ...micros
  },
  props: {
  },
  async created () {
    if (this.email) {
      this.form.email = this.email.email
    } else {
      this.form.email = ''
    }
  },
  data () {
    return {
      form: {
        email: ''
      },
      emailConfirm: '',
      emailValidatePending: true,
      codeConfirm: null,
      timerEnd: false,
      timerReset: true
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      isLoaded: 'myProfileKyc/isLoaded',
      email: 'myProfileKyc/email',
      levels: 'myProfileKyc/levels'
    }),
    emailConfirmErrors () {
      if (this.codeConfirm === false) {
        return 'Wrong Confirm Code'
      }
    },
    emailErrors () {
      return makeValidationErrors(this.$v.form.email, {
        required: 'Email is a required field',
        email: 'Invalid Email'
      })
    },
    isConfirmed () {
      if (this.profile && this.profile.email) {
        if (this.profile.email.status === 'ACCEPTED') {
          return true
        }
      } else {
        return false
      }
    }
  },
  validations: {
    form: {
      email: { required, email }
    }
  },
  methods: {
    moment,
    ...mapActions({
      updateEmail: 'myProfileKyc/updateEmail',
      validateEmail: 'myProfileKyc/validateEmail',
      emailResendConfirm: 'myProfileKyc/resendEmailConfirmation'
    }),
    async handleEmailCodeInput (value) {
      if (value.length === 5) {
        const response = await this.validateEmail({
          code: this.emailConfirm
        })
        this.codeConfirm = response.isConfirmed
        this.$nextTick(() => {
          if (this.email && this.email.status === 'ACCEPTED') {
            this.$emit('changeStep', 1)
          }
        })
      }
    },
    async handleReset () {
      if (this.email) {
        this.form.email = this.email.email
      } else {
        this.form.email = ''
      }
    },
    async handleSubmit () {
      this.emailValidatePending = false
      setImmediate(async () => {
        await this.updateEmail({
          ...this.form
        })
      })
    },
    async handleResendEmail () {
      this.emailValidatePending = false
      this.timerReset = false
      setTimeout(() => {
        this.timerReset = true
      }, 50)
      setImmediate(async () => {
        await this.emailResendConfirm({
          ...this.form
        })
      })
    },
    changeNumber () {
      this.emailValidatePending = true
      this.timerEnd = false
    }
  }
}
