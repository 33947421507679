import moment from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'
import * as components from 'src/components'
import { EditMonoovaBankModal } from 'src/modals'

export default {
  components: {
    ...components
  },
  props: {
    ssoUserId: String,
    isMobile: Boolean
  },
  data () {
    return {}
  },
  async created () {
    this.clearUserMonoovaBank()
    await this.getUserMonoovaBank({ssoUserId: this.ssoUserId})
  },
  computed: {
    ...mapState({
      bank: state => state.monoova.bank
    }),
    ...mapGetters({
      hasMonoovaUserActionsAccess: 'grants/hasMonoovaUserActionsAccess'
    })
  },
  methods: {
    moment,
    ...mapActions({
      getUserMonoovaBank: 'monoova/getUserMonoovaBank',
      clearUserMonoovaBank: 'monoova/clearUserMonoovaBank',
      openModal: 'modals/open'
    }),
    async handleEdit () {
      this.openModal({
        factory: () => EditMonoovaBankModal,
        data: {
          bank: this.bank.value,
          ssoUserId: this.ssoUserId
        }
      })
    }
  }
}
