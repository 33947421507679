import {mapActions, mapGetters} from 'vuex'
import {CurrencyFiatEdit} from 'src/modals'

export default {
  props: {
    currencyFiat: Object
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleError: 'interaction/error',
      deleteFiatCurrency: 'currencies/deleteFiatCurrency'
    }),
    async handleEdit () {
      this.openModal({
        factory: () => CurrencyFiatEdit,
        data: {
          currencyFiat: this.currencyFiat
        }
      })
    },
    async handleDelete (id) {
      await this.handleConfirmation({
        message: 'Do you really want to delete this fiat currency?',
        handler: async () => {
          await this.deleteFiatCurrency({
            id: id
          })
          this.handleClose()
          await this.handleToast({
            message: 'Currency deleted'
          })
        }
      })
    }
  }
}
