import Joi from 'joi'
import AbstractModel from './AbstractModel'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  name: Joi.string().allow(null, ''),
  isLocked: Joi.boolean(),
  grants: Joi.array().items(
    Joi.object().keys({
      id: Joi.string().required(),
      resource: Joi.string().required(),
      role: Joi.string().required()
    })
  ).allow(null),
  credentials: Joi.array().items(
    Joi.object().keys({
      id: Joi.string().required(),
      type: Joi.string().required(),
      login: Joi.string().allow(null, ''),
      state: Joi.string().allow(null, ''),
      isConfirmed: Joi.boolean(),

      // social fields
      socialId: Joi.string().allow(null, ''),
      firstName: Joi.string().allow(null, ''),
      lastName: Joi.string().allow(null, '')
    })
  ).allow(null),
  verificators: Joi.array().items(
    Joi.object().keys({
      id: Joi.string().required(),
      type: Joi.string().required()
    })
  ).allow(null)
})

export default class PassportUserModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new PassportUserModel({
      ...data
    }, { stripUnknown: true })
  }

  hasVerificator () {
    return this.verificators != null && this.verificators.length > 0
  }
}
