/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tower': {
    width: 27,
    height: 36,
    viewBox: '0 0 27 36',
    data: '<path pid="0" d="M13.47 29.7h12.638v4.78H13.471M13.545 22.661h6.344l3.035 7.038h-9.453M13.545 22.661h9.007l-3.176-3.308-1.232-9.321H13.47" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M13.638 29.7H1v4.78h12.638M13.563 22.661H7.219L4.184 29.7h9.453M13.563 22.661H4.557l3.176-3.308 1.232-9.321h4.673M6.55 1v9.032h14.003V1h-2.812v4.144H15.41V1h-3.813v4.144H9.28V1H6.55z" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
