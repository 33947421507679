import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object({
  key: Joi.string().required(),
  symbol: Joi.string().required(),
  name: Joi.string().required(),
  totalDeposited: Joi.number()
})

export default class FiatCurrencyModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }

    return new FiatCurrencyModel({
      key: data.key,
      name: data.name,
      symbol: data.symbol,
      totalDeposited: data.totalDeposited
    })
  }
}
