import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { EthereumPocketModel } from 'src/models'
import * as components from 'src/components'

export default {
  props: {
    pocket: EthereumPocketModel,
    pockets: VueTypes.arrayOf(EthereumPocketModel)
  },
  components: {
    ...components
  },
  async mounted () {
    if (this.pocket != null) {
      this.subscription = {
        pocket: this.pocket,
        listener: await this.subscribe({
          pocket: this.pocket
        })
      }
    }
    if (this.pockets != null) {
      this.subscriptions = await Promise.all(
        this.pockets.map(async pocket => ({
          pocket,
          listener: await this.subscribe({
            pocket: pocket
          })
        }))
      )
    }
  },
  async destroyed () {
    if (this.subscription) {
      await this.unsubscribe(this.subscription)
      this.subscription = null
    }
    if (this.subscriptions) {
      await Promise.all(this.subscriptions.map(s => this.unsubscribe(s)))
      this.subscriptions = null
    }
  },
  render () {
    if (this.$slots.default) {
      if (this.$slots.default.length === 1) {
        return this.$slots.default[0]
      } else if (this.$slots.default.length > 1) {
        throw new Error('BalanceSubscription component may contain max one child')
      }
    }
    return null
  },
  methods: {
    ...mapActions({
      subscribe: 'ethereumBalances/subscribe',
      unsubscribe: 'ethereumBalances/unsubscribe'
    })
  }
}
