class DepositAddressType {
  static ETHEREUM = new DepositAddressType('ETHEREUM', 'ETHEREUM')
  static BITCOIN = new DepositAddressType('BITCOIN', 'BITCOIN')
  static BITCOIN_TEST = new DepositAddressType('BITCOIN_TEST', 'BITCOIN_TEST')
  static LITECOIN = new DepositAddressType('LITECOIN', 'LITECOIN')
  static LITECOIN_TEST = new DepositAddressType('LITECOIN_TEST', 'LITECOIN_TEST')
  static RIPPLE = new DepositAddressType('RIPPLE', 'RIPPLE')
  static EOS = new DepositAddressType('EOS', 'EOS')
  static RONIN = new DepositAddressType('RONIN', 'RONIN')
  static WAVES = new DepositAddressType('WAVES', 'WAVES')
  static TRON = new DepositAddressType('TRON', 'TRON')

  static values () {
    return Object.values(DepositAddressType).map(e => e.name)
  }

  constructor (name, label) {
    this.name = name
    this.label = label
  }
}

export default DepositAddressType
