import {
  AddressOperationModel
} from 'src/models'

import {
  PLASMA,
  BACKEND,
  withAuthorization
} from 'src/remotes'

export default () => ({
  namespaced: true,
  actions: {
    async fetchPlasmaAddressOperationStatus ({ dispatch, state }, { address, operationType }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get(`/admin/account/${address}/operation/${operationType}/status`, withAuthorization(token))
      return AddressOperationModel.fromJson(data)
    },
    async fetchBackendAddressOperationStatus ({ dispatch, state }, { address, operationType }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`/manager/account/${address}/operation/${operationType}/status`, withAuthorization(token))
      return AddressOperationModel.fromJson(data)
    },
    async blockPlasmaAddressOperation ({ dispatch, state }, { address, operationType }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.post(`/admin/account/block`, {
        address,
        blockType: operationType
      }, withAuthorization(token))
      return AddressOperationModel.fromJson(data)
    },
    async unblockPlasmaAddressOperation ({ dispatch, state }, { address, operationType }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.post(`/admin/account/unblock`, {
        address,
        blockType: operationType
      }, withAuthorization(token))
      return AddressOperationModel.fromJson(data)
    },
    async blockExchangeAddressOperation ({ dispatch, state }, { address, operationType }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`/manager/account/block`, {
        address,
        operationType
      }, withAuthorization(token))
      return AddressOperationModel.fromJson(data)
    },
    async unblockExchangeAddressOperation ({ dispatch, state }, { address, operationType }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`/manager/account/unblock`, {
        address,
        operationType
      }, withAuthorization(token))
      return AddressOperationModel.fromJson(data)
    }
  }
})
