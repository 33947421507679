import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Deposits'
  },
  components: {
    ...components
  },
  data () {
    return {
      visibleCount: 10,
      filters: {
        statuses: null,
        types: null
      },
      isMobile: window.innerWidth <= 1200
    }
  },
  computed: {
    ...mapGetters({
      activePage: 'manageDepositsAddresses/activePage',
      limit: 'manageDepositsAddresses/limit',
      count: 'manageDepositsAddresses/count',
      depositAddresses: 'manageDepositsAddresses/items',
      totalPages: 'manageDepositsAddresses/totalPages'
    }),
    items () {
      return this.depositAddresses.value
    },
    statusesOptions () {
      return [
        { value: 'PENDING', label: 'Pending' },
        { value: 'COMPLETE', label: 'Complete' },
        { value: 'REMOVING', label: 'Removing' },
        { value: 'REMOVED', label: 'Removed' }
      ]
    },
    typeOptions () {
      return [
        { value: 'ETHEREUM', label: 'Ethereum' },
        { value: 'BITCOIN', label: 'Bitcoin' },
        { value: 'BITCOIN_TEST', label: 'Bitcoin Test' },
        { value: 'RIPPLE', label: 'Ripple' },
        { value: 'LITECOIN', label: 'Litecoin' },
        { value: 'LITECOIN_TEST', label: 'Litecoin Test' },
        { value: 'EOS', label: 'Eos' },
        { value: 'RONIN', label: 'Rronin' },
        { value: 'WAVES', label: 'Waves' }
      ]
    }
  },
  async created () {
    await this.handleLoad()
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    ...mapActions({
      loadDepositAddresses: 'manageDepositsAddresses/loadDepositAddresses',
      refreshDepositAddress: 'manageDepositsAddresses/refreshDepositAddress',
      removeDepositAddress: 'manageDepositsAddresses/removeDepositAddress',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast',
      handleClose: 'modals/close'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    async handleLoad () {
      await this.loadDepositAddresses({
        filters: this.filters,
        page: {
          activePage: 1,
          count: this.count
        }
      })
    },
    async handleClearStatuses () {
      this.filters.statuses = null
      await this.handleLoad()
    },
    async handleClearTypes () {
      this.filters.type = null
      await this.handleLoad()
    },
    async handleStatuses (statuses) {
      this.filters.statuses = statuses
      await this.handleLoad()
    },
    async handleType (types) {
      this.filters.types = types
      await this.handleLoad()
    },
    async handleActivePage (value) {
      await this.loadDepositAddresses({
        filters: this.filters,
        page: {
          activePage: value,
          count: this.count
        }
      })
    },
    async handleRefresh (id) {
      await this.refreshDepositAddress({id})
      await this.handleLoad()
    },
    async handleRemove (id) {
      await this.handleConfirmation({
        handler: async () => {
          await this.removeDepositAddress({id})
          this.handleClose()
          await this.handleToast({
            message: 'Deposit Address removed'
          })
          await this.handleLoad()
        }
      })
    }
  }
}
