/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-profile': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a10 10 0 100 20 10 10 0 000-20zM8 8h8a1 1 0 01.968.761L12 11.863 7.035 8.752A1 1 0 018 8zm9 7a1 1 0 01-1 1H8a1 1 0 01-1-1V9.867L12 13l5-3.122V15z"/>'
  }
})
