import { PROFILE, withAuthorization } from 'src/remotes'
import { omitBy, isNil } from 'lodash'

const CHAT_LOADED = 'chat/loaded'
const CHAT_LOADING = 'chat/loading'
const UPDATE_CHANNEL = 'chat/channel/update'

export default () => ({
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    activePage: 1,
    numberOfElements: 10, // page size
    limit: 100,
    items: [],
    total: null
  },
  mutations: {
    [CHAT_LOADING] (state, users) {
      state.isLoading = true
    },
    [CHAT_LOADED]: (state, { items }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        items
      }, isNil))
    },
    [UPDATE_CHANNEL] (state, channel) {
      const ch = state.items.findIndex(e => e.name === channel.name)
      state.items[ch] = channel
      state.isLoading = false
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    limit: state => state.limit,
    items: state => state.items,
    totalPages (state) {
      const count = state.total
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async loadChatChannels ({ state, commit, dispatch }) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('/chat/channels', withAuthorization(token))
      commit(CHAT_LOADED, { items: data })
    },
    async add ({ commit, dispatch }, { name, displayName, description }) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post(`/chat/manager/channels`, {
        name: name,
        displayName: displayName,
        description: description
      }, withAuthorization(token))
      dispatch('loadChatChannels')
    },
    async update ({ commit, dispatch }, { name, displayName, description }) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`/chat/manager/channels/n/${name}/update`, {
        name: name,
        displayName: displayName,
        description: description
      }, withAuthorization(token))
      commit(UPDATE_CHANNEL, data)
      dispatch('loadChatChannels')
    },
    async delete ({ commit, dispatch }, { name }) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post(`/chat/manager/channels/n/${name}/delete`, null, withAuthorization(token))
      dispatch('loadChatChannels')
    }
  }
})
