import { mapGetters } from 'vuex'
// import { oauthPassportAuthenticatorURL } from 'src/authenticator'

export default {
  props: {
    src: String,
    title: String,
    text: String
  },
  data () {
    return {
      form: {
        email: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    })
  },
  methods: {
    handleSubmit () {
      // const url = oauthPassportAuthenticatorURL(this.config, null, { mode: 'signup', login: this.form.email })
      // document.location.href = url
    }
  }
}
