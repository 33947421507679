import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    message: String,
    closeTitle: String,
    handler: Function,
    error: Error,
    verificationLink: Boolean
  },
  data () {
    return {
      showTrace: false
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    })
  },
  mounted () {
    this.$refs.close.$el.focus()
  }
}
