import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  title: Joi.string().required(),
  address: Joi.string().required()
})

export default class SpecialAddressItemModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new SpecialAddressItemModel(data, { stripUnknown: true })
  }
}
