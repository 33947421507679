import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.number(),
  encrypted: Joi.any()
})

export default class WalletClientModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return `wallet-client-${this.id}`
  }

  static fromJson (data) {
    return new WalletClientModel({
      id: data.id,
      encrypted: data.encrypted
    })
  }
}
