import { debounce, pickBy, isEmpty, isEqual } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Profiles'
  },
  components: {
    ...components
  },
  data () {
    return {
      isExportBusy: false,
      filters: {},
      types: {
        'emailCredential': 'Email (registration)',
        'emailKyc': 'Email (kyc)',
        'wallet': 'Wallet',
        'ssoUserId': 'Passport id'
      },
      typeOfSearch: '',
      search: '',
      isMobile: window.innerWidth <= 1024
    }
  },
  computed: {
    ...mapGetters({
      customers: 'customers/items',
      isLoaded: 'customers/isLoaded',
      isLoading: 'customers/isLoading',
      actualDate: 'customers/actualDate',
      activePage: 'customers/activePage',
      numberOfElements: 'customers/numberOfElements',
      totalPages: 'customers/totalPages'
    }),
    searchOptions () {
      let options = []
      for (const [key, value] of Object.entries(this.types)) {
        options.push({
          key: `${key}`,
          value: `${value}`
        })
      }
      return options
    }
  },
  async created () {
    await this.clearCustomers()
    await this.init()
    if (!isEmpty(this.$route.query)) {
      await this.handleSearch()
    }
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    ...mapActions({
      loadMoreCustomers: 'customers/loadMore',
      clearCustomers: 'customers/clearCustomers'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1024
    },
    async init () {
      this.filters = pickBy(this.$route.query, (value, key) => {
        return Object.keys(this.types).includes(key) || key === 'page'
      })

      const filter = Object.entries(this.filters).find(([key]) => Object.keys(this.types).includes(key))
      if (filter) {
        this.typeOfSearch = filter[0]
        this.search = filter[1]
      }
    },
    toCustomer (item) {
      this.$router.replace({
        query: null
      })
      this.$router.push({
        path: `i/${item.id}`
      })
    },
    async chooseType (value) {
      this.typeOfSearch = value
      this.search = ''
      await this.handleClear()
    },
    async handleSearch () {
      if (this.search && this.typeOfSearch) {
        Object.assign(this.filters, {
          [this.typeOfSearch]: this.search.trim()
        })
        await this.handleLoad()
      } else {
        this.filters = {}
      }
    },
    handleSearchDebounced: debounce(
      function () {
        return this.handleSearch()
      },
      400
    ),
    async handleClear () {
      if (!isEmpty(this.$route.query)) {
        await this.$router.replace({
          query: null
        })
      }
      this.filters = {}
      await this.clearCustomers()
      await this.handleSearch()
    },
    async handleLoad () {
      await this.loadMoreCustomers({
        filters: this.filters
      })
      if (!isEqual(this.$route.query, this.filters)) {
        await this.$router.push({
          query: {
            ...this.filters
          }
        })
      }
    }
  }
}
