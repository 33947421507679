/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'globus': {
    width: 40,
    height: 42,
    viewBox: '0 0 40 42',
    data: '<path pid="0" d="M33.086 21.915c0 7.21-5.848 13.058-13.057 13.058-7.21 0-13.058-5.848-13.058-13.058S12.819 8.857 20.029 8.857c7.209 0 13.057 5.849 13.057 13.058z" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M25.293 21.173l3.52 3.718-3.52 3.933-2.854-3.004-3.66 1.342-1.24-4.966 5.858-2.375.554 1.54 1.342-.188zM28.75 18.713l-2.488-2.177M6.97 21.915l4.713-4.637 2 1.445 2.976-.366v-3.773l5.688-.517v-2.59l3.107-1.437M15.053 33.996l5.022-2.234h3.99l.413 2.44M13.035 21.305l1.465 2.243" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M17.68 1l-1.55 2.31a18.724 18.724 0 013.728-.395" _stroke="#506CF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M27.538 4.474C34.278 7.394 39 14.096 39 21.906c0 10.486-8.505 18.99-19 18.99S1 32.393 1 21.907" _stroke="#506CF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="8.41 8.41"/><path pid="4" d="M18.178 5.271c-.657-.6-1.314-1.192-1.971-1.793" _stroke="#506CF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5 5"/>'
  }
})
