import { mapActions } from 'vuex'
import { validateMnemonic } from 'bip39'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isBusy: false,
      isPasswordVisible: false,
      form: {
        name: '',
        password: '',
        mnemonic: ''
      }
    }
  },
  validations: {
    form: {
      name: { required },
      password: {
        required
      },
      mnemonic: {
        required,
        valid () {
          return validateMnemonic(this.form.mnemonic)
        }
      }
    }
  },
  computed: {
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    mnemonicErrors () {
      if (this.$v.form.mnemonic.$dirty) {
        if (!this.$v.form.mnemonic.required) {
          return [
            'Mnemonic is a required field'
          ]
        }
        if (!this.$v.form.mnemonic.valid) {
          return [
            'Invalid mnemonic'
          ]
        }
      }
      return []
    },
    passwordErrors () {
      if (this.$v.form.password.$dirty) {
        if (!this.$v.form.password.required) {
          return [
            'Password is a required field'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createWallet: 'wallets/createWallet'
    }),
    handleSubmit () {
      if (this.isBusy) {
        return
      }
      this.isBusy = true
      setImmediate(async () => {
        try {
          await this.createWallet({
            type: 'MEMORY',
            name: this.form.name,
            options: {
              password: this.form.password,
              mnemonic: this.form.mnemonic
            }
          })
          await this.handleToast({
            message: 'Wallet added'
          })
          await this.handleClose()
        } catch (e) {
          await this.handleError({
            message: 'Cannot add wallet',
            error: e
          })
        } finally {
          this.isBusy = false
        }
      })
    }
  }
}
