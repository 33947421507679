import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'
// import { oauthGoogleAuthenticatorURL, oauthFacebookAuthenticatorURL, oauthPassportAuthenticatorURL } from 'src/authenticator'

export default {
  components: {
    ...components
  },
  data () {
    return {
      form: {
        login: '',
        password: ''
      },
      error: null,
      isErrorVisible: false,
      isBusy: false,
      isPasswordVisible: false
    }
  },
  validations: {
    form: {
      login: { required },
      password: { required }
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      profile: 'passport/user'
    }),
    oauthGoogleURL () {
      // return oauthGoogleAuthenticatorURL(this.config, null)
    },
    oauthFacebookURL () {
      // return oauthFacebookAuthenticatorURL(this.config, null)
    },
    oauthPassportURL () {
      // return oauthPassportAuthenticatorURL(this.config, null)
    },
    oauthPassportRegisterURL () {
      // return oauthPassportAuthenticatorURL(this.config, null, { mode: 'signup' })
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      signin: 'passport/signin'
    }),
    togglePasswordVisibility () {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    handleSubmit () {
      this.isBusy = true
      this.isErrorVisible = false
      this.error = null
      setImmediate(async () => {
        try {
          await this.signin({
            ...this.form,
            scope: this.config.passportDefaults.scope,
            grants: this.config.passportDefaults.grants
          })
          await this.handleClose()
          setImmediate(async () => {
            this.$router.push('/private')
          })
        } catch (e) {
          this.isErrorVisible = true
          if (e.response.data.error && e.response.data.error.message) {
            this.error = e.response.data.error.message
          } else {
            this.error = 'Wrong credentials'
          }
        } finally {
          this.isBusy = false
        }
      })
    }
  }
}
