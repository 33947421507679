export default {
  root: [
    'res:///?root',
    'res://audt.to/?root'
  ],
  depositsView: [
    'res://audt.to/deposits?view'
  ],
  depositsAction: [
    'res://audt.to/deposits?root'
  ],
  withdrawalsView: [
    'res://audt.to/withdrawals?view'
  ],
  withdrawalsAction: [
    'res://audt.to/withdrawals?root'
  ],
  profilesView: [
    'res://audt.to/profiles?root'
  ],
  accountOperations: [
    'res://audt.to/accountOperations?root'
  ],
  transfers: [
    'res://audt.to/transfers?root'
  ],
  mfaActions: [
    'res://audt.to/mfaActions?root'
  ],
  customFee: [
    'res://audt.to/customFee?root'
  ],
  monoovaUserActions: [
    'res://audt.to/monoovaUsers?root'
  ],
  userActivityView: [
    'res://audt.to/userActivity?root'
  ],
  kycView: [
    'res://audt.to/kyc?view'
  ],
  kycActions: [
    'res://audt.to/kyc?root'
  ],
  monoovaSettingsView: [
    'res://audt.to/monoovaSettings?view'
  ],
  monoovaSettingsActions: [
    'res://audt.to/monoovaSettings?root'
  ],
  trades: [
    'res://audt.to/trades?root'
  ],
  currencies: [
    'res://audt.to/currencies?root'
  ],
  markets: [
    'res://audt.to/markets?root'
  ],
  stats: [
    'res://audt.to/stats?root'
  ],
  referral: [
    'res://audt.to/referral?root'
  ],
  blog: [
    'res://audt.to/blog?root'
  ],
  chat: [
    'res://audt.to/chat?root'
  ],
  depositAddressesView: [
    'res://audt.to/custody?root'
  ],
  developer: [
    'res://audt.to/developer?root'
  ]
}
