import { render, staticRenderFns } from "./PurchaseCommissionTable.htm?vue&type=template&id=61f1564d&scoped=true&external"
import script from "./PurchaseCommissionTable.js?vue&type=script&lang=js&external"
export * from "./PurchaseCommissionTable.js?vue&type=script&lang=js&external"
import style0 from "./PurchaseCommissionTable.scss?vue&type=style&index=0&id=61f1564d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f1564d",
  null
  
)

export default component.exports