import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { isAddress } from 'web3-utils'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isBusy: false,
      isConnectBusy: false,
      pluginStatus: null,
      addressOptions: [],
      form: {
        name: '',
        address: null
      }
    }
  },
  validations: {
    form: {
      name: { required },
      address: {
        required,
        valid () {
          return isAddress(this.form.address)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPluginStatus: 'plugins/getPluginStatus'
    }),
    addressInfo () {
      const address = this.form.address
      const addressOptions = this.addressOptions
      return addressOptions.find(option => option.address === address)
    },
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    addressErrors () {
      if (this.$v.form.address.$dirty) {
        if (!this.$v.form.address.required) {
          return [
            'Address is a required field'
          ]
        }
        if (!this.$v.form.address.valid) {
          return [
            'Invalid Address'
          ]
        }
      }
      return []
    }
  },
  mounted () {
    this.handleDiscover()
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createWallet: 'wallets/createWallet'
    }),
    handleSubmit () {
      if (this.isBusy) {
        return
      }
      this.isBusy = true
      setImmediate(async () => {
        try {
          await this.createWallet({
            type: 'PLUGIN',
            name: this.form.name,
            options: {
              subtype: 'metamask',
              address: this.form.address
            }
          })
          await this.handleToast({
            message: 'Wallet added'
          })
          await this.handleClose()
        } catch (e) {
          await this.handleError({
            message: 'Cannot add wallet',
            error: e
          })
        } finally {
          this.isBusy = false
        }
      })
    },
    async handleDiscover () {
      this.pluginStatus = this.getPluginStatus('metamask')
      if (this.pluginStatus.isActive) {
        this.addressOptions = await this.pluginStatus.plugin.getAddressInfoList()
        this.form.address = (this.addressOptions.length > 0)
          ? this.addressOptions[0].address
          : null
      }
    },
    async handleConnect () {
      if (this.isConnectBusy) {
        return
      }
      this.isConnectBusy = true
      setImmediate(async () => {
        try {
          await this.handleDiscover()
          if (this.pluginStatus == null) {
            return
          }
          await this.pluginStatus.plugin.init()
          await this.handleDiscover()
        } finally {
          this.isConnectBusy = false
        }
      })
    }
  }
}
