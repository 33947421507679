import BigNumber from 'bignumber.js'
import EventEmitter from 'events'

export const UNLIMITED = new BigNumber(2).pow(256).minus(1)
export const ZERO = new BigNumber(0)

export default class AbstractTokenDAO extends EventEmitter {
  constructor (token) {
    super()
    // this.setMaxListeners(100)
    this.token = token
  }

  // eslint-disable-next-line no-unused-vars
  connect (web3, options) {
    // do nothing in basic implementation
  }

  disconnect () {
    // do nothing in basic implementation
  }

  addDecimals (amount) {
    return amount == null
      ? null
      : amount.multipliedBy(new BigNumber(10).pow(this.token.decimals))
  }

  removeDecimals (value) {
    return value == null
      ? null
      : new BigNumber(value).div(new BigNumber(10).pow(this.token.decimals))
  }

  formatValueString (symbol, value, isSigned) {
    const scale = [
      { power: 0, suffix: ' wei' },
      { power: 3, suffix: ' Kwei' },
      { power: 6, suffix: ' Mwei' },
      { power: 9, suffix: ' Gwei' }
    ]
    const constants = [
      { value: UNLIMITED, title: 'Unlimited' }
    ]
    const abs = value.abs()
    const sign = value.isLessThan(0)
      ? '-'
      : value.isGreaterThan(0)
        ? '+'
        : ''
    const knownConstant = constants.find(v => v.value.isEqualTo(abs))
    if (knownConstant) {
      return (isSigned && sign)
        ? `${sign} ${knownConstant.title} ${symbol}`
        : `${knownConstant.title} ${symbol}`
    }
    if (abs.isGreaterThan(0)) {
      for (const { power, suffix } of scale) {
        if (this.token.decimals >= power + 6 && abs.isLessThan(new BigNumber(10).pow(power + 3))) {
          return (isSigned && sign)
            ? `${sign} ${abs.dividedBy(new BigNumber(10).pow(power)).toString()}${suffix} ${symbol}`
            : `${abs.toString()}${suffix} ${symbol}`
        }
      }
    }
    return (isSigned && sign)
      ? `${sign} ${this.removeDecimals(abs).toString()} ${symbol}`
      : `${this.removeDecimals(abs).toString()} ${symbol}`
  }

  get isWrapSupported () {
    return false
  }

  get isUnwrapSupported () {
    return false
  }

  get isDepositSupported () {
    return false
  }

  get isWithdrawSupported () {
    return false
  }

  get isConnected () {
    return false
  }

  get isTradeSupported () {
    return false
  }

  get isTransferSupported () {
    return false
  }

  get isApproveSupported () {
    return false
  }

  get isAutoAllowance () {
    return false
  }

  // eslint-disable-next-line no-unused-vars
  getBalance (address) {
    throw new Error('Not implemented')
  }

  // eslint-disable-next-line no-unused-vars
  getAllowance (owner, spender) {
    throw new Error('Not implemented')
  }

  // eslint-disable-next-line no-unused-vars
  createTransferTx (sender, recipient, amount) {
    throw new Error('Not implemented')
  }

  getContractAddress () {
    return this.token.address
  }

  async getPastEvents (name, options) {
    return this.contract.getPastEvents(name, options)
  }
}
