import Joi from 'joi'
import PluginModel from './PluginModel'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'

const schemaFactory = () => ({
  id: Joi.string().required(),
  type: Joi.string().valid('RaidoDepositPlugin'),
  minKycLevel: Joi.number().min(0).max(4).required(),
  minAmount: Joi.string().allow([null, '']),
  maxAmount: Joi.string().allow([null, '']),
  fiatCurrency: Joi.string().required(),
  // TODO: add required after prod will be updated
  depositAddressType: Joi.string().allow([null, '']),
  raidoInCurrencyId: Joi.string().required(),
  raidoOutCurrencyId: Joi.string().required(),
  commission: Joi.alternatives().try(
    Joi.object().type(FixedCommissionModel),
    Joi.object().type(PercentCommissionModel),
    Joi.object().type(DefaultCommissionModel)
  ).allow(null)
})

export default class RaidoDepositPluginModel extends PluginModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new RaidoDepositPluginModel({
      id: data.id,
      type: 'RaidoDepositPlugin',
      minKycLevel: data.minKycLevel,
      fiatCurrency: data.fiatCurrency,
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      depositAddressType: data.depositAddressType,
      raidoInCurrencyId: data.raidoInCurrencyId,
      raidoOutCurrencyId: data.raidoOutCurrencyId,
      commission: this.prepareCommission(data.commission)
    })
  }
}
