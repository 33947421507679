import { render, staticRenderFns } from "./MonoovaMainPage.htm?vue&type=template&id=7b1a0d88&scoped=true&external"
import script from "./MonoovaMainPage.js?vue&type=script&lang=js&external"
export * from "./MonoovaMainPage.js?vue&type=script&lang=js&external"
import style0 from "./MonoovaMainPage.scss?vue&type=style&index=0&id=7b1a0d88&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1a0d88",
  null
  
)

/* custom blocks */
import block0 from "./MonoovaMainPage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports