import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import ShortBlogArticleModel from './ShortBlogArticleModel'

const schemaFactory = () => ({
  items: Joi.array().items(Joi.object().type(ShortBlogArticleModel)).optional(),
  page: Joi.number().default(0),
  totalPages: Joi.number().default(0),
  count: Joi.number().default(0)
})

export default class BlogArticlesResponse extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new BlogArticlesResponse({
      items: data.items.map(ShortBlogArticleModel.fromJson),
      page: data.page,
      totalPages: data.totalPages,
      count: data.count
    })
  }
}
