import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import * as components from 'src/components'
import { AddMonoovaWhitelistModal } from 'src/modals'

export default {
  components: {
    ...components
  },
  props: {
    isBusy: Boolean,
    isMobile: Boolean
  },
  computed: {
    ...mapGetters({
      monoovaAccount: 'monoova/account',
      whitelist: 'monoova/whitelist',
      hasMonoovaUserActionsAccess: 'grants/hasMonoovaUserActionsAccess'
    }),
    whitelistItems () {
      return this.whitelist.value
    },
    monoovaAccountId () {
      const monoova = this.monoovaAccount && this.monoovaAccount.value
      return monoova && monoova.id
    }
  },
  methods: {
    moment,
    ...mapActions({
      updateMonoovaWhitelist: 'monoova/updateMonoovaWhitelist',
      openModal: 'modals/open'
    }),
    async handleAdd () {
      this.openModal({
        factory: () => AddMonoovaWhitelistModal,
        data: {
          title: 'Add whitelist',
          monoovaAccountId: this.monoovaAccountId,
          handler: async () => {}
        }
      })
    },
    async handleUpdate (whitelistItemId, enable) {
      await this.updateMonoovaWhitelist({
        accountId: this.monoovaAccountId,
        whitelistItemId: whitelistItemId,
        enable: !enable
      })
    }
  }
}
