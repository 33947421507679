import Accounts from 'web3-eth-accounts'
import SignerModel from './SignerModel'
import { toPrivateKeyHex, privateToPublicKeyHex } from 'src/utils'

export default class SignerMemoryModel extends SignerModel {
  constructor ({ wallet }) {
    super()
    this.wallet = wallet
    Object.freeze(this)
  }

  get address () {
    return this.wallet[0].address
  }

  // this method is a part of base interface
  getAddress () {
    return this.address
  }

  // this method is a part of base interface
  getPublicKey () {
    return privateToPublicKeyHex(this.wallet[0].privateKey)
  }

  getPrivateKey () {
    return this.wallet[0].privateKey
  }

  async signTransaction (txData) {
    return this.wallet[0].signTransaction(txData)
  }

  async signData (data) {
    return this.wallet[0].sign(data)
  }

  static async create ({ seed, privateKey, mnemonic }) {
    const accounts = new Accounts()
    const wallet = accounts.wallet.create(0)
    const account = await accounts.privateKeyToAccount(toPrivateKeyHex({ seed, privateKey, mnemonic }))
    await wallet.add(account)
    return new SignerMemoryModel({ wallet })
  }

  // Should be synchronous by design
  encrypt (password) {
    return this.wallet.encrypt(password)
  }

  // Should be synchronous by design
  static decrypt ({ encrypted, password }) {
    const accounts = new Accounts()
    const wallet = accounts.wallet.decrypt(encrypted, password)
    return new SignerMemoryModel({ wallet })
  }
}
