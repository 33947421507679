import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  addressesCount: Joi.number().required(),
  totalReward: Joi.string().required()
})

export default class RewardsSummaryModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new RewardsSummaryModel(data, { stripUnknown: true })
  }
}
