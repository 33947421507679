import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  name: Joi.string().required(),
  showOnLanding: Joi.boolean()
})

export default class BlogCategoryModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new BlogCategoryModel(data, { stripUnknown: true })
  }
}
