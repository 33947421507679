import {
  PrivateLayout,
  PrivateContent,
  OrdersPage,
  CustomersPage,
  KycPage,
  SetupPage,
  HistoryPage,
  ProxyPage,
  CurrenciesPage,
  MarketPage,
  ProfileDetailsPage,
  NotificationPage,
  StatsPage,
  DepositsPage,
  PartnersProgramsPage,
  ErrorPage,
  CurrencyPage,
  CustomFeePage,
  CustomFeeCreatePage,
  CurrencyCreatePage,
  CurrencyEditPage,
  CurrencyViewPage,
  BlogPage,
  BlogPageCreate,
  BlogPageEdit,
  ChatAdminPage,
  SystemAdminPage,
  MonoovaPage,
  RapidIdPage,
  MonoovaMainPage,
  MonoovaRejectedTransactionPage,
  BundlesPage,
  BundleCreatePage,
  BundleEditPage,
  ManageDepositsAddressesPage,
  TradesPage
} from 'src/pages'

import {
  FinancialStatsPartial,
  UsersStatsPartial,
  PurchasesPartial,
  RedemptionsPartial,
  WalletPartial,
  VerificationPartial,
  FindCustomerPartial,
  UserActivityPartial,
  ProfileGeneralPartial,
  TwofaPartial,
  MyPurchasesPartial,
  MyRedemptionsPartial,
  StatsPartial,
  CustomAddressesPartial,
  ChatUsersPartial,
  CustomFeePartial,
  ChatChannelsPartial,
  SystemCachesPartial,
  SystemMonitoringPartial,
  SystemSOLRPartial,
  SystemOperationsPartial,
  SystemBalancesPartial,
  KycTabContent,
  KycCustomerContent,
  KycTemplates,
  KycCustomerHistory,
  MonoovaGeneralPartial,
  RewardsSummaryStatsPartial
} from 'src/partials'

export default ({ store }) => [{
  path: '/private',
  component: PrivateLayout,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: PrivateContent,
      children: [
        { path: '', redirect: '/private/customer/setup' },
        ...errorRoutesFactory(),
        ...customerRoutesFactory(store),
        ...adminRoutesFactory(store),
        ...systemRoutesFactory(store)
      ]
    }
  ]
}]

const errorRoutesFactory = () => [{
  path: 'error',
  component: ProxyPage,
  children: [
    {
      path: 'error',
      component: ErrorPage
    }
  ]
}]

const customerRoutesFactory = (store) => [{
  path: 'customer',
  component: ProxyPage,
  children: [
    { path: '', redirect: 'setup' },
    {
      path: 'setup',
      component: SetupPage,
      children: [
        { path: '', redirect: 'wallet' },
        { path: 'wallet', component: WalletPartial },
        {
          path: 'verification',
          component: VerificationPartial,
          beforeEnter: (to, from, next) => {
            store.dispatch('myProfileKyc/loadProfile')
            next()
          }
        },
        { path: '2fa', component: TwofaPartial }
      ]
    },
    {
      path: 'history',
      component: HistoryPage,
      children: [
        { path: '', redirect: 'purchases' },
        { path: 'purchases', component: MyPurchasesPartial },
        { path: 'redemptions', component: MyRedemptionsPartial }
      ]
    }
  ]
}]

const adminRoutesFactory = (store) => [{
  path: 'admin',
  component: ProxyPage,
  children: [
    { path: '', redirect: 'orders' },
    {
      path: 'orders',
      component: OrdersPage,
      children: [
        { path: '', redirect: 'redemptions' },
        {
          path: 'purchases',
          component: PurchasesPartial
        },
        {
          path: 'redemptions',
          component: RedemptionsPartial
        }
      ]
    },
    {
      path: 'kyc',
      component: KycPage,
      children: [
        { path: '', redirect: 'address' },
        { path: 'address', name: 'address', component: KycTabContent },
        { path: 'documents', name: 'documents', component: KycTabContent },
        { path: 'submitted', name: 'submitted', component: KycTabContent },
        { path: 'complicated', name: 'complicated', component: KycTabContent },
        { path: 'templates', name: 'templates', component: KycTemplates },
        { path: 'kyc-customer/i/:id', name: 'kyc-customer', component: KycCustomerContent },
        { path: 'kyc-history/i/:id', name: 'kyc-history', component: KycCustomerHistory }
      ]
    },
    {
      path: 'rapidid',
      component: RapidIdPage,
      children: []
    },
    {
      path: 'customers',
      component: CustomersPage,
      children: [
        { path: '', redirect: 'profiles' },
        { path: 'profiles', component: FindCustomerPartial }
      ]
    },
    {
      path: 'customers/i/:id',
      component: ProfileDetailsPage,
      children: [
        { path: '', redirect: 'general' },
        { path: 'general', component: ProfileGeneralPartial },
        { path: 'custom-fee', component: CustomFeePartial },
        { path: 'monoova', component: MonoovaGeneralPartial },
        { path: 'activity', component: UserActivityPartial }
      ]
    },
    {
      path: 'balances',
      component: DepositsPage,
      children: [
        { path: '', redirect: 'custom' },
        { path: 'custom', component: CustomAddressesPartial }
      ]
    },
    {
      path: 'monoova',
      component: MonoovaPage,
      children: [
        { path: '', redirect: 'main' },
        { path: 'main', component: MonoovaMainPage },
        { path: 'rejected-transactions', component: MonoovaRejectedTransactionPage }
      ]
    },
    {
      path: 'notification',
      component: NotificationPage
    },
    {
      path: 'trades',
      component: TradesPage
    }
  ]
}]

const systemRoutesFactory = (store) => [{
  path: 'system',
  component: ProxyPage,
  children: [
    {
      path: '',
      redirect: 'currencies'
    },
    {
      path: 'currencies',
      component: CurrenciesPage
    },
    {
      path: 'currency-create',
      component: CurrencyCreatePage
    },
    {
      path: 'currency-edit/:currency',
      component: CurrencyEditPage
    },
    {
      path: 'currencies/:currency',
      component: CurrencyPage,
      children: [
        { path: '', redirect: 'general' },
        { path: 'general', component: CurrencyViewPage }
      ]
    },
    {
      path: 'markets',
      component: MarketPage
    },
    {
      path: 'bundles',
      component: BundlesPage
    },
    {
      path: 'bundle-create',
      component: BundleCreatePage
    },
    {
      path: 'bundle-edit/:symbol',
      component: BundleEditPage
    },
    {
      path: 'stats',
      component: StatsPage,
      children: [
        { path: '', redirect: 'markets' },
        { path: 'markets', component: StatsPartial },
        { path: 'users', component: UsersStatsPartial },
        { path: 'financial', component: FinancialStatsPartial },
        { path: 'rewards', component: RewardsSummaryStatsPartial }
      ]
    },
    {
      path: 'partners-programs',
      component: PartnersProgramsPage
    },
    {
      path: 'custom-fee',
      component: CustomFeePage
    },
    {
      path: 'custom-fee/custom-fee-create',
      component: CustomFeeCreatePage
    },
    {
      path: 'blog',
      component: BlogPage
    },
    {
      path: 'blog/create',
      component: BlogPageCreate
    },
    {
      path: 'blog/edit/:url',
      component: BlogPageEdit
    },
    {
      path: 'chat',
      component: ChatAdminPage,
      children: [
        { path: '', redirect: 'users' },
        { path: 'users', component: ChatUsersPartial },
        { path: 'channels', component: ChatChannelsPartial }
      ]
    },
    {
      path: 'system',
      component: SystemAdminPage,
      children: [
        { path: '', redirect: 'caches' },
        { path: 'caches', component: SystemCachesPartial },
        { path: 'monitoring', component: SystemMonitoringPartial },
        { path: 'solr', component: SystemSOLRPartial },
        { path: 'operations', component: SystemOperationsPartial },
        { path: 'balances', component: SystemBalancesPartial }
      ]
    },
    {
      path: 'deposit-addresses',
      component: ManageDepositsAddressesPage
    }
  ]
}]
