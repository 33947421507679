<template>
  <section class="root-toast-stack">
    <toast v-for="item in stack" :toast="item.toast" :key="item.id" />
  </section>
</template>

<script>
import Toast from './Toast.vue'

export default {
  props: {
    stack: Array
  },
  components: {
    Toast
  }
}
</script>

<style scoped lang="scss">
  .root-toast-stack {
    position: relative;
    overflow: visible;
    z-index: 40000000;
    height: 1px;
  }
</style>
