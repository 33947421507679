import * as components from 'src/components'
import * as directives from 'src/directives'
import { LaxMixinFactory } from 'src/mixins'

export default {
  components: {
    ...components
  },
  directives: {
    ...directives
  },
  mixins: [
    LaxMixinFactory(function () {
      const { top } = this.$refs.blocks.getBoundingClientRect()
      const { scrollY } = window
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    })
  ],
  data () {
    return {
      circleDataLaxOpacity: 0,
      circleDataLaxTranslateY: 0,
      startScrollOffset: 0,
      endScrollOffset: 0,
      textBlocks: [
        {
          id: 1,
          icon: 's-icon',
          title: 'You send AUD to the bank',
          text: 'We work with a licenced Australian bank',
          class: 'text-1',
          offset: 10
        },
        {
          id: 2,
          icon: 'convert-icon',
          title: 'We receive your AUD',
          text: 'We instantly convert your dollars to AUDT',
          class: 'text-2',
          offset: 20
        },
        {
          id: 3,
          icon: 'stack-coins-icon',
          title: 'You receive AUDT',
          text: 'in your wallet',
          class: 'text-3',
          offset: 30
        }
      ],
      imgs: [
        {
          id: 4,
          src: '/assets/img/main/laptop.svg',
          class: 'img-1',
          offset: 10,
          alt: 'receive AUDT'
        },
        {
          id: 5,
          src: '/assets/img/main/safe-l.svg',
          class: 'img-2',
          offset: 20,
          alt: 'audt work with a Australian bank'
        },
        {
          id: 6,
          src: '/assets/img/main/code.svg',
          class: 'img-3',
          offset: 30,
          alt: 'convert your dollars to AUDT'
        }
      ]
    }
  },
  mounted () {
    this.$el.style.visibility = 'visible'
  },
  computed: {
    blockItems () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      return this.textBlocks.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${start + item.offset + index * 50} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateY: `${start + item.offset + index * 100} 400px, ${end + item.offset + index * 30} 0px`,
          dataLaxOpacitySmall: `${start + item.offset + index * 150} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateYSmall: `${start + item.offset + index * 100} 100px, ${end + item.offset + index * 300} 0px`
        })
      )
    },
    imgItems () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      return this.imgs.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${start + item.offset + index * 50} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateY: `${start + item.offset + index * 150} 200px, ${end + item.offset + index * 30} 0px`,
          dataLaxOpacitySmall: `${start + item.offset + index * 50} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateYSmall: `${start + item.offset + index * 100} 100px, ${end + item.offset + index * 300} 0px`
        })
      )
    }
  }
}
