import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  createdAt: Joi.string().required(),
  state: Joi.string().required(),
  requestBody: Joi.object(),
  responseBody: Joi.object(),
  result: Joi.object().keys({
    score: Joi.string().allow([null, '']),
    confidenceLevel: Joi.string().allow([null, '']),
    recommendation: Joi.string().allow([null, ''])
  }).allow(null).optional()
})

export default class RiskRequestModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return `risk-request-${this.id}`
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }

    return new RiskRequestModel({
      id: data.id,
      createdAt: data.createdAt,
      state: data.state,
      requestBody: data.requestBody,
      responseBody: data.responseBody,
      result: data.result == null
        ? null
        : {
          score: data.result.score,
          confidenceLevel: data.result.confidenceLevel,
          recommendation: data.result.recommendation
        }
    })
  }
}
