import uniqid from 'uniqid'

export const MODALS_OPEN = 'modals/open'
export const MODALS_CLOSE = 'modals/close'
export const MODALS_REPLACE = 'modals/replace'
export const MODALS_REMOVE = 'modals/remove'
export const MODALS_UPDATE_FLAGS = 'modals/update/flags'

export default () => ({
  namespaced: true,
  state () {
    return {
      stack: []
    }
  },
  mutations: {
    [MODALS_OPEN]: (state, modal) => {
      state.stack.push({
        id: uniqid(),
        isClosing: false,
        modal
      })
    },
    [MODALS_REMOVE]: (state, { tags }) => {
      state.stack = state.stack.filter(entry => {
        const modalTags = entry.modal.tags
        if (modalTags) {
          for (const tag of tags) {
            if (modalTags.indexOf(tag) < 0) {
              return true
            }
          }
          return false
        }
        return true
      })
    },
    [MODALS_CLOSE]: (state) => {
      state.stack.pop()
    },
    [MODALS_UPDATE_FLAGS]: (state, { modal, flags }) => {
      Object.assign(modal, flags)
    },
    [MODALS_REPLACE]: (state, modal) => {
      const stack = state.stack
      stack.splice(stack.length - 1, 1, {
        id: uniqid(),
        isClosing: false,
        modal
      })
      state.stack = [
        ...stack
      ]
    }
  },
  actions: {
    open ({ commit }, modal) {
      commit(MODALS_OPEN, modal)
    },
    close ({ state, commit }) {
      return new Promise(resolve => {
        if (state.stack.length) {
          const modal = state.stack[state.stack.length - 1]
          if (!modal.modal.onClose) {
            commit(MODALS_CLOSE)
            resolve()
          } else {
            commit(MODALS_UPDATE_FLAGS, {
              modal,
              flags: { isClosing: true }
            })
            modal.modal.onClose(() => {
              commit(MODALS_UPDATE_FLAGS, {
                modal,
                flags: { isClosing: false }
              })
              commit(MODALS_CLOSE)
              resolve()
            })
          }
        }
      })
    },
    remove ({ commit }, { tags }) {
      commit(MODALS_REMOVE, { tags })
    },
    replace ({ commit }, modal) {
      commit(MODALS_REPLACE, modal)
    }
  }
})
