import { render, staticRenderFns } from "./AboutPage.htm?vue&type=template&id=6cab50b1&scoped=true&external"
import script from "./AboutPage.js?vue&type=script&lang=js&external"
export * from "./AboutPage.js?vue&type=script&lang=js&external"
import style0 from "./AboutPage.scss?vue&type=style&index=0&id=6cab50b1&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cab50b1",
  null
  
)

export default component.exports