import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  type: Joi.string().valid('PercentCommission'),
  percent: Joi.number().required()
})

export default class PercentCommissionModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new PercentCommissionModel({
      type: 'PercentCommission',
      percent: Number(data.percent)
    })
  }
}
