// import uniqid from 'uniqid'
import AbstractDevice from '../AbstractDevice'
import { WalletEntryModel, SignerServerModel } from 'src/models'

const LOCK = 'ServerPlugin'

export default class ServerPlugin extends AbstractDevice {
  get name () {
    return 'server'
  }

  get title () {
    return 'Server Plugin'
  }

  async init () {
    this.emit('connected')
    // eslint-disable-next-line
    console.log('ServerPlugin initialized')
  }

  async create (entry: WalletEntryModel, { token }) {
    return SignerServerModel.decrypt({ entry, token })
  }

  async decrypt (entry: WalletEntryModel, { token }) {
    return SignerServerModel.decrypt({ entry, token })
  }

  getLock () {
    return LOCK
  }
}
