import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  page: Joi.number().default(0),
  size: Joi.number().default(10)
})

export default class BlogArticlesRequest extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }
}
