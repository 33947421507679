import { mapActions, mapGetters } from 'vuex'
import uniqid from 'uniqid'

export default {
  props: {
    markets: Array,
    availableMarkets: Array,
    readonly: Boolean
  },
  computed: {
    ...mapGetters({
      getMarketBySymbol: 'markets/getMarketBySymbol'
    }),
    option () {
      return this.availableMarkets.map(e => e.name)
    }
  },
  methods: {
    uniqid,
    ...mapActions({
      loadMarkets: 'markets/loadMarkets'
    }),
    currentMarket (market) {
      if (market.symbol) {
        return this.getMarketBySymbol(market.symbol).name
      }
    },
    selectedMarkets (name) {
      return this.availableMarkets.find(m => m.name === name)
    },
    handleMarketAdd () {
      this.$emit('marketAdd', {
        id: uniqid(),
        symbol: '',
        percent: ''
      })
    },
    handleMarketRemove (market) {
      this.$emit('marketRemove', market.id)
    },
    updateMarketField (market, field, value) {
      let newSymbol
      let newPercent
      if (field === 'market') {
        newSymbol = this.selectedMarkets(value).symbol
      }
      if (field === 'percent') {
        newPercent = value
      }
      market = {
        ...market,
        symbol: newSymbol || market.symbol,
        percent: newPercent || market.percent
      }
      this.$emit('marketUpdate', market)
    }
  }
}
