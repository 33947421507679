import Joi from 'joi'
import { AbstractModel, ImageModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  name: Joi.string().allow([null, '']),
  title: Joi.string().allow([null, '']),
  description: Joi.string().allow([null, '']),
  icon: Joi.object().type(ImageModel).allow(null),
  strategies: Joi.array().allow(null),
  requirements: Joi.array().allow(null)
})

export default class DomainDetails extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new DomainDetails(
      {
        ...data,
        icon: ImageModel.fromJson(data.icon)
      },
      { stripUnknown: true }
    )
  }
}
