import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    section: String,
    title: String,
    explanation: String
  }
}
