import { mapGetters, mapState } from 'vuex'

export default {
  metaInfo: {
    title: 'System'
  },
  components: {},
  computed: {
    ...mapState({}),
    ...mapGetters({})
  },
  async created () {},
  data () {
    return {}
  },
  methods: {}
}
