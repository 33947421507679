import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isBusy: false,
      form: {
        name: '',
        privateKey: ''
      }
    }
  },
  validations: {
    form: {
      name: { required },
      privateKey: {
        required,
        valid () {
          const value = this.form.privateKey
          return value == null // nil check
            ? false
            : (value.length >= 64 && /^(0x)?[0-9A-Fa-f]{64,}$/g.test(value))
        }
      }
    }
  },
  computed: {
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    privateKeyErrors () {
      if (this.$v.form.privateKey.$dirty) {
        if (!this.$v.form.privateKey.required) {
          return [
            'Private Key is a required field'
          ]
        }
        if (!this.$v.form.privateKey.valid) {
          return [
            'Invalid Private Key'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createWallet: 'wallets/createWallet'
    }),
    handleSubmit () {
      if (this.isBusy) {
        return
      }
      this.isBusy = true
      setImmediate(async () => {
        try {
          await this.createWallet({
            type: 'SERVER',
            name: this.form.name,
            options: {
              privateKey: this.form.privateKey
            }
          })
          await this.handleToast({
            message: 'Wallet added'
          })
          await this.handleClose()
        } catch (e) {
          await this.handleError({
            message: 'Cannot add wallet',
            error: e
          })
        } finally {
          this.isBusy = false
        }
      })
    }
  }
}
