import { mapActions } from 'vuex'

export default {
  props: {
    data: Object
  },
  computed: {
    canAccept () {
      return this.data.purchase.state === 'PENDING'
    },
    canRefresh () {
      if (this.data.purchase.type === 'DEPOSIT_ITEZ') {
        return false
      }
      return (this.data.purchase.state === 'PENDING' &&
        (this.data.purchase.type === 'DEPOSIT_ADV_CASH' || this.data.purchase.type === 'DEPOSIT_GEO_PAY'))
    },
    canReject () {
      if (this.data.purchase.type === 'DEPOSIT_ITEZ') {
        return false
      }
      return this.data.purchase.state === 'PENDING'
    },
    canRepeatDeposit () {
      if (this.data.purchase.type === 'DEPOSIT_ITEZ') {
        return false
      }
      return this.data.purchase.state === 'ACCEPTED'
    },
    canFinish () {
      if (this.data.purchase.type === 'DEPOSIT_ITEZ') {
        return false
      }
      return this.data.purchase.state === 'ACCEPTED'
    }
  },
  methods: {
    ...mapActions({
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast',
      acceptDeposit: 'purchases/acceptPurchase',
      refreshPurchaseState: 'purchases/refreshPurchaseState',
      rejectPurchase: 'purchases/rejectPurchase',
      retryDeposit: 'purchases/retryDeposit',
      finishDeposit: 'purchases/finishDeposit'
    }),
    async handleAccept () {
      await this.handleConfirmation({
        handler: async () => {
          await this.acceptDeposit({
            id: this.data.purchase.id,
            deposit: this.data.purchase,
            amount: this.data.purchase.amount
          })
          await this.handleToast({
            message: 'Purchase accepted'
          })
          this.$emit('update')
        }
      })
    },
    async handleRefresh () {
      await this.handleConfirmation({
        handler: async () => {
          await this.refreshPurchaseState({ id: this.data.purchase.id })
          await this.handleToast({
            message: 'Purchase refreshed'
          })
          this.$emit('update')
        }
      })
    },
    async handleReject () {
      await this.handleConfirmation({
        handler: async () => {
          await this.rejectPurchase({
            id: this.data.purchase.id
          })
          await this.handleToast({
            message: 'Purchase rejected'
          })
          this.$emit('update')
        }
      })
    },
    async handleRetryDeposit () {
      await this.handleConfirmation({
        handler: async () => {
          await this.retryDeposit({
            id: this.data.purchase.id
          })
          this.$emit('update')
        }
      })
    },
    async handleFinish () {
      await this.handleConfirmation({
        handler: async () => {
          await this.finishDeposit({
            id: this.data.purchase.id
          })
          this.$emit('update')
        }
      })
    }
  }
}
