import { render, staticRenderFns } from "./SectionWithdrawals.htm?vue&type=template&id=47dba553&scoped=true&external"
import script from "./SectionWithdrawals.js?vue&type=script&lang=js&external"
export * from "./SectionWithdrawals.js?vue&type=script&lang=js&external"
import style0 from "./SectionWithdrawals.scss?vue&type=style&index=0&id=47dba553&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dba553",
  null
  
)

export default component.exports