export default {
  props: {
    state: String
  },
  computed: {
    displayState () {
      switch (this.state) {
        case 'CREATED':
          return 'PENDING'
        default:
          return this.state
      }
    }
  }
}
