import { mapActions, mapGetters } from 'vuex'
import { get } from 'lodash'
import { AddWithdrawalDiscountModal } from 'src/modals'
export default {
  props: {
    details: Object
  },
  data () {
    return {
      isLoading: false
    }
  },
  async mounted () {
    await this.loadWithdrawalDiscount({ ssoUserId: this.ssoUserId })
  },
  computed: {
    ...mapGetters({
      getDiscount: 'withdrawalDiscount/getWithdrawalDiscount'
    }),
    discount () {
      return this.getDiscount(this.ssoUserId).value
    },
    ssoUserId () {
      return get(this.details, 'ssoUserId', '-')
    }
  },
  methods: {
    ...mapActions({
      loadWithdrawalDiscount: 'withdrawalDiscount/loadWithdrawalDiscount',
      removeDepositAddress: 'withdrawalDiscount/removeDepositAddress',
      openModal: 'modals/open'
    }),
    handleAddDiscount () {
      this.openModal({
        factory: () => AddWithdrawalDiscountModal,
        data: {
          ssoUserId: this.ssoUserId
        }
      })
    },
    async handleRemoveDiscount () {
      try {
        this.isLoading = true
        await this.removeDepositAddress({
          ssoUserId: this.ssoUserId
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
