import { mapGetters, mapActions } from 'vuex'
import { numeric } from 'vuelidate/lib/validators'
import * as components from 'src/components'
import { mask } from 'vue-the-mask'

const WAIT_EMAIL_INTERVAL = 60 // 60s
const WAIT_PHONE_INTERVAL = 60 // 60s

export default {
  directives: {
    mask
  },
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
      profile: 'myProfile/profile'
    }),
    isPhoneConfirmed () {
      const submitted = this.profile.level2.submitted
      // const approved = this.profile.level2.approved
      return !submitted || submitted.isPhoneConfirmed
    },
    isEmailConfirmed () {
      const submitted = this.profile.level2.submitted
      // const approved = this.profile.level2.approved
      return !submitted || submitted.isEmailConfirmed
    }
  },
  props: {
    isInitialEmailCodeJustSent: Boolean,
    isInitialPhoneCodeJustSent: Boolean,
    onComplete: Function
  },
  data () {
    return {
      isBusy: false,
      isPhoneCodeJustSent: false,
      isPhoneCodeInvalid: false,
      phoneCodeCountdown: this.isInitialPhoneCodeJustSent ? WAIT_PHONE_INTERVAL : 0,
      phoneCodeSentTime: this.isInitialPhoneCodeJustSent ? Date.now() : null,
      isEmailCodeJustSent: false,
      isEmailCodeInvalid: false,
      emailCodeCountdown: this.isInitialEmailCodeJustSent ? WAIT_EMAIL_INTERVAL : 0,
      emailCodeSentTime: this.isInitialEmailCodeJustSent ? Date.now() : null,
      form: {
        phoneCode: null,
        emailCode: null
      }
    }
  },
  validations: {
    form: {
      phoneCode: { numeric },
      emailCode: { numeric }
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      const now = Date.now()
      if (this.phoneCodeCountdown) {
        if (now > this.phoneCodeSentTime + WAIT_PHONE_INTERVAL * 1000) {
          this.phoneCodeCountdown = 0
          this.phoneCodeSentTime = null
        } else {
          this.phoneCodeCountdown = Math.floor((WAIT_PHONE_INTERVAL * 1000 - now + this.phoneCodeSentTime) / 1000)
        }
      }
      if (this.emailCodeCountdown) {
        if (now > this.emailCodeSentTime + WAIT_EMAIL_INTERVAL * 1000) {
          this.emailCodeCountdown = 0
          this.emailCodeSentTime = null
        } else {
          this.emailCodeCountdown = Math.floor((WAIT_EMAIL_INTERVAL * 1000 - now + this.emailCodeSentTime) / 1000)
        }
      }
    }, 1000)
  },
  destroyed () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      validatePhone: 'myProfile/validateLevel2Phone',
      validateEmail: 'myProfile/validateLevel2Email',
      confirm: 'myProfile/confirmLevel2'
    }),
    async handleValidatePhone () {
      this.isPhoneCodeJustSent = true
      try {
        await this.validatePhone()
        this.phoneCodeSentTime = Date.now()
        this.phoneCodeCountdown = WAIT_PHONE_INTERVAL
      } finally {
        this.isPhoneCodeJustSent = false
      }
    },
    async handleValidateEmail () {
      this.isEmailCodeJustSent = true
      try {
        await this.validateEmail()
        this.emailCodeSentTime = Date.now()
        this.emailCodeCountdown = WAIT_EMAIL_INTERVAL
      } finally {
        this.isEmailCodeJustSent = false
      }
    },
    async handlePhoneCodeInput () {
      this.$v.form.phoneCode.$touch()
      this.isPhoneCodeInvalid = false
      if (this.form.phoneCode.length === 5) {
        this.handleSubmit()
      }
    },
    async handleEmailCodeInput () {
      this.$v.form.emailCode.$touch()
      this.isEmailCodeInvalid = false
      if (this.form.emailCode.length === 5) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.$v.$touch()
      if (!this.isEmailConfirmed) {
        this.$v.form.emailCode.$touch()
      }
      if (!this.isPhoneConfirmed) {
        this.$v.form.phoneCode.$touch()
      }
      try {
        this.isBusy = true
        let close = false
        if (!this.$v.$invalid) {
          const { emailCode, phoneCode } = this.form
          if ((emailCode !== '' && emailCode != null && !this.isEmailConfirmed) || (phoneCode !== '' && phoneCode !== null && !this.isPhoneConfirmed)) {
            const { isEmailTried, isPhoneTried, isEmailVerified, isPhoneVerified } = await this.confirm({
              emailCode: this.isEmailConfirmed
                ? undefined
                : emailCode,
              phoneCode: this.isPhoneConfirmed
                ? undefined
                : phoneCode
            })
            this.isEmailCodeInvalid = (isEmailTried && !isEmailVerified)
            this.isPhoneCodeInvalid = (isPhoneTried && !isPhoneVerified)
            if (!isEmailTried && !isPhoneTried) {
              close = true
            }
            if (this.onComplete != null) {
              this.onComplete({
                isEmailConfirmed: this.isEmailConfirmed,
                isPhoneConfirmed: this.isPhoneConfirmed
              })
            }
          } else {
            close = true
          }
          if (this.isEmailConfirmed && this.isPhoneConfirmed) {
            close = true
          }
          if (close) {
            this.handleClose()
          }
        }
      } finally {
        this.isBusy = false
      }
    }
    // async emailSubmit () {
    //   this.$v.$touch()
    //   if (!this.isEmailConfirmed) {
    //     this.$v.form.emailCode.$touch()
    //   }
    //   try {
    //     if (!this.$v.$invalid) {
    //       const emailCode = this.form.emailCode
    //       if ((emailCode !== '' && emailCode != null && !this.isEmailConfirmed)) {
    //         const {isEmailTried, isEmailVerified} = await this.confirm({
    //           emailCode: this.isEmailConfirmed ? undefined : emailCode
    //         })
    //         this.isEmailCodeInvalid = (isEmailTried && !isEmailVerified)
    //       }
    //     }
    //   } finally {
    //
    //   }
    // },
    // async phoneSubmit () {
    //   if (!this.isPhoneConfirmed) {
    //     this.$v.form.phoneCode.$touch()
    //   }
    //   try {
    //     if (!this.$v.invalid) {
    //       const phoneCode = this.form.phoneCode
    //       if ((phoneCode !== '' && phoneCode !== null && !this.isPhoneConfirmed)) {
    //         const { isPhoneTried, isPhoneVerified } = await this.confirm({
    //           phoneCode: this.isPhoneConfirmed
    //             ? undefined
    //             : phoneCode
    //         })
    //         this.isPhoneCodeInvalid = (isPhoneTried && !isPhoneVerified)
    //       }
    //     }
    //   } finally {
    //
    //   }
    // }
  }
}
