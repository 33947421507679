class BlockedAddressOperationType {
  static AUTO_WITHDRAWAL = new BlockedAddressOperationType('AUTO_WITHDRAWAL', 'EXCHANGE')
  static TRANSFER = new BlockedAddressOperationType('TRANSFER', 'PLASMA')
  static WITHDRAWAL = new BlockedAddressOperationType('WITHDRAWAL', 'PLASMA')

  static values () {
    return Object.values(BlockedAddressOperationType).map(e => e.value)
  }

  constructor (value, service) {
    this.value = value
    this.service = service
  }
}

export default BlockedAddressOperationType
