import { LoadableListModel, ManageDepositsAddressesModel } from 'src/models'
import { BACKEND, withAuthorization } from 'src/remotes'

export const DEPOSITS_ADDRESSES_LOADING = 'depositsAddresses/loading'
export const DEPOSITS_ADDRESSES_LOADED = 'depositsAddresses/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      limit: 30, // elements per page
      count: null, // elements total
      activePage: 1,
      items: new LoadableListModel(ManageDepositsAddressesModel),
      filters: {
        statuses: [],
        types: [],
        account: null
      }
    }
  },
  mutations: {
    [DEPOSITS_ADDRESSES_LOADING]: (state) => {
      state.items = state.items.loading()
    },
    [DEPOSITS_ADDRESSES_LOADED]: (state, { items, count, activePage }) => {
      state.items = state.items.loaded(...items)
      state.count = count
      state.activePage = activePage
    }
  },
  getters: {
    limit: state => state.limit,
    count: state => state.count,
    items: state => state.items,
    activePage: state => state.activePage,
    totalPages (state) {
      const count = state.count
      const pageSize = state.limit
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async fetchDepositAddresses ({ state, commit, dispatch }, { offset, limit, filters = {} } = { limit: state.limit, filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('manager/custody/deposit-addresses', withAuthorization(token, {
        params: {
          ...filters,
          offset,
          limit
        }
      }))
      return data
    },
    async loadDepositAddresses ({ state, commit, dispatch }, {
      filters,
      page = {
        activePage: 1,
        limit: state.limit
      }
    } = {
      page: {
        activePage: 1,
        limit: state.limit
      }
    }) {
      commit(DEPOSITS_ADDRESSES_LOADING)
      const { items, count } = await dispatch('fetchDepositAddresses', {
        filters,
        offset: (page.activePage - 1) * state.limit,
        limit: state.limit
      })
      commit(DEPOSITS_ADDRESSES_LOADED, {
        activePage: parseInt(page.activePage),
        count,
        items: items.map(ManageDepositsAddressesModel.fromJson)
      })
    },
    async refreshDepositAddress ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`manager/custody/deposit-addresses/refresh/${id}`, null, withAuthorization(token))
    },
    async removeDepositAddress ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`manager/custody/deposit-addresses/unregister/${id}`, null, withAuthorization(token))
    }
  }
})
