import { mapGetters } from 'vuex'
import { PlasmaPocketModel } from 'src/models'
import { formatNumber } from 'accounting'
import BigNumber from 'bignumber.js'

export default {
  props: {
    pocket: PlasmaPocketModel,
    formatNumberCount: Number,
    isLockedBalance: Boolean,
    showLockedBalance: Boolean
  },
  methods: {
    formatNumber,
    BigNumber
  },
  computed: {
    ...mapGetters({
      getPlasmaBalance: 'plasmaBalances/balance'
    }),
    balance () {
      const amount = new BigNumber(this.getPlasmaBalance(this.pocket).amount)
      if (!amount.eq(0)) {
        return this.getPlasmaBalance(this.pocket)
      } else {
        return false
      }
    },
    formattedBalances () {
      return this.formatNumber(this.isLockedBalance ? this.balance.lockedAmount : this.balance.amount, this.formatNumberCount ? this.formatNumberCount : 2)
    },
    lockedAmount () {
      return this.formatNumber(this.balance.lockedAmount, this.formatNumberCount ? this.formatNumberCount : 2)
    },
    isLockedAmountExist () {
      return new BigNumber(this.balance.lockedAmount).gt(0)
    }
  }
}
