import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      isFrontend: false,
      cookiesLink: 'here the link'
    }
  },
  computed: {
    ...mapGetters({
      isCookiesBarVisible: 'cookies/isCookiesBarVisible'
    })
  },
  methods: {
    ...mapActions({
      cookieStateToggle: 'cookies/cookieStateToggle'
    })
  },
  mounted () {
    this.isFrontend = true
  }
}
