import * as components from 'src/components'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    ...components
  },
  data () {
    return {
      filters: {
        from: null,
        to: null
      },
      isExporting: false
    }
  },
  computed: {
    ...mapGetters({
      usersStats: 'stats/usersStats'
    }),
    sortedKycStats () {
      if (!this.usersStats.isLoaded) {
        return []
      }
      const statsByKycType = {}

      for (const kycStat of this.usersStats.value.countByKycLevels) {
        statsByKycType[kycStat.kycType] = kycStat
      }

      return ['EmailKycRequest', 'PhoneKycRequest', 'PersonalKycRequest', 'AddressKycRequest', 'DocumentsKycRequest'].map(type => {
        return statsByKycType[type]
      }).filter(e => e != null)
    }
  },
  async created () {
    await this.reloadData()
  },
  methods: {
    ...mapActions({
      loadUsersStats: 'stats/loadUsersStats',
      export: 'stats/exportUserStats'
    }),
    getKycLabel (item) {
      const type = item.kycType
      if (type === 'EmailKycRequest') {
        return '(kyc) Emails'
      } else if (type === 'PhoneKycRequest') {
        return '(kyc) Phone'
      } else if (type === 'PersonalKycRequest') {
        return '(kyc) Personal'
      } else if (type === 'AddressKycRequest') {
        return '(kyc) Address'
      } else if (type === 'DocumentsKycRequest') {
        return '(kyc) Documents'
      }
      return 'Unknown'
    },
    async handleFrom (value) {
      this.filters.from = value && moment(value, 'YYYY-MM-DD').startOf('day').toDate()
      await this.reloadData()
    },
    async handleTo (value) {
      this.filters.to = value && moment(value, 'YYYY-MM-DD').endOf('day').toDate()
      await this.reloadData()
    },
    async reloadData () {
      await this.loadUsersStats({
        from: this.filters.from,
        to: this.filters.to
      })
    },
    async handleExport () {
      this.isExporting = true
      setImmediate(async () => {
        try {
          const { exportLink } = await this.export({
            from: this.filters.from,
            to: this.filters.to
          })

          this.createExportLinkAndClick({
            href: exportLink
          })
        } finally {
          this.isExporting = false
        }
      })
    },
    createExportLinkAndClick ({ href }) {
      const el = document.createElement('a')
      el.setAttribute('href', href)
      el.setAttribute('download', true)
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
    }
  }
}
