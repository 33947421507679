import { omitBy, isNil } from 'lodash'
import { WithdrawalModel } from 'src/models'
import { BACKEND, withAuthorization, withAuthorizationAndVerification } from 'src/remotes'

export const REDEMPTIONS_LOADING = 'redemptions/loading'
export const REDEMPTIONS_LOADED = 'redemptions/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      actualDate: null,
      activePage: 1,
      numberOfElements: 10, // page size
      limit: 100,
      items: [],
      count: null,
      filters: {
        state: null
      }
    }
  },
  mutations: {
    [REDEMPTIONS_LOADING]: (state) => {
      state.isLoading = true
    },
    [REDEMPTIONS_LOADED]: (state, { actualDate, count, items, activePage, numberOfElements }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        actualDate,
        activePage,
        numberOfElements,
        count,
        items
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    items: state => state.items,
    totalPages (state) {
      const count = state.count
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async fetch ({ dispatch }, { actualDate, offset, limit, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { state, type, symbol } = filters
      const { data } = await BACKEND.get('me/withdrawals', withAuthorization(token, {
        params: {
          state: (state == null || state === '*' || state.length === 0)
            ? null
            : arrayOfQueryParam(state),
          type: (type == null || type === '*' || type.length === 0)
            ? null
            : arrayOfQueryParam(type),
          symbol: (symbol == null || symbol === '*' || symbol.length === 0)
            ? null
            : arrayOfQueryParam(symbol),
          actualDate,
          offset,
          limit
        }
      }))
      return data
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      }
    ) {
      if (!state.isLoading) {
        commit(REDEMPTIONS_LOADING)
        const { actualDate, items, count } = await dispatch('fetch', {
          actualDate: !reset && state.isLoaded
            ? state.actualDate
            : null,
          filters,
          offset: (page.activePage - 1) * page.numberOfElements,
          limit: page.numberOfElements
        })
        commit(REDEMPTIONS_LOADED, {
          actualDate,
          activePage: page.activePage,
          numberOfElements: page.numberOfElements,
          count,
          items: items.map(WithdrawalModel.fromJson)
        })
      }
    },
    async createRedeem ({ state, commit, dispatch }, { currency, amount, wallet, bankAccount }) {
      const withdrawal = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post('me/withdrawals', {
            symbol: currency.symbol,
            address: wallet.address,
            amount,
            bankAccount: bankAccount.key
          }, withAuthorizationAndVerification(token, code))
          const withdrawal = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: [withdrawal, ...state.items]
          })
          return withdrawal
        }
      }, { root: true })
      return withdrawal
    },
    async cancelRedeem ({ state, commit, dispatch }, { id }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post(`me/withdrawals/i/${id}/cancel`, null, withAuthorizationAndVerification(token, code))
          const redeem = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: state.items.map(
              p => p.id !== redeem.id
                ? p
                : WithdrawalModel.fromJson(redeem)
            )
          })
          return redeem
        }
      }, { root: true })
      return result
    }
  }
})

function arrayOfQueryParam (value) {
  return value == null
    ? null
    : (Array.isArray(value) ? value : [value])
}
