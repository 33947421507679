import BigNumber from 'bignumber.js'
import AbstractTokenDAO from './AbstractTokenDAO'

export default class EIP20TokenDAO extends AbstractTokenDAO {
  constructor (token, abi) {
    super(token)
    this.abi = abi
  }

  connect (web3, options) {
    if (this.isConnected) {
      this.disconnect()
    }
    this.contract = new web3.eth.Contract(this.abi.value.abi, this.token.address, options)
    this.eventsEmitter = this.contract.events.allEvents({})
      .on('data', this.handleData.bind(this))
      .on('changed', this.handleChanged.bind(this))
      .on('error', this.handleError.bind(this))
    this.statsInterval = setInterval(() => {
      if (this.isConnected) {
        this.handleStats()
      }
    }, 10 * 1000)
  }

  disconnect () {
    if (this.isConnected) {
      this.eventsEmitter.removeAllListeners()
      clearInterval(this.statsInterval)
      this.statsInterval = null
      this.eventsEmitter = null
      this.contract = null
    }
  }

  get isConnected () {
    return this.contract != null // nil check
  }

  get isTradeSupported () {
    return true
  }

  get isTransferSupported () {
    return true
  }

  get isApproveSupported () {
    return true
  }

  async getTotalSupply () {
    const res = await this.contract.methods.totalSupply().call()
    return new BigNumber(res)
  }

  async getBalance (address) {
    return new BigNumber(await this.contract.methods.balanceOf(address).call())
  }

  async getAllowance (owner, spender) {
    return new BigNumber(await this.contract.methods.allowance(owner, spender).call())
  }

  createTransferTx (sender, recipient, amount) {
    const value = BigNumber.isBigNumber(amount)
      ? amount.toString(10)
      : amount
    const data = this.contract.methods.transfer(recipient, value).encodeABI()
    return {
      from: sender,
      to: this.token.address,
      data
    }
  }

  createApproveTx (owner, spender, amount) {
    const value = BigNumber.isBigNumber(amount)
      ? amount.toString(10)
      : amount
    const data = this.contract.methods.approve(spender, value).encodeABI()
    const tx = {
      from: owner,
      to: this.token.address,
      data
    }
    return tx
  }

  handleData (data) {
    switch (data.event) {
      case 'Transfer':
        this.handleStats()
        return this.handleTransferData(data)
      case 'Approval':
        return this.handleApprovalData(data)
    }
  }

  handleChanged (event) {
    // eslint-disable-next-line
    console.warn('[EIP20TokenDAO] Event changed', event)
  }

  handleError (error) {
    // eslint-disable-next-line
    console.error('[EIP20TokenDAO] Error in event subscription', error)
  }

  handleTransferData (data) {
    const { returnValues } = data
    setImmediate(() => {
      this.emit('transfer', {
        key: `${data.transactionHash}/${data.logIndex}`,
        data,
        log: {
          ...data,
          ...data.raw
        },
        token: this.token,
        from: returnValues[0],
        to: returnValues[1],
        value: new BigNumber(returnValues[2])
      })
    })
  }

  handleApprovalData (data) {
    const { returnValues } = data
    setImmediate(() => {
      this.emit('approval', {
        key: `${data.transactionHash}/${data.logIndex}`,
        data,
        log: {
          ...data,
          ...data.raw
        },
        token: this.token,
        owner: returnValues[0],
        spender: returnValues[1],
        value: new BigNumber(returnValues[2])
      })
    })
  }

  handleStats () {
    setImmediate(() => {
      this.emit('stats', {
        token: this.token
      })
    })
  }
}
