import { stringToColour } from 'src/utils/lib/stringToColour'

export default {
  props: {
    type: String,
    reference: String
  },
  computed: {
    number () {
      const type = this.type
      const reference = this.reference
      if (type == null || reference == null) {
        return null
      }
      if (reference.indexOf(type) === 0) {
        return reference.substring(type.length)
      }
    },
    title () {
      const type = this.type
      const number = this.number
      const reference = this.reference
      if (type != null && number != null) {
        return `${type} ${number}`
      } else if (reference != null) {
        return reference
      } else if (type != null) {
        return this.type
      }
      return null
    }
  },
  methods: {
    colorize (value) {
      return stringToColour(value)
    }
  }
}
