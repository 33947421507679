import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object().keys({
  id: Joi.string().required(),
  account: Joi.string().required(),
  depositAddress: Joi.string().required(),
  index: Joi.number(),
  plasmaHash: Joi.string().allow([null, '']),
  state: Joi.string().required(),
  type: Joi.string().required(),
  version: Joi.string().required()
})

export default class ManageDepositsAddressesModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new ManageDepositsAddressesModel({
      ...data
    }, { stripUnknown: true })
  }
}
