import Joi from 'joi'
import PluginModel from './PluginModel'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'
import { WithdrawalNetwork } from 'src/dictionaries'

const schemaFactory = () => ({
  id: Joi.string().required(),
  type: Joi.string().valid('RaidoWithdrawPlugin'),
  minKycLevel: Joi.number().min(0).max(4).required(),
  minAmount: Joi.string().allow([null, '']),
  maxAmount: Joi.string().allow([null, '']),
  fiatCurrency: Joi.string().required(),
  raidoInCurrencyId: Joi.string().required(),
  raidoOutCurrencyId: Joi.string().required(),
  isSepa: Joi.boolean().default(false),
  // TODO: after prod will be updated, remove null from possible values and mark field required
  network: Joi.string().allow([...WithdrawalNetwork.values(), null]),
  commission: Joi.alternatives().try(
    Joi.object().type(FixedCommissionModel),
    Joi.object().type(PercentCommissionModel),
    Joi.object().type(DefaultCommissionModel)
  ).allow(null)
})

export default class RaidoWithdrawPluginModel extends PluginModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new RaidoWithdrawPluginModel({
      id: data.id,
      type: 'RaidoWithdrawPlugin',
      minKycLevel: data.minKycLevel,
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      raidoInCurrencyId: data.raidoInCurrencyId,
      raidoOutCurrencyId: data.raidoOutCurrencyId,
      isSepa: data.isSepa,
      fiatCurrency: data.fiatCurrency,
      network: data.network,
      commission: this.prepareCommission(data.commission)
    })
  }
}
