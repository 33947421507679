import * as components from 'src/components'
import * as directives from 'src/directives'

export default {
  components: {
    ...components
  },
  props: {
    section: String,
    title: String,
    explanation: String
  },
  directives: {
    ...directives
  }
}
