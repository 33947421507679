import {mapActions} from 'vuex'
import * as components from 'src/components'
import SelectDate from 'src/components/private/SelectDate/SelectDate.vue'
import moment from 'moment'

export default {
  components: {
    ...components,
    SelectDate
  },
  data () {
    return {
      isAddInProgress: false,
      form: {
        date: moment().startOf('day').toDate(),
        showOnLanding: false
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      rescanDeposits: 'monoova/rescanDeposits'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.rescanDeposits({
            date: this.form.date
          })
          this.handleClose()
        } catch (e) {
          await this.handleError({
            error: e,
            message: 'Cannot rescan deposits'
          })
        } finally {
          this.isAddInProgress = false
        }
      })
    },
    handleDate (value) {
      this.form.date = moment(value).startOf('day').toDate()
    }
  }
}
