<template>
  <div class="root-modal" :class="{
    'root-modal-is-closing': isClosing
  }">
    <div class="content" @click.self="handleClose">
      <proxy :component="modal.factory()" :pass="modal.data" ref="component" />
    </div>
  </div>
</template>

<script>
import Proxy from './Proxy.vue'

export default {
  props: {
    modal: Object,
    isClosing: Boolean
  },
  components: {
    Proxy
  },
  methods: {
    handleClose () {
      const form = this.$el.querySelector('form')
      if (form) {
        form.reset()
      } else {
        this.$store.dispatch('modals/close')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~src/styles/mixins';
@import '~src/styles/variables';
@import '~src/styles/animations/fadeIn';
@import '~src/styles/animations/fadeOut';

.root-modal {
  @include fadeIn();
  opacity: 0;
  animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;

  &-is-closing {
    @include fadeOut();
    opacity: 1;
    animation-duration: 0.4s;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
  }

  @include position(fixed, 0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.300);
  display: flex;
  align-items: center;
  justify-content: center;

  >.content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 auto;
    height: 100%;
    max-height: 86%;
    width: 100%;
    >* {
      flex: 0 0 auto;
    }
    @media (max-width: $breakpoint-mobile) {
      max-height: 100%;
    }
  }
}
</style>
