import {isNil, omitBy} from 'lodash'
import { ReferralData, LoadableModel, LoadableMapModel } from 'src/models'
import {PASSPORT, BACKEND, withAuthorization} from 'src/remotes'

export const CAMPAIGNS_LOADING = 'campaigns/loading'
export const CAMPAIGNS_LOADED = 'campaigns/loaded'

export const CAMPAIGNS_DETAILS_LOADING = 'campaigns/details/loading'
export const CAMPAIGNS_DETAILS_LOADED = 'campaigns/details/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      activePage: 1,
      limit: 30, // elements per page
      count: null, // elements total
      campaigns: [],
      table: new LoadableMapModel(LoadableModel)
    }
  },
  mutations: {
    [CAMPAIGNS_LOADING]: (state) => {
      state.isLoading = true
    },
    [CAMPAIGNS_LOADED]: (state, { offset, limit, count, campaigns, activePage }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        limit,
        count,
        campaigns,
        activePage
      }, isNil))
    },
    [CAMPAIGNS_DETAILS_LOADING]: (state, { id }) => {
      const model = state.table.get(id) || new LoadableModel(ReferralData)
      state.table = state.table.put(id, model.loading())
    },
    [CAMPAIGNS_DETAILS_LOADED]: (state, { id, value }) => {
      const model = state.table.get(id) || new LoadableModel(ReferralData)
      state.table = state.table.put(id, model.loaded(value))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    limit: state => state.limit,
    count: state => state.count,
    campaigns: state => state.campaigns,
    activePage: state => state.activePage,
    totalPages (state) {
      const count = state.count
      const pageSize = state.limit
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    },
    getDetailData: state => id => state.table.get(id) || new LoadableModel(ReferralData)
  },
  actions: {
    async fetchCampaigns ({ dispatch, state }, { offset, limit } = { limit: state.limit }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.get('/api/v1/referral/campaigns/link', withAuthorization(token, {
        params: {
          offset,
          limit
        }
      }))
      return data
    },
    async loadCampaigns ({ state, commit, dispatch }) {
      commit(CAMPAIGNS_LOADING)
      const { offset, limit, count, campaigns } = await dispatch('fetchCampaigns')
      commit(CAMPAIGNS_LOADED, {offset, limit, count, campaigns})
    },
    async loadMore ({ state, commit, dispatch }, {
      reset = false,
      page = {
        activePage: 1,
        limit: state.limit
      }
    } = {
      reset: false,
      page: {
        activePage: 1,
        limit: state.limit
      }
    }) {
      if (!state.isLoading) {
        commit(CAMPAIGNS_LOADING)
        const { campaigns, count } = await dispatch('fetchCampaigns', {
          offset: (page.activePage - 1) * state.limit,
          limit: state.limit
        })
        commit(CAMPAIGNS_LOADED, {
          activePage: page.activePage,
          count,
          campaigns: campaigns
        })
      }
    },
    async fetchDetails ({ dispatch, state }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const [{ data: revenue }, { data: friends }] = await Promise.all([
        BACKEND.get(`/manager/revenue/campaigns/link/i/${id}`, withAuthorization(token)),
        PASSPORT.get(`/api/v1/referral/campaigns/link/i/${id}/friends`, withAuthorization(token))
      ])
      return {
        id,
        revenue,
        friends
      }
    },
    async loadDetails ({ commit, dispatch }, { id }) {
      commit(CAMPAIGNS_DETAILS_LOADING, { id })
      const data = await dispatch('fetchDetails', { id })
      commit(CAMPAIGNS_DETAILS_LOADED, { id, value: ReferralData.fromJson(data) })
    },
    async deactivateCampaign ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.post(`/api/v1/referral/campaigns/link/i/${id}/deactivate`, null, withAuthorization(token))
      return data
    },
    async prolongCampaign ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.post(`/api/v1/referral/campaigns/link/i/${id}/prolong`, null, withAuthorization(token))
      return data
    },
    async activateCampaign ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.post(`/api/v1/referral/campaigns/link/i/${id}/activate`, null, withAuthorization(token))
      return data
    }
  }
})
