import {
  LoadableListModel,
  BlogCategoryModel,
  ShortBlogArticleModel,
  BlogArticlesRequest,
  BlogArticlesResponse,
  BlogArticleModel
} from 'src/models'
import {
  DuplicateFilenameUploadError
} from 'src/errors'
import _ from 'lodash'

import {
  PUBLIC_BACKEND,
  withAuthorization
} from 'src/remotes'

export const CATEGORY_LOADING = 'blog/category/loading'
export const CATEGORY_LOADED = 'blog/category/loaded'
export const ARTICLES_LOADING = 'blog/loading'
export const ARTICLES_LOADED = 'blog/loaded'
export const DEFAULT_PAGE_SIZE = 15

export default () => ({
  namespaced: true,
  state () {
    return {
      page: 0,
      totalPages: 0,
      categories: new LoadableListModel(BlogCategoryModel),
      articles: new LoadableListModel(ShortBlogArticleModel)
    }
  },
  mutations: {
    [CATEGORY_LOADING]: (state) => {
      state.categories = state.categories.loading()
    },
    [CATEGORY_LOADED]: (state, { categories }) => {
      state.categories = state.categories.loaded(...categories)
    },
    [ARTICLES_LOADING]: (state) => {
      state.articles = state.articles.loading()
    },
    [ARTICLES_LOADED]: (state, { articles, page, totalPages, count }) => {
      state.articles = state.articles.loaded(...articles)
      state.page = page
      state.totalPages = totalPages
    }
  },
  getters: {
    categories: state => state.categories,
    articles: state => state.articles
  },
  actions: {
    async fetchCategories () {
      const { data } = await PUBLIC_BACKEND.get('blog/categories')
      return data.map(BlogCategoryModel.fromJson)
    },
    async loadCategories ({ commit, dispatch }) {
      commit(CATEGORY_LOADING)
      const categories = await dispatch('fetchCategories')
      commit(CATEGORY_LOADED, { categories })
    },
    async createCategory ({ dispatch }, { name, showOnLanding }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post('blog/categories', { name, showOnLanding }, withAuthorization(token))
      await dispatch('loadCategories')
    },
    async editCategory ({ dispatch }, { id, name, showOnLanding }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post(`blog/categories/${id}`, { name, showOnLanding }, withAuthorization(token))
      await dispatch('loadCategories')
    },
    async deleteCategory ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post(`blog/categories/${id}/remove`, null, withAuthorization(token))
      await dispatch('loadCategories')
    },
    async fetchArticles ({ dispatch, state }, { page, size }) {
      const request = new BlogArticlesRequest({
        page,
        size
      })
      const { data } = await PUBLIC_BACKEND.get('blog/articles', {
        params: request
      })
      return BlogArticlesResponse.fromJson(data)
    },
    async loadArticles ({ state, commit, dispatch }, { page, size }) {
      commit(ARTICLES_LOADING)
      const {
        items,
        page: resultPage,
        totalPages
      } = await dispatch('fetchArticles', { page, size })
      commit(ARTICLES_LOADED, {
        articles: items,
        page: resultPage,
        totalPages
      })
    },
    async getArticleByUrl ({ state }, { url }) {
      const { data } = await PUBLIC_BACKEND.get(`blog/articles/${url}`)
      return BlogArticleModel.fromJson(data)
    },
    async createArticle ({ dispatch }, { form }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post('blog/articles', _.omitBy(form, _.isNil), withAuthorization(token))
      await dispatch('loadCategories')
    },
    async editArticle ({ dispatch }, { url, form }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post(`blog/articles/${url}`, _.omitBy(form, _.isNil), withAuthorization(token))
      await dispatch('loadCategories')
    },
    async deleteArticle ({ dispatch, state }, { url }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post(`blog/articles/${url}/remove`, null, withAuthorization(token))
      await dispatch('loadArticles', {page: 0, size: DEFAULT_PAGE_SIZE})
    },
    async uploadImage ({ dispatch }, { file }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const formData = new FormData()
      formData.append('file', file, file.name)
      try {
        const { data } = await PUBLIC_BACKEND.post(`blog/image/upload`, formData, withAuthorization(token))
        return data.fileUrl
      } catch (e) {
        if (e.response && e.response.data.error.message.includes('Unique filename could not be generated')) {
          throw new DuplicateFilenameUploadError()
        }
        throw e
      }
    }
  }
})
