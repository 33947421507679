import { isNil, omitBy, get } from 'lodash'
import { PROFILE, withAuthorization } from 'src/remotes'
import { REGISTRY } from 'src/services'
import { CustomerKycDetails, ProfileSubscribeRequest, ProfileUnsubscribeRequest } from 'src/models'

export const PROFILE_LOADING = 'profile/loading'
export const PROFILE_LOADED = 'profile/loaded'
export const REPLACE_LEVELS = 'profile/replaceLevels'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      profile: {},
      levels: {
        firstLevel: '',
        level1: {
          phone: '',
          email: ''
        },
        level2: '',
        level3: '',
        level4: ''
      }
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    profile: state => state.profile,
    levels: state => state.levels,
    phone: state => state.profile.phone,
    email: state => state.profile.email,
    personal: state => state.profile.personal,
    address: state => state.profile.address,
    documents: state => !!state.profile.documents && !!state.profile.documents.attachments.length && !!state.profile.documents.selfie
  },
  mutations: {
    [PROFILE_LOADING]: (state) => {
      state.isLoading = true
    },
    [PROFILE_LOADED]: (state, profile) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        profile
      }, isNil))
    },
    [REPLACE_LEVELS]: (state, levels) => {
      state.levels = levels
    }
  },
  actions: {
    async fetchProfile ({ dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('kyc/me', withAuthorization(token))
      return data
    },
    async loadProfile ({ state, commit, dispatch }) {
      if (!state.isLoading) {
        commit(PROFILE_LOADING)
        const profile = await dispatch('fetchProfile')
        commit(PROFILE_LOADED, CustomerKycDetails.fromJson(profile))
        dispatch('updateSteps')
      }
    },
    getStepItemStatus ({ state }, profileLevelStatus) {
      switch (profileLevelStatus) {
        case 'SUBMITTED':
        case 'COMPLICATED':
          return 'submitted'
        case 'REJECTED':
          return 'declined'
        case 'ACCEPTED':
          return 'validated'
        case 'RESET':
          return ''
        default:
          return ''
      }
    },
    calculateLevel1Status ({ state }, { phone, email }) {
      if (phone === 'submitted' || email === 'submitted') {
        return 'submitted'
      }
      if (phone === 'rejected' || email === 'rejected') {
        return 'rejected'
      }
      if (phone === 'checking' || email === 'checking') {
        return 'checking'
      }
      if (phone === 'declined' || email === 'declined') {
        return 'declined'
      }
      if (phone === 'validated' && email === 'validated') {
        return 'validated'
      }
      return ''
    },
    async updateSteps ({ state, commit, dispatch }) {
      const phoneStatus = await dispatch('getStepItemStatus', get(state.profile, 'phone.status'))
      const emailStatus = await dispatch('getStepItemStatus', get(state.profile, 'email.status'))
      const personalStatus = await dispatch('getStepItemStatus', get(state.profile, 'personal.status'))
      const addressStatus = await dispatch('getStepItemStatus', get(state.profile, 'address.status'))
      const documentsStatus = await dispatch('getStepItemStatus', get(state.profile, 'documents.status'))

      let rapidIdStatus = null

      if (state.profile.isRapidIDComplete) {
        rapidIdStatus = 'approved'
      } else if (state.profile.activeRapidIdRequest) {
        rapidIdStatus = 'checking'
      }

      const levels = {
        level1: {
          phone: phoneStatus,
          email: emailStatus
        },
        firstLevel: await dispatch('calculateLevel1Status', { phone: phoneStatus, email: emailStatus }),
        level2: rapidIdStatus || personalStatus,
        level3: rapidIdStatus || addressStatus,
        level4: rapidIdStatus || documentsStatus
      }

      commit(REPLACE_LEVELS, levels)
    },
    async updatePhone ({ commit, dispatch }, { phone }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/phone', {
        phone
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data)
      commit(PROFILE_LOADED, profile)
      return profile
    },
    async validatePhone ({ commit, dispatch }, { code }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/phone/confirm', {
        code
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data.profile)
      commit(PROFILE_LOADED, profile)
      dispatch('updateSteps')
      return data
    },
    async resendPhoneConfirmation ({ commit, dispatch }, { phone }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/phone/resendConfirmation', {
        phone
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data.profile)
      commit(PROFILE_LOADED, profile)
      return profile
    },
    async updateEmail ({ commit, dispatch }, { email }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/email', {
        email
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data)
      commit(PROFILE_LOADED, profile)
      dispatch('updateSteps')
      return profile
    },
    async validateEmail ({ commit, dispatch }, { code }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/email/confirm', {
        code
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data.profile)
      commit(PROFILE_LOADED, profile)
      return data
    },
    async resendEmailConfirmation ({ commit, dispatch }, { email }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/email/resendConfirmation', {
        email
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data.profile)
      commit(PROFILE_LOADED, profile)
      return profile
    },
    async updatePersonal ({ commit, dispatch }, { firstName, lastName, birthDate }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/personal', {
        firstName,
        lastName,
        birthDate
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data)
      commit(PROFILE_LOADED, profile)
      dispatch('updateSteps')
      return profile
    },
    async updateAddress ({ commit, dispatch }, { country, state, city, zip, addressLine1, addressLine2 }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/address', {
        country,
        state,
        city,
        zip,
        addressLine1,
        addressLine2
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data)
      commit(PROFILE_LOADED, profile)
      dispatch('updateSteps')
      return profile
    },
    async updateDocuments ({ state, commit, dispatch }, documents) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post('kyc/me/documents', {
        selfie: documents.selfie,
        attachments: documents.attachments
      }, withAuthorization(token))
      const profile = CustomerKycDetails.fromJson(data)
      commit(PROFILE_LOADED, profile)
      dispatch('updateSteps')
      return profile
    },
    async subscribe ({ commit, dispatch }, { sessionId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const listener = async m => {
        if (m.type === 'update' && m.channel === 'profile') {
          const profile = await dispatch('fetchProfile')
          commit(PROFILE_LOADED, CustomerKycDetails.fromJson(profile))
          await dispatch('updateSteps')
        }
      }

      const profileSocketClient = REGISTRY.getService('profileSocketClient')
      profileSocketClient
        .on('message', listener)
        .ensure(new ProfileSubscribeRequest({
          requestId: `profile-session-${sessionId}`,
          payload: {
            token
          }
        }))
      return listener
    },
    async unsubscribe (_, { sessionId, listener }) {
      const profileSocketClient = REGISTRY.getService('profileSocketClient')
      profileSocketClient
        .send(new ProfileUnsubscribeRequest({
          requestId: `profile-session-${sessionId}`
        }))
        .removeListener('message', listener)
    },
    async startRapidId ({ dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post('kyc/me/rapidId/start', null, withAuthorization(token))
      await dispatch('loadProfile')
      await dispatch('updateSteps')
    }
  }
})
