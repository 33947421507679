/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact-telegram': {
    width: 16,
    height: 16,
    viewBox: '0 -60 512 511',
    data: '<path pid="0" d="M507.773 7.293c-5.015-6.11-13.332-8.375-20.726-5.656L17.39 171.359C8.008 174.77 1.42 183.04.199 192.941c-1.21 9.856 3.133 19.418 11.281 24.922l67.133 45.828a21.13 21.13 0 0021.832 1.243L246.07 188.14l-113.496 84.746c-5.328 3.98-8.512 10.328-8.512 16.976v78.5c0 8.098 4.442 15.516 11.59 19.367a22.028 22.028 0 0022.61-1.015l51.004-33.832 50.156 34.199c4.394 3.012 9.629 4.598 14.91 4.598 1.785 0 3.578-.184 5.344-.551 7.043-1.469 13.273-5.82 17.097-11.938L509.262 28.81c4.136-6.746 3.543-15.38-1.489-21.516zm0 0"/>'
  }
})
