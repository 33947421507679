// eslint-disable-next-line no-undef
const config = global.__APP_CONFIG__

export default () => ({
  namespaced: true,
  actions: {
    async handleMessage ({ dispatch }, message) {
      if (message.origin === config.pages.url) {
        const data = JSON.parse(message.data)
        switch (data.type) {
          case 'POLI:PURCHASE:STARTED':
            return dispatch('handlePoliStarted', data.payload)
          case 'POLI:PURCHASE:SUCCESS':
            return dispatch('handlePoliSuccess', data.payload)
          case 'POLI:PURCHASE:FAILURE':
            return dispatch('handlePoliFailure', data.payload)
          case 'POLI:PURCHASE:CANCELLED':
            return dispatch('handlePoliCancelled', data.payload)
        }
        // eslint-disable-next-line
        console.log(`[Messages API] Unknown message type: ${data.type}`, data)
      }
    },
    async handlePoliStarted ({ dispatch }, { reference }) {
      try {
        await dispatch('myPurchases/discoverPurchaseByReference', { reference }, { root: true })
        await dispatch('interaction/toast', {
          message: 'Purchase request registered'
        }, { root: true })
      } catch (e) {
        // eslint-disable-next-line
        console.log(`[Messages API] Invalid purchase reference: ${reference}`)
      }
    },
    async handlePoliSuccess ({ dispatch }, { reference }) {
      try {
        await dispatch('myPurchases/discoverPurchaseByReference', { reference }, { root: true })
        await dispatch('interaction/toast', {
          message: 'Purchase request verified'
        }, { root: true })
      } catch (e) {
        // eslint-disable-next-line
        console.log(`[Messages API] Invalid purchase reference: ${reference}`)
      }
    },
    async handlePoliFailed ({ dispatch }, { reference }) {
      try {
        await dispatch('myPurchases/discoverPurchaseByReference', { reference }, { root: true })
        await dispatch('interaction/toast', {
          message: 'Purchase request interrupted'
        }, { root: true })
      } catch (e) {
        // eslint-disable-next-line
        console.log(`[Messages API] Invalid purchase reference: ${reference}`)
      }
    },
    async handlePoliCancelled ({ dispatch }, { reference }) {
      try {
        await dispatch('myPurchases/discoverPurchaseByReference', { reference }, { root: true })
        await dispatch('interaction/toast', {
          message: 'Purchase request cancelled'
        }, { root: true })
      } catch (e) {
        // eslint-disable-next-line
        console.log(`[Messages API] Invalid purchase reference: ${reference}`)
      }
    }
  }
})
