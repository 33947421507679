import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import BlogCategoryModel from './BlogCategoryModel'

export const schemaFactory = () => Joi.object().keys({
  id: Joi.string().required(),
  publishDate: Joi.date().required(),

  title: Joi.string().required(),

  author: Joi.object().keys({
    name: Joi.string().required(),
    position: Joi.string().allow([null, '']),
    avatar: Joi.string().required()
  }),

  preview: Joi.object().keys({
    image: Joi.string().required(),
    imageAlt: Joi.string().required(),
    description: Joi.string().required()
  }),

  metainfo: Joi.object().keys({
    url: Joi.string().required(),
    categories: Joi.object().type(BlogCategoryModel).required(),
    tags: Joi.array().items(Joi.string())
  }),

  seo: Joi.object().keys({
    metatags: Joi.array().items(Joi.object().keys({
      name: Joi.string().required(),
      content: Joi.string().required()
    })).optional(),
    ogmetatags: Joi.array().items(Joi.object().keys({
      property: Joi.string().required(),
      content: Joi.string().required()
    })).optional()
  })
})

export default class ShortBlogArticleModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new ShortBlogArticleModel({
      ...data,
      metainfo: {
        ...data.metainfo,
        categories: BlogCategoryModel.fromJson(data.metainfo.categories)
      }
    }, { stripUnknown: true })
  }
}
