import { LoadableListModel, FiatCurrencyModel } from 'src/models'
import { PROFILE } from 'src/remotes'

export const REGISTRY_LOADING = 'registry/loading'
export const REGISTRY_LOADED = 'registry/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      fiatCurrencies: new LoadableListModel(FiatCurrencyModel)
    }
  },
  mutations: {
    [REGISTRY_LOADING]: (state) => {
      state.fiatCurrencies = state.fiatCurrencies.loading()
    },
    [REGISTRY_LOADED]: (state, { fiatCurrencies }) => {
      state.fiatCurrencies = state.fiatCurrencies.loaded(...fiatCurrencies)
    }
  },
  getters: {
    fiatCurrencies: state => state.fiatCurrencies,
    getFiatCurrency: state => key => state.fiatCurrencies.isLoaded
      ? state.fiatCurrencies.value.find(fc => fc.key === key)
      : null,
    getFiatCurrencyBySymbol: state => symbol => state.fiatCurrencies.isLoaded
      ? state.fiatCurrencies.value.find(fc => fc.symbol === symbol)
      : null,
    isLoaded: state => state.fiatCurrencies.isLoaded
  },
  actions: {
    async fetchRegistry () {
      const { data } = await PROFILE.get('registry')
      const {
        fiatCurrencies
      } = data
      return {
        fiatCurrencies: fiatCurrencies.map(FiatCurrencyModel.fromJson)
      }
    },
    async loadRegistry ({ commit, dispatch }) {
      commit(REGISTRY_LOADING)
      const { fiatCurrencies } = await dispatch('fetchRegistry')
      commit(REGISTRY_LOADED, { fiatCurrencies })
    }
  }
})
