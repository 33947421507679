import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  type: Joi.string().valid(['passport', 'profile']).required(),
  id: Joi.string().required()
})

export default class ProfileIdentityModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  get key () {
    return `identity-${this.type}-${this.id}`
  }
}
