import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    message: String
  },
  data () {
    return {}
  },
  validations: {},
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    })
  }
}
