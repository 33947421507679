import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import TokenDAOModel from './TokenDAOModel'

export const schemaFactory = () => ({
  token: Joi.object().type(TokenDAOModel).required(),
  address: Joi.string().required()
})

export default class EthereumPocketModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  get key () {
    return `[token:${this.token.token.key}]-[account:${this.address.toLowerCase()}]`
  }
}
