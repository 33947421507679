import * as components from 'src/components'
import { copyToClipboard } from 'src/utils/lib/common'

export default {
  components: {
    ...components
  },
  props: {
    value: [ Number, String, Object, Array, Date ],
    small: Boolean,
    top: Boolean,
    right: Boolean,
    bottom: Boolean,
    left: Boolean
  },
  data () {
    return {
      isCopied: false
    }
  },
  methods: {
    handleCopy (e) {
      e.preventDefault()
      e.stopPropagation()
      copyToClipboard(this.value)
      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    }
  }
}
