import * as components from 'src/components'
import { mapGetters } from 'vuex'
import { PlasmaPocketModel } from 'src/models/domain'
import * as micros from 'src/micros'
import { copyToClipboard } from 'src/utils/lib/common'
import { formatNumber } from 'accounting'
import BigNumber from 'bignumber.js'

export default {
  components: {
    ...components,
    ...micros
  },
  props: {
    wallets: Array,
    isMobile: Boolean
  },
  data () {
    return {
      currentWallet: Object,
      isCopied: false
    }
  },
  async created () {
    this.currentWallet = this.wallets[0]
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      getPlasmaBalance: 'plasmaBalances/balance'
    }),
    items () {
      const currencies = this.currencies.value
      return [this.currentWallet].map(wallet => ({
        wallet,
        pockets: currencies.map(currency => new PlasmaPocketModel({
          currency,
          address: wallet.address
        }))
      }))
    }
  },
  methods: {
    formatNumber,
    BigNumber,
    changeWallet (key) {
      this.currentWallet = this.wallets.find(e => e.address === key)
      this.$emit('wallet', this.currentWallet)
    },
    copyClipboard () {
      copyToClipboard(this.currentWallet.address)
      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    }
  }
}
