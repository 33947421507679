/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credentials-telegram': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path pid="0" d="M21 1.64l-3.188 14.907c-.25 1.062-.828 1.344-1.734.844l-4.828-3.563-2.344 2.25c-.312.313-.64.469-.984.469l.375-4.922 8.953-8.063c.219-.218.219-.343 0-.374-.188-.063-.39-.016-.61.14L5.579 10.313l-4.781-1.5c-.531-.157-.781-.391-.75-.704.031-.312.36-.578.984-.796l18.61-7.22c.468-.155.843-.093 1.125.188.28.25.359.703.234 1.36z"/>'
  }
})
