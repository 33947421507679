/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stack-coins-icon': {
    width: 26,
    height: 26,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 14.396c.056.058.155.133.295.216.407.241 1.05.479 1.877.685C6.949 15.742 9.389 16 12 16c2.611 0 5.051-.258 6.828-.703.826-.206 1.47-.445 1.878-.684.14-.083.238-.159.294-.217v-2.711C19.028 12.553 15.769 13 12 13s-7.028-.447-9-1.315v2.711zm0 2.289v2.711c.056.058.155.133.295.216.407.241 1.05.479 1.877.686C6.949 20.742 9.389 21 12 21c2.611 0 5.051-.258 6.828-.703.826-.206 1.47-.445 1.878-.684.14-.084.238-.159.294-.217v-2.711C19.028 17.554 15.769 18 12 18s-7.028-.447-9-1.315zm0-7.289c.056.058.155.133.295.216.407.241 1.05.479 1.877.685C6.949 10.742 9.389 11 12 11c2.611 0 5.051-.258 6.828-.703.826-.206 1.47-.445 1.878-.684.14-.083.238-.159.294-.217V6.685C19.028 7.554 15.769 8 12 8s-7.028-.447-9-1.315v2.711zM3.123 4.5c.047.035.104.073.171.112.408.241 1.052.479 1.877.686C6.95 5.742 9.39 6 12 6c2.611 0 5.051-.258 6.828-.702.826-.207 1.47-.446 1.878-.686.059-.034.116-.072.172-.112a2.116 2.116 0 00-.172-.112c-.408-.241-1.052-.479-1.878-.685C17.051 3.256 14.612 3 12 3c-2.611 0-5.051.258-6.829.703-.825.206-1.47.445-1.877.685-.059.034-.116.072-.171.112zM1 19.5v-15C1 2.204 5.813 1 12 1s11 1.204 11 3.5v15c0 2.297-4.813 3.5-11 3.5S1 21.797 1 19.5z" _fill="#FF7715"/>'
  }
})
