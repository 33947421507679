import moment from 'moment'
import {mapActions, mapGetters, mapState} from 'vuex'
import * as components from 'src/components'
import { copyToClipboard } from 'src/utils/lib/common'
import { SendEmailModal } from 'src/modals'

export default {
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData',
      hasMfaActionsAccess: 'grants/hasMfaActionsAccess'
    }),
    ...mapState({
      profileId: state => state.route.params.id
    }),
    hasEmailCredentials () {
      return this.getProfileData(this.profileId).value.credentials.find(e => e.type === 'EMAIL' && e.isConfirmed) != null
    },
    ssoUserId () {
      return this.getProfileData(this.profileId).value.profile.ssoUserId
    },
    verificators () {
      return this.getProfileData(this.profileId).value.verificators
    }
  },
  methods: {
    moment,
    ...mapActions({
      reSand2faEmail: 'customers/reSand2faEmail',
      openModal: 'modals/open'
    }),
    copyClipboard (path) {
      copyToClipboard(path)
    },
    async reSand (verificator) {
      if (this.hasEmailCredentials) {
        await this.reSand2faEmail({
          ssoUserId: this.ssoUserId,
          verificator: verificator
        })
      } else {
        this.openModal({
          factory: () => SendEmailModal,
          data: {
            title: 'Send to Email',
            ssoUserId: this.ssoUserId,
            verificator: verificator
          }
        })
      }
    }
  }
}
