import { omitBy, isNil } from 'lodash'
import { DepositModel, DepositSubscribeRequest, DepositUnsubscribeRequest, DepositPlasmaModel, DirectTransferModel } from 'src/models'
import { REGISTRY } from 'src/services'
import {BACKEND, PLASMA, withAuthorization} from 'src/remotes'

export const PURCHASES_LOADING = 'purchases/loading'
export const PURCHASES_LOADED = 'purchases/loaded'
export const PURCHASES_AFFECTED = 'purchases/affected'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      actualDate: null,
      activePage: 1,
      numberOfElements: 10, // page size
      limit: 100,
      items: [],
      plasmaDepositsItems: [],
      directTransferItems: [],
      count: null,
      updatesCount: 0,
      filters: {
        state: null,
        type: null,
        symbol: null
      }
    }
  },
  mutations: {
    [PURCHASES_AFFECTED]: (state, updatesDiff = +1) => {
      state.updatesCount += updatesDiff
    },
    [PURCHASES_LOADING]: (state) => {
      state.isLoading = true
    },
    [PURCHASES_LOADED]: (state, { actualDate, updatesCount, count, items, activePage, numberOfElements }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        actualDate,
        updatesCount,
        activePage,
        numberOfElements,
        count,
        items
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    updatesCount: state => state.updatesCount,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    items: state => state.items,
    plasmaDepositsItems: state => state.plasmaDepositsItems,
    directTransferItems: state => state.directTransferItems,
    totalPages (state) {
      const count = state.count
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async subscribe ({ state, commit, dispatch }, { sessionId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const listener = async m => {
        if (m.type === 'update' && m.channel === 'deposits') {
          commit(PURCHASES_AFFECTED, +1)
          const localCopy = state.items.find(p => p.id === m.payload.id)
          if (localCopy != null) {
            dispatch('refreshPurchase', { id: localCopy.id })
          }
        }
      }

      const exchangeSocketClient = REGISTRY.getService('exchangeSocketClient')
      exchangeSocketClient
        .on('message', listener)
        .ensure(new DepositSubscribeRequest({
          requestId: `exchange-session-deposits-${sessionId}`,
          payload: {
            token
          }
        }))

      return listener
    },
    async unsubscribe (_, { sessionId, listener }) {
      const exchangeSocketClient = REGISTRY.getService('exchangeSocketClient')
      exchangeSocketClient
        .send(new DepositUnsubscribeRequest({
          requestId: `exchange-session-deposits-${sessionId}`
        }))
        .removeListener('message', listener)
    },
    async fetch ({ dispatch }, { actualDate, offset, limit, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { state, type, symbol, addresses } = filters
      const { data } = await BACKEND.post('manager/deposits/query', {
        state: (state == null || state === '*' || state.length === 0)
          ? null
          : state,
        type: (type == null || type === '*' || type.length === 0)
          ? null
          : type,
        symbol: (symbol == null || symbol === '*' || symbol.length === 0)
          ? null
          : symbol,
        addresses,
        actualDate,
        offset,
        limit
      }, withAuthorization(token))
      return data
    },
    async fetchTyped ({ dispatch }, options) {
      const { items } = await dispatch('fetch', options)
      return items.map(DepositModel.fromJson)
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      }
    ) {
      if (!state.isLoading) {
        commit(PURCHASES_LOADING)
        const { actualDate, items, count } = await dispatch('fetch', {
          actualDate: !reset && state.isLoaded
            ? state.actualDate
            : null,
          filters,
          offset: (page.activePage - 1) * page.numberOfElements,
          limit: page.numberOfElements
        })
        commit(PURCHASES_LOADED, {
          actualDate,
          updatesCount: reset ? 0 : undefined,
          activePage: page.activePage,
          numberOfElements: page.numberOfElements,
          count,
          items: items.map(DepositModel.fromJson)
        })
      }
    },
    async refreshPurchase ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`manager/deposits/i/${id}`, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : purchase
        )
      })
      return purchase
    },
    async acceptPurchase ({ state, commit, dispatch }, { id, fiatAmount, exchangeRate }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const body = omitBy({
        exchangeRate,
        fiatAmount: fiatAmount == null
          ? undefined
          : fiatAmount.toString()
      }, isNil)
      const { data } = await BACKEND.post(`manager/deposits/i/${id}/accept`, body, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : purchase
        )
      })
      return purchase
    },
    async retryDeposit ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`manager/deposits/i/${id}/retry`, {}, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : purchase
        )
      })
      return purchase
    },
    async finishDeposit ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`manager/deposits/i/${id}/finish`, {}, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : purchase
        )
      })
      return purchase
    },
    async refreshPurchaseState ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`manager/deposits/i/${id}/refresh`, { id }, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : purchase
        )
      })
      return purchase
    },
    async rejectPurchase ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`manager/deposits/i/${id}/reject`, null, withAuthorization(token))
      const purchase = DepositModel.fromJson(data)
      commit(PURCHASES_LOADED, {
        items: state.items.map(
          p => p.id !== purchase.id
            ? p
            : purchase
        )
      })
      return purchase
    },
    async fetchPlasmaDeposits ({ dispatch }, { address }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('/manager/deposits', withAuthorization(token, {
        params: {
          address
        }
      }))
      const plasmaDepositsItems = data.map(DepositPlasmaModel.fromJson)
      return plasmaDepositsItems
    },
    async fetchDirectTransfer ({ dispatch }, { address }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('/manager/transfers', withAuthorization(token, {
        params: {
          address
        }
      }))
      const directTransferItems = data.map(DirectTransferModel.fromJson)
      return directTransferItems
    }
  }
})
