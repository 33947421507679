import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  phone: Joi.string().required(),
  status: Joi.string().required(),
  createdAt: Joi.date().required(),
  expiredAt: Joi.date().required(),
  firstName: Joi.string().allow([null, '']),
  lastName: Joi.string().allow([null, '']),
  middleName: Joi.string().allow([null, '']),
  rapidId: Joi.string().required(),
  userId: Joi.string().required()
})

export default class RapidIDModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new RapidIDModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
