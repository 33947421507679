import Joi from 'joi'
import AbstractModel from './AbstractModel'

export const schemaFactory = () => ({
  name: Joi.string().required(),
  domain: Joi.string().required().allow('audt.to'),
  provider: Joi.string().valid('SMS', 'EMAIL', 'WEBSOCKET').required(),
  value: Joi.boolean()
})

export default class ToggleNotificationSettingModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new ToggleNotificationSettingModel({
      name: data.name,
      domain: 'audt.to',
      provider: data.provider && data.provider.toUpperCase(),
      value: data.value
    })
  }
}
