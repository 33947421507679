import {mapActions, mapGetters, mapState} from 'vuex'
import moment from 'moment'
import BlogCategoryPartial from 'src/partials/private/BlogCategoryPartial/BlogCategoryPartial.vue'
import { BlogCategoryCreateModal } from 'src/modals'

const DEFAULT_PAGE_SIZE = 15

export default {
  metaInfo: {
    title: 'Blog Page'
  },
  components: {
    BlogCategoryPartial
  },
  computed: {
    ...mapState({
      page: state => state.blog.page,
      totalPages: state => state.blog.totalPages
    }),
    ...mapGetters({
      categories: 'blog/categories',
      articles: 'blog/articles'
    })
  },
  async created () {
    await this.loadCategories()
    await this.loadArticles({
      page: 0,
      size: DEFAULT_PAGE_SIZE
    })
  },
  data () {
    return {
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadCategories: 'blog/loadCategories',
      loadArticles: 'blog/loadArticles',
      deleteArticle: 'blog/deleteArticle',
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleClose: 'modals/close',
      handleToast: 'interaction/toast'
    }),
    async handleAdd () {
      this.openModal({
        factory: () => BlogCategoryCreateModal
      })
    },
    async handleDeleteArticle (url) {
      await this.handleConfirmation({
        message: 'Do you really want to delete this article?',
        handler: async () => {
          await this.deleteArticle({
            url: url
          })
          this.handleClose()
          await this.handleToast({
            message: 'Article deleted'
          })
        }
      })
    },
    async handleActivePage (value) {
      this.loadArticles({
        page: value - 1,
        size: DEFAULT_PAGE_SIZE
      })
    }
  }
}
