// import uniqid from 'uniqid'
import {mapActions, mapGetters} from 'vuex'

export default {
  props: {
    tagsList: Array
  },
  computed: {
    ...mapGetters({
    })
  },
  data () {
    return {
      tagText: []
    }
  },
  methods: {
    ...mapActions({
    }),
    handleTagAdd () {
      this.$emit('tagAdd', '')
    },
    updateTagField (idx, value) {
      this.$emit('updateTagField', idx, value)
    },
    handleTagRemove (idx) {
      this.$emit('tagRemove', idx)
    }
  }
}
