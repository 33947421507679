import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { TokenDAOModel } from 'src/models'
import * as components from 'src/components'

export default {
  props: {
    token: TokenDAOModel,
    tokens: VueTypes.arrayOf(TokenDAOModel)
  },
  components: {
    ...components
  },
  async mounted () {
    if (this.token != null) {
      this.subscription = {
        token: this.token,
        listener: await this.subscribe({
          token: this.token
        })
      }
    }
    if (this.tokens != null) {
      this.subscriptions = await Promise.all(
        this.tokens.map(async token => ({
          token,
          listener: await this.subscribe({
            token: token
          })
        }))
      )
    }
  },
  async destroyed () {
    if (this.subscription) {
      await this.unsubscribe(this.subscription)
      this.subscription = null
    }
    if (this.subscriptions) {
      await Promise.all(this.subscriptions.map(s => this.unsubscribe(s)))
      this.subscriptions = null
    }
  },
  render () {
    if (this.$slots.default) {
      if (this.$slots.default.length === 1) {
        return this.$slots.default[0]
      } else if (this.$slots.default.length > 1) {
        throw new Error('EthereumStatsSubscription component may contain max one child')
      }
    }
    return null
  },
  methods: {
    ...mapActions({
      subscribe: 'ethereumStats/subscribe',
      unsubscribe: 'ethereumStats/unsubscribe'
    })
  }
}
