import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    templates: Array,
    handler: Function
  },
  data () {
    return {
      template: null,
      message: null
    }
  },
  computed: {},
  validations: {},
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    selectTemplate (template) {
      this.template = template
      if (this.message !== null) {
        let str = this.message + '\n\n'
        this.message = str.concat(template.text)
      } else {
        this.message = template.text
      }
    },
    reject () {
      if (this.message) {
        this.handler({
          ...this.template,
          text: this.message
        })
      }
      this.handleClose()
    }
  }
}
