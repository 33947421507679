import { sha256 } from 'src/utils'

const SAVE_CODE_VERIFIER = 'auth/codeVerifier/save'

export default () => ({
  namespaced: true,
  state: {
    oauthCodeVerifier: null
  },
  mutations: {
    [SAVE_CODE_VERIFIER] (state, { codeVerifier }) {
      state.oauthCodeVerifier = codeVerifier
    }
  },
  actions: {
    async initiate ({ commit, rootState, rootGetters, dispatch }, { mode } = {}) {
      const config = rootGetters['config/config']
      const authConfig = config.auth

      // generate pkce code verifier
      const { codeVerifier, codeChallenge } = generatePkce()
      commit(SAVE_CODE_VERIFIER, { codeVerifier })

      const result = new URL(authConfig.passportUrl)
      result.searchParams.set('clientId', authConfig.clientId)
      result.searchParams.set('redirect_uri', authConfig.redirectUrl)

      if (mode) {
        result.searchParams.set('mode', mode)
      }

      result.searchParams.set('code_challenge', codeChallenge)

      document.location.href = result.toString()
    }
  }
})

function generatePkce () {
  const length = Math.ceil((Math.floor(Math.random() * 128) + 43) / 2)
  const randomArray = new Uint32Array(length)

  window.crypto.getRandomValues(randomArray)

  const randomHexString = Array.from(randomArray, dec2hex).join('')

  const hashedHex = sha256(randomHexString)
  const hashedBytes = hexToBytes(hashedHex)
  const base64urlencodeString = base64urlencode(hashedBytes)

  return {
    codeVerifier: randomHexString,
    codeChallenge: base64urlencodeString
  }
}

function hexToBytes (hex) {
  for (var bytes = [], c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16))
  }
  return bytes
}

function base64urlencode (str) {
  // Convert the ArrayBuffer to string using Uint8 array.
  // btoa takes chars from 0-255 and base64 encodes.
  // Then convert the base64 encoded to base64url encoded.
  // (replace + with -, replace / with _, trim trailing
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

function dec2hex (dec) {
  return ('0' + dec.toString(16)).substr(-2)
}
