import { mapActions } from 'vuex'
import * as components from 'src/components'
import WalletAddServerPartial from './WalletAddServerPartial/WalletAddServerPartial.vue'
import WalletAddClientPartial from './WalletAddClientPartial/WalletAddClientPartial.vue'
import WalletAddDevicePartial from './WalletAddDevicePartial/WalletAddDevicePartial.vue'
import WalletAddPluginPartial from './WalletAddPluginPartial/WalletAddPluginPartial.vue'
import WalletAddReadonlyPartial from './WalletAddReadonlyPartial/WalletAddReadonlyPartial.vue'

export default {
  components: {
    ...components,
    WalletAddServerPartial,
    WalletAddClientPartial,
    WalletAddDevicePartial,
    WalletAddPluginPartial,
    WalletAddReadonlyPartial
  },
  data () {
    return {
      storage: 'server',
      options: [
        { key: 'server', title: 'Server', icon: '/assets/icons/server.svg' },
        { key: 'client', title: 'Browser', icon: '/assets/icons/planet.svg' },
        { key: 'device', title: 'Device', icon: null },
        { key: 'plugin', title: 'Plug-In', icon: null }
      ]
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    handleStorage (storage) {
      this.storage = storage
    }
  }
}
