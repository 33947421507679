import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  dateTime: Joi.string().allow([null, '']),
  transactionType: Joi.string().allow([null, '']),
  bsb: Joi.string().allow([null, '']),
  accountNumber: Joi.string().allow([null, '']),
  amount: Joi.string().allow([null, '']),
  reason: Joi.string().allow([null, '']),
  sourceBsb: Joi.string().allow([null, '']),
  sourceAccountNumber: Joi.string().allow([null, '']),
  sourceAccountName: Joi.string().allow([null, '']),
  ssoUserId: Joi.string().allow([null, ''])
})

export default class MonoovaRejectedTransactionModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null || data === '') {
      return null
    }
    return new MonoovaRejectedTransactionModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
