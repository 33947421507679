import { LoadableMapModel, EthereumStatsModel } from 'src/models'

const STATS_LOADED = 'ethereumStats/loaded'
const STATS_LOADING = 'ethereumStats/loading'
const STATS_RESET = 'ethereumStats/reset'

const initialState = () => ({
  table: new LoadableMapModel(EthereumStatsModel)
})

export default () => ({
  namespaced: true,
  state: initialState(),
  mutations: {
    [STATS_LOADED]: (state, { token, totalSupply }) => {
      const model = state.table.get(token.key) || new EthereumStatsModel({ token })
      state.table = state.table.put(token.key, model.loaded({ totalSupply }))
    },
    [STATS_LOADING]: (state, { token }) => {
      const model = state.table.get(token.key) || new EthereumStatsModel({ token })
      state.table = state.table.put(token.key, model.loading())
    },
    [STATS_RESET]: (state) => {
      Object.assign(state, initialState())
    }
  },
  getters: {
    stats: state => token => state.table.get(token.key) || new EthereumStatsModel({ token })
  },
  actions: {
    async update ({ commit }, { token }) {
      commit(STATS_LOADING, { token })
      const totalSupply = await token.dao.getTotalSupply()
      commit(STATS_LOADED, { token, totalSupply })
    },
    async subscribe ({ dispatch }, { token }) {
      const listener = function () {
        dispatch('update', { token })
      }
      token.dao.on('stats', listener)
      dispatch('update', { token })
      return listener
    },
    async unsubscribe (_, { token, listener }) {
      token.dao.removeListener('stats', listener)
    }
  }
})
