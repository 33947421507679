import BigNumber from 'bignumber.js'
import { LoadableMapModel, PlasmaBalanceModel } from 'src/models'

const PLASMA_BALANCE_LOADED = 'plasma/balances/loaded'
const PLASMA_BALANCE_LOADING = 'plasma/balances/loading'
const PLASMA_BALANCES_RESET = 'plasma/balances/reset'

const initialState = () => ({
  table: new LoadableMapModel(PlasmaBalanceModel)
})

export default () => ({
  namespaced: true,
  state () {
    return initialState()
  },
  mutations: {
    [PLASMA_BALANCE_LOADED]: (state, { pocket, balance, lockedBalance }) => {
      const model = state.table.get(pocket.key) || new PlasmaBalanceModel({ pocket })
      state.table = state.table.put(pocket.key, model.loaded({ balance, lockedBalance }))
    },
    [PLASMA_BALANCE_LOADING]: (state, { pocket }) => {
      const model = state.table.get(pocket.key) || new PlasmaBalanceModel({ pocket })
      state.table = state.table.put(pocket.key, model.loading())
    },
    [PLASMA_BALANCES_RESET]: (state) => {
      Object.assign(state, initialState())
    }
  },
  getters: {
    balance: state => pocket => state.table.get(pocket.key) || new PlasmaBalanceModel({ pocket })
  },
  actions: {
    async loadAllBalances ({ commit }, { pockets }) {
      const firstPocket = pockets[0]
      const dao = firstPocket.currency.dao
      const address = firstPocket.address

      const pocketByCurrency = {}

      for (const pocket of pockets) {
        commit(PLASMA_BALANCE_LOADING, { pocket })
        pocketByCurrency[pocket.currency.currency.address] = pocket
      }

      const balances = await dao.getAllBalances(address)

      for (const balance of balances) {
        const pocket = pocketByCurrency[balance.token]

        if (pocket == null) {
          continue
        }

        commit(PLASMA_BALANCE_LOADED, { pocket, balance: balance.balance, lockedBalance: balance.lockedBalance })
      }
    },
    async update ({ commit }, { pocket }) {
      commit(PLASMA_BALANCE_LOADING, { pocket })
      const {balance, lockedBalance} = await pocket.currency.dao.getBalance(pocket.address)
      commit(PLASMA_BALANCE_LOADED, { pocket, balance, lockedBalance })
    },
    async subscribe ({ commit, dispatch }, { pocket, notLoadBalance = false }) {
      const balanceListener = ({ payload }) => {
        if (
          payload.address.toLowerCase() === pocket.address.toLowerCase() &&
          payload.token.toLowerCase() === pocket.currency.currency.address.toLowerCase()
        ) {
          const balance = new BigNumber(payload.confirmedDelta).plus(new BigNumber(payload.unconfirmedDelta))
          const lockedBalance = new BigNumber(payload.lockedBalance)
          commit(PLASMA_BALANCE_LOADED, { pocket, balance, lockedBalance })
        }
      }

      const subscribeListener = () => {
        // закоментированно, когда заимплементили loadAllBalances
        // случай, когда балансы балансы поменялись
        // между отправкой запроса на подписку и установлением подписки.
        // Случай крайне редкий.
        // если будут запросы с продакшна, переделать здесь использование loadAllBalances
        // и вторго метода subscribe с массивом pockets
        // dispatch('update', { pocket })
      }

      pocket.currency.dao
        .subscribeAddress(pocket.address.toLowerCase())
        .on('BALANCE', balanceListener)
        .on('@_SUBSCRIBED', subscribeListener)

      if (!notLoadBalance) {
        dispatch('update', { pocket })
      }

      return { balanceListener, subscribeListener }
    },
    async unsubscribe (_, { pocket, listener }) {
      const { balanceListener, subscribeListener } = listener

      pocket.currency.dao
        .unsubscribeAddress(pocket.address.toLowerCase())
        .removeListener('BALANCE', balanceListener)
        .removeListener('@_SUBSCRIBED', subscribeListener)
    }
  }
})
