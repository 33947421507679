import { render, staticRenderFns } from "./SectionPlasmaWithdrawals.htm?vue&type=template&id=6dc88ff1&scoped=true&external"
import script from "./SectionPlasmaWithdrawals.js?vue&type=script&lang=js&external"
export * from "./SectionPlasmaWithdrawals.js?vue&type=script&lang=js&external"
import style0 from "./SectionPlasmaWithdrawals.scss?vue&type=style&index=0&id=6dc88ff1&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc88ff1",
  null
  
)

export default component.exports