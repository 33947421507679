import { omitBy, isNil } from 'lodash'
import { UserModel, UserDetails } from 'src/models'
import {PASSPORT, withAuthorization} from 'src/remotes'

export const USERS_LOADING = 'users/loading'
export const USERS_LOADED = 'users/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      actualDate: null,
      activePage: 1,
      numberOfElements: 10, // page size
      limit: 100,
      items: [],
      count: null,
      filters: {
        q: null
      }
    }
  },
  mutations: {
    [USERS_LOADING]: (state) => {
      state.isLoading = true
    },
    [USERS_LOADED]: (state, { actualDate, count, items, activePage, numberOfElements }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        activePage,
        numberOfElements,
        actualDate,
        count,
        items
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    limit: state => state.limit,
    items: state => state.items,
    totalPages (state) {
      const count = state.count
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async fetch ({ dispatch }, { actualDate, offset, limit, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.get('/api/v1/manager/users', withAuthorization(token, {
        params: {
          ...filters,
          actualDate,
          offset,
          limit
        }
      }))
      return data
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      }
    ) {
      if (!state.isLoading) {
        commit(USERS_LOADING)
        const { actualDate, items, count } = await dispatch('fetch', {
          actualDate: !reset && state.isLoaded
            ? state.actualDate
            : null,
          filters,
          offset: (page.activePage - 1) * page.numberOfElements,
          limit: page.numberOfElements
        })
        commit(USERS_LOADED, {
          actualDate,
          activePage: page.activePage,
          numberOfElements: page.numberOfElements,
          count,
          items: items.map(UserModel.fromJson)
        })
      }
    },
    async refreshView ({ state, dispatch }) {
      await dispatch('loadMore', {
        reset: true,
        filters: state.filters,
        page: {
          activePage: state.activePage,
          numberOfElements: state.numberOfElements
        }
      })
    },
    async fetchDetails ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.get(`/api/v1/manager/users/i/${id}/details`, withAuthorization(token))
      return UserDetails.fromJson(data)
    },
    async fetchModel ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.get(`/api/v1/manager/users/i/${id}`, withAuthorization(token))
      return UserModel.fromJson(data)
    },
    async removeUser ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PASSPORT.delete(`/api/v1/manager/users/i/${id}`, withAuthorization(token))
      await dispatch('refreshView')
    },
    async lockedUser ({ state, commit, dispatch }, { ssoUserId, isLocked, lockReason }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      if (isLocked) {
        await PASSPORT.post(`/api/v1/manager/users/i/${ssoUserId}/lock`, {
          lockReason: lockReason
        }, withAuthorization(token))
      } else {
        await PASSPORT.post(`/api/v1/manager/users/i/${ssoUserId}/unlock`, null, withAuthorization(token))
      }
    },
    async updateUser ({ state, commit, dispatch }, { id, isLocked, name, credentials, socials, grants }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.put(`/api/v1/manager/users/i/${id}`, {
        name,
        isLocked,
        credentials: credentials.map(item => ({
          action: item.action,
          credential: item.credential
        })),
        socials: socials.map(item => ({
          action: item.action,
          social: item.social
        })),
        grants: grants.map(item => ({
          action: item.action,
          grant: item.grant
        }))
      }, withAuthorization(token))
      const { errors, details } = data
      await dispatch('refreshView')
      return {
        errors,
        details: UserDetails.fromJson(details)
      }
    },
    async createUser ({ state, commit, dispatch }, { name, isLocked, credentials, socials, grants }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PASSPORT.post(`/api/v1/manager/users`, {
        name,
        isLocked,
        credentials: credentials.map(item => ({
          action: item.action,
          credential: item.credential
        })),
        socials: socials.map(item => ({
          action: item.action,
          social: item.social
        })),
        grants: grants.map(item => ({
          action: item.action,
          grant: item.grant
        }))
      }, withAuthorization(token))
      const { errors, details } = data
      await dispatch('refreshView')
      return {
        errors,
        details: UserDetails.fromJson(details)
      }
    }
  }
})
