import uniqid from 'uniqid'

export default {
  props: {
    multiple: Boolean,
    accept: String
  },
  data () {
    return {
      id: uniqid()
    }
  },
  methods: {
    onChange (e) {
      if (e.srcElement.files.length) {
        this.$emit('change', Array.from(e.srcElement.files))
      }
      this.$refs.uploaderInput.value = ''
    },
    openExplorer () {
      this.$refs.uploaderInput.click()
    }
  }
}
