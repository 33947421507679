export { default as CheckDeviceModal } from './lib/CheckDeviceModal/CheckDeviceModal.vue'
export { default as WalletAddModal } from './lib/WalletAddModal/WalletAddModal.vue'
export { default as ConfirmationModal } from './lib/ConfirmationModal/ConfirmationModal.vue'
export { default as PromptModal } from './lib/PromptModal/PromptModal.vue'
export { default as ErrorModal } from './lib/ErrorModal/ErrorModal.vue'
export { default as SignInModal } from './lib/SignInModal/SignInModal.vue'
export { default as SignUpModal } from './lib/SignUpModal/SignUpModal.vue'
export { default as PhoneEmailVerificationModal } from './lib/PhoneEmailVerificationModal/PhoneEmailVerificationModal.vue'
export { default as EnterCodeModal } from './lib/EnterCodeModal/EnterCodeModal.vue'
export { default as CurrencyAdd } from './lib/CurrencyModals/CurrencyAdd/CurrencyAdd.vue'
export { default as CurrencyEdit } from './lib/CurrencyModals/CurrencyEdit/CurrencyEdit.vue'
export { default as CurrencyFeeModal } from './lib/CurrencyModals/CurrencyFeeModal/CurrencyFeeModal.vue'
export { default as MarketAdd } from './lib/MarketModals/MarketAdd/MarketAdd.vue'
export { default as MarketEdit } from './lib/MarketModals/MarketEdit/MarketEdit.vue'
export { default as PurchasesDetailsModal } from './lib/PurchasesDetailsModal/PurchasesDetailsModal.vue'
export { default as AddressAdd } from './lib/CustomAddressModals/AddressAdd/AddressAdd.vue'
export { default as AddressEdit } from './lib/CustomAddressModals/AddressEdit/AddressEdit.vue'
export { default as AddTemplateModal } from './lib/AddTemplateModal/AddTemplateModal.vue'
export { default as InfoModal } from './lib/InfoModal/InfoModal.vue'
export { default as RejectVerificationModal } from './lib/RejectVerificationModal/RejectVerificationModal.vue'
export { default as ComplicateVerificationModal } from './lib/ComplicateVerificationModal/ComplicateVerificationModal.vue'
export { default as CurrencyFiatAdd } from './lib/CurrencyFiatModals/CurrencyFiatAdd/CurrencyFiatAdd.vue'
export { default as CurrencyFiatEdit } from './lib/CurrencyFiatModals/CurrencyFiatEdit/CurrencyFiatEdit.vue'
export { default as BlogCategoryCreateModal } from './lib/BlogModals/BlogCategoryCreateModal/BlogCategoryCreateModal.vue'
export { default as BlogCategoryEditModal } from './lib/BlogModals/BlogCategoryEditModal/BlogCategoryEditModal.vue'
export { default as AddChatChannel } from './lib/AddChatChannel/AddChatChannel.vue'
export { default as LogsModal } from './lib/LogsModal/LogsModal.vue'
export { default as WithdrawnWithdrawalModal } from './lib/WithdrawnWithdrawalModal/WithdrawnWithdrawalModal.vue'
export { default as RescanMonoovaDepositsModal } from './lib/RescanMonoovaDepositsModal/RescanMonoovaDepositsModal.vue'
export { default as CreateMonoovaAccountsModal } from './lib/CreateMonoovaAccountsModal/CreateMonoovaAccountsModal.vue'
export { default as ResetLevelModal } from './lib/ResetLevelModal/ResetLevelModal.vue'
export { default as SendEmailModal } from './lib/SendEmailModal/SendEmailModal.vue'
export { default as AddMonoovaWhitelistModal } from './lib/AddMonoovaWhitelistModal/AddMonoovaWhitelistModal.vue'
export { default as EditMonoovaBankModal } from './lib/EditMonoovaBankModal/EditMonoovaBankModal.vue'
export { default as AddWithdrawalDiscountModal } from './lib/AddWithdrawalDiscountModal/AddWithdrawalDiscountModal.vue'
export { default as AddCommentBlockUserModal } from './lib/AddCommentBlockUserModal/AddCommentBlockUserModal.vue'
