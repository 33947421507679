import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.ya?ml/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default (
  { locale = 'en', fallbackLocale = 'en' } =
  { locale: 'en', fallbackLocale: 'en' }
) => new VueI18n({
  locale,
  fallbackLocale,
  messages: loadLocaleMessages()
})
