import {mapActions} from 'vuex'
import {OperationCanceledError} from 'src/errors'
import {CurrencyDAOModel} from 'src/models'
import {required} from 'vuelidate/lib/validators'
import * as components from 'src/components'
import {isGreaterThanOrEqual} from 'src/validations'
import BigNumber from 'bignumber.js'

export default {
  components: {
    ...components
  },
  props: {
    currency: CurrencyDAOModel,
    initial: Object,
    handler: Function
  },
  data () {
    return {
      isEditInProgress: false,
      methods: [
        { key: 0, title: 'Bank Transfer', value: 'BANK_TRANSFER' },
        { key: 1, title: 'POLi', value: 'POLI' }
      ],
      form: Object.assign({
        min: '',
        max: '',
        percent: '',
        type: null
      }, this.initial || {})
    }
  },
  computed: {
    typeErrors () {
      if (this.$v.form.type.$dirty) {
        if (!this.$v.form.type.required) {
          return [
            'Type should be selected'
          ]
        }
      }
    },
    minErrors () {
      if (this.$v.form.min.$dirty) {
        if (!this.$v.form.min.required) {
          return [
            'Min Decimals is a required field'
          ]
        }
        if (!this.$v.form.min.numberCheck) {
          return [
            'Min should be more than 0'
          ]
        }
      }
    },
    maxErrors () {
      if (this.$v.form.max.$dirty) {
        if (!this.$v.form.max.required) {
          return [
            'Max Decimals is a required field'
          ]
        }
        if (!this.$v.form.max.numberCheck) {
          return [
            'Max should be more than 0'
          ]
        }
      }
    },
    percentErrors () {
      if (this.$v.form.percent.$dirty) {
        if (!this.$v.form.percent.required) {
          return [
            'Max Decimals is a required field'
          ]
        }
        if (!this.$v.form.percent.validCheck) {
          return [
            'Fee range from 0 to 100'
          ]
        }
      }
    },
    methodErrors () {
      if (this.$v.form.method.$dirty) {
        if (!this.$v.form.method.required) {
          return [
            'Max Decimals is a required field'
          ]
        }
      }
    }
  },
  validations: {
    form: {
      min: {
        required,
        numberCheck (value) {
          return isGreaterThanOrEqual(value, 0)
        }
      },
      max: {
        required,
        numberCheck (value) {
          return isGreaterThanOrEqual(value, 0)
        }
      },
      percent: {
        required,
        validCheck (value) {
          let number = new BigNumber(value)
          return number.lte(100) && number.gte(0)
        }
      },
      type: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error'
    }),
    async handleSubmit () {
      this.isEditInProgress = true
      setImmediate(async () => {
        try {
          if (this.handler) {
            await this.handler(
              this.form
            )
          }
          await this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isEditInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
