import Joi from 'joi'
import PluginModel from './PluginModel'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'

const schemaFactory = () => ({
  id: Joi.string().required(),
  type: Joi.string().valid('AdvCashWithdrawPlugin'),
  minKycLevel: Joi.number().min(0).max(4).required(),
  minAmount: Joi.string().allow([null, '']),
  advCashCurrencySymbol: Joi.string().required(),
  commission: Joi.alternatives().try(
    Joi.object().type(FixedCommissionModel),
    Joi.object().type(PercentCommissionModel),
    Joi.object().type(DefaultCommissionModel)
  ).allow(null)
})

export default class AdvCashWithdrawPluginModel extends PluginModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new AdvCashWithdrawPluginModel({
      id: data.id,
      type: 'AdvCashWithdrawPlugin',
      minKycLevel: data.minKycLevel,
      advCashCurrencySymbol: data.advCashCurrencySymbol,
      minAmount: data.minAmount,
      commission: this.prepareCommission(data.commission)
    })
  }
}
