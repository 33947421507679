import {
  LoadableListModel,
  ExtendedCurrencyModel,
  CreateExtendedCurrencyRequest,
  CurrencyDAOModel,
  FiatCurrency
} from 'src/models'
import {
  BACKEND,
  PLASMA,
  withAuthorization
} from 'src/remotes'
import { REGISTRY } from 'src/services'

export const CURRENCIES_LOADING = 'currencies/loading'
export const CURRENCIES_LOADED = 'currencies/loaded'
export const FIAT_CURRENCIES_LOADING = 'currencies/fiat/loading'
export const FIAT_CURRENCIES_LOADED = 'currencies/fiat/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      list: new LoadableListModel(CurrencyDAOModel),
      fiatCurrencylist: new LoadableListModel(FiatCurrency),
      filters: {
        from: null,
        to: null
      }
    }
  },
  mutations: {
    [CURRENCIES_LOADING]: (state) => {
      state.list = state.list.loading()
    },
    [CURRENCIES_LOADED]: (state, { currencies }) => {
      state.list = state.list.loaded(...currencies)
    },
    [FIAT_CURRENCIES_LOADING]: (state) => {
      state.fiatCurrencylist = state.fiatCurrencylist.loading()
    },
    [FIAT_CURRENCIES_LOADED]: (state, { fiatCurrencies }) => {
      state.fiatCurrencylist = state.fiatCurrencylist.loaded(...fiatCurrencies)
    }
  },
  getters: {
    currencies: state => state.list,
    fiatCurrencies: state => state.fiatCurrencylist,
    getCurrency: state => address => state.list.isLoaded && state.list.value.find(
      currency => currency.currency.address.toLowerCase() === address.toLowerCase()
    ),
    getCurrencyBySymbol: state => symbol => state.list.isLoaded && state.list.value.find(
      currency => currency.currency.symbol.toLowerCase() === symbol.toLowerCase()
    ),
    isLoaded: state => state.list.isLoaded,
    getFiatCurrencyBySymbol: state => symbol => state.fiatCurrencylist.isLoaded && state.fiatCurrencylist.value.find(
      fiatCurrency => fiatCurrency.symbol.toLowerCase() === symbol.toLowerCase()
    ),
    getFiatCurrencyById: state => id => state.fiatCurrencylist.isLoaded && state.fiatCurrencylist.value.find(
      fiatCurrency => fiatCurrency.id === id
    )
  },
  actions: {
    async fetchCurrencies () {
      const { data } = await BACKEND.get('currencies')
      const currencies = data.map(ExtendedCurrencyModel.fromJson)
      currencies.sort((a, b) => a.sortOrder - b.sortOrder)
      return currencies
    },
    async fetchFiatCurrencies () {
      const { data } = await BACKEND.get('currencies/fiat')
      const fiatCurrencies = data.map(FiatCurrency.fromJson)
      return fiatCurrencies
    },
    async loadCurrencies ({ state, commit, dispatch }) {
      commit(CURRENCIES_LOADING)
      const models = await dispatch('fetchCurrencies')
      state.list.forEach(currency => {
        currency.dao.disconnect()
        currency.dao.removeAllListeners()
      })
      // TODO @ipavlenko: Think how to deal with subscriptions to existent DAOs
      const currencies = await Promise.all(
        models.map(model => dispatch('initCurrency', { model }))
      )
      commit(CURRENCIES_LOADED, { currencies })
    },
    async loadFiatCurrencies ({ state, commit, dispatch }) {
      commit(FIAT_CURRENCIES_LOADING)
      const fiatCurrencies = await dispatch('fetchFiatCurrencies')
      commit(FIAT_CURRENCIES_LOADED, { fiatCurrencies })
    },
    async reconnectCurrencies ({ state }) {
      const plasmaSocketClient = REGISTRY.getService('plasmaSocketClient')
      state.list.forEach(currency => {
        currency.dao.disconnect()
        currency.dao.connect(plasmaSocketClient, PLASMA)
      })
    },
    async initCurrency (_, { model }) {
      const plasmaSocketClient = REGISTRY.getService('plasmaSocketClient')
      const currency = CurrencyDAOModel.fromCurrencyModel(model)
      currency.dao.connect(plasmaSocketClient, PLASMA)

      return currency
    },
    async createCurrency ({ state, commit, dispatch }, { ...params }) {
      const request = CreateExtendedCurrencyRequest.fromJson({
        ...params
      })

      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post('currencies', request, withAuthorization(token))
      await dispatch('loadCurrencies')
    },
    async editCurrency ({ state, commit, dispatch }, { currency, ...params }) {
      const request = CreateExtendedCurrencyRequest.fromJson({
        ...params
      })

      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`currencies/${currency.currency.symbol}`, request, withAuthorization(token))
      await dispatch('loadCurrencies')
    },
    async deleteCurrency ({ state, commit, dispatch }, { symbol }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`currencies/${symbol}/remove`, null, withAuthorization(token))
      await dispatch('loadCurrencies')
    },
    async createFiatCurrency ({ state, commit, dispatch }, {...params}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post('currencies/fiat', {
        ...params
      }, withAuthorization(token))
      await dispatch('loadFiatCurrencies')
    },
    async editFiatCurrency ({ state, commit, dispatch }, { id, title, rateServiceSymbol, icon }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`currencies/fiat/${id}`, {title, rateServiceSymbol, icon}, withAuthorization(token))
      await dispatch('loadFiatCurrencies')
    },
    async deleteFiatCurrency ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`currencies/fiat/${id}/remove`, null, withAuthorization(token))
      await dispatch('loadFiatCurrencies')
    }
  }
})
