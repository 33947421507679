import BigNumber from 'bignumber.js'
import AbstractTokenDAO from './AbstractTokenDAO'

class ETHDAO extends AbstractTokenDAO {
  connect (web3) {
    if (this.isConnected) {
      this.disconnect()
    }
    this.web3 = web3
    this.logsEmitter = this.web3.eth.subscribe('newBlockHeaders')
      .on('data', this.handleBlockData.bind(this))
      .on('error', this.handleBlockError.bind(this))
  }

  disconnect () {
    if (this.isConnected) {
      this.logsEmitter.removeAllListeners()
      this.logsEmitter = null
      this.web3 = null
    }
  }

  get isConnected () {
    return this.web3 != null // nil check
  }

  async handleBlockData (data) {
    const [block, gasPrice] = await Promise.all([
      this.web3.eth.getBlock(data.hash, true),
      this.web3.eth.getGasPrice()
    ])
    if (block) {
      setImmediate(() => {
        this.emit('block', block)
        this.emit('network', {
          gasPrice: new BigNumber(gasPrice)
        })
        if (block.transactions) {
          for (const tx of block.transactions) {
            this.emit('tx', tx)
          }
        }
      })
    }
  }

  handleBlockError (error) {
    // eslint-disable-next-line no-console
    console.error('[ETHDAO] Error in block headers event subscription', error)
  }
}

export const ethDAO = new ETHDAO()
export default ETHDAO
