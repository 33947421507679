import { mapActions } from 'vuex'
import moment from 'moment'
import * as partials from 'src/partials'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Customers'
  },
  components: {
    ...components,
    ...partials
  },
  data () {
    return {
      historyState: null,
      attachments: []
    }
  },
  async created () {
    let history = await this.getCustomerHistory({ id: this.$route.params.id })
    if (this.$route.query.type === 'ADDRESS') {
      this.historyState = {
        id: history.id,
        phone: history.phone.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        email: history.email.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        personal: history.personal.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        address: history.address.find(el => moment(el.createdAt).valueOf() === moment(this.$route.query.createdAt).valueOf())
      }
    }
    if (this.$route.query.type === 'DOCUMENTS') {
      this.historyState = {
        id: history.id,
        phone: history.phone.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        email: history.email.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        personal: history.personal.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        address: history.address.find(el => el.status === 'ACCEPTED' && moment(el.createdAt) < moment(this.$route.query.createdAt)),
        documents: history.documents.find(el => moment(el.createdAt).valueOf() === moment(this.$route.query.createdAt).valueOf())
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      getCustomerHistory: 'customers/getCustomerHistory',
      openFile: 'media/openFile'
    })
  }
}
