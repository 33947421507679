import * as partials from 'src/partials'

export default {
  metaInfo: {
    title: 'Orders'
  },
  components: {
    ...partials
  }
}
