import {mapActions, mapState, mapGetters} from 'vuex'
import moment from 'moment'
import { RescanMonoovaDepositsModal, CreateMonoovaAccountsModal } from 'src/modals'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Monoova management page'
  },
  components: {
    ...components
  },
  data () {
    return {
      isMobile: window.innerWidth <= 1080
    }
  },
  computed: {
    ...mapState({
      page: state => state.monoova.page,
      totalPages: state => state.monoova.totalPages,
      accounts: state => state.monoova.accounts,
      subscriptions: state => state.monoova.subscriptions
    }),
    ...mapGetters({
      hasMonoovaSettingsActionsAccess: 'grants/hasMonoovaSettingsActionsAccess'
    })
  },
  async created () {
    await Promise.all([
      this.loadAccounts({}),
      this.loadSubscriptions()
    ])
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    moment,
    ...mapActions({
      loadAccounts: 'monoova/loadAccounts',
      refreshAccount: 'monoova/refreshAccount',
      loadSubscriptions: 'monoova/loadSubscriptions',
      subsribeWeebhooks: 'monoova/subsribeWeebhooks',
      unsubsribeWeebhook: 'monoova/unsubsribeWeebhook',
      openModal: 'modals/open'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1080
    },
    createAccounts () {
      this.openModal({
        factory: () => CreateMonoovaAccountsModal
      })
    },
    rescanDeposits () {
      this.openModal({
        factory: () => RescanMonoovaDepositsModal
      })
    },
    canRefresh (account) {
      return account.status === 'CREATED' || account.status === 'ASSIGNING_PAYID'
    },
    async reloadAccounts () {
      this.loadAccounts({})
    },
    async reloadSubscriptions () {
      this.loadSubscriptions()
    },
    async handleSubscribe () {
      await this.subsribeWeebhooks()
    },
    async handleUnsubscribe (subscribe) {
      await this.unsubsribeWeebhook({ id: subscribe.id })
    },
    async handleRefresh (id) {
      await this.refreshAccount({ id })
    },
    async handleNavigation (value) {
      this.loadAccounts({
        page: value - 1
      })
    }
  }
}
