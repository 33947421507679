import Joi from 'joi'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object({
  active: Joi.boolean().required(),
  createdAt: Joi.string().required(),
  currency: Joi.string().required(),
  id: Joi.number().required(),
  owner: Joi.string().required(),
  rate: Joi.number().required(),
  requestedWithdraw: Joi.boolean().required(),
  requestedWithdrawAt: Joi.string().required().allow([null, '']),
  revenueAmount: Joi.string().required().allow([null, '']),
  stakeAddress: Joi.string().required()
})

export default class StakeModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
  }

  get momentCreatedAt () {
    return moment(this.createdAt)
  }

  get momentDateOfExpired () {
    let dateOfExpired = this.momentCreatedAt
    const now = moment()
    do {
      dateOfExpired = dateOfExpired.add(1, 'years')
    } while (dateOfExpired.isSameOrBefore(now))
    return dateOfExpired
  }

  get dateOfExpired () {
    return this.momentDateOfExpired.format('DD.MM.YYYY HH:mm')
  }

  get visibleCreatedAt () {
    return this.momentCreatedAt.format('DD.MM.YYYY HH:mm')
  }

  get visibleRequestedWithdrawAt () {
    return this.requestedWithdrawAt
      ? moment(this.requestedWithdrawAt).format('DD.MM.YYYY HH:mm')
      : ''
  }

  get estimatedTimeOfArrival () {
    if (!this.requestedWithdraw) {
      return null
    }
    return moment(this.requestedWithdrawAt)
      .add(1, 'weeks')
      .format('DD.MM.YYYY')
  }

  getRevenueAmount (currency) {
    if (!this.revenueAmount) {
      return ''
    }
    const divider = new BigNumber(10)
      .pow(currency.currency.tradeDecimals)
    return new BigNumber(this.revenueAmount).div(divider).toString()
  }

  getEstimatedRevenue (startAmount) {
    startAmount = new BigNumber(startAmount)
    if (startAmount.lte(0)) {
      return 0
    }
    const diffInDays = this.momentDateOfExpired.diff(this.momentCreatedAt, 'days')
    const percentPerDay = new BigNumber(this.rate).div(100).times(startAmount).div(365)
    const income = percentPerDay.times(diffInDays)
    return income.plus(startAmount).toString()
  }

  getCurrentRevenut (startAmount) {
    startAmount = new BigNumber(startAmount)
    if (startAmount.lte(0)) {
      return 0
    }
    const now = moment()
    const diffInSeconds = now.diff(this.momentCreatedAt, 'seconds')
    const percentPerSecond = new BigNumber(this.rate).div(100).times(startAmount).div(365 * 24 * 60 * 60)
    const income = percentPerSecond.times(diffInSeconds)
    return income.plus(startAmount).toString()
  }

  static fromJson (data, context, options) {
    return new StakeModel({
      ...data
    })
  }
}
