import {mapActions, mapGetters} from 'vuex'
import {required, numeric} from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'
import {isGreaterThan, isGreaterThanOrEqual, isLessThanOrEqual} from 'src/validations'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isAddInProgress: false,
      symbolsArr: [],
      form: {
        symbol: '',
        name: '',
        address: '',
        icon: '',
        background: 'transparent',
        fiatSymbol: '',
        decimals: '',
        displayDecimals: '',
        tradeDecimals: '',
        withdrawalFee: '',
        crypto: true,
        depositEnabled: false,
        withdrawalEnabled: false,
        transferEnabled: false,
        buyEnabled: false,
        purchaseEnabled: false,
        redeemEnabled: false,
        active: true
      }
    }
  },
  validations: {
    form: {
      symbol: {
        required,
        symbolCheck (value) {
          return !this.currencies.value.map(e => e.currency.symbol).includes(value)
        }
      },
      name: {
        required
      },
      address: {
        required,
        addressCheck (value) {
          return !this.currencies.value.map(e => e.currency.address).includes(value.toLowerCase())
        }
      },
      icon: {
        required,
        startCheck (val) {
          return val.startsWith('data:image/svg+xml;base64,') || ''
        }
      },
      background: {
        required
      },
      decimals: {
        required,
        numeric,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      tradeDecimals: {
        required,
        numeric,
        tradeDecimals (value) {
          return isGreaterThanOrEqual(value, this.form.decimals)
        }
      },
      displayDecimals: {
        required,
        numeric,
        displayDecimals (value) {
          return isLessThanOrEqual(value, this.form.decimals)
        }
      },
      withdrawalFee: {
        required,
        numberCheck (value) {
          return isGreaterThanOrEqual(value, 0)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies'
    }),
    symbolErrors () {
      if (this.$v.form.symbol.$dirty) {
        if (!this.$v.form.symbol.required) {
          return [
            'Symbol is a required field'
          ]
        }
        if (!this.$v.form.symbol.symbolCheck) {
          return [
            'This symbol already exists'
          ]
        }
      }
      return []
    },
    addressErrors () {
      if (this.$v.form.address.$dirty) {
        if (!this.$v.form.address.required) {
          return [
            'Address is a required field'
          ]
        }
        if (!this.$v.form.address.addressCheck) {
          return [
            'This address already exists'
          ]
        }
      }
    },
    decimalsErrors () {
      if (this.$v.form.decimals.$dirty) {
        if (!this.$v.form.decimals.required) {
          return [
            'Decimals is a required field'
          ]
        }
        if (!this.$v.form.decimals.numeric) {
          return [
            'Decimals can be integer only'
          ]
        }
        if (!this.$v.form.decimals.numberCheck) {
          return [
            'Decimals must be number and more than 0'
          ]
        }
      }
    },
    tradeDecimalsErrors () {
      if (this.$v.form.tradeDecimals.$dirty) {
        if (!this.$v.form.tradeDecimals.required) {
          return [
            'tradeDecimals is a required field'
          ]
        }
        if (!this.$v.form.tradeDecimals.numeric) {
          return [
            'Trade Decimals can be integer only'
          ]
        }
        if (!this.$v.form.tradeDecimals.tradeDecimals) {
          return [
            'Trade Decimals must be greater or equal than Decimals'
          ]
        }
      }
    },
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
    },
    iconErrors () {
      if (this.$v.form.icon.$dirty) {
        if (!this.$v.form.icon.required) {
          return [
            'Icon is a required field'
          ]
        }
        if (!this.$v.form.icon.startCheck) {
          return [
            'Icon text should start with data:image/svg+xml;base64,'
          ]
        }
      }
    },
    backgroundErrors () {
      if (this.$v.form.background.$dirty) {
        if (!this.$v.form.background.required) {
          return [
            'Background is a required field'
          ]
        }
      }
    },
    displayDecimalsErrors () {
      if (this.$v.form.displayDecimals.$dirty) {
        if (!this.$v.form.displayDecimals.required) {
          return [
            'Display Decimals is a required field'
          ]
        }
        if (!this.$v.form.displayDecimals.numeric) {
          return [
            'Display Decimals can be integer only'
          ]
        }
        if (!this.$v.form.displayDecimals.displayDecimals) {
          return [
            'Display Decimals  must be smaller than Decimals'
          ]
        }
      }
    },
    withdrawalFeeErrors () {
      if (this.$v.form.withdrawalFee.$dirty) {
        if (!this.$v.form.withdrawalFee.required) {
          return [
            'Withdrawal Fee is a required field'
          ]
        }
        if (!this.$v.form.withdrawalFee.numberCheck) {
          return [
            'Withdrawal fee should be 0 or more'
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createCurrency: 'currencies/createCurrency'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createCurrency({
            ...this.form,
            address: (this.form.address).toLowerCase()
          })
          await this.handleToast({
            message: 'Currency added'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot add currency'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
