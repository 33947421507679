import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Deposits'
  },
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
    })
  },
  async created () {
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions({})
  }
}
