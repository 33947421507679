import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    message: String,
    closeTitle: String,
    handler: Function,
    error: Error
  },
  data () {
    return {
      showTrace: false
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    handleSingUp () {
      // TODO: @fenrirheimr: implement
    }
  }
}
