import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
  },
  async created () {
  },
  data () {
    return {
      timer: null,
      timerInterval: null
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile'
    })
  },
  watch: {
  },
  methods: {
    moment,
    calculateTimer () {
      if (this.profile.activeRapidIdRequest) {
        const now = new Date()
        const expiredAt = this.profile.activeRapidIdRequest.expiredAt
        const duration = moment.duration(expiredAt.getTime() - now.getTime())
        const formatDuration = time => {
          const days = Math.floor(time / 8.64e7)
          const hours = duration.get('days') * 24 + duration.get('hours')
          const lastDay = time - days * 8.64e7
          return (hours < 10 ? '0' + hours : hours) + ':' + moment.utc(lastDay).format('mm:ss')
        }
        this.timer = formatDuration(duration)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timerInterval)
  },
  mounted () {
    this.calculateTimer()
    this.timerInterval = setInterval(() => {
      this.calculateTimer()
    }, 1000)
  }
}
