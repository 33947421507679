// import { REGISTRY } from 'src/services'
import { LoadableListModel, TokenModel, TokenDAOModel } from 'src/models'

export const TOKENS_LOADING = 'tokens/loading'
export const TOKENS_LOADED = 'tokens/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      list: new LoadableListModel(TokenDAOModel)
    }
  },
  mutations: {
    [TOKENS_LOADING]: (state) => {
      state.list = state.list.loading()
    },
    [TOKENS_LOADED]: (state, { tokens }) => {
      state.list = state.list.loaded(...tokens)
    }
  },
  getters: {
    getETHToken: state => state.list.isLoaded && state.list.value.find(
      token => token.token.address === null
    ),
    getToken: state => address => state.list.isLoaded && state.list.value.find(
      token => address != null // nil check
        ? token.token.address !== null && token.token.address.toLowerCase() === address.toLowerCase()
        : token.token.address === null
    ),
    getTokenByKey: state => key => state.list.isLoaded && state.list.value.find(
      token => token.token.key === key
    ),
    isLoaded: state => state.list.isLoaded
  },
  actions: {
    async fetchTokens ({ rootGetters }) {
      const currencies = rootGetters['currencies/currencies']
      const tokens = currencies.value.map(currency => TokenModel.fromJson({
        address: currency.currency.address,
        symbol: currency.currency.symbol,
        decimals: currency.currency.decimals,
        contract: 'EIP20'
      }))
      tokens.push(TokenModel.fromJson({
        address: null,
        symbol: 'ETH',
        decimals: 18,
        contract: null
      }))
      return tokens
    },
    async loadTokens ({ state, commit, dispatch }) {
      try {
        commit(TOKENS_LOADING)
        const models = await dispatch('fetchTokens')
        // state.list.forEach(token => {
        //   token.dao.disconnect()
        //   token.dao.removeAllListeners()
        // })
        const tokens = await Promise.all(
          models.map(model => dispatch('initToken', { model }))
        )
        commit(TOKENS_LOADED, { tokens })
      } catch (e) {
        // eslint-disable-next-line
        console.log('Cannot load tokens', e)
        throw e
      }
    },
    async reconnectTokens ({ state }) {
      // const web3Client = REGISTRY.getService('web3Client')
      // state.list.forEach(token => {
      //   token.dao.disconnect()
      //   token.dao.connect(web3Client.web3)
      // })
    },
    async initToken ({ rootGetters }, { model }) {
      // const web3Client = REGISTRY.getService('web3Client')
      const token = TokenDAOModel.fromTokenModel(model, {
        getAbiByContract: rootGetters['abis/getAbiByContract'],
        getDAO: rootGetters['daos/getDAO']
      })
      // token.dao.connect(web3Client.web3)

      token.dao
        .on('transfer', async (_) => {})
        .on('approval', async (_) => {})

      return token
    }
  }
})
