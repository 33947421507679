import moment from 'moment'
import pluralize from 'pluralize'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import AddChatChannel from 'src/modals/lib/AddChatChannel/AddChatChannel.vue'

export default {
  metaInfo: {
    title: 'Channels'
  },
  components: {
    ...components
  },
  computed: {
    ...mapState({
      chatChannels: state => state.chat.channels
    }),
    ...mapGetters({
      channels: 'chatChannels/items',
      isLoaded: 'chatChannels/isLoaded',
      isLoading: 'chatChannels/isLoading',
      activePage: 'chatChannels/activePage',
      numberOfElements: 'chatChannels/numberOfElements',
      totalPages: 'chatChannels/totalPages'
    }),
    items () {
      const activePage = this.activePage
      const numberOfElements = this.numberOfElements
      return this.channels.map((channel, index) => ({
        index: (activePage - 1) * numberOfElements + index + 1,
        channel
      }))
    }
  },
  data () {
    return {
      visibleCount: 8
    }
  },
  async created () {
    await this.loadChatChannels()
  },
  methods: {
    pluralize,
    momentUtc: moment.utc,
    ...mapActions({
      openModal: 'modals/open',
      loadChatChannels: 'chatChannels/loadChatChannels',
      removeChannel: 'chatChannels/delete'
    }),
    handleRemove (item) {
      this.removeChannel({ name: item.channel.name })
    },
    handleEdit (item) {
      this.openModal({
        factory: () => AddChatChannel,
        data: {
          title: 'Add Channel',
          name: item.channel.name,
          displayName: item.channel.displayName,
          description: item.channel.description
        }
      })
    },
    addChannel () {
      this.openModal({
        factory: () => AddChatChannel,
        data: {
          title: 'Add Channel'
        }
      })
    },
    async handleActivePage (value) {
      this.loadChatChannels({
        page: {
          activePage: value,
          numberOfElements: this.numberOfElements
        }
      })
    }
  }
}
