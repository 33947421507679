import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  title: Joi.string().required(),
  description: Joi.string().required(),
  baseCurrencySymbol: Joi.string().required(),
  markets: Joi.array().items(Joi.object().keys({
    percent: Joi.number().required(),
    symbol: Joi.string().required()
  })).required(),
  bundleId: Joi.number().required()
})

export default class BundlesModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new BundlesModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
