import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  from: Joi.string().required(),
  to: Joi.string().required(),
  isLoading: Joi.boolean().required(),
  isLoaded: Joi.boolean().required(),
  price: Joi.number().allow(null)
})

export default class RateModel extends AbstractModel {
  constructor (data, options) {
    super(Object.assign({
      isLoading: true,
      isLoaded: false,
      price: null
    }, data), schemaFactory(), options)
  }

  loaded ({ price }) {
    return Object.assign(this, {
      isLoaded: true,
      isLoading: false,
      price
    })
  }

  loading () {
    return Object.assign(this, {
      isLoading: true
    })
  }
}
