import { mapGetters } from 'vuex'
import { formatNumber } from 'accounting'
import * as components from 'src/components'
import * as micros from 'src/micros'

export default {
  components: {
    ...components,
    ...micros
  },
  props: {
    monitor: Object
  },
  computed: {
    ...mapGetters({
      getPlasmaBalance: 'plasmaBalances/balance',
      getEthereumBalance: 'ethereumBalances/balance',
      getEthereumStats: 'ethereumStats/stats',
      web3SessionId: 'network/web3SessionId'
    }),
    relayPlasmaBalance () {
      return this.getPlasmaBalance(this.monitor.plasmaPocket)
    },
    relayEthereumBalance () {
      return this.getEthereumBalance(this.monitor.ethereumPocket)
    },
    relayFiatBalance () {
      return this.monitor.fiatCurrency.totalDeposited
    },
    tokenStats () {
      return this.getEthereumStats(this.monitor.token)
    }
  },
  methods: {
    formatNumber
  }
}
