import Joi from 'joi'
import { AbstractModel, ImageModel, AttachmentModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  ssoUserId: Joi.string().allow([null, '']).optional(),
  ipAddress: Joi.string().allow([null, '']).optional(),
  level1: Joi.object().keys({
    submitted: Joi.object().allow(null),
    approved: Joi.object().allow(null)
  }),
  level2: Joi.object().keys({
    submitted: Joi.object().allow(null),
    approved: Joi.object().allow(null)
  }),
  level3: Joi.object().keys({
    submitted: Joi.object().allow(null),
    approved: Joi.object().allow(null)
  }),
  level4: Joi.object().keys({
    submitted: Joi.object().allow(null),
    approved: Joi.object().allow(null)
  }),
  notifications: Joi.object().allow(null),
  exchangeSettings: Joi.object().allow(null),
  signatures: Joi.array().items(Joi.object()).allow(null),
  twofa: Joi.object().allow(null)
})

export default class CustomerDetails extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get isApproved () {
    return this.verificationLevel === 4
  }

  get verificationLevel () {
    if (!(this.level1 && this.level1.approved)) return 0
    if (!(this.level2 && this.level2.approved)) return 1
    if (!(this.level3 && this.level3.approved)) return 2
    if (!(this.level4 && this.level4.approved)) return 3
    return 4
  }

  get levels () {
    return [
      { name: 'level1', title: 'L1' },
      { name: 'level2', title: 'L2' },
      { name: 'level3', title: 'L3' },
      { name: 'level4', title: 'L4' }
    ].map(
      ({ name, title }) => {
        const level = this[name]
        const isEmpty = level.approved == null && level.submitted == null
        const isApproved = level.approved != null
        const isSubmitted = level.submitted != null
        const isSet = isApproved && !isSubmitted
        const isRejected = isSubmitted && !!level.submitted.validationComment
        return {
          name,
          title,
          isEmpty,
          isApproved,
          isSubmitted,
          isRejected,
          isSet
        }
      }
    )
  }

  getProperty (level, name) {
    const isApproved = this[level].approved != null
    const isSubmitted = this[level].submitted != null
    return {
      isApproved,
      isSubmitted,
      isEmpty: !isApproved && !isSubmitted,
      isSet: isApproved && !isSubmitted,
      approved: isApproved ? this[level].approved[name] : null,
      submitted: isSubmitted ? this[level].submitted[name] : null,
      suggested: isSubmitted
        ? this[level].submitted[name]
        : (
          isApproved
            ? this[level].approved[name]
            : null
        )
    }
  }

  get firstName () {
    return this.getProperty('level1', 'firstName')
  }

  get lastName () {
    return this.getProperty('level1', 'lastName')
  }

  get birthDate () {
    return this.getProperty('level1', 'birthDate')
  }

  get email () {
    return this.getProperty('level2', 'email')
  }

  get phone () {
    return this.getProperty('level2', 'phone')
  }

  get passport () {
    return this.getProperty('level3', 'passport')
  }

  get expirationDate () {
    return this.getProperty('level3', 'expirationDate')
  }

  get country () {
    return this.getProperty('level4', 'country')
  }

  get state () {
    return this.getProperty('level4', 'state')
  }

  get city () {
    return this.getProperty('level4', 'city')
  }

  get zip () {
    return this.getProperty('level4', 'zip')
  }

  get addressLine1 () {
    return this.getProperty('level4', 'addressLine1')
  }

  get addressLine2 () {
    return this.getProperty('level4', 'addressLine2')
  }

  get displayAvatarUrl () {
    if (!this) return null
    if (this.level1.submitted) {
      return this.level1.submitted.avatar
        ? this.level1.submitted.avatar.url
        : null
    } else if (this.level1.approved) {
      return this.level1.approved.avatar
        ? this.level1.approved.avatar.url
        : null
    }
    return null
  }

  get displayName () {
    const firstName = this.getProperty('level1', 'firstName').suggested
    const lastName = this.getProperty('level1', 'lastName').suggested
    if (firstName != null && lastName != null) {
      return `${firstName} ${lastName}`
    } else if (lastName != null) {
      return lastName
    } else if (firstName != null) {
      return firstName
    }
    return null
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new CustomerDetails({
      ...data,
      level1: {
        submitted: !data.level1.submitted ? null : {
          ...data.level1.submitted,
          birthDate: !data.level1.submitted.birthDate ? null : new Date(data.level1.submitted.birthDate),
          avatar: ImageModel.fromJson(data.level1.submitted.avatar)
        },
        approved: !data.level1.approved ? null : {
          ...data.level1.approved,
          birthDate: !data.level1.approved.birthDate ? null : new Date(data.level1.approved.birthDate),
          avatar: ImageModel.fromJson(data.level1.approved.avatar)
        }
      },
      level2: {
        submitted: !data.level2.submitted ? null : data.level2.submitted,
        approved: !data.level2.approved ? null : data.level2.approved
      },
      level3: {
        submitted: !data.level3.submitted ? null : {
          ...data.level3.submitted,
          expirationDate: !data.level3.submitted.expirationDate ? null : new Date(data.level3.submitted.expirationDate),
          attachments: !data.level3.submitted.attachments ? null : data.level3.submitted.attachments.map(AttachmentModel.fromJson)
        },
        approved: !data.level3.approved ? null : {
          ...data.level3.approved,
          expirationDate: !data.level3.approved.expirationDate ? null : new Date(data.level3.approved.expirationDate),
          attachments: !data.level3.approved.attachments ? null : data.level3.approved.attachments.map(AttachmentModel.fromJson)
        }
      },
      level4: {
        submitted: !data.level4.submitted ? null : {
          ...data.level4.submitted,
          attachments: !data.level4.submitted.attachments ? null : data.level4.submitted.attachments.map(AttachmentModel.fromJson)
        },
        approved: !data.level4.approved ? null : {
          ...data.level4.approved,
          attachments: !data.level4.approved.attachments ? null : data.level4.approved.attachments.map(AttachmentModel.fromJson)
        }
      },
      signatures: data.signatures && data.signatures.map(e => ({
        type: e.type,
        value: e.value
      })),
      twofa: data.twofa
    })
  }
}
