import {mapActions} from 'vuex'
import {OperationCanceledError} from 'src/errors'
import {CurrencyDAOModel} from 'src/models'
import {numeric, required} from 'vuelidate/lib/validators'
import {isGreaterThanOrEqual, isLessThanOrEqual} from 'src/validations'
import BigNumber from 'bignumber.js'
import PurchaseCommissionTable from 'src/components/private/PurchaseCommissionTable/PurchaseCommissionTable.vue'

function getWithdrawalFee (currency) {
  return new BigNumber(currency.currency.withdrawalFee)
    .div(new BigNumber(10).pow(currency.currency.tradeDecimals))
    .toString()
}

export default {
  components: {
    PurchaseCommissionTable
  },
  props: {
    currency: CurrencyDAOModel
  },
  data () {
    return {
      isEditInProgress: false,
      form: {
        name: this.currency.currency.name,
        icon: this.currency.currency.icon,
        background: this.currency.currency.background,
        fiatSymbol: this.currency.currency.fiatSymbol,
        displayDecimals: this.currency.currency.displayDecimals,
        withdrawalFee: getWithdrawalFee(this.currency),
        crypto: this.currency.currency.crypto,
        depositEnabled: this.currency.currency.depositEnabled,
        withdrawalEnabled: this.currency.currency.withdrawalEnabled,
        transferEnabled: this.currency.currency.transferEnabled,
        buyEnabled: this.currency.currency.buyEnabled,
        purchaseEnabled: this.currency.currency.purchaseEnabled,
        redeemEnabled: this.currency.currency.redeemEnabled,
        active: this.currency.currency.active,
        purchaseCommissions: this.currency.currency.purchaseCommissions,
        showOnLanding: this.currency.currency.showOnLanding,
        sortOrder: this.currency.currency.sortOrder
      }
    }
  },
  computed: {
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
    },
    iconErrors () {
      if (this.$v.form.icon.$dirty) {
        if (!this.$v.form.icon.required) {
          return [
            'Icon is a required field'
          ]
        }
        if (!this.$v.form.icon.startCheck) {
          return [
            'Icon text should start with data:image/svg+xml;base64,'
          ]
        }
      }
    },
    backgroundErrors () {
      if (this.$v.form.background.$dirty) {
        if (!this.$v.form.background.required) {
          return [
            'Background is a required field'
          ]
        }
      }
    },
    displayDecimalsErrors () {
      if (this.$v.form.displayDecimals.$dirty) {
        if (!this.$v.form.displayDecimals.required) {
          return [
            'Display Decimals is a required field'
          ]
        }
        if (!this.$v.form.displayDecimals.numeric) {
          return [
            'Display Decimals can be integer only'
          ]
        }
        if (!this.$v.form.displayDecimals.displayDecimals) {
          return [
            'Display Decimals  must be smaller than Decimals'
          ]
        }
      }
    },
    sortOrderErrors () {
      if (this.$v.form.sortOrder.$dirty) {
        if (!this.$v.form.sortOrder.required) {
          return [
            'Sort order is a required field'
          ]
        }
        if (!this.$v.form.sortOrder.numeric) {
          return [
            'Sort order can be integer only'
          ]
        }
      }
    },
    withdrawalFeeErrors () {
      if (this.$v.form.withdrawalFee.$dirty) {
        if (!this.$v.form.withdrawalFee.required) {
          return [
            'Withdrawal Fee is a required field'
          ]
        }
        if (!this.$v.form.withdrawalFee.numberCheck) {
          return [
            'Withdrawal fee should be 0 or more'
          ]
        }
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      icon: {
        required,
        startCheck (val) {
          return val.startsWith('data:image/svg+xml;base64,') || ''
        }
      },
      background: {
        required
      },
      displayDecimals: {
        required,
        numeric,
        displayDecimals (value) {
          return isLessThanOrEqual(value, this.currency.currency.decimals)
        }
      },
      withdrawalFee: {
        required,
        numberCheck (value) {
          return isGreaterThanOrEqual(value, 0)
        }
      },
      sortOrder: {
        required,
        numeric
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      editCurrency: 'currencies/editCurrency'
    }),
    async handleSubmit () {
      this.isEditInProgress = true
      setImmediate(async () => {
        try {
          await this.editCurrency({
            currency: this.currency,
            ...this.form
          })
          await this.handleToast({
            message: 'Currency edited'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isEditInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
