import { render, staticRenderFns } from "./StatsPage.htm?vue&type=template&id=5c7c0205&scoped=true&external"
import script from "./StatsPage.js?vue&type=script&lang=js&external"
export * from "./StatsPage.js?vue&type=script&lang=js&external"
import style0 from "./StatsPage.scss?vue&type=style&index=0&id=5c7c0205&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c7c0205",
  null
  
)

export default component.exports