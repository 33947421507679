import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { ProfileIdentityModel } from 'src/models'

export default {
  props: {
    identity: ProfileIdentityModel,
    identities: VueTypes.arrayOf(ProfileIdentityModel)
  },
  async mounted () {
    if (this.identity != null) {
      this.subscription = {
        identity: this.identity,
        listener: await this.subscribe({
          identity: this.identity
        })
      }
    }
    if (this.identities != null) {
      this.subscriptions = await Promise.all(
        this.identities.map(async identity => ({
          identity,
          listener: await this.subscribe({
            identity: identity
          })
        }))
      )
    }
  },
  async destroyed () {
    if (this.subscription) {
      await this.unsubscribe(this.subscription)
      this.subscription = null
    }
    if (this.subscriptions) {
      await Promise.all(this.subscriptions.map(s => this.unsubscribe(s)))
      this.subscriptions = null
    }
  },
  render () {
    if (this.$slots.default) {
      if (this.$slots.default.length === 1) {
        return this.$slots.default[0]
      } else if (this.$slots.default.length > 1) {
        throw new Error('ProfileIdentitySubscription component may contain max one child')
      }
    }
    return null
  },
  methods: {
    ...mapActions({
      subscribe: 'identities/subscribe',
      unsubscribe: 'identities/unsubscribe'
    })
  }
}
