import moment from 'moment'
import qs from 'qs'
import _ from 'lodash'
import { PLASMA, BACKEND, withAuthorization } from 'src/remotes'

export default () => ({
  namespaced: true,
  state () {
    return {}
  },
  mutations: {},
  actions: {
    async getStatistics ({ dispatch }, { address, from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('statistics/address', withAuthorization(token, {
        params: {
          addresses: address,
          from: `${moment(from).format()}`,
          to: `${moment(to).format()}`
        },
        paramsSerializer: function (params) {
          return qs.stringify(_.omitBy(params, _.isNil), {arrayFormat: 'repeat'})
        }
      }))
      return data
    },
    async getExchangeStatistics ({ dispatch }, { address, from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('manager/statistics/address', withAuthorization(token, {
        params: {
          addresses: address,
          from: `${moment(from).valueOf()}`,
          to: `${moment(to).valueOf()}`
        },
        paramsSerializer: function (params) {
          return qs.stringify(_.omitBy(params, _.isNil), {arrayFormat: 'repeat'})
        }
      }))
      return data
    }
  }
})
