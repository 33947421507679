import { mapActions } from 'vuex'
import { WithdrawnWithdrawalModal } from 'src/modals'

const ADV_CASH_RETRY_PROCESS_STATES = new Set(['CREATED', 'PLASMA_WITHDRAW_INIT', 'PLASMA_WITHDRAW_SUCCESS', 'REMOTE_SERVICE_TRANSFER_INIT', 'PLASMA_WITHDRAW_FAILED', 'REMOTE_SERVICE_TRANSFER_FAILED'])
const ADV_CASH_FINISH_PROCESS_STATES = new Set(['PLASMA_WITHDRAW_INIT', 'REMOTE_SERVICE_TRANSFER_INIT', 'PLASMA_WITHDRAW_FAILED', 'REMOTE_SERVICE_TRANSFER_FAILED'])
const MONOOVA_FINISH_PROCESS_STATES = new Set(['PLASMA_WITHDRAW_INIT', 'REMOTE_SERVICE_TRANSFER_INIT', 'PLASMA_WITHDRAW_FAILED', 'REMOTE_SERVICE_TRANSFER_FAILED'])
const MONOOVA_RETRY_PROCESS_STATES = new Set(['CREATED', 'PLASMA_WITHDRAW_INIT', 'PLASMA_WITHDRAW_SUCCESS', 'REMOTE_SERVICE_TRANSFER_INIT', 'REMOTE_SERVICE_TRANSFER_SUCCESS', 'PLASMA_WITHDRAW_FAILED', 'REMOTE_SERVICE_TRANSFER_FAILED'])
const GEO_PAY_RETRY_PROCESS_STATES = new Set(['CREATED', 'PLASMA_WITHDRAW_INIT', 'PLASMA_WITHDRAW_SUCCESS', 'REMOTE_SERVICE_TRANSFER_INIT', 'REMOTE_SERVICE_TRANSFER_SUCCESS', 'PLASMA_WITHDRAW_FAILED', 'REMOTE_SERVICE_TRANSFER_FAILED', 'PLASMA_REFUND_STARTED', 'PLASMA_REFUND_FAILED'])
const GEO_PAY_FINISH_PROCESS_STATES = new Set(['PLASMA_WITHDRAW_INIT', 'REMOTE_SERVICE_TRANSFER_INIT', 'PLASMA_REFUND_STARTE', 'PLASMA_WITHDRAW_FAILED', 'REMOTE_SERVICE_TRANSFER_FAILED', 'PLASMA_REFUND_FAILED'])

export default {
  props: {
    data: Object
  },
  computed: {
    canAccept () {
      return this.data.withdrawal.state === 'PENDING'
    },
    canReject () {
      if (this.data.withdrawal.type === 'GEO_PAY_CASH'
        || this.data.withdrawal.type === 'WITHDRAWAL_ADV_CASH'
        || this.data.withdrawal.type === 'WITHDRAWAL_MONOOVA') {
        return this.data.withdrawal.state === 'PENDING' ||
          this.data.withdrawal.state === 'ACCEPTED' ||
          this.data.withdrawal.state === 'FAILED'
      }

      return this.data.withdrawal.state === 'PENDING'
        || (this.data.withdrawal.state === 'ACCEPTED' && this.data.withdrawal.processState === 'SECOND_AUTO_CONFIRMED')
        || (this.data.withdrawal.state === 'FAILED' && this.data.withdrawal.processState === 'ERROR')
    },
    canRetry () {
      const withdrawal = this.data.withdrawal

      if (withdrawal.type === 'WITHDRAWAL_ADV_CASH') {
        const isStateCanRetry = withdrawal.state === 'PENDING' || withdrawal.state === 'ACCEPTED'
        return isStateCanRetry && ADV_CASH_RETRY_PROCESS_STATES.has(this.data.withdrawal.processState)
      }
      if (withdrawal.type === 'WITHDRAWAL_MONOOVA') {
        const isStateCanRetry = withdrawal.state === 'PENDING' || withdrawal.state === 'ACCEPTED'
        return isStateCanRetry && MONOOVA_RETRY_PROCESS_STATES.has(this.data.withdrawal.processState)
      }
      if (withdrawal.type === 'GEO_PAY') {
        const isStateCanRetry = withdrawal.state === 'PENDING' || withdrawal.state === 'ACCEPTED'
        return isStateCanRetry && GEO_PAY_RETRY_PROCESS_STATES.has(this.data.withdrawal.processState)
      }
      return (withdrawal.state === 'FAILED' && withdrawal.processState === 'ERROR')
    },
    canFinish () {
      const withdrawal = this.data.withdrawal

      if (withdrawal.type === 'WITHDRAWAL_ADV_CASH') {
        const isStateCanFinish = withdrawal.state === 'PENDING' || withdrawal.state === 'ACCEPTED'
        return isStateCanFinish && ADV_CASH_FINISH_PROCESS_STATES.has(this.data.withdrawal.processState)
      }

      if (withdrawal.type === 'WITHDRAWAL_MONOOVA') {
        const isStateCanFinish = withdrawal.state === 'PENDING' || withdrawal.state === 'ACCEPTED'
        return isStateCanFinish && MONOOVA_FINISH_PROCESS_STATES.has(this.data.withdrawal.processState)
      }

      if (withdrawal.type === 'GEO_PAY') {
        const isStateCanFinish = withdrawal.state === 'PENDING' || withdrawal.state === 'ACCEPTED'
        return isStateCanFinish && GEO_PAY_FINISH_PROCESS_STATES.has(this.data.withdrawal.processState)
      }
      return false
    },
    canStop () {
      return (this.data.withdrawal.state === 'ACCEPTED' && this.data.withdrawal.processState === 'SECOND_AUTO_CONFIRMED')
    },
    canMarkWithdrawn () {
      return (this.data.withdrawal.state === 'FAILED' && this.data.withdrawal.processState === 'ERROR')
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast',
      acceptRedeem: 'redemptions/acceptRedeem',
      rejectRedeem: 'redemptions/rejectRedeem',
      finishRedeem: 'redemptions/finishRedeem',
      retryRedeem: 'redemptions/retryRedeem',
      stopRedeem: 'redemptions/stopRedeem'
    }),
    async handleAccept () {
      await this.handleConfirmation({
        handler: async () => {
          await this.acceptRedeem({
            id: this.data.withdrawal.id
          })
          await this.handleToast({
            message: 'Redemption accepted'
          })
          this.$emit('update')
        }
      })
    },
    async handleReject () {
      await this.handleConfirmation({
        handler: async () => {
          await this.rejectRedeem({
            id: this.data.withdrawal.id
          })
          await this.handleToast({
            message: 'Redemption rejected'
          })
          this.$emit('update')
        }
      })
    },
    async handleRetry () {
      await this.handleConfirmation({
        handler: async () => {
          await this.retryRedeem({
            id: this.data.withdrawal.id
          })
          this.$emit('update')
        }
      })
    },
    async handleFinish () {
      await this.handleConfirmation({
        handler: async () => {
          await this.finishRedeem({
            id: this.data.withdrawal.id
          })
          this.$emit('update')
        }
      })
    },
    async handleStop () {
      await this.handleConfirmation({
        handler: async () => {
          await this.stopRedeem({
            id: this.data.withdrawal.id
          })
          this.$emit('update')
        }
      })
    },
    async handleWithdrawn () {
      this.openModal({
        factory: () => WithdrawnWithdrawalModal,
        data: {
          id: this.data.withdrawal.id
        }
      })
    }
  }
}
