/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wallet': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" opacity=".2" d="M18 15v1a2.006 2.006 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h14a2.006 2.006 0 012 2v1H9a2 2 0 00-2 2v8a2 2 0 002 2h9zm-9-2h10V5H9v8zm4-2.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" _fill="#12143C"/>'
  }
})
