/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pen': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M1 15.384c1.277-.929 3.642-2.328 6.355-1.933 4.056.582 6.374 4.806 8.073 7.885 3.624 6.571 2.094 9.472 4.844 12.429 1.793 1.915 5.436 3.924 13.847 2.779" _stroke="#424DE4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="8 8"/><path pid="1" d="M1 39.069h38M30.646 27.268L12.63 1 8.714 3.687l18.015 26.267 3.917-2.686zM30.645 27.269l3.473 9.275-7.388-6.59M36.523 1.135l-1.755 3.764 1.755 3.398 1.896-3.51-1.896-3.652zM32.27 7.987l-2.272 4.863 2.272 4.384 2.44-4.534-2.44-4.713z" _stroke="#424DE4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
