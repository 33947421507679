import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  bsb: Joi.string().required(),
  bankAccountNumber: Joi.string().required(),
  bankAccountName: Joi.string().required(),
  clientUniqueId: Joi.string().required(),
  payId: Joi.string().allow(['', null]),
  ssoUserId: Joi.string().allow(['', null]),
  plasmaAddress: Joi.string().allow(['', null]),
  status: Joi.string().required().allow(['CREATED', 'ASSIGNING_PAYID', 'FREE', 'ASSIGNING', 'ASSIGNED', 'BROKEN'])
})

export default class MonoovaAccountModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null || data === '') {
      return null
    }
    return new MonoovaAccountModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
