import { omitBy, isNil } from 'lodash'
import { CustomerUpdateModel, CustomerDetails } from 'src/models'
import { PROFILE, withAuthorization, withAuthorizationAndVerification } from 'src/remotes'

export const UPDATES_LOADING = 'updates/loading'
export const UPDATES_LOADED = 'updates/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      actualDate: null,
      activePage: 1,
      limit: 10,
      count: null,
      items: [],
      filters: {
        level: null,
        state: null
      }
    }
  },
  mutations: {
    [UPDATES_LOADING]: (state) => {
      state.isLoading = true
    },
    [UPDATES_LOADED]: (state, { actualDate, items, limit, count, activePage }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        actualDate,
        items,
        limit,
        count,
        activePage
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    items: state => state.items,
    limit: state => state.limit,
    count: state => state.count,
    activePage: state => state.activePage,
    totalPages (state) {
      const count = state.count
      const pageSize = state.limit
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async fetch ({ dispatch }, { actualDate, offset, limit, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('manager/updates', withAuthorization(token, {
        params: {
          ...filters,
          actualDate,
          offset,
          limit
        }
      }))
      return data
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          limit: state.limit
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          limit: state.limit
        }
      }
    ) {
      if (!state.isLoading) {
        commit(UPDATES_LOADING)
        const { actualDate, items, count } = await dispatch('fetch', {
          actualDate: !reset && state.isLoaded
            ? state.actualDate
            : null,
          filters,
          offset: (page.activePage - 1) * state.limit,
          limit: state.limit
        })
        commit(UPDATES_LOADED, {
          actualDate,
          activePage: page.activePage,
          count,
          items: items
        })
      }
    },
    async updateLevelFromAdmin ({ commit, dispatch }, { id, level, data }) {
      const profile = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const result = await PROFILE.put(`manager/profiles/i/${id}/verification/level${level}`, {
            ...data
          }, withAuthorizationAndVerification(token, code))
          const profile = CustomerDetails.fromJson(result.data)
          commit(UPDATES_LOADED, profile)
          return profile
        }
      }, { root: true })
      return profile
    },
    async acceptProfileUpdate ({ state, commit, dispatch }, { id }) {
      const update = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await PROFILE.post(`manager/updates/i/${id}/accept`, null, withAuthorizationAndVerification(token, code))
          const update = CustomerUpdateModel.fromJson(data)
          commit(UPDATES_LOADED, {
            items: state.items.map(
              p => p.id !== update.id
                ? p
                : update
            )
          })
          return update
        }
      }, { root: true })
      return update
    },
    async rejectProfileUpdate ({ state, commit, dispatch }, { id, validationComment }) {
      const update = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await PROFILE.post(`manager/updates/i/${id}/reject`, {
            validationComment
          }, withAuthorizationAndVerification(token, code))
          const update = CustomerUpdateModel.fromJson(data)
          commit(UPDATES_LOADED, {
            items: state.items.map(
              p => p.id !== update.id
                ? p
                : update
            )
          })
          return update
        }
      }, { root: true })
      return update
    }
  }
})
