import { throttle } from 'lodash'
import lax from 'lax.js'

export const createFromConfig = ({ property = 'laxProperty', selector = '.lax-scroll-animate', handler, interval = 300 }) => ({
  mounted () {
    const laxProperty = {
      elements: null,
      handler: null,
      interval: null,
      size: {
        width: null,
        height: null
      }
    }

    laxProperty.elements = this.$el.querySelectorAll(selector)
    for (const el of laxProperty.elements) {
      lax.addElement(el)
    }
    lax.update()

    laxProperty.handler = throttle(() => {
      handler.bind(this)()
      setImmediate(() => {
        lax.updateElements()
      })
    }, interval)
    setImmediate(() => {
      laxProperty.handler.bind(this)()
    })

    laxProperty.interval = setInterval(() => {
      const { width, height } = this.$el.getBoundingClientRect()
      if (laxProperty.size.with !== width || laxProperty.size.height !== height) {
        laxProperty.size.with = width
        laxProperty.size.height = height
        this[property].handler.bind(this)()
      }
    }, 50)

    this[property] = Object.freeze(laxProperty)
  },
  beforeDestroy () {
    const laxProperty = this[property]
    if (laxProperty != null) {
      if (laxProperty.elements) {
        for (const el of laxProperty.elements) {
          lax.removeElement(el)
        }
      }
      if (laxProperty.interval) {
        clearInterval(laxProperty.interval)
      }
      this[property] = null
    }
  }
})

export const createFromHandler = handler => createFromConfig({ handler })

export default arg => {
  if (arg instanceof Function) {
    return createFromHandler(arg)
  }
  return createFromConfig(arg)
}
