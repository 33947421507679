import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'
import * as partials from 'src/partials'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Rapid Id'
  },
  components: {
    ...components,
    ...partials
  },
  data () {
    return {
      rapidIdLoading: false
    }
  },
  async created () {
    try {
      this.rapidIdLoading = true
      await this.loadRapidIdItems({})
    } finally {
      this.rapidIdLoading = false
    }
  },
  computed: {
    ...mapGetters({
      rapidIdItems: 'customers/rapidIdItems',
      hasKycActionsAccess: 'grants/hasKycActionsAccess'
    }),
    ...mapState({
      page: state => state.customers.page,
      totalPages: state => state.customers.rapidTotalPages
    }),
    items () {
      return this.rapidIdItems.value
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadRapidIdItems: 'customers/loadRapidIdItems',
      refreshRapidId: 'customers/refreshRapidId'
    }),
    async handleRefresh (id) {
      this.refreshRapidId({
        id: id
      })
    },
    async reloadRapidId () {
      try {
        this.rapidIdLoading = true
        await this.loadRapidIdItems({})
      } finally {
        this.rapidIdLoading = false
      }
    },
    async handleNavigation (value) {
      this.loadRapidIdItems({
        page: value - 1
      })
    }
  }
}
