import {mapActions, mapGetters} from 'vuex'

export default {
  metaInfo: {
    title: 'Verification'
  },
  components: {
  },
  data () {
    return {
      currentStep: '0',
      sectionLevel1: false,
      sectionLevel2: false,
      sectionLevel3: false,
      sectionLevel4: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile'
    }),
    sectionsLogic () {
      if (this.profile) {
        if (this.profile.email && this.profile.phone) {
          if (this.profile.email.status === 'ACCEPTED' && this.profile.phone.status === 'ACCEPTED') {
            this.sectionLevel1 = true
            this.currentStep = '01'
          } else {
            this.sectionLevel1 = false
          }
        }
        if (this.profile.personal) {
          if (this.profile.personal.status === 'ACCEPTED') {
            this.sectionLevel2 = true
            this.currentStep = '02'
          } else {
            this.sectionLevel2 = false
          }
        }
        if (this.profile.address) {
          if (this.profile.address.status === 'ACCEPTED') {
            this.sectionLevel3 = true
            this.currentStep = '03'
          } else {
            this.sectionLevel3 = false
          }
        }
        if (this.profile.documents) {
          if (this.profile.documents.status === 'ACCEPTED') {
            this.sectionLevel4 = true
            this.currentStep = '04'
          } else {
            this.sectionLevel4 = false
          }
        }
        if (this.profile.email) {
          if (this.profile.email.status === 'RESET') {
            this.currentStep = '0'
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      loadProfile: 'myProfileKyc/loadProfile'
    })
  },
  mounted () {
  }
}
