import {
  PUBLIC_BACKEND,
  BACKEND,
  PROFILE,
  PLASMA_RW,
  PASSPORT,
  withAuthorization, PLASMA
} from 'src/remotes'
import { omitBy, isNil } from 'lodash'

const QUEUEE_STATS_LOADING = 'queuesStats/loading'
const QUEUEE_STATS_LOADED = 'queuesStats/loaded'
const ACCOUNTS_STATS_LOADING = 'accountsStats/loading'
const ACCOUNTS_STATS_LOADED = 'accountsStats/loaded'
const LAST_OPERATION_LOADING = 'lastOperation/loading'
const LAST_OPERATION_LOADED = 'lastOperation/loaded'
const OPERATION_DETAILS_LOADING = 'operationDetails/loading'
const OPERATION_DETAILS_LOADED = 'operationDetails/loaded'
const OPERATION_LOADING = 'operation/loading'
const OPERATION_LOADED = 'operation/loaded'

export default () => ({
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    queuesStats: {},
    accountsStats: {},
    lastOperation: {},
    operationDetails: {},
    operation: {}
  },
  mutations: {
    [QUEUEE_STATS_LOADING] (state) {
      state.isLoading = true
    },
    [QUEUEE_STATS_LOADED]: (state, { queuesStats }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        queuesStats
      }, isNil))
    },
    [ACCOUNTS_STATS_LOADING] (state) {
      state.isLoading = true
    },
    [ACCOUNTS_STATS_LOADED]: (state, { accountsStats }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        accountsStats
      }, isNil))
    },
    [LAST_OPERATION_LOADING] (state) {
      state.isLoading = true
    },
    [LAST_OPERATION_LOADED]: (state, { lastOperation }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        lastOperation
      }, isNil))
    },
    [OPERATION_DETAILS_LOADING] (state) {
      state.isLoading = true
    },
    [OPERATION_DETAILS_LOADED]: (state, { operationDetails }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        operationDetails
      }, isNil))
    },
    [OPERATION_LOADING] (state) {
      state.isLoading = true
    },
    [OPERATION_LOADED]: (state, { operation }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        operation
      }, isNil))
    }
  },
  getters: {
    queuesStats: state => state.queuesStats,
    accountsStats: state => state.accountsStats,
    lastOperation: state => state.lastOperation,
    operationDetails: state => state.operationDetails,
    operation: state => state.operation
  },
  actions: {
    async clearExchangeBackendCashes ({dispatch}, { type }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post('system/cache/clear', { type }, withAuthorization(token))
    },
    async clearProfileCashes ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post('system/cache/clear', null, withAuthorization(token))
    },
    async dropCfCache ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post('/system/cf/drop-cache', null, withAuthorization(token))
    },
    async clearPublicCashes ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post('system/clear-cache', null, withAuthorization(token))
    },
    async clearPassportCashes ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PASSPORT.post('/api/v1/system/cache/clear', null, withAuthorization(token))
    },
    async reindexPublicFaq ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post('faq/questions/reindex', null, withAuthorization(token))
    },
    async reindexPublicBlog ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PUBLIC_BACKEND.post('blog/articles/reindex', null, withAuthorization(token))
    },
    async clearRelayBackendCashes ({dispatch}, { caches }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PLASMA_RW.post('monitoring/cache/evict', { caches }, withAuthorization(token))
    },
    async getQueuesStats ({commit, dispatch}) {
      commit(QUEUEE_STATS_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA_RW.get('monitoring/engines/queues/stats', withAuthorization(token))
      commit(QUEUEE_STATS_LOADED, { queuesStats: data })
    },
    async accountsFlush ({commit, dispatch}, { address }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PLASMA_RW.post('monitoring/operations/accounts/flush', null, withAuthorization(token, { params: { address } }))
    },
    async getAccountsStats ({commit, dispatch}) {
      commit(ACCOUNTS_STATS_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA_RW.get('monitoring/operations/accounts/stats', withAuthorization(token))
      commit(ACCOUNTS_STATS_LOADED, { accountsStats: data })
    },
    async getLastOperation ({commit, dispatch}) {
      commit(LAST_OPERATION_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA_RW.get('monitoring/operations/last', withAuthorization(token))
      commit(LAST_OPERATION_LOADED, { lastOperation: data })
    },
    async getOperationDetails ({commit, dispatch}, { uuid }) {
      commit(OPERATION_DETAILS_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA_RW.get(`monitoring/operation/${uuid}`, withAuthorization(token))
      commit(OPERATION_DETAILS_LOADED, { operationDetails: data })
    },
    async getOperation ({commit, dispatch}, { filters = {} } = { filters: {} }) {
      commit(OPERATION_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('/system/operations', withAuthorization(token, {
        params: {
          ...filters
        }
      }))
      commit(OPERATION_LOADED, { operation: data })
    },
    async downloadSystemBalances ({ dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('/admin/balances/all/export/csv', withAuthorization(token))
      const blob = new Blob([data])
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'balances.csv'
      link.click()
    },
    async startPerformanceMonitoring ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PLASMA_RW.post('/monitoring/performance/enable', null, withAuthorization(token))
    },
    async stopPerformanceMonitoring ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PLASMA_RW.post('monitoring/performance/disable', null, withAuthorization(token))
    },
    async loadPerformanceMetrics ({dispatch}) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA_RW.get('monitoring/performance/metrics', withAuthorization(token))
      return data
    }
  }
})
