import * as components from 'src/components'
import SectionFormLayout from '../SectionFormLayout/SectionFormLayout.vue'
import * as directives from 'src/directives'

export default {
  components: {
    ...components,
    SectionFormLayout
  },
  data () {
    return {
      data: [
        {
          id: 1,
          name: 'twitter',
          title: 'Follow us on Twitter',
          url: 'https://twitter.com/audt_to',
          target: true
        },
        {
          id: 2,
          name: 'telegram',
          title: 'Join our news channel',
          url: 'https://t.me/audt_to',
          target: true
        },
        {
          id: 3,
          name: 'mail',
          title: 'Subscribe to our newsletter',
          url: '/contact-us',
          target: false
        }
      ]
    }
  },
  directives: {
    ...directives
  }
}
