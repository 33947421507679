// import uniqid from 'uniqid'
import EventEmitter from 'events'
import AsyncLock from 'async-lock'
import { WalletEntryModel } from 'src/models'

export default class AbstractDevice extends EventEmitter {
  constructor () {
    super()
    this.signers = Object.create(null) // signers mapped by entry key
    this.lock = new AsyncLock({ domainReentrant: true })
  }

  getLock () {
    throw new Error('Not Implemented')
  }

  // eslint-disable-next-line
  async init () {
    throw new Error('Not Implemented')
  }

  // eslint-disable-next-line no-unused-vars
  async register (entry, options) {
    // do nothing in base implementation
  }

  async signTransaction (wallet: WalletEntryModel, txData) {
    return this.withSigner(
      wallet,
      async signer => {
        return signer.signTransaction(txData)
      }
    )
  }

  async signData (wallet: WalletEntryModel, data) {
    return this.withSigner(
      wallet,
      async signer => {
        return signer.signData(data)
      }
    )
  }

  async withSigner (entry, callable) {
    return this._safeExec(
      async () => {
        return this._withSigner(entry, callable)
      }
    )
  }

  async _withSigner (entry, callable) {
    const key = entry.key
    let signer = this.signers[key]
    if (signer == null) {
      signer = await this._createSigner(entry)
      this.signers[entry.key] = signer
    }
    return callable(signer)
  }

  async _createSigner (entry: WalletEntryModel) {
    return new Promise((resolve, reject) => {
      const cancelCallback = reject
      const successCallback = async context => {
        try {
          const signer = this.decrypt(entry, context)
          resolve(signer)
        } catch (e) {
          this.emitter.emit('unpack', {
            entry,
            error: e,
            successCallback,
            cancelCallback
          })
        }
      }
      this.emitter.emit('unpack', {
        entry,
        successCallback,
        cancelCallback
      })
    })
  }

  async _safeExec (callable) {
    return this.lock.acquire(
      this.getLock(),
      callable
    )
  }
}
