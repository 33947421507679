import {mapActions, mapGetters} from 'vuex'
import {DuplicateFilenameUploadError} from 'src/errors'
import { copyToClipboard } from 'src/utils/lib/common'

const config = global.__APP_CONFIG__

export default {
  computed: {
    ...mapGetters({
    })
  },
  data () {
    return {
      duplicateError: '',
      imgBasePath: config.blog.imageUrlPrefix,
      imgList: []
    }
  },
  methods: {
    ...mapActions({
      uploadImage: 'blog/uploadImage'
    }),
    copyClipboard (path) {
      copyToClipboard(path)
    },
    handleUpload (e) {
      if (!e.target.id) {
        this.activeUpload = e.target.parentElement.id
      } else {
        this.activeUpload = e.target.id
      }
      this.$refs['uploader-input'].click()
    },
    async handleChange (e) {
      this.upload = true
      let imgPath = ''
      try {
        imgPath = await this.uploadImage({ file: e.target.files[0] })
        this.imgList.push(imgPath)
      } catch (e) {
        if (e instanceof DuplicateFilenameUploadError) {
          this.duplicateError = 'Uploading image name duplicated. Change image name'
        }
      } finally {
        this.upload = false
      }
    }
  }
}
