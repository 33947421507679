
export default {
  props: {
    fullWidthCenter: Boolean,
    leftColWidth: String
  },
  computed: {
    hasLeft () {
      return !!this.$slots.left
    },
    hasCenter () {
      return !!this.$slots.center
    },
    hasRight () {
      return !!this.$slots.right
    },
    hasBottom () {
      return !!this.$slots.bottom
    },
    calcPadding () {
      return `calc(1rem + ${this.leftColWidth}px)`
    }
  }
}
