import {
  LoadableModel,
  LoadableListModel,
  MonoovaAccountModel,
  GetMonoovaAccountsResponse,
  MonoovaSubscriptionModel,
  MonoovaWhitelistModel,
  MonoovaBankModel,
  MonoovaRejectedTransactionModel
} from 'src/models'

import {
  BACKEND,
  withAuthorization
} from 'src/remotes'

export const ACCOUNTS_LOADING = 'monoova/accounts/loading'
export const ACCOUNTS_LOADED = 'monoova/accounts/loaded'
export const SBSCRIPTIONS_LOADING = 'monoova/subscriptions/loading'
export const SBSCRIPTIONS_LOADED = 'monoova/subscriptions/loaded'
export const ACCOUNT_LOADING = 'monoova/account/loading'
export const ACCOUNT_LOADED = 'monoova/account/loaded'
export const BANK_LOADING = 'monoova/bank/loading'
export const BANK_LOADED = 'monoova/bank/loaded'
export const BANK_CLEAR = 'monoova/bank/clear'
export const WHITELIST_LOADING = 'monoova/whitelist/loading'
export const WHITELIST_LOADED = 'monoova/whitelist/loaded'
export const REJECTED_TRANSACTIONS_LOADING = 'monoova/rejectedTransactions/loading'
export const REJECTED_TRANSACTIONS_LOADED = 'monoova/rejectedTransactions/loaded'

export const DEFAULT_PAGE_SIZE = 30

export default () => ({
  namespaced: true,
  state () {
    return {
      page: 0,
      totalPages: 0,
      accounts: new LoadableListModel(MonoovaAccountModel),
      subscriptions: new LoadableListModel(MonoovaSubscriptionModel),
      account: new LoadableModel(MonoovaAccountModel),
      bank: new LoadableModel(MonoovaBankModel),
      whitelist: new LoadableListModel(MonoovaWhitelistModel),
      rejectedTransactions: new LoadableListModel(MonoovaRejectedTransactionModel)
    }
  },
  mutations: {
    [ACCOUNTS_LOADING]: (state) => {
      state.accounts = state.accounts.loading()
    },
    [ACCOUNTS_LOADED]: (state, { accounts, page, totalPages, count }) => {
      state.accounts = state.accounts.loaded(...accounts)
      state.page = page
      state.totalPages = totalPages
    },
    [SBSCRIPTIONS_LOADING]: (state) => {
      state.subscriptions = state.subscriptions.loading()
    },
    [SBSCRIPTIONS_LOADED]: (state, { items }) => {
      state.subscriptions = state.subscriptions.loaded(...items)
    },
    [ACCOUNT_LOADING]: (state) => {
      state.account = state.account.loading()
    },
    [ACCOUNT_LOADED]: (state, { account }) => {
      state.account = state.account.loaded(account)
    },
    [BANK_LOADING]: (state) => {
      state.bank = state.bank.loading()
    },
    [BANK_LOADED]: (state, { bank }) => {
      state.bank = state.bank.loaded(bank)
    },
    [BANK_CLEAR]: (state) => {
      state.bank = new LoadableModel(MonoovaBankModel)
    },
    [WHITELIST_LOADING]: (state) => {
      state.whitelist = state.whitelist.loading()
    },
    [WHITELIST_LOADED]: (state, { whitelist }) => {
      state.whitelist = state.whitelist.loaded(...whitelist)
    },
    [REJECTED_TRANSACTIONS_LOADING]: (state) => {
      state.rejectedTransactions = state.rejectedTransactions.loading()
    },
    [REJECTED_TRANSACTIONS_LOADED]: (state, { transactions }) => {
      state.rejectedTransactions = state.rejectedTransactions.loaded(...transactions)
    }
  },
  getters: {
    accounts: state => state.accounts,
    account: state => state.account,
    bank: state => state.bank,
    whitelist: state => state.whitelist
  },
  actions: {
    async fetchAccounts ({ dispatch, state }, { page, size }) {
      const request = {
        limit: size,
        offset: page * size
      }
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('/monoova/manager/accounts', withAuthorization(token, {
        params: request
      }))
      return GetMonoovaAccountsResponse.fromJson(data)
    },
    async loadAccounts ({ state, commit, dispatch }, { page = 0, size = DEFAULT_PAGE_SIZE }) {
      commit(ACCOUNTS_LOADING)
      const {
        items,
        offset,
        count
      } = await dispatch('fetchAccounts', { page, size })
      commit(ACCOUNTS_LOADED, {
        accounts: items,
        page: Math.floor(offset / size),
        totalPages: Math.ceil(count / size)
      })
    },
    async refreshAccount ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/monoova/manager/account/${id}/refresh`, {}, withAuthorization(token))
      await dispatch('loadAccounts', { page: state.page, size: DEFAULT_PAGE_SIZE })
    },
    async createAccounts ({ state, commit, dispatch }, { count }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/monoova/manager/accounts/create`, { count }, withAuthorization(token))
    },
    async assignAccount ({ state, commit, dispatch }, { address, ssoUserId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/monoova/manager/account/assign`, { address, ssoUserId }, withAuthorization(token))
    },
    async rescanDeposits ({ state, commit, dispatch }, { date }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/monoova/manager/scan/deposits`, { date }, withAuthorization(token))
    },
    async fetchSubscriptions ({ dispatch, state }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('/monoova/manager/subscriptions', withAuthorization(token))
      return data.map(e => MonoovaSubscriptionModel.fromJson(e))
    },
    async loadSubscriptions ({ state, commit, dispatch }) {
      commit(SBSCRIPTIONS_LOADING)
      const items = await dispatch('fetchSubscriptions')
      commit(SBSCRIPTIONS_LOADED, { items })
    },
    async subsribeWeebhooks ({ state, commit, dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/monoova/manager/subscribe`, null, withAuthorization(token))
      await dispatch('loadSubscriptions')
    },
    async unsubsribeWeebhook ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/monoova/manager/unsubscribe/${id}`, null, withAuthorization(token))
      await dispatch('loadSubscriptions')
    },
    async loadAccount ({ commit, dispatch }, { id, plasmaAddress }) {
      commit(ACCOUNT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`/monoova/manager/user/i/${id}/account`, withAuthorization(token, {
        params: {
          plasmaAddress
        }
      }))
      commit(ACCOUNT_LOADED, { account: MonoovaAccountModel.fromJson(data) })
    },
    async getUserMonoovaBank ({ commit, dispatch }, { ssoUserId }) {
      commit(BANK_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`/monoova/manager/user/i/${ssoUserId}/withdrawal/bank`, withAuthorization(token))
      commit(BANK_LOADED, { bank: MonoovaBankModel.fromJson(data) })
      return data
    },
    clearUserMonoovaBank ({commit}) {
      commit(BANK_CLEAR)
    },
    async editUserMonoovaBank ({ state, commit, dispatch }, { ssoUserId, status, type, accountName, accountNumber, bsb, payId }) {
      commit(BANK_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`/monoova/manager/user/i/${ssoUserId}/withdrawal/bank/edit`, {
        status: status,
        type: type,
        accountName: accountName,
        accountNumber: accountNumber,
        bsb: bsb,
        payId: payId
      }, withAuthorization(token))
      commit(BANK_LOADED, { bank: MonoovaBankModel.fromJson(data) })
    },
    async loadMonoovaWhitelist ({ commit, dispatch }, { accountId }) {
      commit(WHITELIST_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`/monoova/manager/account/i/${accountId}/whitelist`, withAuthorization(token))
      const whitelist = data.map(MonoovaWhitelistModel.fromJson)
      commit(WHITELIST_LOADED, { whitelist })
    },
    async addMonoovaWhitelist ({ state, commit, dispatch }, { accountId, bsb, accountName, accountNumber }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`/monoova/manager/account/i/${accountId}/whitelist`, { bsb, accountName, accountNumber }, withAuthorization(token))
      const whitelist = data.map(MonoovaWhitelistModel.fromJson)
      commit(WHITELIST_LOADED, { whitelist })
    },
    async updateMonoovaWhitelist ({ state, commit, dispatch }, { accountId, whitelistItemId, enable }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`/monoova/manager/account/i/${accountId}/whitelist/i/${whitelistItemId}/update`, { enable }, withAuthorization(token))
      const whitelist = data.map(MonoovaWhitelistModel.fromJson)
      commit(WHITELIST_LOADED, { whitelist })
    },
    async fetchRejectedMonoovaTransactions ({ dispatch, state }, { date }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('/monoova/manager/rejectedTransactions', withAuthorization(token, {
        params: {
          date
        }
      }))
      return data.map(e => MonoovaRejectedTransactionModel.fromJson(e))
    },
    async loadRejectedMonoovaTransactions ({ state, commit, dispatch }, { date }) {
      commit(REJECTED_TRANSACTIONS_LOADING)
      const transactions = await dispatch('fetchRejectedMonoovaTransactions', { date })
      commit(REJECTED_TRANSACTIONS_LOADED, { transactions })
    }
  }
})
