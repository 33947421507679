import { render, staticRenderFns } from "./BundlesPage.htm?vue&type=template&id=6516b8f6&scoped=true&external"
import script from "./BundlesPage.js?vue&type=script&lang=js&external"
export * from "./BundlesPage.js?vue&type=script&lang=js&external"
import style0 from "./BundlesPage.scss?vue&type=style&index=0&id=6516b8f6&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6516b8f6",
  null
  
)

/* custom blocks */
import block0 from "./BundlesPage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports