import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  status: Joi.string().allow([ null ]).valid('UNCONFIRMED', 'CONFIRMED'),
  type: Joi.string().allow([ null ]).valid('BSB', 'PAY_ID'),
  accountName: Joi.string().allow([ null ]),
  accountNumber: Joi.string().max(9).regex(/^\d+$/).when('type', { is: 'BSB', then: Joi.required(), otherwise: Joi.allow([ null, '' ]) }),
  bsb: Joi.string().length(6).when('type', { is: 'BSB', then: Joi.required(), otherwise: Joi.allow([ null, '' ]) }),
  payId: Joi.string().max(256).when('type', { is: 'PAY_ID', then: Joi.required(), otherwise: Joi.allow([ null, '' ]) })
})

export default class MonoovaBankModel extends AbstractModel {
  constructor (data, options) {
    super(
      data,
      schemaFactory,
      options
    )
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null || data === '') {
      return null
    }
    return new MonoovaBankModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
