import cookies from 'browser-cookies'

export default ({ store }) => {
  const routes = [
    {
      path: '/server',
      // component: DefaultLayout,
      async beforeEnter (to, from, next) {
        try {
          const token = cookies.get('Token')
          const refreshToken = cookies.get('RefreshToken')
          const user = await store.dispatch('passport/fetch', { token })
          await store.dispatch('passport/session', { token, refreshToken, user })
          next({
            path: '/private'
          })
        } catch (e) {
          next({
            path: '/'
          })
        }
      }
    }
  ]
  routes.push({
    path: '/auth/callback',
    async beforeEnter (to, from, next) {
      const code = to.query.code

      try {
        await store.dispatch('passport/exchangeGrantCode', { code })

        return next({
          path: '/private'
        })
      } catch (e) {
        // document.location.href = `${window.location.origin}`

        // TODO: auth error page
        // return next({
        //   path: '/'
        // })
      }
    }
  })

  return routes
}
