import {mapActions, mapGetters} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    currencyFiat: Object
  },
  data () {
    return {
      isAddInProgress: false,
      form: {
        title: this.currencyFiat.title,
        rateServiceSymbol: this.currencyFiat.rateServiceSymbol,
        icon: this.currencyFiat.icon
      }
    }
  },
  validations: {
    form: {
      title: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
    }),
    titleErrors () {
      if (this.$v.form.title.$dirty) {
        if (!this.$v.form.title.required) {
          return [
            'Title is a required field'
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      editFiatCurrency: 'currencies/editFiatCurrency'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.editFiatCurrency({
            id: this.currencyFiat.id,
            title: this.form.title,
            rateServiceSymbol: this.form.rateServiceSymbol,
            icon: this.form.icon
          })
          await this.handleToast({
            message: 'Currency edited'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
