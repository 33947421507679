import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as partials from 'src/partials'
import * as components from 'src/components'
import { debounce, pickBy, isEmpty, isEqual } from 'lodash'

export default {
  metaInfo: {
    title: 'KYC'
  },
  components: {
    ...components,
    ...partials
  },
  data () {
    return {
      sort: 'asc',
      isBusy: false,
      history: null,
      customer: null,
      attachments: null,
      headers: [
        {
          text: 'Created At',
          align: 'start',
          sortable: false,
          value: 'levelCreatedAt'
        },
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName'
        },
        {
          text: 'Middle Name',
          align: 'start',
          sortable: false,
          value: 'middleName'
        },
        {
          text: 'Last Name',
          align: 'start',
          sortable: false,
          value: 'lastName'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'reload'
        }
      ],
      filters: {},
      types: {
        'emailCredential': 'Email (registration)',
        'emailKyc': 'Email (kyc)'
      },
      typeOfSearch: '',
      search: ''
    }
  },
  async created () {
    await this.init()
    await this.getTableData(this.key)
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      isLoading: 'customers/isLoading',
      tableData: 'customers/tableData',
      numberOfElements: 'customers/numberOfElements',
      totalPages: 'customers/totalPages',
      activePage: 'customers/activePage'
    }),
    searchOptions () {
      let options = []
      for (const [key, value] of Object.entries(this.types)) {
        options.push({
          key: `${key}`,
          value: `${value}`
        })
      }
      return options
    },
    items () {
      return this.tableData.items
    },
    key () {
      return this.getKey(this.$route.name)
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadMoreKYCUpdates: 'customers/loadMoreKYCUpdates'
    }),
    async init () {
      this.filters = pickBy(this.$route.query, (value, key) => {
        return Object.keys(this.types).includes(key)
      })
      const filter = Object.entries(this.filters).find(([key]) => Object.keys(this.types).includes(key))
      if (filter) {
        this.typeOfSearch = filter[0]
        this.search = filter[1]
      }
    },
    async chooseType (value) {
      this.typeOfSearch = value
      this.search = ''
      await this.handleClear()
    },
    async handleClear () {
      if (!isEmpty(this.$route.query)) {
        await this.$router.replace({
          query: null
        })
      }
      this.filters = {}
      await this.handleRefresh()
    },
    handleSearchDebounced: debounce(
      function () {
        return this.handleSearch()
      },
      400
    ),
    async handleSearch () {
      if (this.search && this.typeOfSearch) {
        Object.assign(this.filters, {
          [this.typeOfSearch]: this.search.trim()
        })
        if (this.typeOfSearch === 'emailCredential') {
          await this.loadMoreKYCUpdates({
            key: this.key,
            page: {
              activePage: 1,
              numberOfElements: this.numberOfElements
            },
            sort: this.sort,
            emailCredential: this.search
          })
        } else {
          await this.loadMoreKYCUpdates({
            key: this.key,
            page: {
              activePage: 1,
              numberOfElements: this.numberOfElements
            },
            sort: this.sort,
            emailKyc: this.search
          })
        }
      } else {
        this.filters = {}
      }
      if (!isEqual(this.$route.query, this.filters)) {
        await this.$router.push({
          query: {
            ...this.filters
          }
        })
      }
    },
    async handleSort () {
      if (this.sort === 'asc') {
        this.sort = 'desc'
        await this.getTableData(this.key)
      } else {
        this.sort = 'asc'
        await this.getTableData(this.key)
      }
    },
    async handleRefresh () {
      if (!isEmpty(this.$route.query)) {
        await this.$router.replace({
          query: null
        })
      }
      this.filters = {}
      await this.getTableData(this.key)
    },
    getKey (key) {
      switch (key) {
        case 'address':
          return 'address'
        case 'documents':
          return 'documents'
        case 'submitted':
          return 'resubmitted'
        case 'complicated':
          return 'complicated'
      }
    },
    async getTableData (key) {
      const page = this.$route.query.page
      await this.loadMoreKYCUpdates({
        key: key,
        page: {
          activePage: page || 1,
          numberOfElements: this.numberOfElements
        },
        sort: this.sort
      })
    },
    async toClient (item) {
      this.isBusy = true
      if (!this.$route.params.id) {
        await this.$router.push({ path: `kyc-customer/i/${item.id}`, hash: this.key })
      }
      this.isBusy = false
    },
    async handleLoad (value) {
      await this.loadMoreKYCUpdates({
        key: this.key,
        page: {
          activePage: value !== undefined ? value : 1,
          numberOfElements: this.numberOfElements
        }
      })
      await this.$router.push({
        query: {
          page: value
        }
      })
    }
  }
}
