import { render, staticRenderFns } from "./SectionPlasmaDeposits.htm?vue&type=template&id=b0290594&scoped=true&external"
import script from "./SectionPlasmaDeposits.js?vue&type=script&lang=js&external"
export * from "./SectionPlasmaDeposits.js?vue&type=script&lang=js&external"
import style0 from "./SectionPlasmaDeposits.scss?vue&type=style&index=0&id=b0290594&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0290594",
  null
  
)

export default component.exports