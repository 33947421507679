/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'convert-icon': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M22.599 17.418v-9.75A4.267 4.267 0 0018.333 3.4h-3.826l1.69-1.692L14.69.2l-3.511 3.513a1.067 1.067 0 000 1.508l3.512 3.513 1.508-1.51-1.691-1.69h3.826a2.133 2.133 0 012.132 2.134v9.75c-1.836.476-3.2 2.13-3.2 4.115a4.267 4.267 0 008.534 0c0-1.985-1.364-3.64-3.2-4.115zm-1.067 6.248a2.133 2.133 0 110-4.266 2.133 2.133 0 010 4.267zM14.82 20.78l-3.512-3.514-1.509 1.509 1.692 1.692-3.826-.001a2.133 2.133 0 01-2.132-2.134v-9.75c1.837-.476 3.2-2.13 3.2-4.115a4.267 4.267 0 00-8.534 0c0 1.985 1.363 3.64 3.2 4.115v9.75A4.267 4.267 0 007.665 22.6h3.826L9.8 24.291l1.508 1.509 3.511-3.513a1.066 1.066 0 000-1.508zM4.466 6.6a2.133 2.133 0 110-4.267 2.133 2.133 0 010 4.267z" _fill="#FF7715"/>'
  }
})
