import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  name: Joi.string().required(),
  isActive: Joi.boolean(),
  isBusy: Joi.boolean(),
  info: Joi.any(),
  device: Joi.any()
})

export default class DeviceStatus extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.name
  }

  update (props) {
    return new DeviceStatus({
      ...this,
      ...props
    })
  }
}
