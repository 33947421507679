import { render, staticRenderFns } from "./ProfileDetailsPage.htm?vue&type=template&id=374669f6&scoped=true&external"
import script from "./ProfileDetailsPage.js?vue&type=script&lang=js&external"
export * from "./ProfileDetailsPage.js?vue&type=script&lang=js&external"
import style0 from "./ProfileDetailsPage.scss?vue&type=style&index=0&id=374669f6&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374669f6",
  null
  
)

export default component.exports