import * as components from 'src/components'
import SectionLayout from '../SectionLayout/SectionLayout.vue'
import * as directives from 'src/directives'
import { LaxMixinFactory } from 'src/mixins'

export default {
  components: {
    ...components,
    SectionLayout
  },
  directives: {
    ...directives
  },
  mixins: [
    LaxMixinFactory(function () {
      const { top } = this.$refs.blocks.getBoundingClientRect()
      const { scrollY } = window
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    })
  ],
  data () {
    return {
      startScrollOffset: 0,
      endScrollOffset: 0,
      data: [
        {
          id: 1,
          title: 'Purchase AUDT',
          offset: 0,
          content: [
            {id: 1, text: 'Register, create your profile and verify your account'},
            {id: 2, text: 'Deposit funds via bank transfer'},
            {id: 3, text: 'Add your wallet or create a new one'},
            {id: 4, text: 'The bank confirms receipt of AUD'},
            {id: 5, text: 'We transfer the equivalent number of tokens to your wallet'}
          ]
        },
        {
          id: 2,
          title: 'Redeem AUDT',
          offset: 0,
          content: [
            {id: 1, text: 'Send AUDT back to us'},
            {id: 2, text: 'We confirm receipt of AUDT'},
            {id: 3, text: 'We instruct the bank to make a transfer'},
            {id: 4, text: 'AUD is credited to your designated card'}
          ]
        }
      ]
    }
  },
  computed: {
    items () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      return this.data.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${start + item.offset + index * 50} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateY: `${start + item.offset + index * 150} 150px, ${end + item.offset + index * 30} 0px`
        })
      )
    }
  }
}
