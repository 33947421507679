import { CustomerModel, LoadableMapModel, ProfileIdentityModel, ProfileIdentityDetailsModel } from 'src/models'
import { PROFILE, withAuthorization } from 'src/remotes'

const PROFILE_IDENTITY_LOADED = 'profile/identities/loaded'
const PROFILE_IDENTITY_LOADING = 'profile/identities/loading'
const PROFILE_IDENTITIES_RESET = 'profile/identities/reset'

const initialState = () => ({
  table: new LoadableMapModel(ProfileIdentityDetailsModel)
})

export default () => ({
  namespaced: true,
  state () {
    return initialState()
  },
  mutations: {
    [PROFILE_IDENTITY_LOADED]: (state, { identity, customer }) => {
      // eslint-disable-next-line
      // console.log('Update', identity.key, customer)
      const model = state.table.get(identity.key) || new ProfileIdentityDetailsModel({ identity })
      state.table = state.table.put(identity.key, model.loaded({ customer }))
    },
    [PROFILE_IDENTITY_LOADING]: (state, { identity }) => {
      const model = state.table.get(identity.key) || new ProfileIdentityDetailsModel({ identity })
      state.table = state.table.put(identity.key, model.loading())
    },
    [PROFILE_IDENTITIES_RESET]: (state) => {
      Object.assign(state, initialState())
    }
  },
  getters: {
    getDetails: state => identity => state.table.get(identity.key) || new ProfileIdentityDetailsModel({ identity })
  },
  actions: {
    async reload ({ commit, dispatch }, { identities }) {
      for (const identity of identities) {
        commit(PROFILE_IDENTITY_LOADING, { identity })
      }
      if (identities.length > 0) {
        const token = await dispatch('passport/requireToken', null, { root: true })
        const { data } = await PROFILE.post('kyc/manager/identities', {
          passportIds: identities.filter(identity => identity.type === 'passport').map(identity => identity.id)
        }, withAuthorization(token))
        for (const item of data) {
          const customer = CustomerModel.fromJson(item)

          commit(PROFILE_IDENTITY_LOADED, {
            identity: new ProfileIdentityModel({
              type: 'profile',
              id: item.id
            }),
            customer
          })

          if (customer.ssoUserId != null) {
            commit(PROFILE_IDENTITY_LOADED, {
              identity: new ProfileIdentityModel({
                type: 'passport',
                id: item.ssoUserId
              }),
              customer
            })
          }
        }
      }
    },
    async subscribe (/* { state, commit }, { identity } */) {
      return {}
    },
    async unsubscribe (/* _, { identity, listener } */) {
      // do nothing in basic implementation
    }
  }
})
