import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object().keys({
  id: Joi.number().required(),
  baseAmount: Joi.string().required(),
  baseCurrencySymbol: Joi.string().required(),
  direction: Joi.string().required(),
  makerAddress: Joi.string().required(),
  makerFeeAmount: Joi.string().required(),
  makerFeeSymbol: Joi.string().required(),
  makerOrderHash: Joi.string().required(),
  price: Joi.string().required(),
  quoteAmount: Joi.string().required(),
  quoteCurrencySymbol: Joi.string().required(),
  securityContext: Joi.string().allow([null, '']),
  takerAddress: Joi.string().required(),
  takerFeeAmount: Joi.string().required(),
  takerFeeSymbol: Joi.string().required(),
  takerOrderHash: Joi.string().required(),
  timestamp: Joi.date().required()
})

export default class BoTradesModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new BoTradesModel({
      ...data
    }, { stripUnknown: true })
  }
}
