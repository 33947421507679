import * as partials from 'src/partials'
import {mapGetters} from 'vuex'

export default {
  metaInfo: {
    title: 'History'
  },
  components: {
    ...partials
  },
  computed: {
    ...mapGetters({
      profileSessionId: 'network/profileSessionId'
    })
  }
}
