import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import moment from 'moment'
import _, { debounce } from 'lodash'

export default {
  metaInfo: {
    title: 'Custom Fee'
  },
  components: {
    ...components,
    ...micros
  },
  data () {
    return {
      visibleCount: 10,
      filters: {
        market: null,
        address: null
      }
    }
  },
  async created () {
    await this.init()
    await this.loadCustomFee({
      filters: this.filters,
      itemsPage: {
        activePage: this.loadedPage,
        count: this.count
      }
    })
  },
  computed: {
    ...mapGetters({
      isLoaded: 'customFee/isLoaded',
      isLoading: 'customFee/isLoading',
      activePage: 'customFee/activePage',
      itemsFee: 'customFee/items',
      totalPages: 'customFee/totalPages',
      markets: 'markets/markets',
      getMarketBySymbol: 'markets/getMarketBySymbol'
    }),
    items () {
      return this.itemsFee.map((item) => ({
        ...item,
        market: this.getMarketBySymbol(item.marketSymbol)
      }))
    },
    marketsList () {
      return this.markets.value.map(e => e.symbol)
    },
    loadedPage () {
      return this.$route.query.page || 1
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadCustomFee: 'customFee/loadCustomFee',
      removeCustomFee: 'customFee/removeCustomFee',
      handleToast: 'interaction/toast'
    }),
    async init () {
      this.filters = {
        market: this.$route.query.market || null,
        address: this.$route.query.address || null
      }
    },
    queryParams (value) {
      const params = {
        page: value,
        reset: false,
        market: this.filters.market,
        address: this.filters.address
      }
      return _.pickBy(params, _.identity)
    },
    async handleRefresh () {
      await this.loadCustomFee({
        reset: true
      })
    },
    async handleRemove (item) {
      await this.removeCustomFee({
        id: item.id
      })
      await this.handleToast({
        message: 'Custom Fee Removed'
      })
      await this.handleRefresh()
    },
    async handleActivePage (value) {
      if (this.$route.query.page !== value) {
        await this.loadCustomFee({
          filters: this.filters,
          itemsPage: {
            activePage: value,
            count: this.count
          }
        })
        await this.$router.replace({
          query: this.queryParams(value)
        })
      }
    },
    async handleSymbol (marketSymbol) {
      this.filters.market = marketSymbol
      await this.handleLoad()
    },
    async handleSearch (value) {
      this.filters.address = value
      await this.handleLoad()
    },
    async handleEmpty () {
      this.filters.address = null
      await this.handleLoad()
    },
    handleSearchDebounced: debounce(
      function (value) {
        return value ? this.handleSearch(value) : this.handleEmpty()
      },
      500
    ),
    async handleLoad () {
      await this.loadCustomFee({
        filters: this.filters
      })
      await this.$router.replace({
        query: this.queryParams()
      })
    }
  }
}
