import * as partials from 'src/partials'

export default {
  head: {
    // title: 'Welcome',
    // titleTemplate: '%s | AUDT',
    htmlAttrs: {
      lang: 'en'
    },
    link: [
      { rel: 'icon', href: '/assets/favicon.ico' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap&subset=latin-ext' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Material+Icons' }
    ],
    meta: [
      { charset: 'utf-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
      { name: 'description', content: 'AUDT stablecoin linked to Australian dollar. The stability and trust of traditional currency with the openness and freedom of cryptocurrency.' },
      { name: 'keywords', content: 'Stablecoin,Australian dollar,Cryptocurrency' }
    ],
    script: [
      { src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, defer: true }
    ]
  },
  components: {
    ...partials
  },
  mounted () {
    setImmediate(() => {
      this.initZendesk()
    })
  },
  beforeDestroy () {
    window.zE('webWidget', 'hide')
  },
  methods: {
    initZendesk () {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.id = 'ze-snippet'
      script.async = true
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=8bdb7fc6-9e55-421d-9e87-897c5d1abf93'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }
}
