import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import ExtendedCurrencyModel from './currency/ExtendedCurrencyModel'
import CurrencyDAO from 'src/daos/lib/CurrencyDAO'

export const schemaFactory = () => Joi.object({
  currency: Joi.object().type(ExtendedCurrencyModel).required(),
  dao: Joi.object().type(CurrencyDAO).required()
})

export default class CurrencyDAOModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.currency.key
  }

  static fromCurrencyModel (currency) {
    const dao = new CurrencyDAO({ currency })
    return new CurrencyDAOModel({
      currency,
      dao
    })
  }
}
