import { mapActions } from 'vuex'
import { OperationCanceledError } from 'src/errors'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    message: String,
    submitTitle: String,
    resetTitle: String,
    handler: Function,
    userId: String
  },
  data () {
    return {
      isConfirmLoading: false,
      level: null
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleError: 'interaction/error',
      resetCustomer: 'customers/resetCustomer'
    }),
    async handleSubmit () {
      this.isConfirmLoading = true
      setImmediate(async () => {
        try {
          if (this.handler) {
            await this.resetCustomer({
              userId: this.userId,
              level: this.level
            })
            await this.handler()
          }
          await this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isConfirmLoading = false
        }
      })
    }
  }
}
