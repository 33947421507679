import {mapActions, mapGetters} from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import * as components from 'src/components'
import Editor from '@tinymce/tinymce-vue'
import { DuplicateFilenameUploadError } from 'src/errors'

const config = global.__APP_CONFIG__

export default {
  metaInfo: {
    title: 'Blog Page'
  },
  components: {
    editor: Editor,
    ...components
  },
  async created () {
    await this.loadCategories()
    await this.loadArticles(0, 15)
  },
  watch: {
  },
  data () {
    return {
      isBusy: false,
      upload: false,
      upload2: false,
      activeUpload: '',
      duplicateError: '',
      duplicateError2: '',
      content: '',
      imgBasePath: config.blog.imageUrlPrefix,
      imgTinyPath: '',
      form: {
        title: '',
        content: '',
        preview: {
          description: '',
          image: '',
          imageAlt: ''
        },
        author: {
          avatar: '',
          avatarUrl: '',
          name: '',
          position: ''
        },
        metainfo: {
          url: '',
          categories: '',
          tags: []
        },
        seo: {
          metatags: [],
          ogmetatags: []
        },
        publishDate: null
      },
      tinyOptions: {
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          `undo redo | formatselect | bold italic backcolor |
          alignleft aligncenter alignright alignjustify |
          bullist numlist outdent indent code image imagetools preview | removeformat | help`,
        image_title: true
      }
    }
  },
  validations: {
    form: {
      title: {required},
      preview: {
        description: {required},
        image: {required},
        imageAlt: {required}
      },
      author: {
        avatar: {
          required (value) {
            return value != null
              || (this.form.author.avatarUrl !== '' && this.form.author.avatarUrl != null)
          }
        },
        name: {required},
        position: {required}
      },
      metainfo: {
        url: {required},
        categories: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      categories: 'blog/categories',
      articles: 'blog/articles'
    }),
    titleErrors () {
      if (this.$v.form.title.$dirty) {
        if (!this.$v.form.title.required) {
          return [
            'Title is a required field'
          ]
        }
      }
    },
    descriptionErrors () {
      if (this.$v.form.preview.description.$dirty) {
        if (!this.$v.form.preview.description.required) {
          return [
            'Description is a required field'
          ]
        }
      }
    },
    imageErrors () {
      if (this.$v.form.preview.image.$dirty) {
        if (!this.$v.form.preview.image.required) {
          return 'Image is a required field'
        }
      }
    },
    imageAltErrors () {
      if (this.$v.form.preview.imageAlt.$dirty) {
        if (!this.$v.form.preview.imageAlt.required) {
          return [
            'Image descriptiion is a required field'
          ]
        }
      }
    },
    avatarErrors () {
      if (this.$v.form.author.avatar.$dirty) {
        if (!this.$v.form.author.avatar.required) {
          return 'Avatar is a required field'
        }
      }
    },
    nameErrors () {
      if (this.$v.form.author.name.$dirty) {
        if (!this.$v.form.author.name.required) {
          return ['Avatar is a required field']
        }
      }
    },
    positionErrors () {
      if (this.$v.form.author.position.$dirty) {
        if (!this.$v.form.author.position.required) {
          return ['Avatar is a required field']
        }
      }
    },
    urlErrors () {
      if (this.$v.form.metainfo.url.$dirty) {
        if (!this.$v.form.metainfo.url.required) {
          return ['Url is a required field']
        }
      }
    },
    categoryErrors () {
      if (this.$v.form.metainfo.categories.$dirty) {
        if (!this.$v.form.metainfo.categories.required) {
          return ['Categories is a required field']
        }
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadCategories: 'blog/loadCategories',
      loadArticles: 'blog/loadArticles',
      createArticle: 'blog/createArticle',
      uploadImage: 'blog/uploadImage',
      uploadImgMed: 'media/uploadImage',
      handleToast: 'interaction/toast'
    }),
    categorySelect (item) {
      return this.categories.value.forEach((e) => {
        if (e.name === item) {
          this.form.metainfo.categories = e.id
        }
      })
    },
    addTag (tag) {
      this.form.metainfo.tags.push(tag)
    },
    updateTagField (idx, value) {
      this.form.metainfo.tags.splice(idx, 1, value)
    },
    removeTag (tag) {
      const index = this.form.metainfo.tags.findIndex(pl => pl === tag)
      this.form.metainfo.tags.splice(index, 1)
    },
    addMetaTag (tag) {
      this.form.seo.metatags.push(tag)
    },
    updateMetaTag (tag) {
      const index = this.form.seo.metatags.findIndex(pl => pl.id === tag.id)
      this.form.seo.metatags.splice(index, 1, tag)
    },
    removeMetaTag (id) {
      const index = this.form.seo.metatags.findIndex(pl => pl.id === id)
      this.form.seo.metatags.splice(index, 1)
    },
    addOgMetaTag (tag) {
      this.form.seo.ogmetatags.push(tag)
    },
    updateOgMetaTag (tag) {
      const index = this.form.seo.ogmetatags.findIndex(pl => pl.id === tag.id)
      this.form.seo.ogmetatags.splice(index, 1, tag)
    },
    removeOgMetaTag (id) {
      const index = this.form.seo.ogmetatags.findIndex(pl => pl.id === id)
      this.form.seo.ogmetatags.splice(index, 1)
    },
    metaTagsChange () {
      let tagsArr = []
      this.form.seo.metatags.forEach((e) => {
        tagsArr.push({'name': e.name, 'content': e.content})
      })
      this.form.seo.metatags = tagsArr
    },
    ogMetaTagsChange () {
      let tagsArr = []
      this.form.seo.ogmetatags.forEach((e) => {
        tagsArr.push({'property': e.property, 'content': e.content})
      })
      this.form.seo.ogmetatags = tagsArr
    },
    handleUpload (e) {
      if (!e.target.id) {
        this.activeUpload = e.target.parentElement.id
      } else {
        this.activeUpload = e.target.id
      }
      this.$refs['uploader-input'].click()
    },
    async handleSubmit () {
      setImmediate(async () => {
        try {
          this.isBusy = true
          // this.tagsChange()
          this.metaTagsChange()
          this.ogMetaTagsChange()

          const form = this.form
          if (form.author.avatarUrl !== '' && form.author.avatarUrl != null) {
            form.author.avatar = form.author.avatarUrl
          }

          await this.createArticle({
            form
          })
          this.$v.$reset()
          this.form = {
            title: '',
            content: '',
            preview: {
              description: '',
              image: '',
              imageAlt: ''
            },
            author: {
              avatar: '',
              name: '',
              position: ''
            },
            metainfo: {
              url: '',
              categories: '',
              tags: []
            },
            seo: {
              metatags: [],
              ogmetatags: []
            },
            publishDate: null
          }
          await this.handleToast({
            message: 'Article created'
          })
        } finally {
          this.isBusy = false
        }
      })
    },
    async handleChange (e) {
      setImmediate(async () => {
        try {
          if (this.activeUpload === 'image') {
            this.upload = true
            this.form.preview.image = this.imgBasePath + await this.uploadImage({ file: e.target.files[0] })
          } else if (this.activeUpload === 'avatar') {
            this.upload2 = true
            this.form.author.avatar = this.imgBasePath + await this.uploadImage({ file: e.target.files[0] })
          }
          this.duplicateError = ''
          this.duplicateError2 = ''
        } catch (e) {
          if (e instanceof DuplicateFilenameUploadError) {
            if (this.activeUpload === 'image') {
              this.duplicateError = 'Uploading image name duplicated. Change image name'
            }
            if (this.activeUpload === 'avatar') {
              this.duplicateError2 = 'Uploading image name duplicated. Change image name'
            }
          }
        } finally {
          this.upload = false
          this.upload2 = false
        }
      })
    }
  }
}
