import { PLASMA, BACKEND, PROFILE, PASSPORT, withAuthorization } from 'src/remotes'
import moment from 'moment'
import {
  LoadableModel,
  LoadableListModel,
  MarketStatsItemModel,
  UsersStatsModel,
  ExchangeFinancialStatsModel,
  PlasmaFinancialStatItemModel,
  RewardsSummaryModel
} from 'src/models'
import querystring from 'querystring'
import _ from 'lodash'

export const MARKET_STATS_LOADING = 'stats/market/loading'
export const MARKET_STATS_LOADED = 'stats/market/loaded'
export const USERS_STATS_LOADING = 'stats/users/loading'
export const USERS_STATS_LOADED = 'stats/users/loaded'
export const EXCHANGE_FINANCIAL_STATS_LOADING = 'stats/exchange/financial/loading'
export const EXCHANGE_FINANCIAL_STATS_LOADED = 'stats/exchange/financial/loaded'
export const PLASMA_FINANCIAL_STATS_LOADING = 'stats/plasma/financial/loading'
export const PLASMA_FINANCIAL_STATS_LOADED = 'stats/plasma/financial/loaded'
export const REWARDS_SUMMARY_LOADING = 'stats/rewards/loading'
export const REWARDS_SUMMARY_LOADED = 'stats/rewards/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      marketStats: new LoadableListModel(MarketStatsItemModel),
      usersStats: new LoadableModel(UsersStatsModel),
      exchangeFinancialStats: new LoadableModel(ExchangeFinancialStatsModel),
      plasmaFinancialStats: new LoadableListModel(PlasmaFinancialStatItemModel),
      rewardsSummary: new LoadableModel(RewardsSummaryModel)
    }
  },
  mutations: {
    [MARKET_STATS_LOADING]: (state) => {
      state.marketStats = state.marketStats.loading()
    },
    [MARKET_STATS_LOADED]: (state, { items }) => {
      state.marketStats = state.marketStats.loaded(...items)
    },
    [USERS_STATS_LOADING]: (state) => {
      state.usersStats = state.usersStats.loading()
    },
    [USERS_STATS_LOADED]: (state, { stats }) => {
      state.usersStats = state.usersStats.loaded(stats)
    },
    [EXCHANGE_FINANCIAL_STATS_LOADING]: (state) => {
      state.exchangeFinancialStats = state.exchangeFinancialStats.loading()
    },
    [EXCHANGE_FINANCIAL_STATS_LOADED]: (state, { stats }) => {
      state.exchangeFinancialStats = state.exchangeFinancialStats.loaded(stats)
    },
    [PLASMA_FINANCIAL_STATS_LOADING]: (state) => {
      state.plasmaFinancialStats = state.plasmaFinancialStats.loading()
    },
    [PLASMA_FINANCIAL_STATS_LOADED]: (state, { items }) => {
      state.plasmaFinancialStats = state.plasmaFinancialStats.loaded(...items)
    },
    [REWARDS_SUMMARY_LOADING]: (state) => {
      state.rewardsSummary = state.rewardsSummary.loading()
    },
    [REWARDS_SUMMARY_LOADED]: (state, { data }) => {
      state.rewardsSummary = state.rewardsSummary.loaded(data)
    }
  },
  getters: {
    marketStats: state => state.marketStats,
    usersStats: state => state.usersStats,
    exchangeFinancialStats: state => state.exchangeFinancialStats,
    plasmaFinancialStats: state => state.plasmaFinancialStats,
    rewardsSummary: state => state.rewardsSummary
  },
  actions: {
    async loadMarketStats ({ commit, dispatch }, { from, to }) {
      commit(MARKET_STATS_LOADING)
      const items = await dispatch('fetchMarketStats', { from, to })
      commit(MARKET_STATS_LOADED, { items })
    },
    async fetchMarketStats ({ commit, dispatch }, { from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('statistics/market', withAuthorization(token, {
        params: {
          from: moment(from).toISOString(),
          to: moment(to).toISOString()
        }
      }))
      return data.map(e => MarketStatsItemModel.fromJson(e))
    },
    async loadUsersStats ({ commit, dispatch }, { from, to }) {
      commit(USERS_STATS_LOADING)
      const stats = await dispatch('fetchUsersStats', { from, to })
      commit(USERS_STATS_LOADED, { stats })
    },
    async fetchUsersStats ({ commit, dispatch }, { from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('statistics/users', withAuthorization(token, {
        params: {
          from: from && moment(from).toISOString(),
          to: to && moment(to).toISOString()
        }
      }))
      return UsersStatsModel.fromJson(data)
    },
    async loadFinancialStats ({ commit, dispatch }, { from, to }) {
      commit(EXCHANGE_FINANCIAL_STATS_LOADING)
      const stats = await dispatch('fetchFinancialStats', { from, to })
      commit(EXCHANGE_FINANCIAL_STATS_LOADED, { stats })
    },
    async fetchFinancialStats ({ commit, dispatch }, { from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('statistics/financial', withAuthorization(token, {
        params: {
          from: moment(from).toISOString(),
          to: moment(to).toISOString()
        }
      }))
      return ExchangeFinancialStatsModel.fromJson(data)
    },
    async loadPlasmaFinancialStats ({ commit, dispatch }, { from, to }) {
      commit(PLASMA_FINANCIAL_STATS_LOADING)
      const items = await dispatch('fetchPlasmaFinancialStats', { from, to })
      commit(PLASMA_FINANCIAL_STATS_LOADED, { items })
    },
    async fetchPlasmaFinancialStats ({ commit, dispatch }, { from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('statistics/deposit_withdraw', withAuthorization(token, {
        params: {
          from: moment(from).toISOString(),
          to: moment(to).toISOString()
        }
      }))
      return data.map(e => PlasmaFinancialStatItemModel.fromJson(e))
    },
    async exportMarketStats ({ commit, dispatch }, { from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })

      const request = {
        from: moment(from).toISOString(),
        to: moment(to).toISOString()
      }

      const urlSuffix = '/statistics/market/export/csv'

      const acknowledgeRequest = {
        scope: 'audt.to',
        grants: null,
        method: 'GET',
        baseURL: null,
        path: urlSuffix,
        query: null
      }

      const { data: { ackToken } } = await PASSPORT.post('/api/v1/security/acknowledge', acknowledgeRequest, withAuthorization(token))

      const exportLink = PLASMA.defaults.baseURL + urlSuffix
      const exportParams = querystring.encode({
        ackToken,
        ...request
      })

      return {
        exportLink: `${exportLink}?${exportParams}`
      }
    },
    async exportUserStats ({ commit, dispatch }, { from, to }) {
      const token = await dispatch('passport/requireToken', null, { root: true })

      const rawRequest = {
        from: from && moment(from).toISOString(),
        to: from && moment(to).toISOString()
      }
      const request = _.omitBy(rawRequest, _.isNil)

      const pathname = new URL(PROFILE.defaults.baseURL).pathname
      const urlSuffix = '/statistics/users/csv'

      const acknowledgeRequest = {
        scope: 'audt.to',
        grants: null,
        method: 'GET',
        baseURL: null,
        path: pathname + urlSuffix,
        query: request
      }

      const { data: { ackToken } } = await PASSPORT.post('/api/v1/security/acknowledge', acknowledgeRequest, withAuthorization(token))

      const exportLink = PROFILE.defaults.baseURL + urlSuffix
      const exportParams = querystring.encode({
        ackToken,
        ...request
      })

      return {
        exportLink: `${exportLink}?${exportParams}`
      }
    },
    async loadRewardsSummary ({ commit, dispatch }, { from, to }) {
      commit(REWARDS_SUMMARY_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('admin/rewards/statistics', withAuthorization(token, {
        params: {
          from: moment(from).toISOString(),
          till: moment(to).toISOString()
        }
      }))
      commit(REWARDS_SUMMARY_LOADED, { data: RewardsSummaryModel.fromJson(data) })
    }
  }
})
