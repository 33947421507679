import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import MonoovaAccountModel from './MonoovaAccountModel'

const schemaFactory = () => ({
  items: Joi.array().items(Joi.object().type(MonoovaAccountModel)).optional(),
  count: Joi.number().default(0),
  offset: Joi.number().default(0),
  limit: Joi.number().default(0)
})

export default class GetMonoovaAccountsResponse extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new GetMonoovaAccountsResponse({
      items: data.items.map(MonoovaAccountModel.fromJson),
      offset: data.offset,
      limit: data.limit,
      count: data.count
    })
  }
}
