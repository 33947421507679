import { LoadableListModel, UserActivityModel } from 'src/models'
import { PROFILE, withAuthorization } from 'src/remotes'

export const ACTIVITY_LOADING = 'activity/loading'
export const ACTIVITY_LOADED = 'activity/loaded'
export const CLEAR_ACTIVITY = 'activity/clear'

export default () => ({
  namespaced: true,
  state () {
    return {
      limit: 30, // per page
      page: 0,
      totalPages: 0,
      items: new LoadableListModel(UserActivityModel)
    }
  },
  mutations: {
    [ACTIVITY_LOADING]: (state) => {
      state.items = state.items.loading()
    },
    [ACTIVITY_LOADED]: (state, { items, page, totalPages }) => {
      state.items = state.items.loaded(...items)
      state.page = page
      state.totalPages = totalPages
    },
    [CLEAR_ACTIVITY]: (state) => {
      state.limit = 30
      state.page = 0
      state.totalPages = 0
      state.items = new LoadableListModel(UserActivityModel)
    }
  },
  getters: {
    items: state => state.items,
    page: state => state.page,
    totalPages: state => state.totalPages
  },
  actions: {
    async loadUserActivity ({ state, commit, dispatch }, { userId, page, from, to }) {
      commit(ACTIVITY_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('manager/activity', withAuthorization(token, {
        params: {
          userId,
          from,
          to,
          limit: state.limit,
          page
        }
      }))
      commit(ACTIVITY_LOADED, {
        items: data.items.map(UserActivityModel.fromJson),
        page: data.page,
        totalPages: data.totalPages
      })
    },
    async clearActivity ({ commit }) {
      commit(CLEAR_ACTIVITY)
    }
  }
})
