/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-google': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a10 10 0 100 20 10 10 0 000-20zm4.088 14.538A5.7 5.7 0 0112.112 18 6.008 6.008 0 016.75 9.3 6.066 6.066 0 0112.112 6a5.7 5.7 0 014.013 1.575L14.4 9.3a3.2 3.2 0 00-2.288-.9 3.594 3.594 0 00-3.375 2.475A3.54 3.54 0 008.55 12c.007.382.07.761.187 1.125a3.594 3.594 0 003.375 2.475 3.482 3.482 0 002.025-.562 2.742 2.742 0 001.2-1.8h-3.225v-2.326h5.662c.076.395.114.797.113 1.2a5.92 5.92 0 01-1.799 4.426z"/>'
  }
})
