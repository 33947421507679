import { mapActions } from 'vuex'
import { ToastStack, ModalStack } from 'src/components'
import BigNumber from 'bignumber.js'

// for Almost never return exponential notation:
BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const config = global.__APP_CONFIG__

export default {
  name: 'app',
  components: {
    ModalStack,
    ToastStack
  },
  async mounted () {
    this.initializeGooglePlaces()
    await Promise.all([
      // this.initAbis(),
      this.initNetwork(),
      this.loadProfileRegistry()
    ])
    await this.loadCurrencies()
    await this.loadFiatCurrencies()
    await this.loadMarkets()
    await this.loadTokens()
  },
  methods: {
    ...mapActions({
      initAbis: 'abis/initialize',
      initNetwork: 'network/initialize',
      loadProfileRegistry: 'profileRegistry/loadRegistry',
      loadCurrencies: 'currencies/loadCurrencies',
      loadFiatCurrencies: 'currencies/loadFiatCurrencies',
      loadMarkets: 'markets/loadMarkets',
      loadTokens: 'tokens/loadTokens'
    }),
    initializeGooglePlaces () {
      if (config.googlePlaces && config.googlePlaces.enabled) {
        const googleConfig = config.googlePlaces
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleConfig.apiKey}&libraries=places`
        document.body.appendChild(script)
      }
    }
  }
}
