import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  computed: {
    ...mapState({
      table: state => state.externalNotifications.table,
      availableNotifications: state => state.externalNotifications.availablesAdmin,
      availableProviders: state => state.externalNotifications.availableProviders,
      isLoading: state => state.externalNotifications.loading,
      isLoaded: state => state.externalNotifications.loaded
    }),
    notifications () {
      const values = this.isLoaded ? this.table : {}

      return Object.entries(this.availableNotifications).map(([key, passportName]) => {
        const providers = this.availableProviders.map(provider => ({
          provider,
          value: _.get(values, `${passportName}.${provider}.value.value`, false),
          isLoading: _.get(values, `${passportName}.${provider}.isLoading`, this.isLoading),
          isLoaded: _.get(values, `${passportName}.${provider}.isLoaded`, this.isLoaded)
        }))

        return {
          notification: {
            key, // key for public title
            passportName
          },
          providers
        }
      })
    }
  },
  async created () {
    await this.loadNotifications()
  },
  methods: {
    ...mapActions({
      toggleNotification: 'externalNotifications/toggleNotification',
      loadNotifications: 'externalNotifications/loadNotifications'
    }),
    async handleToggle (item, provider) {
      await this.toggleNotification({
        name: item.notification.passportName,
        provider: provider.provider,
        value: !provider.value
      })
    }
  }
}
