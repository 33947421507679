/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credentials-email': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 4H4a2 2 0 00-1.99 2L2 18a2.006 2.006 0 002 2h16a2.006 2.006 0 002-2V6a2.006 2.006 0 00-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" _fill="#000"/>'
  }
})
