import { AbstractModel } from 'src/models/core'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'

export default class PluginModel extends AbstractModel {
  static prepareCommission (commission) {
    if (!commission) {
      return null
    }
    switch (commission.type) {
      case 'FixedCommission':
        return FixedCommissionModel.fromJson(commission)
      case 'PercentCommission':
        return PercentCommissionModel.fromJson(commission)
      case 'DefaultCommission':
        return DefaultCommissionModel.fromJson(commission)
      default:
        return null
    }
  }
}
