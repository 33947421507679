import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  name: Joi.string().required(),
  icon: Joi.string().required(),
  background: Joi.string().required(),
  fiatSymbol: Joi.string().allow([null, '']),
  displayDecimals: Joi.number().min(1).required(),
  withdrawalFee: Joi.string().required(),
  crypto: Joi.boolean().default(false),
  depositEnabled: Joi.boolean().default(false),
  withdrawalEnabled: Joi.boolean().default(false),
  transferEnabled: Joi.boolean().default(false),
  buyEnabled: Joi.boolean().default(false),
  purchaseEnabled: Joi.boolean().default(false),
  redeemEnabled: Joi.boolean().default(false),
  active: Joi.boolean().default(false),
  showOnLanding: Joi.boolean(),
  sortOrder: Joi.number().required(),
  purchaseCommissions: Joi.array().items(Joi.object().keys({
    min: Joi.string().required(),
    max: Joi.string().required(),
    percent: Joi.string().required(),
    type: Joi.string().required()
  }))
})

export default class UpdateCurrencyRequest extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  static fromJs (data) {
    return new UpdateCurrencyRequest(data, { stripUnknown: true })
  }
}
