import { mapActions } from 'vuex'

const RELAY_CASHES = [
  'tradingVolumeCache',
  'addressStatisticsCache',
  'customFee',
  'orderByHashCache',
  'orderbookCache',
  'rawOrderbookCache',
  'groupOrderbookCache',
  'candlesCache',
  'marketsCache',
  'marketsListCache',
  'checkNonce',
  'currencyCache',
  'currenciesListCache',
  'tickersCache',
  'tickers24Cache',
  'tradesCache',
  'balanceModelCache',
  'accountLimitsCache',
  'publicAccountLimitsCache'
]

export default {
  metaInfo: {
    title: 'Caches'
  },
  data () {
    return {
      selectedExchangeCashes: 'CURRENCY',
      selectedRelayCashes: '*',
      exchangeBackendCashes: [
        'FIAT',
        'CURRENCY'
      ],
      relayBackendCashes: [
        '*',
        ...RELAY_CASHES
      ],
      errorMessage: '',
      dropExchangeBackendCashes: false,
      dropExchangeBackendCashesFailed: false,
      dropProfileCashes: false,
      dropProfileCashesFailed: false,
      dropRelayCashes: false,
      dropRelayCashesFailed: false,
      dropPublicCashes: false,
      dropPublicCashesFailed: false,
      dropPassportCashes: false,
      dropPassportCashesFailed: false,
      dropCfSuccess: false,
      dropCfFailed: false
    }
  },
  methods: {
    ...mapActions({
      clearExchangeBackendCashes: 'system/clearExchangeBackendCashes',
      clearProfileCashes: 'system/clearProfileCashes',
      dropCfCache: 'system/dropCfCache',
      clearPublicCashes: 'system/clearPublicCashes',
      clearPassportCashes: 'system/clearPassportCashes',
      clearRelayBackendCashes: 'system/clearRelayBackendCashes'
    }),
    getExchangeBackendCashes (item) {
      this.selectedExchangeCashes = item
    },
    getRelayBackendCashes (item) {
      this.selectedRelayCashes = item
    },
    async handleDropExchangeBackendCashes () {
      try {
        await this.clearExchangeBackendCashes({
          type: this.selectedExchangeCashes
        })
        this.dropExchangeBackendCashes = true
        setTimeout(() => {
          this.dropExchangeBackendCashes = false
        }, 5000)
      } catch (e) {
        this.dropExchangeBackendCashesFailed = false
        setTimeout(() => {
          this.dropExchangeBackendCashesFailed = false
        }, 5000)
      }
    },
    async handleDropProfile () {
      try {
        await this.clearProfileCashes()
        this.dropProfileCashes = true
        setTimeout(() => {
          this.dropProfileCashes = false
        }, 5000)
      } catch (e) {
        this.dropProfileCashesFailed = true
        setTimeout(() => {
          this.dropProfileCashesFailed = false
        }, 5000)
      }
    },
    async handleDropPublic () {
      try {
        await this.clearPublicCashes()
        this.dropPublicCashes = true
        setTimeout(() => {
          this.dropPublicCashes = false
        }, 5000)
      } catch (e) {
        this.dropPublicCashesFailed = true
        setTimeout(() => {
          this.dropPublicCashesFailed = false
        }, 5000)
      }
    },
    async handleDropPassport () {
      try {
        await this.clearPassportCashes()
        this.dropPassportCashes = true
        setTimeout(() => {
          this.dropPassportCashes = false
        }, 5000)
      } catch (e) {
        this.dropPassportCashesFailed = true
        setTimeout(() => {
          this.dropPassportCashesFailed = false
        }, 5000)
      }
    },
    async handleDropRelayBackendCashes () {
      try {
        const caches = this.selectedRelayCashes === '*' ? RELAY_CASHES : this.selectedRelayCashes
        await this.clearRelayBackendCashes({
          caches
        })
        this.dropRelayBackendCashes = true
        setTimeout(() => {
          this.dropRelayBackendCashes = false
        }, 5000)
      } catch (e) {
        this.dropRelayBackendCashesFailed = false
        setTimeout(() => {
          this.dropRelayBackendCashesFailed = false
        }, 5000)
      }
    },
    async handleDropCf () {
      try {
        await this.dropCfCache()
        this.dropCfSuccess = true
        setTimeout(() => {
          this.dropCfSuccess = false
        }, 5000)
      } catch (e) {
        this.dropCfFailed = true
        setTimeout(() => {
          this.dropCfFailed = false
        }, 5000)
      }
    }
  }
}
