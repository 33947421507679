export const INITIALIZE = 'initialize'
export const UPDATE_LOCALE = 'update/locale'

export default () => ({
  namespaced: true,
  state () {
    return {
      ref: Object.freeze({
        i18n: null
      }),
      localeDefault: null,
      localeOptions: [
        { key: 'en', title: 'Eng' },
        { key: 'ru', title: 'Rus' }
      ]
    }
  },
  getters: {
    localeOptions (state) {
      return state.localeOptions
    },
    localeDefault (state) {
      return state.localeDefault
    },
    locale (state) {
      return !state.ref.i18n
        ? state.localeOptions[0]
        : (state.localeOptions.find(l => l.key === state.ref.i18n.locale) || state.localeOptions[0])
    },
    i18n (state) {
      return state.ref.i18n
    }
  },
  mutations: {
    [INITIALIZE] (state, i18n) {
      state.ref = Object.freeze({
        i18n
      })
    },
    [UPDATE_LOCALE] (state, locale) {
      state.ref.i18n.locale = locale
      state.localeDefault = locale
    }
  },
  actions: {
    async initialize ({ state, commit }, i18n) {
      commit(INITIALIZE, i18n)
      commit(UPDATE_LOCALE, state.localeDefault || 'en')
    },
    async selectLocale ({ commit }, locale) {
      commit(UPDATE_LOCALE, locale)
    },
    async resetLocale ({ state, commit }) {
      commit(UPDATE_LOCALE, state.localeDefault)
    }
  }
})
