import { PassportClient } from '@chronobank/passport.client'
import SocketClient from './lib/SocketClient'
import Web3Client from './lib/Web3Client'

function wsFromHttp (urlString) {
  const url = urlString.indexOf('//') === 0
    ? new URL(`${document.location.protocol}${urlString}`)
    : new URL(urlString)
  return `${url.protocol === 'https:' ? 'wss:' : 'ws:'}//${url.host}`
}

export const socketClientFactory = config => {
  return new SocketClient({
    url: wsFromHttp(config.backendService.endpoint.baseURL)
  }).start()
}

export const plasmaSocketClientFactory = config => {
  return new SocketClient({
    url: config.plasmaService.socket
  }).start()
}

export const exchangeSocketClientFactory = config => {
  return new SocketClient({
    // eslint-disable-next-line no-undef
    url: wsFromHttp(config.backendService.endpoint.baseURL)
  }).start()
}

export const profileSocketClientFactory = config => {
  return new SocketClient({
    // eslint-disable-next-line no-undef
    url: wsFromHttp(config.profileService.endpoint.baseURL)
  }).start()
}

export const ratesSocketClientFactory = config => {
  return new SocketClient({
    // eslint-disable-next-line no-undef
    url: config.ratesService.socket
  }).start()
}

export const passportClientFactory = config => {
  return new PassportClient(config.passportService.endpoint)
}

export const trezorDeviceFactory = config => {
  const TrezorDevice = require('./lib/TrezorDevice').default
  return new TrezorDevice(config.trezorDevice.manifest)
}

export const ledgerDeviceFactory = () => {
  const LedgerDevice = require('./lib/LedgerDevice').default
  return new LedgerDevice()
}

export const memoryDeviceFactory = () => {
  const MemoryDevice = require('./lib/MemoryDevice').default
  return new MemoryDevice()
}

export const metamaskPluginFactory = () => {
  const MetamaskPlugin = require('./lib/MetamaskPlugin').default
  return new MetamaskPlugin()
}

export const serverPluginFactory = () => {
  const ServerPlugin = require('./lib/ServerPlugin').default
  return new ServerPlugin()
}

export const web3ClientFactory = (config) => {
  const Web3 = require('web3')
  const provider = new Web3.providers.HttpProvider(config.blockchain.rpc)
  const web3 = new Web3(provider)
  return new Web3Client(web3, config.blockchain).start()
}

export class Registry {
  constructor () {
    this.services = {}
    this.devices = {}
    this.plugins = {}
  }

  registerService (name, service) {
    this.services[name] = service
    return this
  }

  registerDevice (name, device) {
    this.devices[name] = device
    return this
  }

  registerPlugin (name, plugin) {
    this.plugins[name] = plugin
    return this
  }

  getService (name) {
    return this.services[name]
  }

  getDevice (name) {
    return this.devices[name]
  }

  getPlugin (name) {
    return this.plugins[name]
  }

  getAllServices () {
    return this.services
  }

  getAllDevices () {
    return this.devices
  }

  getAllPlugins () {
    return this.plugins
  }
}
export const REGISTRY = new Registry()
