import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isBusy: false,
      isUploadBusy: false,
      isPasswordVisible: false,
      form: {
        name: '',
        filename: '',
        v3: null
      }
    }
  },
  validations: {
    form: {
      name: { required },
      password: { required },
      v3: {
        required,
        valid () {
          return this.form.v3 == null
            ? false
            : (this.form.v3.encrypted != null)
        }
      }
    }
  },
  computed: {
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    passwordErrors () {
      if (this.$v.form.password.$dirty) {
        if (!this.$v.form.password.required) {
          return [
            'Password is a required field'
          ]
        }
      }
      return []
    },
    v3Errors () {
      if (this.$v.form.v3.$dirty) {
        if (!this.$v.form.v3.required) {
          return [
            'Wallet File is a required field'
          ]
        }
        if (!this.$v.form.v3.valid) {
          return [
            'Cannot read wallet file'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createWallet: 'wallets/createWallet',
      decryptV3: 'wallets/decryptV3',
      uploadV3: 'wallets/uploadV3'
    }),
    handleSubmit () {
      if (this.isBusy) {
        return
      }
      this.isBusy = true
      setImmediate(async () => {
        try {
          const { privateKey } = await this.decryptV3({
            encrypted: this.form.v3.encrypted,
            password: this.form.password
          })
          await this.createWallet({
            type: 'MEMORY',
            name: this.form.name,
            options: {
              password: this.form.password,
              privateKey
            }
          })
          await this.handleToast({
            message: 'Wallet added'
          })
          await this.handleClose()
        } catch (e) {
          await this.handleError({
            message: 'Cannot add wallet',
            error: e
          })
        } finally {
          this.isBusy = false
        }
      })
    },
    handleBrowse () {
      this.$refs.uploader.openExplorer()
    },
    async handleUpload (files) {
      if (files == null || files.length < 1) {
        this.form.v3 = null
        this.$v.form.v3.$touch()
      } else {
        this.isUploadBusy = true
        setImmediate(async () => {
          try {
            const { encrypted } = await this.uploadV3({ file: files[0] })
            this.form.v3 = {
              encrypted,
              filename: files[0].name
            }
          } catch (e) {
            this.form.v3 = {
              encrypted: null,
              filename: files[0].name
            }
          } finally {
            this.$v.form.v3.$touch()
            this.isUploadBusy = false
          }
        })
      }
    }
  }
}
