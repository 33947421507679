import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  allow: Joi.boolean()
})

export default class AddressOperationModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new AddressOperationModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
