import moment from 'moment'
import { mapGetters } from 'vuex'
import * as components from 'src/components'
import { ProfileIdentityModel } from 'src/models'

export default {
  components: {
    ...components
  },
  props: {
    deposits: Array,
    isMobile: Boolean
  },
  computed: {
    ...mapGetters({
      getFiatCurrencyById: 'currencies/getFiatCurrencyById',
      hasDepositsActionAccess: 'grants/hasDepositsActionAccess'
    }),
    items () {
      return this.deposits.map((deposit, index) => ({
        index,
        address: deposit.address,
        purchase: deposit,
        moment: moment(deposit.createdAt),
        identities: (deposit.identities || []).map(
          id => new ProfileIdentityModel({
            type: 'passport',
            id
          })
        )
      }))
    }
  },
  watch: {
    purchases: {
      immediate: true,
      handler () {
        this.$emit('refreshWallets')
      }
    }
  },
  methods: {
    moment,
    getFiatCurrencyTitle (item) {
      const purchase = item.purchase
      if (!purchase.fiatCurrencyId) {
        return null
      }
      const fiatCurrency = this.getFiatCurrencyById(purchase.fiatCurrencyId)
      if (!fiatCurrency) {
        return null
      }
      return fiatCurrency
    }
  }
}
