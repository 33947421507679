import { render, staticRenderFns } from "./TwoFaSection.htm?vue&type=template&id=ef906750&scoped=true&external"
import script from "./TwoFaSection.js?vue&type=script&lang=js&external"
export * from "./TwoFaSection.js?vue&type=script&lang=js&external"
import style0 from "./TwoFaSection.scss?vue&type=style&index=0&id=ef906750&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef906750",
  null
  
)

export default component.exports