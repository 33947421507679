import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    depositAddresses: Array,
    isMobile: Boolean
  },
  methods: {
    ...mapActions({
      refreshDepositAddress: 'manageDepositsAddresses/refreshDepositAddress',
      removeDepositAddress: 'manageDepositsAddresses/removeDepositAddress',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast',
      handleClose: 'modals/close'
    }),
    async handleRefresh (id) {
      await this.refreshDepositAddress({id})
      this.$emit('update')
    },
    async handleRemove (id) {
      await this.handleConfirmation({
        handler: async () => {
          await this.removeDepositAddress({id})
          this.handleClose()
          await this.handleToast({
            message: 'Deposit Address removed'
          })
          this.$emit('update')
        }
      })
    }
  }
}
