import { mapState, mapGetters, mapActions } from 'vuex'
import * as partials from 'src/partials'

export default {
  metaInfo: {
    title: 'Profile Details'
  },
  components: {
    ...partials
  },
  computed: {
    ...mapState({
      profileId: state => state.route.params.id
    }),
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData'
    }),
    profileData () {
      return this.getProfileData(this.profileId)
    }
  },
  mounted () {
    this.loadProfileData({ id: this.profileId })
  },
  methods: {
    ...mapActions({
      loadProfileData: 'customers/loadKYCProfileData'
    })
  }
}
