import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    type: String,
    message: String,
    closeTitle: String
  },
  computed: {
    buttonColor () {
      switch (this.type || 'INFO') {
        case 'ERROR':
          return 'error'
        case 'WARNING':
          return 'warning'
        case 'INFO':
        default:
          return 'primary'
      }
    }
  },
  methods: {
    ...mapActions({
      closeToast: 'toasts/close'
    }),
    handleClose () {
      this.$emit('close')
    }
  }
}
