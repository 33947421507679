import { mapActions, mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: 'Monitoring'
  },
  data () {
    return {
      queuesStatsResponse: '',
      isQueuesStats: false,
      isQueuesStatsFailed: false,
      accountAddress: null,
      accountsFlushResponse: '',
      isAccountsFlush: false,
      isAccountsFlushFailed: false,
      isAccountsStats: false,
      isAccountsStatsFailed: false,
      accountsStatsResponse: '',
      isLastOperation: false,
      isLastOperationFailed: false,
      lastOperationResponse: '',
      operationUuid: null,
      operationUuidResponse: '',
      isOperationUuid: false,
      isOperationUuidFailed: false,
      perfomanceMetricsData: ''
    }
  },
  computed: {
    ...mapGetters({
      queuesStats: 'system/queuesStats',
      accountsStats: 'system/accountsStats',
      lastOperation: 'system/lastOperation',
      operationDetails: 'system/operationDetails'
    })
  },
  methods: {
    ...mapActions({
      getQueuesStats: 'system/getQueuesStats',
      accountsFlush: 'system/accountsFlush',
      getAccountsStats: 'system/getAccountsStats',
      getLastOperation: 'system/getLastOperation',
      getOperationDetails: 'system/getOperationDetails',
      startPerformanceMonitoring: 'system/startPerformanceMonitoring',
      stopPerformanceMonitoring: 'system/stopPerformanceMonitoring',
      loadPerformanceMetrics: 'system/loadPerformanceMetrics'
    }),
    async handleGetQueuesStats () {
      try {
        await this.getQueuesStats()
        this.queuesStatsResponse = JSON.stringify(this.queuesStats, undefined, 3)
        this.isQueuesStats = true
        setTimeout(() => {
          this.isQueuesStats = false
        }, 5000)
      } catch (e) {
        this.isQueuesStatsFailed = true
        setTimeout(() => {
          this.isQueuesStatsFailed = false
        }, 5000)
      }
    },
    async handleAccountsFlush () {
      try {
        await this.accountsFlush({
          address: this.accountAddress
        })
        this.accountsFlushResponse = ''
        this.isAccountsFlushFailed = false
        this.isAccountsFlush = true
        setTimeout(() => {
          this.isAccountsFlush = false
        }, 5000)
      } catch (e) {
        this.accountsFlushResponse = JSON.stringify(e.response.data.error, undefined, 3)
        this.isAccountsFlushFailed = true
        setTimeout(() => {
          this.isAccountsFlushFailed = false
        }, 5000)
      }
    },
    async handleGetAccountsStats () {
      try {
        await this.getAccountsStats()
        this.accountsStatsResponse = JSON.stringify(this.accountsStats, undefined, 3)
        this.isAccountsStats = true
        setTimeout(() => {
          this.isAccountsStats = false
        }, 5000)
      } catch (e) {
        this.accountsStatsResponse = JSON.stringify(e.response.data.error, undefined, 3)
        this.isAccountsStatsFailed = true
        setTimeout(() => {
          this.isAccountsStatsFailed = false
        }, 5000)
      }
    },
    async handleGetLastOperation () {
      try {
        await this.getLastOperation()
        this.lastOperationResponse = JSON.stringify(this.lastOperation, undefined, 3)
        this.isLastOperation = true
        setTimeout(() => {
          this.isLastOperation = false
        }, 5000)
      } catch (e) {
        this.lastOperationResponse = JSON.stringify(e.response.data.error, undefined, 3)
        this.isLastOperationFailed = true
        setTimeout(() => {
          this.isLastOperationFailed = false
        }, 5000)
      }
    },
    async handleGetOperationDetails () {
      try {
        await this.getOperationDetails({
          uuid: this.operationUuid
        })
        this.operationUuidResponse = JSON.stringify(this.operationDetails, undefined, 3)
        this.isOperationUuid = true
        setTimeout(() => {
          this.isOperationUuid = false
        }, 5000)
      } catch (e) {
        this.operationUuidResponse = JSON.stringify(e.response.data.error, undefined, 3)
        this.isOperationUuidFailed = true
        setTimeout(() => {
          this.isOperationUuidFailed = false
        }, 5000)
      }
    },
    async handleStartPerformanceMonitoring () {
      await this.startPerformanceMonitoring()
    },
    async handleStopPerformanceMonitoring () {
      await this.stopPerformanceMonitoring()
    },
    async handleLoadPerformanceMetrics () {
      const data = await this.loadPerformanceMetrics()
      this.perfomanceMetricsData = JSON.stringify(data, undefined, 3)
    }
  }
}
