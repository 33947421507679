var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-find-customer-partial"},[_c('div',{staticClass:"find-customer-partial"},[_c('div',{staticClass:"head"},[_c('v-layout',{staticClass:"mx-n2 my-2",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md3":"","sm6":"","xs12":""}},[_c('div',{staticClass:"pa-2"},[_c('v-select',{attrs:{"items":_vm.searchOptions,"color":"info","append-icon":"keyboard_arrow_down","item-value":"key","item-text":"value","label":"Type of Search","filled":"","rounded":"","hide-details":""},on:{"change":_vm.chooseType},model:{value:(_vm.typeOfSearch),callback:function ($$v) {_vm.typeOfSearch=$$v},expression:"typeOfSearch"}})],1)]),_c('v-flex',{attrs:{"md9":"","sm9":"","xs12":""}},[_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"prepend-inner-icon":"search","label":"Search","filled":"","rounded":"","clearable":"","hide-details":"","color":"info"},on:{"click:clear":_vm.handleClear,"input":_vm.handleSearchDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1)],1),(_vm.customers.length > 0)?_c('div',{staticClass:"body"},[(!_vm.isMobile)?_c('div',{staticClass:"table"},[_vm._m(0),_c('div',{ref:"container",staticClass:"table-body"},_vm._l((_vm.customers),function(item,index){return _c('div',{key:index,staticClass:"table-row",on:{"click":function($event){return _vm.toCustomer(item)}}},[_c('div',{staticClass:"table-cell cell-name"},[_c('div',{staticClass:"inner"},[_c('atom-customer',{attrs:{"customer":item}})],1)]),_c('div',{staticClass:"table-cell cell-email"},[_c('div',{staticClass:"inner"},[(item.email)?_c('a',{class:{
                    'value-pending': item.isLevel2Pending,
                    'value-rejected': item.isLevel2Rejected,
                    'value-verified': item.isLevel2Valid,
                  },attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))]):_c('span',[_vm._v("—")])])]),_c('div',{staticClass:"table-cell cell-phone"},[_c('div',{staticClass:"inner"},[(item.phone)?_c('span',{class:{
                    'value-pending': item.isLevel2Pending,
                    'value-rejected': item.isLevel2Rejected,
                    'value-verified': item.isLevel2Valid,
                  }},[_vm._v(_vm._s(item.phone))]):_c('span',[_vm._v("—")])])])])}),0)]):_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-body"},_vm._l((_vm.customers),function(item,index){return _c('mobile-table-row',{key:index},[_c('div',{attrs:{"slot":"center"},slot:"center"},[_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"inner d-flex"},[_c('b',[_vm._v("Name:")]),_c('atom-customer',{attrs:{"customer":item}})],1)]),_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"inner"},[_c('b',[_vm._v("Email:")]),(item.email)?_c('a',{class:{
                    'value-pending': item.isLevel2Pending,
                    'value-rejected': item.isLevel2Rejected,
                    'value-verified': item.isLevel2Valid,
                  },attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))]):_c('span',[_vm._v("—")])])]),_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"inner"},[_c('b',[_vm._v("Phone:")]),(item.phone)?_c('span',{class:{
                      'value-pending': item.isLevel2Pending,
                      'value-rejected': item.isLevel2Rejected,
                      'value-verified': item.isLevel2Valid,
                    }},[_vm._v(_vm._s(item.phone))]):_c('span',[_vm._v("—")])])])]),_c('div',{attrs:{"slot":"right"},slot:"right"},[_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"inner"},[_c('v-btn',{staticClass:"btn-transparent",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.toCustomer(item)}}},[_vm._v("Details")])],1)])])])}),1)])]):_vm._e(),(!_vm.isLoading && _vm.isLoaded && _vm.customers.length === 0)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"no-data"},[_vm._v("\n      No data\n    ")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-head"},[_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell cell-name"},[_c('div',{staticClass:"inner"},[_vm._v("Name")])]),_c('div',{staticClass:"table-cell cell-email"},[_c('div',{staticClass:"inner"},[_vm._v("Email")])]),_c('div',{staticClass:"table-cell cell-phone"},[_c('div',{staticClass:"inner"},[_vm._v("Phone")])])])])}]

export { render, staticRenderFns }