import { mapValues } from 'lodash'
import { PluginStatus } from 'src/models'

import { REGISTRY } from 'src/services'
import CheckDeviceModal from 'src/modals/lib/CheckDeviceModal/CheckDeviceModal.vue'

const INIT = 'init'
const PLUGIN_STATUS = 'plugin/status'

export default () => ({
  namespaced: true,
  state () {
    return {
      statuses: []
    }
  },
  mutations: {
    [INIT] (state) {
      state.statuses = mapValues(REGISTRY.getAllPlugins(), (plugin, name) => new PluginStatus({ name, plugin }))
    },
    [PLUGIN_STATUS] (state, { name, ...props }) {
      const status = state.statuses[name]
      if (status) {
        state.statuses = {
          ...state.statuses,
          [name]: status.update(props)
        }
      }
    }
  },
  getters: {
    getPluginStatus: (state) => (name) => state.statuses[name] || new PluginStatus({ name, isActive: false })
  },
  actions: {
    async init ({ state, commit, dispatch }) {
      commit(INIT, REGISTRY.getAllPlugins())
      Object.values(state.statuses).map(status =>
        status.plugin
          .on('connected', () => commit(PLUGIN_STATUS, { name: status.name, isActive: true }))
          .on('disconnected', () => commit(PLUGIN_STATUS, { name: status.name, isActive: false }))
          .on('prompt', ({ title, message, plugin }) => dispatch('prompt', { title, message, plugin }))
      )
    },
    async prompt ({ dispatch, getters }, { title, message, plugin }) {
      dispatch('modals/open', {
        factory: () => CheckDeviceModal,
        data: {
          title,
          message,
          plugin: getters.getPluginStatus(plugin)
        }
      }, { root: true })
    }
  }
})
