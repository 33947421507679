import uniqid from 'uniqid'

export const TOASTS_OPEN = 'toasts/open'
export const TOASTS_CLOSE = 'toasts/close'

export default () => ({
  namespaced: true,
  state () {
    return {
      stack: []
    }
  },
  mutations: {
    [TOASTS_OPEN]: (state, { id = uniqid(), ...other }) => {
      const stack = state.stack.filter(entry => entry.id !== id)
      stack.push({
        id,
        toast: { id, ...other }
      })
      state.stack = stack
    },
    [TOASTS_CLOSE]: (state, { id }) => {
      // eslint-disable-next-line
      console.log('Close', { id })
      state.stack = state.stack.filter(entry => entry.id !== id)
    }
  },
  actions: {
    open ({ commit }, toast) {
      commit(TOASTS_OPEN, toast)
    },
    close ({ commit }, { id }) {
      commit(TOASTS_CLOSE, { id })
    }
  }
})
