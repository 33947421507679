import { mapGetters } from 'vuex'

export default {
  props: {
    from: String,
    to: String
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      getRate: 'fiatRates/rate'
    }),
    rate () {
      return this.getRate(this.from, this.to)
    }
  }
}
