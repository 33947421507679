import { mapGetters, mapActions } from 'vuex'

import VerificationRapid from './VerificationRapid/VerificationRapid.vue'
import VerificationOnTimex from './VerificationOnTimex/VerificationOnTimex.vue'

export default {
  components: {
    VerificationRapid,
    VerificationOnTimex
  },
  async created () {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
  },
  methods: {
    ...mapActions({
    }),
    handleTxFlow () {
      this.$emit('txFlow')
    }
  }
}
