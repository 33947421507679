import axios from 'axios'
import { omitBy, isNil } from 'lodash'

// eslint-disable-next-line no-undef
const config = global.__APP_CONFIG__

export const withAuthorization = (authorization, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${authorization}`
  }
})

export const withAuthorizationAndVerification = (authorization, verification, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    ...omitBy({
      'authorization': authorization == null ? null : `Bearer ${authorization}`,
      'x-passport-otp': verification == null ? null : `APP ${verification}`
    }, isNil)
  }
})
export const BACKEND = axios.create(config.backendService.endpoint)
export const PLASMA = axios.create(config.plasmaService.endpoint)
export const PLASMA_RW = axios.create(config.plasmaService.rw.endpoint)
export const PASSPORT = axios.create(config.passportService.endpoint)
export const PROFILE = axios.create(config.profileService.endpoint)
export const SIGNER = axios.create(config.walletService.endpoint)
export const RATES = axios.create(config.ratesService.endpoint)
export const PUBLIC_BACKEND = axios.create(config.publicService.endpoint)

export const TYPES = config.depsoitAddressess.types
