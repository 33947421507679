import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as components from 'src/components'
import CodePartial from '../../../partials/private/CodePartial/CodePartial.vue'

export default {
  components: {
    ...components,
    CodePartial
  },
  props: {
    onComplete: Function,
    onCancel: Function
  },
  data () {
    return {
      isBusy: false,
      isError: false,
      form: {
        code: ''
      }
    }
  },
  validations: {
    form: {
      code: {
        required
      }
    }
  },
  computed: {
    codeErrors () {
      if (this.$v.form.code.$dirty) {
        if (!this.$v.form.code.required) {
          return [
            'Verification code is a required field'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'modals/close'
    }),
    handleInput () {
      this.isError = false
    },
    handleClose () {
      setImmediate(async () => {
        await this.onCancel()
        await this.closeModal()
      })
    },
    handleSubmit () {
      this.isBusy = true
      setImmediate(async () => {
        try {
          try {
            await this.onComplete({
              code: this.form.code
            })
            await this.closeModal()
          } catch (e) {
            this.isError = true
            // eslint-disable-next-line
            console.error('Error in completion handler', e)
            throw e
          }
        } finally {
          this.isBusy = false
        }
      })
    },
    async handleCode (code) {
      this.form.code = code
      await this.handleSubmit()
    }
  }
}
