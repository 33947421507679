import { TREE_MODE, TREE_CUSTOMER, TREE_ADMIN, TREE_SYSTEM } from 'src/structure'

export const MODE_OPTIONS = [
  { key: 'CUSTOMER', title: 'Customer Mode', to: '/private/customer', iconImage: '/assets/icons/user2.svg' },
  { key: 'ADMIN', title: 'Admin Mode', to: '/private/admin', iconImage: '/assets/icons/admin.svg' },
  { key: 'SYSTEM', title: 'System Mode', to: '/private/system', iconImage: '/assets/icons/eye.svg' }
]

const MODE_PATTERN = /^\/private\/([a-zA-Z_][a-zA-Z_0-9]+)(\/.*)?$/

export default ({ router }) => ({
  namespaced: true,
  getters: {
    mode: (state, getters, rootState) => {
      if (rootState.route) {
        const matched = MODE_PATTERN.exec(rootState.route.path)
        if (matched) {
          return MODE_OPTIONS.find(mode => mode.key === matched[1].toUpperCase()) || MODE_OPTIONS[0]
        }
      }
      return MODE_OPTIONS[0]
    },
    modeTree (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return TREE_MODE.getGrantedTree({ hasGrant })
    },
    activeTree (state, getters, rootState, rootGetters) {
      const mode = getters.mode.key
      switch (mode || '') {
        case 'ADMIN':
          return TREE_ADMIN
        case 'SYSTEM':
          return TREE_SYSTEM
        case 'CUSTOMER':
        default:
          return TREE_CUSTOMER
      }
    },
    activeGrantedTree (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      const activeTree = getters.activeTree
      return activeTree.getGrantedTree({ hasGrant })
    }
  },
  actions: {
    push (_, to) {
      return router.push(to)
    }
  }
})
