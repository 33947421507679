import { mapActions, mapGetters } from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Bundles'
  },
  components: {
    ...components
  },
  async created () {
    await this.loadBundle()
  },
  computed: {
    ...mapGetters({
      bundles: 'bundle/bundles',
      isLoading: 'bundle/isLoading'
    })
  },
  methods: {
    ...mapActions({
      loadBundle: 'bundle/loadBundle',
      deleteBundle: 'bundle/deleteBundle',
      handleToast: 'interaction/toast'
    }),
    toBundlePage (symbol) {
      this.$router.push(`/private/system/bundle-edit/${symbol}`)
    },
    removeBundle (id) {
      this.deleteBundle({ id })
    }
  }
}
