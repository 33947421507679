import {mapActions, mapGetters} from 'vuex'
import * as components from 'src/components'
import {CurrencyFiatAdd} from 'src/modals'
import { OperationCanceledError } from 'src/errors'
import CurrencyPartial from 'src/partials/private/CurrencyPartial/CurrencyPartial.vue'
import CurrencyFiatPartial from 'src/partials/private/CurrencyFiatPartial/CurrencyFiatPartial.vue'

export default {
  metaInfo: {
    title: 'Currencies'
  },
  components: {
    ...components,
    CurrencyPartial,
    CurrencyFiatPartial
  },
  data () {
    return {
      isMobile: window.innerWidth <= 1200,
      searchQuery: null
    }
  },
  created () {
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      fiatCurrencies: 'currencies/fiatCurrencies'
    }),
    resultQuery () {
      if (this.searchQuery) {
        return this.currencies.value.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(v => item.currency.symbol.toLowerCase().includes(v))
        })
      } else {
        return this.currencies.value
      }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      deleteCurrency: 'currencies/deleteCurrency',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      handleConfirmation: 'interaction/confirmation'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    async handleAdd () {
      this.openModal({
        factory: () => CurrencyFiatAdd
      })
    },
    getPluginColor (plugin) {
      let color
      // TODO: rewrite for hash function
      plugin === 'FaucetDepositPlugin' ? color = '#ccf280' :
        plugin === 'ManualDepositPlugin' ? color = '#92dcf8' :
          plugin === 'RaidoDepositPlugin' ? color = '#e7c0ff' :
            plugin === 'CryptoDepositPlugin' ? color = '#f5f6a3' :
              plugin === 'MonoovaDepositPlugin' ? color = '#ffdaad' :
                plugin === 'AdvCashDepositPlugin' ? color = '#ffafab' :
                  plugin === 'ManualWithdrawPlugin' ? color = '#92dcf8' :
                    plugin === 'RaidoWithdrawPlugin' ? color = '#e7c0ff' :
                      plugin === 'CryptoWithdrawPlugin' ? color = '#f5f6a3' :
                        plugin === 'MonoovaWithdrawPlugin' ? color = '#ffdaad' :
                          plugin === 'AdvCashWithdrawPlugin' ? color = '#ffafab' :
                            plugin === 'GeoPayDepositPlugin' ? color = '#2f70e0' :
                              plugin === 'GeoPayWithdrawPlugin' ? color = '#bd2fe0' :
                                color = '#eff2f8'
      return color
    },
    sortedDepositPlugins (plugins) {
      return plugins.sort()
    },
    sortedWithdrawPlugins (plugins) {
      return plugins.sort()
    },
    toCurrencyPage (symbol) {
      this.$router.push(`/private/system/currencies/${symbol}`)
    },
    async handleDelete (symbol) {
      await this.handleConfirmation({
        handler: async () => {
          try {
            await this.deleteCurrency({
              symbol: symbol
            })
            await this.handleToast({
              message: 'Currency deleted'
            })
            this.$router.push('/private/system/currencies')
          } catch (e) {
            if (!(e instanceof OperationCanceledError)) {
              await this.handleError({
                error: e,
                message: 'Cannot delete currency'
              })
            }
          }
        }
      })
    }
  }
}
