import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

import MarketStatsItemModel from './MarketStatsItemModel'

const schemaFactory = () => ({
  address: Joi.string().required(),
  from: Joi.string().required(),
  to: Joi.string().required(),
  stats: Joi.array().items(Joi.object().type(MarketStatsItemModel)).allow(null)
})

export default class SpecialAddressMarketStatsModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new SpecialAddressMarketStatsModel({
      ...data,
      stats: data.stats.map(e => MarketStatsItemModel.fromJson(e))
    }, { stripUnknown: true }
    )
  }
}
