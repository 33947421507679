import { mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'Caches'
  },
  data () {
    return {
      isReindexPublicFaq: false,
      isReindexPublicFaqFailed: false,
      isReindexPublicBlog: false,
      isReindexPublicBlogFailed: false
    }
  },
  methods: {
    ...mapActions({
      reindexPublicFaq: 'system/reindexPublicFaq',
      reindexPublicBlog: 'system/reindexPublicBlog'
    }),
    async handleFaqReindex () {
      try {
        await this.reindexPublicFaq()
        this.isReindexPublicFaq = true
        setTimeout(() => {
          this.isReindexPublicFaq = false
        }, 5000)
      } catch (e) {
        this.isReindexPublicFaqFailed = true
        setTimeout(() => {
          this.isReindexPublicFaqFailed = false
        }, 5000)
      }
    },
    async handleBlogReindex () {
      try {
        await this.reindexPublicBlog()
        this.isReindexPublicBlog = true
        setTimeout(() => {
          this.isReindexPublicBlog = false
        }, 5000)
      } catch (e) {
        this.isReindexPublicBlogFailed = true
        setTimeout(() => {
          this.isReindexPublicBlogFailed = false
        }, 5000)
      }
    }
  }
}
