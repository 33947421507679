import { render, staticRenderFns } from "./MonoovaPage.htm?vue&type=template&id=58b2a73d&scoped=true&external"
import script from "./MonoovaPage.js?vue&type=script&lang=js&external"
export * from "./MonoovaPage.js?vue&type=script&lang=js&external"
import style0 from "./MonoovaPage.scss?vue&type=style&index=0&id=58b2a73d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b2a73d",
  null
  
)

export default component.exports