import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import BlogCategoryModel from './BlogCategoryModel'
import { schemaFactory as shortBlogArticleModelSchemaFactory } from './ShortBlogArticleModel'

const schemaFactory = () => shortBlogArticleModelSchemaFactory().keys({
  content: Joi.string().required()
})

export default class BlogArticleModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new BlogArticleModel({
      ...data,
      metainfo: {
        ...data.metainfo,
        categories: BlogCategoryModel.fromJson(data.metainfo.categories)
      }
    }, { stripUnknown: true })
  }
}
