import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import {
  GeoPayDepositPluginModel,
  GeoPayWithdrawPluginModel,
  ItezDepositPluginModel,
  CryptoDepositPluginModel,
  FaucetDepositPluginModel,
  ManualDepositPluginModel,
  CryptoWithdrawPluginModel,
  ManualWithdrawPluginModel,
  RaidoDepositPluginModel,
  RaidoWithdrawPluginModel,
  AdvCashDepositPluginModel,
  AdvCashWithdrawPluginModel,
  MonoovaDepositPluginModel,
  MonoovaWithdrawPluginModel
} from './plugins'

const schemaFactory = () => ({
  symbol: Joi.string().required(),
  name: Joi.string().required(),
  address: Joi.string().required(),
  icon: Joi.string().allow(null),
  coloredIcon: Joi.string().allow([null, '']),
  background: Joi.string().allow(null),
  fiatSymbol: Joi.string().allow([null, '']),
  displayDecimals: Joi.number().required(),
  decimals: Joi.number().min(1).required(),
  tradeDecimals: Joi.number().min(1).required(),
  crypto: Joi.boolean(),
  depositEnabled: Joi.boolean(),
  withdrawalEnabled: Joi.boolean(),
  transferEnabled: Joi.boolean(),
  active: Joi.boolean(),
  link: Joi.string().allow([null, '']),
  showOnLanding: Joi.boolean(),
  showOnCoinPages: Joi.boolean(),
  sortOrder: Joi.number().required(),
  depositPlugins: Joi.array().items(Joi.alternatives().try(
    Joi.object().type(GeoPayDepositPluginModel),
    Joi.object().type(ItezDepositPluginModel),
    Joi.object().type(CryptoDepositPluginModel),
    Joi.object().type(FaucetDepositPluginModel),
    Joi.object().type(ManualDepositPluginModel),
    Joi.object().type(RaidoDepositPluginModel),
    Joi.object().type(AdvCashDepositPluginModel),
    Joi.object().type(MonoovaDepositPluginModel)
  )).allow(null),
  withdrawPlugins: Joi.array().items(Joi.alternatives().try(
    Joi.object().type(GeoPayWithdrawPluginModel),
    Joi.object().type(CryptoWithdrawPluginModel),
    Joi.object().type(ManualWithdrawPluginModel),
    Joi.object().type(RaidoWithdrawPluginModel),
    Joi.object().type(AdvCashWithdrawPluginModel),
    Joi.object().type(MonoovaWithdrawPluginModel)
  )).allow(null)
})

export default class CreateExtendedCurrencyRequest extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, options) {
    if (data == null) {
      return null
    }

    const depositPlugins = []
    for (const plugin of data.depositPlugins) {
      switch (plugin.type) {
        case 'CryptoDepositPlugin':
          depositPlugins.push(CryptoDepositPluginModel.fromJson(plugin))
          break
        case 'FaucetDepositPlugin':
          depositPlugins.push(FaucetDepositPluginModel.fromJson(plugin))
          break
        case 'ManualDepositPlugin':
          depositPlugins.push(ManualDepositPluginModel.fromJson(plugin))
          break
        case 'RaidoDepositPlugin':
          depositPlugins.push(RaidoDepositPluginModel.fromJson(plugin))
          break
        case 'AdvCashDepositPlugin':
          depositPlugins.push(AdvCashDepositPluginModel.fromJson(plugin))
          break
        case 'MonoovaDepositPlugin':
          depositPlugins.push(MonoovaDepositPluginModel.fromJson(plugin))
          break
        case 'ItezDepositPlugin':
          depositPlugins.push(ItezDepositPluginModel.fromJson(plugin))
          break
        case 'GeoPayDepositPlugin':
          depositPlugins.push(GeoPayDepositPluginModel.fromJson(plugin))
          break
      }
    }

    const withdrawPlugins = []
    for (const plugin of data.withdrawPlugins) {
      switch (plugin.type) {
        case 'CryptoWithdrawPlugin':
          withdrawPlugins.push(CryptoWithdrawPluginModel.fromJson(plugin))
          break
        case 'ManualWithdrawPlugin':
          withdrawPlugins.push(ManualWithdrawPluginModel.fromJson(plugin))
          break
        case 'RaidoWithdrawPlugin':
          withdrawPlugins.push(RaidoWithdrawPluginModel.fromJson(plugin))
          break
        case 'AdvCashWithdrawPlugin':
          withdrawPlugins.push(AdvCashWithdrawPluginModel.fromJson(plugin))
          break
        case 'MonoovaWithdrawPlugin':
          withdrawPlugins.push(MonoovaWithdrawPluginModel.fromJson(plugin))
          break
        case 'GeoPayWithdrawPlugin':
          withdrawPlugins.push(GeoPayWithdrawPluginModel.fromJson(plugin))
          break
      }
    }
    return new CreateExtendedCurrencyRequest({
      symbol: data.symbol,
      name: data.name,
      address: data.address,
      icon: data.icon,
      coloredIcon: data.coloredIcon,
      background: data.background,
      fiatSymbol: data.fiatSymbol,
      decimals: data.decimals,
      tradeDecimals: data.tradeDecimals,
      displayDecimals: data.displayDecimals,
      crypto: data.crypto,
      depositEnabled: data.depositEnabled,
      withdrawalEnabled: data.withdrawalEnabled,
      transferEnabled: data.transferEnabled,
      active: data.active,
      showOnLanding: data.showOnLanding,
      showOnCoinPages: data.showOnCoinPages,
      sortOrder: data.sortOrder,
      depositPlugins,
      withdrawPlugins,
      link: data.link
    })
  }
}
