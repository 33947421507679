import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.number(),
  meta: Joi.any(),
  type: Joi.string().allow([null, '']),
  subtype: Joi.string().allow([null, '']),
  derivePath: Joi.string().allow([null, '']),
  address: Joi.string().allow([null, '']),
  pubKey: Joi.string().allow([null, '']),
  prvKey: Joi.string().allow([null, '']),
  status: Joi.string().allow([null, ''])
})

export default class WalletServerModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return `wallet-server-${this.id}`
  }

  get name () {
    return this.meta == null
      ? null
      : this.meta.name
  }

  static fromJson (data) {
    return new WalletServerModel({
      id: data.id,
      meta: data.meta,
      type: data.type,
      subtype: data.subtype,
      derivePath: data.derivePath,
      address: data.address,
      pubKey: data.pubKey,
      prvKey: data.prvKey
    })
  }
}
