import 'core-js'
import 'es6-promise/auto'
import 'setimmediate'
import 'flag-icon-css/css/flag-icon.min.css'
import MessageFormat from 'string-format'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import vuetify from 'src/vuetify'

import { sync } from 'vuex-router-sync'

// add format method to String.prototype
MessageFormat.extend(String.prototype, {})

Vue.use(Vuelidate)
Vue.use(VueI18n)

export default function ({ router, store }) {
  sync(store, router)

  const app = new Vue({
    router,
    store,
    vuetify,
    i18n: store.getters['i18n/i18n'],
    render: h => h(App)
  })

  // eslint-disable-next-line
  for (const child of app.$children) {
    if (child.$vnode == null) {
      child.$vnode = {
        data: {}
      }
    }
  }
  return app
}
