import {mapActions} from 'vuex'
import { WalletAddModal } from 'src/modals'
import VueTypes from 'vue-types'
import AtomSigner from 'src/components/private/AtomSigner/AtomSigner.vue'

export default {
  components: {
    AtomSigner
  },
  props: {
    value: VueTypes.any,
    options: Array,
    small: Boolean,
    text: Boolean,
    filled: Boolean,
    solo: Boolean,
    light: Boolean,
    singleLine: Boolean,
    hideDetails: Boolean,
    outlined: Boolean,
    label: String,
    placeholder: String,
    errorMessages: Array,
    menuProps: Object
  },
  data () {
    return {
      walletKey: this.value
        ? this.value.key
        : null,
      isRemoveInProgress: false
    }
  },
  computed: {
    wallet () {
      const options = this.options
      const walletKey = this.walletKey
      return walletKey == null
        ? null
        : options.find(w => w.key === walletKey)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.walletKey = this.value
          ? this.value.key
          : null
      }
    },
    options: {
      immediate: true,
      handler () {
        let walletKey = null
        if (this.walletKey != null) {
          const selected = this.options.find(a => a.key === this.walletKey)
          if (selected) {
            walletKey = selected.key
          }
        }
        this.walletKey = walletKey
      }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      handleError: 'interaction/error',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast',
      removeWallet: 'wallets/removeWallet'
    }),
    async handleAdd () {
      await this.openModal({
        factory: () => WalletAddModal
      })
      setImmediate(() => {
        this.$refs.select.blur()
      })
    },
    async handleRemoveWallet (entry) {
      await this.handleConfirmation({
        message: 'Do you really want to delete your wallet?',
        handler: async () => {
          this.isRemoveInProgress = true
          setImmediate(async () => {
            try {
              await this.removeWallet(entry)
              await this.handleToast({
                message: 'Wallet removed'
              })
            } catch (e) {
              this.handleError({
                message: 'Cannot remove wallet',
                error: e
              })
            } finally {
              this.isRemoveInProgress = false
            }
          })
        }
      })
    },
    handleInput (key) {
      const wallet = key == null
        ? null
        : this.options.find(w => w.key === key)
      this.$emit('input', wallet)
    },
    handleChange (key) {
      const wallet = key == null
        ? null
        : this.options.find(w => w.key === key)
      this.$emit('change', wallet)
      this.$refs.select.blur()
    },
    handleBlur (...args) {
      this.$emit('blur', ...args)
    }
  }
}
