import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object().keys({
  discount: Joi.number().allow(null),
  ssoUserId: Joi.string().allow([null, ''])
})

export default class WithdrawalDiscountModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data === '') {
      return null
    }
    return new WithdrawalDiscountModel({
      ...data
    }, { stripUnknown: true })
  }
}
