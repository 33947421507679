import { required, email } from 'vuelidate/lib/validators'
import * as components from 'src/components'
import SectionLayout from '../SectionLayout/SectionLayout.vue'
import VueRecaptcha from 'vue-recaptcha'
import {mapGetters, mapActions} from 'vuex'

export default {
  components: {
    ...components,
    SectionLayout,
    VueRecaptcha
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/'
      },
      {
        text: 'Contact Us',
        disabled: true,
        href: ''
      }
    ],
    mailForm: {
      name: '',
      email: '',
      message: ''
    },
    error: null,
    isBusy: false,
    sendStatus: null
  }),
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    captchaSiteKey () {
      return this.config.captcha.captchaSiteKey
    }
  },
  validations: {
    mailForm: {
      name: { required },
      email: { required, email },
      message: { required }
    }
  },
  methods: {
    ...mapActions({
      postEnquiry: 'collaboration/postEnquiry'
    }),
    handleSubmit () {
      this.$v.mailForm.$touch()
      if (this.$v.mailForm.error) {
        return
      }
      this.$refs.recaptcha.execute()
    },
    handleExpired () {
      this.$refs.recaptcha.reset()
    },
    async handlePost (recaptchaToken) {
      this.$refs.recaptcha.reset()
      this.isBusy = true
      this.error = null
      setImmediate(async () => {
        try {
          this.error = null
          this.sendStatus = await this.postEnquiry({
            ...this.mailForm,
            recaptchaToken
          })
          Object.assign(this.mailForm, {
            name: '',
            phone: '',
            email: '',
            message: ''
          })
          this.$v.mailForm.$reset()
        } catch (e) {
          this.error = e.message
        } finally {
          this.isBusy = false
        }
      })
    }
  },
  async mounted () {
  }
}
