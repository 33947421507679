import * as components from 'src/components'
import OptionGenerate from './OptionGenerate/OptionGenerate.vue'
import OptionImportMnemonic from './OptionImportMnemonic/OptionImportMnemonic.vue'
import OptionImportPrivateKey from './OptionImportPrivateKey/OptionImportPrivateKey.vue'
import OptionImportWalletFile from './OptionImportWalletFile/OptionImportWalletFile.vue'

export default {
  components: {
    ...components,
    OptionGenerate,
    OptionImportMnemonic,
    OptionImportPrivateKey,
    OptionImportWalletFile
  },
  data () {
    return {
      type: 'generate',
      options: [
        { key: 'generate', title: 'Generate New' },
        { key: 'mnemonic', title: 'Import Mnemonic' },
        { key: 'privateKey', title: 'Import Private Key' },
        { key: 'walletFile', title: 'Import Wallet File' }
      ]
    }
  }
}
