import { omitBy, isNil } from 'lodash'
import {
  WithdrawalModel,
  WithdrawalPlasmaModel,
  WithdrawalSubscribeRequest,
  WithdrawalUnsubscribeRequest
} from 'src/models'
import { REGISTRY } from 'src/services'
import {PLASMA, BACKEND, withAuthorization} from 'src/remotes'

export const REDEMPTIONS_LOADING = 'redemptions/loading'
export const REDEMPTIONS_LOADED = 'redemptions/loaded'
export const REDEMPTIONS_AFFECTED = 'redemptions/affected'
export const SUMMARY_LOADED = 'redemptions/summary/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      actualDate: null,
      activePage: 1,
      numberOfElements: 10, // page size
      limit: 100,
      items: [],
      plasmaWithdrawalsItems: [],
      count: null,
      updatesCount: 0,
      filters: {
        state: null,
        type: null,
        symbol: null
      },
      summary: null
    }
  },
  mutations: {
    [REDEMPTIONS_AFFECTED]: (state, updatesDiff = +1) => {
      state.updatesCount += updatesDiff
    },
    [REDEMPTIONS_LOADING]: (state) => {
      state.isLoading = true
    },
    [REDEMPTIONS_LOADED]: (state, { actualDate, updatesCount, count, items, activePage, numberOfElements }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        actualDate,
        updatesCount,
        activePage,
        numberOfElements,
        count,
        items
      }, isNil))
    },
    [SUMMARY_LOADED]: (state, { summary }) => {
      state.summary = summary
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    updatesCount: state => state.updatesCount,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    items: state => state.items,
    summary: state => state.summary,
    plasmaWithdrawalsItems: state => state.plasmaWithdrawalsItems,
    totalPages (state) {
      const count = state.count
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async subscribe ({ state, commit, dispatch }, { sessionId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const listener = async m => {
        if (m.type === 'update' && m.channel === 'withdrawals') {
          commit(REDEMPTIONS_AFFECTED, +1)
          const localCopy = state.items.find(p => p.id === m.payload.id)
          if (localCopy != null) {
            dispatch('refreshRedemption', { id: localCopy.id })
          }
        }
      }

      const exchangeSocketClient = REGISTRY.getService('exchangeSocketClient')
      exchangeSocketClient
        .on('message', listener)
        .ensure(new WithdrawalSubscribeRequest({
          requestId: `exchange-session-withdrawals-${sessionId}`,
          payload: {
            token
          }
        }))

      return listener
    },
    async unsubscribe (_, { sessionId, listener }) {
      const exchangeSocketClient = REGISTRY.getService('exchangeSocketClient')
      exchangeSocketClient
        .send(new WithdrawalUnsubscribeRequest({
          requestId: `exchange-session-withdrawals-${sessionId}`
        }))
        .removeListener('message', listener)
    },
    async fetch ({ dispatch }, { actualDate, offset, limit, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { state, type, symbol, addresses } = filters
      const { data } = await BACKEND.post('manager/withdrawals/query', {
        state: (state == null || state === '*' || state.length === 0)
          ? null
          : state,
        type: (type == null || type === '*' || type.length === 0)
          ? null
          : type,
        symbol: (symbol == null || symbol === '*' || symbol.length === 0)
          ? null
          : symbol,
        addresses,
        actualDate,
        offset,
        limit
      }, withAuthorization(token))
      return data
    },
    async fetchTyped ({ dispatch }, options) {
      const { items } = await dispatch('fetch', options)
      return items.map(WithdrawalModel.fromJson)
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      }
    ) {
      commit(REDEMPTIONS_LOADING)
      const { actualDate, items, count } = await dispatch('fetch', {
        actualDate: !reset && state.isLoaded
          ? state.actualDate
          : null,
        filters,
        offset: (page.activePage - 1) * page.numberOfElements,
        limit: page.numberOfElements
      })
      commit(REDEMPTIONS_LOADED, {
        actualDate,
        updatesCount: reset ? 0 : undefined,
        activePage: page.activePage,
        numberOfElements: page.numberOfElements,
        count,
        items: items.map(WithdrawalModel.fromJson)
      })
    },
    async refreshRedemption ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get(`manager/withdrawals/i/${id}`, withAuthorization(token))
      const redemption = WithdrawalModel.fromJson(data)
      commit(REDEMPTIONS_LOADED, {
        items: state.items.map(
          p => p.id !== redemption.id
            ? p
            : redemption
        )
      })
      return redemption
    },
    async acceptRedeem ({ state, commit, dispatch }, { id }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post(`manager/withdrawals/i/${id}/accept`, null, withAuthorization(token))
          const redemption = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: state.items.map(
              p => p.id !== redemption.id
                ? p
                : redemption
            )
          })
          return redemption
        }
      }, { root: true })
      return result
    },
    async retryRedeem ({ state, commit, dispatch }, { id }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post(`manager/withdrawals/i/${id}/retry`, null, withAuthorization(token))
          const redemption = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: state.items.map(
              p => p.id !== redemption.id
                ? p
                : redemption
            )
          })
          return redemption
        }
      }, { root: true })
      return result
    },
    async confirmWithdrawn ({ state, commit, dispatch }, { id, hash }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post(`manager/withdrawals/i/${id}/withdrawn`, {
            hash
          }, withAuthorization(token))
          const redemption = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: state.items.map(
              p => p.id !== redemption.id
                ? p
                : redemption
            )
          })
          return redemption
        }
      }, { root: true })
      return result
    },
    async stopRedeem ({ state, commit, dispatch }, { id }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post(`manager/withdrawals/i/${id}/auto/stop`, null, withAuthorization(token))
          const redemption = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: state.items.map(
              p => p.id !== redemption.id
                ? p
                : redemption
            )
          })
          return redemption
        }
      }, { root: true })
      return result
    },
    async rejectRedeem ({ state, commit, dispatch }, { id }) {
      const result = await dispatch('passport/withCode', {
        onComplete: async ({ code }) => {
          const token = await dispatch('passport/requireToken', null, { root: true })
          const { data } = await BACKEND.post(`manager/withdrawals/i/${id}/reject`, null, withAuthorization(token))
          const redemption = WithdrawalModel.fromJson(data)
          commit(REDEMPTIONS_LOADED, {
            items: state.items.map(
              p => p.id !== redemption.id
                ? p
                : redemption
            )
          })
          return redemption
        }
      }, { root: true })
      return result
    },
    async finishRedeem ({ state, commit, dispatch }, { id, reason }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post(`manager/withdrawals/i/${id}/finish`, {
        reason
      }, withAuthorization(token))
      const redemption = WithdrawalModel.fromJson(data)
      commit(REDEMPTIONS_LOADED, {
        items: state.items.map(
          p => p.id !== redemption.id
            ? p
            : redemption
        )
      })
      return redemption
    },

    async fetchPlasmaWithdrawals ({ dispatch }, { address }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('/manager/withdrawals', withAuthorization(token, {
        params: {
          address
        }
      }))
      const plasmaWithdrawalsItems = data.map(WithdrawalPlasmaModel.fromJson)
      return plasmaWithdrawalsItems
    },
    async loadSummary ({ commit, dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('manager/withdrawals/summary', withAuthorization(token))
      commit(SUMMARY_LOADED, { summary: data })
    }
  }
})
