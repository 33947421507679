import {
  PlasmaTradeModel
} from 'src/models'
import qs from 'qs'
import _ from 'lodash'
import { PLASMA, withAuthorization } from 'src/remotes'

export const PLASMA_TRADES_LOADING = 'plasma/trades/loading'
export const PLASMA_TRADES_LOADED = 'plasma/trades/loaded'

export default () => ({
  namespaced: true,
  actions: {
    async fetch ({ dispatch }, { cursorId, page, size, filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('backoffice/history/trades', withAuthorization(token, {
        params: {
          ...filters,
          cursorId,
          page,
          size
        },
        paramsSerializer: function (params) {
          return qs.stringify(_.omitBy(params, _.isNil), {arrayFormat: 'repeat'})
        }
      }))
      const {
        count,
        trades
      } = data
      return {
        count: count, // TODO @ipavlenko: Remove this fallback value
        items: trades
      }
    },
    async fetchTypedPage ({ dispatch }, options) {
      const { count, items } = await dispatch('fetch', options)
      return {
        count,
        items: items.map(PlasmaTradeModel.fromJson)
      }
    }
  }
})
