import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import WalletServerModel from './WalletServerModel'
import WalletClientModel from './WalletClientModel'

export const schemaFactory = () => ({
  serverWallet: Joi.object().type(WalletServerModel).required(),
  clientWallet: Joi.object().type(WalletClientModel).allow(null)
})

export default class WalletEntryModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return `wallet-entry-${this.serverWallet.id}`
  }

  get id () {
    return this.serverWallet.id
  }

  get name () {
    return this.serverWallet.name
  }

  get address () {
    return this.serverWallet.address
  }

  static fromJson (data) {
    return new WalletEntryModel({
      serverWallet: WalletServerModel.fromJson(data.serverWallet),
      clientWallet: data.clientWallet == null
        ? null
        : WalletClientModel.fromJson(data.clientWallet)
    })
  }
}
