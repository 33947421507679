import { mapGetters, mapActions } from 'vuex'

export default {
  head: {
    title: 'BO'
  },
  computed: {
    ...mapGetters({
      profile: 'passport/user',
      config: 'config/config'
    })
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignIn () {
      await this.authInitiate()
    }
  }
}
