import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Redemptions'
  },
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
      redemptions: 'myRedemptions/items',
      isLoaded: 'myRedemptions/isLoaded',
      isLoading: 'myRedemptions/isLoading',
      actualDate: 'myRedemptions/actualDate',
      activePage: 'myRedemptions/activePage',
      numberOfElements: 'myRedemptions/numberOfElements',
      totalPages: 'myRedemptions/totalPages'
    }),
    states () {
      return [
        { value: '*', label: 'All' },
        { value: 'PENDING', label: 'Pending' }, // This state have several sub-states in some inheritors
        { value: 'CANCELLED', label: 'Cancelled' },
        { value: 'ACCEPTED', label: 'Accepted' },
        { value: 'REJECTED', label: 'Rejected' },
        { value: 'CONFIRMED', label: 'Confirmed' }, // After reducing plasma balance
        { value: 'COMPLETE', label: 'Complete' }
      ]
    },
    items () {
      return this.redemptions.map((redemption, index) => ({
        index,
        redemption
      }))
    },
    groups () {
      const grouped = this.items
        .reduce((target, item) => {
          const m = moment(item.redemption.createdAt).startOf('day')
          const key = m.format('YYYY-MM-DD')
          if (!(key in target)) {
            target[key] = {
              key,
              moment: m,
              items: []
            }
          }
          target[key].items.push(item)
          return target
        }, Object.create(null))
      return Object
        .values(grouped)
        .sort((item1, item2) => {
          return item2.moment.diff(item1.moment)
        })
    }
  },
  data () {
    return {
      filters: {
        state: '*' // Show all by default
      },
      visibleCount: 8
    }
  },
  created () {
    this.handleRefresh()
  },
  methods: {
    momentUtc: moment.utc,
    ...mapActions({
      loadMoreRedemptions: 'myRedemptions/loadMore',
      cancelRedeem: 'myRedemptions/cancelRedeem',
      openModal: 'modals/open',
      handleError: 'interaction/error',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast'
    }),
    async handleState (state) {
      this.filters.state = state
      await this.loadMoreRedemptions({
        reset: true,
        filters: this.filters
      })
    },
    async handleRefresh () {
      await this.loadMoreRedemptions({
        reset: true,
        filters: this.filters
      })
    },
    async handleLoadMore () {
      await this.loadMoreRedemptions({
        filters: this.filters
      })
    },
    async handleCancel ({ redemption }) {
      await this.handleConfirmation({
        handler: async () => {
          await this.cancelRedeem({
            id: redemption.id
          })
          await this.handleToast({
            message: 'Redemption cancelled'
          })
        }
      })
    },
    async handleActivePage (value) {
      this.loadMoreRedemptions({
        filters: this.filters,
        page: {
          activePage: value,
          numberOfElements: this.numberOfElements
        }
      })
    },
    isCancellable (item) {
      const isRaido = item.redemption.type === 'RAIDO'
      return (isRaido && item.redemption.state === 'STARTED')
        || (!isRaido && item.redemption.state === 'PENDING')
    }
  }
}
