import * as components from 'src/components'
import * as directives from 'src/directives'
import SectionLayout from '../SectionLayout/SectionLayout.vue'
import { LaxMixinFactory } from 'src/mixins'

export default {
  components: {
    ...components,
    SectionLayout
  },
  directives: {
    ...directives
  },
  mixins: [
    LaxMixinFactory(function () {
      const { top } = this.$refs.blocks.getBoundingClientRect()
      const { scrollY } = window
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    })
  ],
  data () {
    return {
      startScrollOffset: 0,
      endScrollOffset: 0,
      cases: [
        {
          id: 1,
          img: '/assets/icons/graph.svg',
          title: 'Hedge against volatility',
          text: 'Access to a stablecoin in your local currency (AUD)',
          offset: 0,
          mobileOffset: 100
        },
        {
          id: 2,
          img: '/assets/icons/globus.svg',
          title: 'Global transfers of fixed value',
          text: 'E-commerce payments and remittance rely on the value sent being the value received',
          offset: 100,
          mobileOffset: 150
        },
        {
          id: 3,
          img: '/assets/icons/tower.svg',
          title: 'Enhanced trading strategies',
          text: 'Cross-exchange and multi-currency arbitrage opportunities',
          offset: 200,
          mobileOffset: 250
        },
        {
          id: 4,
          img: '/assets/icons/dollar.svg',
          title: 'Connect with real-world commerce',
          text: 'Integrate real-world currencies or goods with crypto, with dramatically reduced risk',
          offset: 200,
          mobileOffset: 350
        },
        {
          id: 5,
          img: '/assets/icons/australia.svg',
          title: 'Interact with Australian traders',
          text: 'Work with other traders in a common currency, without the need for fiat money',
          offset: 300,
          mobileOffset: 450
        },
        {
          id: 6,
          img: '/assets/icons/pen.svg',
          title: 'And much more',
          text: 'Our team is working hard to improve AUDT every day',
          offset: 400,
          mobileOffset: 550
        }
      ]
    }
  },
  computed: {
    items () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      return this.cases.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${start + item.offset + index * 10} 0, ${end + item.offset + index * 10} 1`,
          dataLaxTranslateY: `${start + item.offset + index * 5} 150px, ${end + item.offset + index * 5} 0px`,
          dataLaxOpacitySmall: `${start + item.mobileOffset + index * 150} 0, ${end + item.mobileOffset + index * 130} 1`,
          dataLaxTranslateYSmall: `${start + item.mobileOffset + index * 150} 150px, ${end + item.mobileOffset + index * 130} 0px`
        })
      )
    }
  }
}
