import {isNil, omitBy} from 'lodash'
import {BACKEND, withAuthorization} from 'src/remotes'

export const ADDRESSES_LOADING = 'customAddresses/loading'
export const ADDRESSES_LOADED = 'customAddresses/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: true,
      isLoaded: false,
      items: []
    }
  },
  mutations: {
    [ADDRESSES_LOADING]: (state) => {
      state.isLoading = true
    },
    [ADDRESSES_LOADED]: (state, { items }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        items
      }, isNil))
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    isLoaded: state => state.isLoaded,
    items: state => state.items
  },
  actions: {
    async fetchAddresses ({ dispatch, state }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('/manager/blockchain/addresses/watched', withAuthorization(token))
      return data
    },
    async loadAddresses ({ state, commit, dispatch }) {
      commit(ADDRESSES_LOADING)
      const items = await dispatch('fetchAddresses')
      commit(ADDRESSES_LOADED, { items })
    },
    async createAddress ({ state, commit, dispatch }, { ...params }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`/manager/blockchain/addresses/watched`, {
        ...params
      }, withAuthorization(token))
      await dispatch('loadAddresses')
    },
    async editAddress ({ state, commit, dispatch }, { item }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.put(`/manager/blockchain/addresses/watched/i/${item.id}`, {
        name: item.name,
        type: item.type,
        description: item.description,
        address: item.address
      }, withAuthorization(token))
      await dispatch('loadAddresses')
    },
    async removeAddress ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.delete(`/manager/blockchain/addresses/watched/i/${id}`, withAuthorization(token))
      await dispatch('loadAddresses')
    }
  }
})
