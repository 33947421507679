import { render, staticRenderFns } from "./SocialLinks.htm?vue&type=template&id=828cde16&scoped=true&external"
import script from "./SocialLinks.js?vue&type=script&lang=js&external"
export * from "./SocialLinks.js?vue&type=script&lang=js&external"
import style0 from "./SocialLinks.scss?vue&type=style&index=0&id=828cde16&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "828cde16",
  null
  
)

export default component.exports