import Web3 from 'web3'
import EventEmitter from 'events'
import uniqid from 'uniqid'

export default class Web3Client extends EventEmitter {
  constructor (web3, blockchain) {
    super()
    this.setMaxListeners(100)
    this.blockchain = blockchain
    this.provider = null
    this.web3 = web3
    this.initialProvider = this.web3.currentProvider
  }

  get isConnected () {
    return this.provider != null
  }

  start () {
    this.tryConnect()
    return this
  }

  async tryConnect () {
    // eslint-disable-next-line
    console.log('[Web3] Trying to connect...')
    try {
      const provider = await Promise.race([
        createTimeout(5000),
        createProvider({
          blockchain: this.blockchain
        })
      ])
      provider.on('end', () => {
        if (this.provider === provider) {
          this.handleClose()
        }
      })
      this.handleOpen({ provider })
      return provider
    } catch (e) {
      this.handleError(e)
      setTimeout(() => {
        this.start()
      }, 3000)
    }
  }

  handleOpen ({ provider }) {
    this.provider = provider
    this.sessionId = uniqid()
    this.web3.setProvider(provider)
    setImmediate(() => {
      this.emit('open', {
        sessionId: this.sessionId
      })
    })
  }

  handleError (e) {
    setImmediate(() => {
      this.emit('error', e)
    })
  }

  handleClose () {
    this.web3.setProvider(this.initialProvider)
    setImmediate(() => {
      this.emit('close', {
        sessionId: this.sessionId
      })
    })
    this.sessionId = null
    this.provider = null
    setTimeout(async () => {
      this.start()
    }, 3000)
  }
}

function createProvider ({ blockchain }) {
  return new Promise((resolve, reject) => {
    try {
      const provider = new Web3.providers.WebsocketProvider(blockchain.websocket)
      provider.on('connect', () => {
        resolve(provider)
      })
    } catch (e) {
      reject(e)
    }
  })
}

function createTimeout (timeout) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('[network] Request timeout'))
    }, timeout)
  })
}
