import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  type: Joi.string().valid('subscribe'),
  channel: Joi.string().valid('profile'),
  requestId: Joi.string().required(),
  payload: Joi.object().keys({
    token: Joi.string().required()
  })
})

export default class ProfileSubscribeRequest extends AbstractModel {
  constructor (data, options) {
    super({
      channel: 'profile',
      type: 'subscribe',
      ...data
    },
    schemaFactory,
    options)
    Object.freeze(this)
  }
}
