import { Grants } from 'src/models'

export default ({ router }) => ({
  namespaced: true,
  getters: {
    hasDepositsViewAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.depositsView,
        ...Grants.depositsAction
      )
    },
    hasDepositsActionAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.depositsAction
      )
    },
    hasWithdrawalsViewAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.withdrawalsView,
        ...Grants.withdrawalsAction
      )
    },
    hasWithdrawalsActionAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.withdrawalsAction
      )
    },
    hasProfilesViewAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.profilesView
      )
    },
    hasAccountOperationsAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.accountOperations
      )
    },
    hasTransfersViewAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.transfers
      )
    },
    hasDepositsAddressesViewAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.depositAddressesView
      )
    },
    hasMfaActionsAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.mfaActions
      )
    },
    hasCustomFeeAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.customFee
      )
    },
    hasMonoovaUserActionsAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.monoovaUserActions
      )
    },
    hasUserActivityViewAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.userActivityView
      )
    },
    hasKycActionsAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.kycActions
      )
    },
    hasMonoovaSettingsActionsAccess (state, getters, rootState, rootGetters) {
      const hasGrant = rootGetters['passport/hasGrant']
      return hasGrant(
        ...Grants.root,
        ...Grants.monoovaSettingsActions
      )
    }
  }
})
