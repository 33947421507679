import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import * as directives from 'src/directives'
import { Grants } from 'src/models'

export default {
  props: {
    wallet: String
  },
  directives: {
    ...directives
  },
  data () {
    return {
      isLoading: false,
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      customers: 'customers/items',
      hasGrant: 'passport/hasGrant'
    }),
    hasReadAccess () {
      return this.hasGrant(
        ...Grants.root,
        ...Grants.profilesView
      )
    },
    availableCustomers () {
      return this.customers.map(c => ({
        name: (c.firstName || c.lastName) ? `${c.firstName} ${c.lastName}` : null,
        id: c.id,
        email: c.email
      }))
    },
    items () {
      return _.omitBy(this.availableCustomers, _.isNil)
    }
  },
  methods: {
    ...mapActions({
      loadMoreCustomers: 'customers/loadMore'
    }),
    async getUser (e) {
      this.isLoading = true
      e.preventDefault()
      e.stopPropagation()
      try {
        await this.loadMoreCustomers({
          filters: {
            wallet: this.wallet
          }
        })
      } finally {
        this.isLoading = false
        this.goToCustomerPage()
      }
    },
    openList () {
      this.isOpen = !true.isOpen
    },
    goToCustomerPage () {
      if (this.customers.length === 1) {
        let routeData = this.$router.resolve({
          path: `/private/admin/customers/i/${this.customers[0].id}`
        })
        window.open(routeData.href, '_blank')
      } else {
        this.openList()
      }
    }
  }
}
