import Vue from 'vue'
import Analytics from 'vue-analytics'

export default async ({ store, router }) => {
  // Only executed on client side
  if (process.client) {
    const config = store.getters['config/config']
    if (config.ga) {
      Vue.use(Analytics, {
        ...config.ga,
        router
      })
    }
  }
}
