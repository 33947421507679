import { render, staticRenderFns } from "./CurrencyViewPage.htm?vue&type=template&id=96c34b08&scoped=true&external"
import script from "./CurrencyViewPage.js?vue&type=script&lang=js&external"
export * from "./CurrencyViewPage.js?vue&type=script&lang=js&external"
import style0 from "./CurrencyViewPage.scss?vue&type=style&index=0&id=96c34b08&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96c34b08",
  null
  
)

export default component.exports