import moment from 'moment'
import {mapGetters, mapActions, mapState} from 'vuex'
import { compact, get } from 'lodash'
import * as components from 'src/components'
import { ResetLevelModal } from 'src/modals'

export default {
  components: {
    ...components
  },
  props: {
    details: Object,
    isMobile: Boolean
  },
  data () {
    return {
      level1Disabled: true,
      level2Disabled: true,
      level3Disabled: true,
      level4Disabled: true,
      form: {
        firstName: '',
        middleName: '',
        lastName: '',
        birthDate: null,
        passport: null,
        expirationDate: null,
        email: null,
        phone: null,
        addressLine1: null,
        zip: null,
        country: null,
        city: null,
        comment: null
      },
      commentDisabled: true
    }
  },
  async created () {
    await this.fillForm()
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      getProfileData: 'customers/getKYCProfileData',
      hasKycActionsAccess: 'grants/hasKycActionsAccess'
    }),
    ...mapState({
      profileId: state => state.route.params.id
    }),
    profileData () {
      return this.getProfileData(this.profileId).value
    },
    userId () {
      return this.$route.params.id
    },
    rapidID () {
      return this.getProfileData(this.profileId).value.profile.rapidID
    },
    profileDetailsURL () {
      // const { vgrService } = this.config
      // if (vgrService != null && vgrService.detailsPage && vgrService.detailsPage.baseURL != null) {
      //   const parms = new URLSearchParams({
      //     ...(vgrService.detailsPage.params || {}),
      //     usernumber: this.details.id
      //   })
      //   const url = new URL(vgrService.detailsPage.baseURL)
      //   url.search = `?${parms.toString()}`
      //   return url.toString()
      // }
      return null
    },
    isRapidExist () {
      return this.rapidID.length > 0
    },
    isRapidIDComplete () {
      return this.getProfileData(this.profileId).value.isRapidIDComplete
    }
  },
  methods: {
    moment,
    ...mapActions({
      openFile: 'media/openFile',
      loadKYCProfileData: 'customers/loadKYCProfileData',
      openModal: 'modals/open'
    }),
    async fillForm () {
      const birthDate = get(this.details, 'personal.birthDate')
      this.form = {
        firstName: get(this.details, 'personal.firstName', '-'),
        middleName: get(this.details, 'personal.middleName', '-'),
        lastName: get(this.details, 'personal.lastName', '-'),
        birthDate: birthDate ? moment(birthDate).format('DD.MM.YYYY') : '-',
        email: get(this.details, 'email.email', '-'),
        phone: get(this.details, 'phone.phone', '-'),
        addressLine1: get(this.details, 'address.addressLine1', '-'),
        addressLine2: get(this.details, 'address.addressLine2', '-'),
        zip: get(this.details, 'address.zip', '-'),
        state: get(this.details, 'address.state', '-'),
        country: get(this.details, 'address.country', '-'),
        city: get(this.details, 'address.city', '-'),
        rejectAddressComment: get(this.details, 'address.rejectComment', ''),
        complicateAddressComment: get(this.details, 'address.complicateComment', ''),
        rejectDocumentsComment: get(this.details, 'documents.rejectComment', ''),
        complicateDocumentsComment: get(this.details, 'documents.complicateComment', ''),
        attachments: this.details && this.details.documents && this.details.documents.attachments.length ?
          await Promise.all(this.details.documents.attachments.map(async el => ({
            id: el.file.id,
            kind: el.type,
            name: el.file.name,
            type: el.file.mimetype
          }))) : null,
        selfie: this.details && this.details.documents && this.details.documents.selfie ? {
          name: this.details.documents.selfie.name,
          id: this.details.documents.selfie.id
        } : null
      }
    },
    async handleReset () {
      this.openModal({
        factory: () => ResetLevelModal,
        data: {
          title: 'Reset to level',
          userId: this.userId,
          handler: async () => {
            await this.loadKYCProfileData({ id: this.userId })
            await this.fillForm()
          }
        }
      })
    },
    getFormatString (...data) {
      if (data) {
        const a = compact(data)
        return a.join(', ')
      }
      return '-'
    }
  }
}
