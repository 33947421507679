/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reddit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 11.779a2.654 2.654 0 00-4.497-1.899c-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058a2.17 2.17 0 002.174 2.163c1.198 0 2.172-.97 2.172-2.163a2.171 2.171 0 00-4.193-.785l-4.329-1.015a.37.37 0 00-.44.249L11.755 7.82c-2.838.034-5.409.798-7.3 2.025a2.643 2.643 0 00-1.799-.712A2.654 2.654 0 000 11.779c0 .97.533 1.811 1.317 2.271a4.716 4.716 0 00-.086.857C1.231 18.818 6.039 22 11.95 22s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81A2.633 2.633 0 0024 11.779zM6.776 13.595c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179L12 19.44l-.013.003c-1.777 0-3.028-.386-3.824-1.179a.369.369 0 010-.523.372.372 0 01.526 0c.65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962a.373.373 0 01.526 0 .37.37 0 010 .524zm-.189-3.095a1.58 1.58 0 01-1.581-1.574c0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z"/>'
  }
})
