import {PROFILE, withAuthorization} from 'src/remotes'

export default () => ({
  namespaced: true,
  actions: {
    async findPlace ({ state, commit, dispatch }, { text }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      try {
        const { data } = await PROFILE.get('/place/findplacefromtext', withAuthorization(token, {
          params: {
            text: text
          }
        }))
        if (data.status === 'ZERO_RESULT') {
          return {
            noResult: true
          }
        }
        return {
          candidates: data.candidates
        }
      } catch (e) {
        return null
      }
    }
  }
})
