import { mapGetters } from 'vuex'
import * as components from 'src/components'
import { copyToClipboard } from 'src/utils/lib/common'

export default {
  components: {
    ...components
  },
  props: {
    isBusy: Boolean
  },
  computed: {
    ...mapGetters({
      monoovaAccount: 'monoova/account',
      hasMonoovaUserActionsAccess: 'grants/hasMonoovaUserActionsAccess'
    }),
    monoovaAccountItems () {
      return this.monoovaAccount && this.monoovaAccount.value
    }
  },
  methods: {
    copyClipboard (path) {
      copyToClipboard(path)
    },
    handleCreate () {
      this.$emit('create')
    }
  }
}
