import examples from 'libphonenumber-js/examples.mobile.json'
import { AsYouType, getExampleNumber, isValidNumber } from 'libphonenumber-js'

export const isValidPhoneNumber = isValidNumber

export function getRelevantPhoneExample (value, fallbackRegion) {
  try {
    const asYouType = new AsYouType()
    asYouType.input(value)
    const country = (asYouType.metadata && asYouType.metadata._country)
      ? asYouType.metadata._country
      : null
    return getExampleNumber(country || fallbackRegion, examples).format('INTERNATIONAL')
  } catch (e) {
    return null
  }
}
