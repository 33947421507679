import Vue from 'vue'
import Vuex from 'vuex'

import * as modulesShared from './shared'

Vue.use(Vuex)

export default function () {
  const store = new Vuex.Store({
    modules: Object.entries(modulesShared)
      .map(([key, factory]) => ({ key, value: factory() }))
      .reduce((t, entry) => ({ ...t, [entry.key]: entry.value }), {})
  })

  return store
}
