import * as components from 'src/components'
import * as directives from 'src/directives'
import SectionLayout from '../SectionLayout/SectionLayout.vue'
import { LaxMixinFactory } from 'src/mixins'

export default {
  components: {
    ...components,
    SectionLayout
  },
  directives: {
    ...directives
  },
  mixins: [
    LaxMixinFactory(function () {
      const { top } = this.$refs.blocks.getBoundingClientRect()
      const { scrollY } = window
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    })
  ],
  data () {
    return {
      startScrollOffset: 0,
      endScrollOffset: 0,
      partners: [
        {
          id: 1,
          img: '/assets/img/main/timex.svg',
          title: 'TimeX',
          text: 'TimeX, a Plasma-based hybrid cryptocurrency exchange, combines the speed and privacy of a centralised system with the security and transparency of a decentralised solution.',
          linktext: 'timex.io',
          url: 'https://timex.io/',
          offset: 100,
          mobileOffset: 100
        },

        {
          id: 2,
          img: '/assets/img/main/paymentx.svg',
          title: 'PaymentX',
          text: 'PaymentX is an automated cryptocurrency payroll solution for your business. Pay the team using crypto in one click, send and receive professional invoices, set up regular payment dates - all quickly and conveniently!',
          linktext: 'paymentx.io',
          url: 'https://paymentx.io/',
          offset: 200,
          mobileOffset: 400
        },

        {
          id: 3,
          img: '/assets/img/main/laborx.svg',
          title: 'LaborX',
          text: 'LaborX provides secure, blockchain-based work contracts. LaborX Digital Escrow ensures prompt, reliable payment, while SmartDispute offers affordable dispute resolution. Coming features include a reputation system, services module and peer-to-peer marketplace.',
          linktext: 'laborx.com',
          url: 'https://laborx.com/',
          offset: 300,
          mobileOffset: 700
        }
      ]
    }
  },
  computed: {
    items () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      return this.partners.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${start + item.offset + index * 50} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateY: `${start + item.offset + index * 150} 100px, ${end + item.offset + index * 30} 0px`,
          dataLaxOpacitySmall: `${start + item.mobileOffset + index * 150} 0, ${end + item.mobileOffset + index * 130} 1`,
          dataLaxTranslateYSmall: `${start + item.mobileOffset + index * 150} 150px, ${end + item.mobileOffset + index * 130} 0px`
        })
      )
    }
  }
}
