const BACKSPACE_KEYCODE = 8

export default {
  data () {
    return {
      number0: '',
      number1: '',
      number2: '',
      number3: '',
      number4: '',
      number5: ''
    }
  },
  methods: {
    keyCheck (el, where) {
      if (!/^\d+$/.test(el.data)) {
        el.target.value = this[`number${where}`]
        return
      }
      el.target.value = el.data
      this[`number${where}`] = el.data
      const next = Math.min(5, where + 1)
      this.$refs[`number${next}`].focus()
      this.handleCode()
    },
    handleKeyboard (el, where) {
      if (el.keyCode === BACKSPACE_KEYCODE) {
        const currentValue = this[`number${where}`]
        const prev = Math.max(0, where - 1)
        if (currentValue) {
          this[`number${where}`] = ''
        } else {
          this[`number${prev}`] = ''
        }
        this.$refs[`number${prev}`].focus()
      }
    },
    copyLogic (el) {
      const copyData = el.clipboardData.getData('Text').replace(/\s/g, '')
      if (!isNaN(Number(copyData))) {
        for (let i = 0; i < 6; ++i) {
          this[`number${i}`] = copyData[i] || ''
        }
        this.handleCode()
      }
    },
    handleCode () {
      let code = ''
      for (let i = 0; i < 6; ++i) {
        const input = this[`number${i}`]
        if (!input) {
          return
        }
        code += input.toString()
      }

      this.$emit('sendCode', code)
    }
  },
  mounted () {
    this.$refs.number0.focus()
  }
}
