import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isActive: false,
      isShown: false
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open'
    }),
    showHideMenu: function () {
      if (this.isActive === false) {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        this.isActive = true
      } else {
        this.isShown = true
        setTimeout(() => {
          this.isShown = false
          this.isActive = false
        }, 400)
      }
    }
  }
}
