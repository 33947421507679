import {isNil, omitBy} from 'lodash'
import {BACKEND, withAuthorization} from 'src/remotes'

export const DEPOSITS_LOADING = 'deposits/loading'
export const DEPOSITS_LOADED = 'deposits/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      activePage: 1,
      limit: 30, // elements per page
      count: null, // elements total
      items: [],
      filters: {
        type: [],
        onlyDeposited: false,
        from: null,
        to: null,
        sort: null
      }
    }
  },
  mutations: {
    [DEPOSITS_LOADING]: (state) => {
      state.isLoading = true
    },
    [DEPOSITS_LOADED]: (state, { offset, limit, count, items, activePage }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        limit,
        count,
        items,
        activePage
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    limit: state => state.limit,
    count: state => state.count,
    items: state => state.items,
    activePage: state => state.activePage,
    totalPages (state) {
      const count = state.count
      const pageSize = state.limit
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async fetchDeposits ({ dispatch, state }, { offset, limit, filters = {} } = { limit: state.limit, filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('/manager/crypto/deposit/stats', withAuthorization(token, {
        params: {
          ...filters,
          offset,
          limit
        }
      }))
      return data
    },
    async loadDeposits ({ state, commit, dispatch }, {
      filters,
      page = {
        activePage: 1,
        limit: state.limit
      }
    } = {
      page: {
        activePage: 1,
        limit: state.limit
      }
    }) {
      if (!state.isLoading) {
        commit(DEPOSITS_LOADING)
        const { items, count } = await dispatch('fetchDeposits', {
          filters,
          offset: (page.activePage - 1) * state.limit,
          limit: state.limit
        })
        commit(DEPOSITS_LOADED, {
          activePage: parseInt(page.activePage),
          count,
          items: items
        })
      }
    }
  }
})
