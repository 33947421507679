/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credentials-login': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a9.982 9.982 0 00-7.979 4H4v.027A9.986 9.986 0 1012 2zm3 2.584A8 8 0 0117.9 17.4 1.993 1.993 0 0016 16h-1v-3a1 1 0 00-1-1H9v-2h1a1 1 0 001-1V7h2a2 2 0 002-2v-.416zM4.207 10.207L6 12l3 3v1a2 2 0 002 2v1.932a7.978 7.978 0 01-6.793-9.725z" _fill="#000"/>'
  }
})
