import SignerModel from './SignerModel'
// import { SIGNER, withAuthorization } from 'src/remotes'

export default class SignerServerModel extends SignerModel {
  constructor ({ address, walletId, token }) {
    super()
    this.address = address
    this.walletId = walletId
    this.token = token
    Object.freeze(this)
  }

  // this method is a part of base interface
  getAddress () {
    return this.address
  }

  async signTransaction (txData) { // tx object
    // eslint-disable-next-line
    console.log('Attempt to sign transaction: ', txData)
    throw new Error('Not implemented')
  }

  async signData (data) { // data object
    // eslint-disable-next-line
    console.log('Attempt to sign data: ', data)
    throw new Error('Not implemented')
  }

  // Should be synchronous by design
  encrypt () {
    return null
  }

  // Should be synchronous by design
  static async create ({ address, plugin }) {
    return new SignerServerModel({ address, plugin })
  }

  // Should be synchronous by design
  static decrypt ({ entry, token }) {
    return new SignerServerModel({
      address: entry.address,
      walletId: entry.id,
      token
    })
  }
}
