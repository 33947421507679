import { render, staticRenderFns } from "./InTouchSection.htm?vue&type=template&id=74930d76&scoped=true&external"
import script from "./InTouchSection.js?vue&type=script&lang=js&external"
export * from "./InTouchSection.js?vue&type=script&lang=js&external"
import style0 from "./InTouchSection.scss?vue&type=style&index=0&id=74930d76&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74930d76",
  null
  
)

export default component.exports