import { render, staticRenderFns } from "./ContactsPartial.htm?vue&type=template&id=04cd54da&scoped=true&external"
import script from "./ContactsPartial.js?vue&type=script&lang=js&external"
export * from "./ContactsPartial.js?vue&type=script&lang=js&external"
import style0 from "./ContactsPartial.scss?vue&type=style&index=0&id=04cd54da&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04cd54da",
  null
  
)

export default component.exports