import EventEmitter from 'events'

export default () => ({
  namespaced: true,
  actions: {
    acceptDeposit ({ dispatch }, { deposit, amount }) {
      const emitter = new EventEmitter()
      dispatch('interaction/confirmation', {
        handler: async () => {
          const purchase = await dispatch('purchases/acceptPurchase', {
            id: deposit.id,
            amount
          }, { root: true })
          emitter.emit('accepted', purchase)
          await dispatch('interaction/toast', {
            message: 'Deposit accepted'
          }, { root: true })
        }
      }, { root: true })
      return emitter
    },
    rejectDeposit ({ dispatch }, { deposit }) {
      const emitter = new EventEmitter()
      dispatch('interaction/confirmation', {
        handler: async () => {
          const purchase = await dispatch('purchases/rejectPurchase', {
            id: deposit.id
          }, { root: true })
          emitter.emit('rejected', purchase)
          await dispatch('interaction/toast', {
            message: 'Deposit rejected'
          }, { root: true })
        }
      }, { root: true })
      return emitter
    },
    acceptWithdrawal ({ dispatch }, { withdrawal }) {
      const em = new EventEmitter()
      dispatch('interaction/confirmation', {
        handler: async () => {
          const redemption = await dispatch('redemptions/acceptRedeem', {
            id: withdrawal.id
          }, { root: true })
          em.emit('accepted', redemption)
          await dispatch('interaction/toast', {
            message: 'Withdrawal accepted'
          }, { root: true })
        }
      }, { root: true })
      return em
    },
    async rejectWithdrawal ({ dispatch }, { withdrawal }) {
      const em = new EventEmitter()
      dispatch('interaction/confirmation', {
        handler: async () => {
          const redemption = await dispatch('redemptions/rejectRedeem', {
            id: withdrawal.id
          }, { root: true })
          em.emit('rejected', redemption)
          await dispatch('interaction/toast', {
            message: 'Withdrawal rejected'
          }, { root: true })
        }
      }, { root: true })
      return em
    }
  }
})
