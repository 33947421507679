import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import CustomerKycDetails from './CustomerKycDetails'
import BankAccountEntryModel from './BankAccountEntryModel'
import CustomerWalletModel from './CustomerWalletModel'

export const schemaFactory = () => ({
  profile: Joi.object().type(CustomerKycDetails),
  addresses: Joi.array().items(
    Joi.object().type(CustomerWalletModel)
  ).allow(null),
  bankAccounts: Joi.array().items(
    Joi.object().type(BankAccountEntryModel)
  ).allow(null),
  isLocked: Joi.boolean(),
  lockReason: Joi.string().allow([null, '']),
  credentials: Joi.array().items(
    Joi.object()
  ).allow(null),
  verificators: Joi.array().items(
    Joi.object()
  ).allow(null)
})

export default class CustomerData extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get kycLevel () {
    let level = 0

    if (this.profile.phone && this.profile.phone.status === 'ACCEPTED' &&
      this.profile.email && this.profile.email.status === 'ACCEPTED') {
      level = 1
    }
    if (this.profile.personal && this.profile.personal.status === 'ACCEPTED') {
      level = 2
    }
    if (this.profile.address && this.profile.address.status === 'ACCEPTED') {
      level = 3
    }
    if (this.profile.documents && this.profile.documents.status === 'ACCEPTED') {
      level = 4
    }

    if (this.isRapidIDComplete) {
      level = 4
    }

    return level
  }

  get isRapidIDComplete () {
    if (!this.profile.rapidID) {
      return false
    }
    return this.profile.rapidID.find(e => e.status === 'ACCEPTED') != null
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new CustomerData(
      {
        profile: CustomerKycDetails.fromJson(data.profile),
        addresses: AbstractModel.buildArray(data.addresses, CustomerWalletModel.fromJson),
        bankAccounts: AbstractModel.buildArray(data.bankAccounts, BankAccountEntryModel.fromJson),
        isLocked: data.isLocked,
        lockReason: data.lockReason,
        credentials: data.credentials,
        verificators: data.verificators
      },
      { stripUnknown: true }
    )
  }
}
