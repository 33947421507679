import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import ProfileIdentityModel from './ProfileIdentityModel'
import CustomerModel from './CustomerModel'

export const schemaFactory = () => ({
  identity: Joi.object().type(ProfileIdentityModel).required(),
  isLoading: Joi.boolean().required(),
  isLoaded: Joi.boolean().required(),
  customer: Joi.object().type(CustomerModel).allow(null)
})

export default class ProfileIdentityDetailsModel extends AbstractModel {
  constructor (data, options) {
    super(Object.assign({
      isLoading: true,
      isLoaded: false,
      customer: null
    }, data), schemaFactory(), options)
  }

  loaded ({ customer }) {
    return Object.assign(this, {
      isLoaded: true,
      isLoading: false,
      customer
    })
  }

  loading () {
    return Object.assign(this, {
      isLoading: true
    })
  }
}
