import {mapActions} from 'vuex'
import {required, requiredIf} from 'vuelidate/lib/validators'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    bank: Object,
    ssoUserId: String
  },
  data () {
    return {
      isBusy: false,
      types: ['BSB', 'PAY_ID'],
      statuses: ['CONFIRMED', 'UNCONFIRMED'],
      newSsoUserId: '',
      form: {
        status: null,
        type: null,
        accountName: null,
        accountNumber: null,
        bsb: null,
        payId: null
      }
    }
  },
  created () {
    this.init()
  },
  validations: {
    form: {
      status: {
        required
      },
      type: {
        required
      },
      accountName: {
        required
      },
      accountNumber: {
        required: requiredIf(function () {
          return this.form.type === 'BSB'
        })
      },
      bsb: {
        required: requiredIf(function () {
          return this.form.type === 'BSB'
        })
      },
      payId: {
        required: requiredIf(function () {
          return this.form.type === 'PAY_ID'
        })
      }
    }
  },
  computed: {
    countErrors () {
      if (this.$v.form.accountName.$dirty) {
        if (!this.$v.form.accountName.required) {
          return [
            'Account Name is a required field'
          ]
        }
      }
      if (this.$v.form.accountNumber.$dirty) {
        if (!this.$v.form.accountNumber.required) {
          return [
            'Account Number is a required field'
          ]
        }
      }
      if (this.$v.form.bsb.$dirty) {
        if (!this.$v.form.bsb.required) {
          return [
            'BSB is a required field'
          ]
        }
      }
      if (this.$v.form.payId.$dirty) {
        if (!this.$v.form.payId.required) {
          return [
            'PAY ID is a required field'
          ]
        }
      }
      return ''
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleError: 'interaction/error',
      editUserMonoovaBank: 'monoova/editUserMonoovaBank'
    }),
    init () {
      this.form = {
        status: this.bank.status || this.statuses[0],
        type: this.bank.type || this.types[0],
        accountName: this.bank.accountName || '',
        accountNumber: this.bank.accountNumber || '',
        bsb: this.bank.bsb || '',
        payId: this.bank.payId || ''
      }
    },
    async handleSubmit () {
      this.isBusy = true
      try {
        await this.editUserMonoovaBank({
          ssoUserId: this.ssoUserId || this.newSsoUserId,
          status: this.form.status,
          type: this.form.type,
          accountName: this.form.accountName,
          accountNumber: this.form.accountNumber,
          bsb: this.form.bsb,
          payId: this.form.payId
        })
        this.handleClose()
      } catch (e) {
        console.log(e)
      } finally {
        this.isBusy = false
      }
    }
  }
}
