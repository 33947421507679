// import deployedAddresses from '@timex/exchange.abi/deployed-addresses'
//
// import EIP20 from '@timex/exchange.abi/contracts/ERC20Interface'
// import ERC20Proxy from '@timex/exchange.abi/contracts/ERC20Proxy'
// import ChronoBankPlatform from '@timex/exchange.abi/contracts/ChronoBankPlatform'
//
// import { ABIModel } from 'src/models'

// eslint-disable-next-line no-undef
// const config = global.__APP_CONFIG__
// const addresses = deployedAddresses[config.blockchain.networkId]

export const ABIS_LOADED = 'abis/byAddress/loaded'

export default () => ({
  namespaced: true,
  state: {
    byAddress: {},
    byContract: {}
  },
  mutations: {
    [ABIS_LOADED] (state, { abi, abis }) {
      const list = []
      if (abis) list.push(...abis)
      if (abi) list.push(abi)

      const byAddress = {}
      const byContract = {}
      for (const model of list) {
        if (model.address) {
          byAddress[model.address.toLowerCase()] = model
        }
        if (model.contract) {
          byContract[model.contract] = model
        }
      }
      state.byAddress = Object.assign({}, state.byAddress, byAddress)
      state.byContract = Object.assign({}, state.byContract, byContract)
    }
  },
  getters: {
    getAbi: state => address => {
      address = address.toLowerCase()
      return state.byAddress[address]
    },
    getAbiByContract: state => contract => {
      return state.byContract[contract] || null
    }
  },
  actions: {
    async initialize ({ commit }) {
      // commit(ABIS_LOADED, {
      //   abis: [
      //     new ABIModel({ isLoaded: true, isLoading: false, contract: 'ERC20Proxy', address: addresses.ERC20Proxy.address, value: ERC20Proxy }),
      //     new ABIModel({ isLoaded: true, isLoading: false, contract: 'EIP20', address: null, value: EIP20 }),
      //     new ABIModel({ isLoaded: true, isLoading: false, contract: 'ChronoBankPlatform', address: addresses.SharedChronoBankPlatform.address, value: ChronoBankPlatform })
      //   ]
      // })
    }
  }
})
