import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  symbol: Joi.string().required(),
  name: Joi.string().required(),
  address: Joi.string().required(),
  icon: Joi.string().required(),
  background: Joi.string().required(),
  fiatSymbol: Joi.string().allow([null, '']),
  displayDecimals: Joi.number().min(1).required(),
  decimals: Joi.number().min(1).required(),
  tradeDecimals: Joi.number().min(1).required(),
  withdrawalFee: Joi.string().required(),
  crypto: Joi.boolean().default(false),
  depositEnabled: Joi.boolean().default(false),
  withdrawalEnabled: Joi.boolean().default(false),
  transferEnabled: Joi.boolean().default(false),
  buyEnabled: Joi.boolean().default(false),
  purchaseEnabled: Joi.boolean().default(false),
  redeemEnabled: Joi.boolean().default(false),
  active: Joi.boolean().default(false)
})

export default class CreateCurrencyRequest extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  static fromJs (data) {
    return new CreateCurrencyRequest(data, { stripUnknown: true })
  }
}
