/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.5 4h-19A2.503 2.503 0 000 6.5v11C0 18.878 1.122 20 2.5 20h19c1.378 0 2.5-1.122 2.5-2.5v-11C24 5.122 22.878 4 21.5 4zM8.82 12.884l-6 5a.5.5 0 01-.764-.617.499.499 0 01.123-.152l6-5a.5.5 0 01.641.769zm3.18.133c-.55 0-1.101-.164-1.581-.493L2.218 6.913a.5.5 0 01.564-.825l8.201 5.611a1.79 1.79 0 002.033 0l8.201-5.611a.5.5 0 01.565.825l-8.201 5.611a2.793 2.793 0 01-1.581.493zm9.884 4.803a.498.498 0 01-.704.064l-6-5a.5.5 0 11.641-.769l6 5c.211.177.24.493.063.705z" _fill="#F7F9FD"/>'
  }
})
