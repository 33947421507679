import * as components from 'src/components'
import {mapGetters, mapActions} from 'vuex'
import {generateKey, generateTotpUri} from 'authenticator'
import {mask} from 'vue-the-mask'
import _ from 'lodash'

export default {
  directives: {
    mask
  },
  metaInfo: {
    title: '2FA'
  },
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
      passportUser: 'passport/user',
      grants: 'passport/grants'
    }),
    formattedSecret: {
      get: function () {
        return this.secret ? this.secret.replace(/\W/g, '').toUpperCase() : null
      },
      set: function (newValue) {
        this.secret = newValue
      }
    },
    authenticatorUri () {
      if (!this.secret) {
        return null
      }
      const name = _.get(this.passportUser, 'credentials[0].login', this.passportUser.id)
      const uri = generateTotpUri(this.secret, name, 'Audt')
      return uri
    },
    isTwoFaCodeLengthValid () {
      return this.code.length === this.twofaConfirmationCodeLength
    },
    inputCodeErrors () {
      if (this.$v.code.$dirty) {
        if (!this.$v.code.validLength) {
          return [
            '2fa code must be 6 symbols length'
          ]
        }
      }
      return []
    }
  },
  validations: {
    code: {
      validLength (value) {
        return value.length === 6
      }
    }
  },
  data () {
    return {
      secret: generateKey(),
      code: '',
      isError: false,
      errorMsg: '',
      isBusy: false,
      twofaConfirmationCodeLength: 6
    }
  },
  watch: {
    code: {
      async handler () {
        if (!this.isBusy && this.isTwoFaCodeLengthValid) {
          if (this.passportUser.hasVerificator()) {
            this.handleDisable2FA()
          } else {
            this.handleSubmit()
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      enable2FA: 'passport/enable2FA',
      disable2FA: 'passport/disable2FA'
    }),
    reloadSecret () {
      this.secret = generateKey()
    },
    handleSubmit () {
      this.isBusy = true
      setImmediate(async () => {
        try {
          try {
            await this.enable2FA({
              secret: this.secret,
              code: this.code
            })
            this.code = ''
            this.isError = false
          } catch (e) {
            console.error('Cannot setup 2FA', e)
            this.isError = true
            this.errorMsg = 'Cannot setup 2fa'
            // throw e
          }
        } finally {
          this.isBusy = false
        }
      })
    },
    handleDisable2FA () {
      this.isBusy = true
      setImmediate(async () => {
        try {
          try {
            await this.disable2FA({ code: this.code })
            this.code = ''
            this.isError = false
          } catch (e) {
            console.error('Cannot disable 2fa', e)
            this.isError = true
            this.errorMsg = 'Cannot disable 2fa'
          }
        } finally {
          this.isBusy = false
        }
      })
    }
  },
  async mounted () {
  }
}
