import * as components from 'src/components'
import * as micros from 'src/micros'
import {mapActions, mapState, mapGetters} from 'vuex'
import moment from 'moment'
import { ProfileIdentityModel } from 'src/models'
import { copyToClipboard } from 'src/utils/lib/common'

export default {
  metaInfo: {
    title: 'Monoova rejected transaction page'
  },
  components: {
    ...components,
    ...micros
  },
  data () {
    return {
      filters: {
        date: moment().startOf('day').toDate()
      },
      isMobile: window.innerWidth <= 1080
    }
  },
  computed: {
    ...mapState({
      rejectedTransactions: state => state.monoova.rejectedTransactions
    }),
    ...mapGetters({
      hasProfilesViewAccess: 'grants/hasProfilesViewAccess'
    }),
    items () {
      if (!this.rejectedTransactions.isLoaded) {
        return []
      }
      return this.rejectedTransactions.value.map(e => ({
        item: e,
        identity: this.getPassportIdentity(e)
      }))
    },
    passportIds () {
      return this.items.filter(e => e.identity != null).map(e => e.identity.id)
    }
  },
  async created () {
    await this.reloadData()
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  watch: {
    passportIds: {
      immediate: true,
      handler () {
        if (!this.hasProfilesViewAccess) {
          return
        }

        this.reloadIdentities({
          identities: this.passportIds.map(
            id => new ProfileIdentityModel({
              type: 'passport',
              id
            })
          )
        })
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadRejectedMonoovaTransactions: 'monoova/loadRejectedMonoovaTransactions',
      reloadIdentities: 'identities/reload'
    }),
    copyClipboard (path) {
      copyToClipboard(path)
    },
    resizeEventListener () {
      this.isMobile = innerWidth <= 1080
    },
    async handleDate (value) {
      this.filters.date = moment(value, 'YYYY-MM-DD').startOf('day').toDate()
      await this.reloadData()
    },
    async reloadData () {
      await this.loadRejectedMonoovaTransactions({
        date: this.filters.date
      })
    },
    getPassportIdentity (item) {
      if (!item.ssoUserId) {
        return null
      }
      return new ProfileIdentityModel({
        type: 'passport',
        id: item.ssoUserId
      })
    }
  }
}
