import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import moment from 'moment'

export default {
  components: {
    ...components,
    ...micros
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile'
    })
  },
  methods: {
    moment,
    ...mapActions({
      startRapidId: 'myProfileKyc/startRapidId'
    }),
    async handleRapidFlow () {
      try {
        this.isLoading = true
        await this.startRapidId()
      } finally {
        this.isLoading = false
      }
    }
  }
}
