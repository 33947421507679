import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import TokenModel from './TokenModel'
import AbstractTokenDAO from 'src/daos/lib/AbstractTokenDAO'
import ETHTokenDAO from 'src/daos/lib/ETHTokenDAO'
import EIP20TokenDAO from 'src/daos/lib/EIP20TokenDAO'

export const schemaFactory = () => Joi.object({
  token: Joi.object().type(TokenModel).required(),
  dao: Joi.object().type(AbstractTokenDAO).required()
})

export default class TokenDAOModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.token.key
  }

  static fromTokenModel (token, { getAbiByContract }) {
    const EIP20abi = getAbiByContract('EIP20')
    let dao
    switch (token.contract || '_') { // prevent npe
      case 'EIP20':
        dao = new EIP20TokenDAO(token, EIP20abi)
        break
      default:
        dao = new ETHTokenDAO(token)
    }
    return new TokenDAOModel({
      token,
      dao
    })
  }
}
