/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credentials-facebook': {
    width: 14,
    height: 24,
    viewBox: '0 0 14 24',
    data: '<path pid="0" d="M10.938 3.984c-.563 0-1.016.094-1.36.282-.312.187-.515.421-.61.703-.093.281-.14.64-.14 1.078V9h3.985l-.563 4.266H8.828V24H4.422V13.266H.812V9h3.61V5.625c0-1.781.5-3.156 1.5-4.125C6.922.5 8.25 0 9.906 0c1.344 0 2.438.063 3.281.188v3.796h-2.25z"/>'
  }
})
