import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { PlasmaPocketModel } from 'src/models'

export default {
  props: {
    pocket: PlasmaPocketModel,
    pockets: VueTypes.arrayOf(PlasmaPocketModel)
  },
  async mounted () {
    if (this.pocket != null) {
      this.subscription = {
        pocket: this.pocket,
        listener: await this.subscribe({
          pocket: this.pocket
        })
      }
    }
    if (this.pockets != null && this.pockets.length > 0) {
      this.subscriptions = await Promise.all(
        this.pockets.map(async pocket => ({
          pocket,
          listener: await this.subscribe({
            pocket: pocket,
            notLoadBalance: true
          })
        }))
      )

      await this.loadAllBalances({
        pockets: this.pockets
      })
    }
  },
  async destroyed () {
    if (this.subscription) {
      await this.unsubscribe(this.subscription)
      this.subscription = null
    }
    if (this.subscriptions) {
      await Promise.all(this.subscriptions.map(s => this.unsubscribe(s)))
      this.subscriptions = null
    }
  },
  render () {
    if (this.$slots.default) {
      if (this.$slots.default.length === 1) {
        return this.$slots.default[0]
      } else if (this.$slots.default.length > 1) {
        throw new Error('PlasmaBalanceSubscription component may contain max one child')
      }
    }
    return null
  },
  methods: {
    ...mapActions({
      loadAllBalances: 'plasmaBalances/loadAllBalances',
      subscribe: 'plasmaBalances/subscribe',
      unsubscribe: 'plasmaBalances/unsubscribe'
    })
  }
}
