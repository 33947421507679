import { render, staticRenderFns } from "./BasicMonoovaInformation.htm?vue&type=template&id=2136f6e1&scoped=true&external"
import script from "./BasicMonoovaInformation.js?vue&type=script&lang=js&external"
export * from "./BasicMonoovaInformation.js?vue&type=script&lang=js&external"
import style0 from "./BasicMonoovaInformation.scss?vue&type=style&index=0&id=2136f6e1&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2136f6e1",
  null
  
)

export default component.exports