import { mapGetters, mapActions } from 'vuex'
import * as partials from 'src/partials'
import * as directives from 'src/directives'
import * as components from 'src/components'
import { NavigationMixinFactory } from 'src/mixins'

export default {
  mixins: [
    NavigationMixinFactory()
  ],
  directives: {
    ...directives
  },
  components: {
    ...components,
    ...partials
  },
  data () {
    return {
      isModeMenuVisible: false,
      isUserMenuVisible: false
    }
  },
  computed: {
    ...mapGetters({
      mode: 'navigation/mode',
      locale: 'i18n/locale',
      localeOptions: 'i18n/localeOptions',
      passport: 'passport/user',
      grants: 'passport/grants',
      profile: 'myProfileKyc/profile',
      activeTree: 'navigation/activeTree',
      activeGrantedTree: 'navigation/activeGrantedTree',
      modeTree: 'navigation/modeTree'
    }),
    hasGrant () {
      const grantedEnpoints = new Set()

      for (const node of this.activeGrantedTree.children) {
        grantedEnpoints.add(node.to)
      }

      let toPath = this.$route.path
      do {
        if (grantedEnpoints.has(toPath)) {
          return true
        }

        const lastSlash = toPath.lastIndexOf('/')
        toPath = toPath.slice(0, lastSlash)
      } while (toPath !== '')

      return false
    }
  },
  methods: {
    ...mapActions({
      handleSelectLocale: 'i18n/selectLocale',
      handleSignout: 'passport/signout'
    }),
    async handleExit () {
      await this.handleSignout()
      this.$router.push('/')
    },
    toggleModeMenu (isVisible) {
      this.isModeMenuVisible = isVisible
    },
    openMenu () {
      this.isUserMenuVisible = !this.isUserMenuVisible
    }
  }
}
