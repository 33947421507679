/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 9.333c-2 0-6 1.006-6 3V14h12v-1.667c0-1.994-4-3-6-3zm3.333-4.666a3.334 3.334 0 10-6.667 0 3.334 3.334 0 006.667 0z" _fill="#12143C" opacity=".2"/>'
  }
})
