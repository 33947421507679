import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  userId: Joi.string().required(),
  ssoUserId: Joi.string().allow([null, '']),
  createdAt: Joi.date().required(),
  bnb: Joi.string(),
  name: Joi.string().required(),
  number: Joi.string(),
  payId: Joi.string(),
  type: Joi.string().required(),
  currency: Joi.string().required(),
  validationComment: Joi.string().allow(['', null]).optional(),
  state: Joi.string().allow(['CREATED', 'ACCEPTED', 'REJECTED'])
})

export default class BankAccountEntryModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }

    return new BankAccountEntryModel({
      id: data.id,
      userId: data.userId,
      ssoUserId: data.ssoUserId,
      createdAt: new Date(data.createdAt),
      payId: data.payId,
      type: data.type,
      bnb: data.bnb,
      name: data.name,
      number: data.number,
      currency: data.currency,
      validationComment: data.validationComment,
      state: data.state
    })
  }
}
