import moment from 'moment'
import * as components from '../../../components'

export default {
  components: {
    ...components
  },
  computed: {
    currentYear () {
      return moment().format('YYYY')
    }
  },
  methods: {
    handleScrollToSelector (selector) {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
    }
  }
}
