import {mapGetters, mapState} from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData'
    }),
    ...mapState({
      profileId: state => state.route.params.id
    }),
    credentials () {
      return this.getProfileData(this.profileId).value.credentials
    }
  }
}
