import Joi from 'joi'
import AbstractModel from './AbstractModel'

const schemaFactory = (type) => ({
  isLoading: Joi.boolean().required(),
  isLoaded: Joi.boolean().required(),
  value: Joi.array().items(Joi.object().type(type)),
  cursor: Joi.any().allow(null)
})

export default class LoadableSequenceModel extends AbstractModel {
  constructor (type, data = {
    isLoaded: false,
    isLoading: false,
    value: [],
    cursor: null
  }) {
    super(data, schemaFactory(type))
    this.type = type
  }

  map (callback) {
    return new LoadableSequenceModel(this.type, {
      isLoaded: this.isLoaded,
      isLoading: this.isLoading,
      value: this.value.map(callback),
      cursor: this.cursor
    })
  }

  loaded (cursor, ...value) {
    return new LoadableSequenceModel(this.type, {
      isLoaded: true,
      isLoading: false,
      value: [...this.value, ...value],
      cursor
    })
  }

  loading () {
    return new LoadableSequenceModel(this.type, {
      isLoaded: this.isLoaded,
      isLoading: true,
      value: this.value,
      cursor: this.cursor
    })
  }
}
