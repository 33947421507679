import { DeviceStatus } from 'src/models'
import { mapValues } from 'lodash'

import { REGISTRY } from 'src/services'
import CheckDeviceModal from 'src/modals/lib/CheckDeviceModal/CheckDeviceModal.vue'

const INIT = 'init'
const DEVICE_STATUS = 'device/status'

export default () => ({
  namespaced: true,
  state () {
    return {
      statuses: null
    }
  },
  mutations: {
    [INIT] (state) {
      state.statuses = mapValues(REGISTRY.getAllDevices(), (device, name) => new DeviceStatus({ name, device }))
    },
    [DEVICE_STATUS] (state, { name, ...props }) {
      const status = state.statuses[name]
      if (status) {
        state.statuses = {
          ...state.statuses,
          [name]: status.update(props)
        }
      }
    }
  },
  getters: {
    getDeviceStatus: (state) => (name) => state.statuses[name] || new DeviceStatus({ name, isActive: false })
  },
  actions: {
    async init ({ state, commit, dispatch }) {
      commit(INIT, REGISTRY.getAllDevices())
      // eslint-disable-next-line
      Object.values(state.statuses).map(status =>
        status.device
          .on('connected', () => commit(DEVICE_STATUS, { name: status.name, isActive: true }))
          .on('disconnected', () => commit(DEVICE_STATUS, { name: status.name, isActive: false }))
          .on('prompt', ({ title, message, device, promptId }) => dispatch('prompt', { title, message, device, promptId }))
          .on('input', ({ device, promptId }) => dispatch('input', { device, promptId }))
      )
    },
    async prompt ({ dispatch, getters }, { title, message, device, promptId }) {
      dispatch('modals/open', {
        factory: () => CheckDeviceModal,
        tags: ['prompt', promptId].filter(entry => entry != null),
        data: {
          title,
          message,
          device: getters.getDeviceStatus(device)
        }
      }, { root: true })
    },
    async input ({ dispatch }, { promptId }) {
      dispatch('modals/remove', {
        tags: ['prompt', promptId].filter(entry => entry != null)
      }, { root: true })
    }
  }
})
