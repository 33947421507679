import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import uniqid from 'uniqid'
import { OperationCanceledError } from 'src/errors'
import * as components from 'src/components'
import MarketPartial from './MarketPartial/MarketPartial.vue'

export default {
  components: {
    ...components,
    MarketPartial
  },
  props: {
    readonly: Boolean,
    currencySymbol: String,
    currentBundle: Object
  },
  data () {
    return {
      isAddInProgress: false,
      form: {
        bundleId: '',
        title: '',
        description: '',
        baseCurrencySymbol: ''
      },
      markets: [],
      currentCurrencySymbol: this.currencySymbol
    }
  },
  async created () {
    await this.loadMarkets()
    await this.loadCurrencies()
  },
  validations: {
    form: {
      title: {
        required
      },
      description: {
        required
      },
      baseCurrencySymbol: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      getCurrencyBySymbol: 'currencies/getCurrencyBySymbol',
      allMarkets: 'markets/markets'
    }),
    option () {
      return this.currencies.value.map(e => e.currency.symbol)
    },
    currentCurrency () {
      return this.currentCurrencySymbol
        ? this.getCurrencyBySymbol(this.currentCurrencySymbol)
        : null
    },
    availableMarkets () {
      return this.allMarkets.value
        .filter(e => e.baseCurrency === this.currentCurrencySymbol || e.quoteCurrency === this.currentCurrencySymbol)
    },
    titleErrors () {
      if (this.$v.form.title.$dirty) {
        if (!this.$v.form.title.required) {
          return [
            'Title is a required field'
          ]
        }
      }
      return []
    },
    descriptionErrors () {
      if (this.$v.form.description.$dirty) {
        if (!this.$v.form.description.required) {
          return [
            'Description is a required field'
          ]
        }
      }
    },
    symbolErrors () {
      if (this.$v.form.baseCurrencySymbol.$dirty) {
        if (!this.$v.form.baseCurrencySymbol.required) {
          return [
            'Currency Symbol is a required field'
          ]
        }
      }
    }
  },
  watch: {
    currentCurrency: {
      handler () {
        if (this.currentCurrency && this.currentBundle) {
          this.form = {
            bundleId: this.currentBundle.bundleId,
            title: this.currentBundle.title,
            description: this.currentBundle.description,
            baseCurrencySymbol: this.currentBundle.baseCurrencySymbol
          }
          this.markets = this.currentBundle.markets.slice(0).map(market => ({
            ...market,
            id: uniqid()
          }))
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      editBundle: 'bundle/editBundle',
      createBundle: 'bundle/createBundle',
      loadMarkets: 'markets/loadMarkets',
      loadCurrencies: 'currencies/loadCurrencies'
    }),
    changeCurrency (value) {
      if (!this.currentCurrencySymbol) {
        this.currentCurrencySymbol = value
      }
      this.markets = []
    },
    async handleSubmit () {
      if (!this.currentBundle) {
        await this.create()
      } else {
        await this.edit()
      }
    },
    async edit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.editBundle({
            ...this.form,
            markets: this.markets.map(market => ({
              ...market
            }))
          })
          await this.handleToast({
            message: 'Bundle updated'
          })
          this.$router.push('/private/system/bundles')
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot edit Bundle'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    },
    async create () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createBundle({
            ...this.form,
            markets: this.markets.map(markets => ({
              ...markets
            }))
          })
          await this.handleToast({
            message: 'Bundle added'
          })
          this.$router.push('/private/system/bundles')
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot add bundle'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    },
    addMarket (market) {
      this.markets.push(market)
    },
    updateMarket (market) {
      const index = this.markets.findIndex(mr => mr.id === market.id)
      this.markets.splice(index, 1, market)
    },
    removeMarket (id) {
      const index = this.markets.findIndex(mr => mr.id === id)
      this.markets.splice(index, 1)
    }
  }
}
