/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'server': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g opacity=".2" fill-rule="evenodd" clip-rule="evenodd" _fill="#12143C"><path pid="0" d="M2.333 1.333a1 1 0 00-1 1v1.51a1 1 0 001 1h11.334a1 1 0 001-1v-1.51a1 1 0 00-1-1H2.333zm11 1.404h-2v.702h2v-.702zM2.333 6.246a1 1 0 00-1 1v1.508a1 1 0 001 1h11.334a1 1 0 001-1V7.246a1 1 0 00-1-1H2.333zM2.333 11.158a1 1 0 00-1 1v1.509a1 1 0 001 1h11.334a1 1 0 001-1v-1.51a1 1 0 00-1-1H2.333z"/></g>'
  }
})
