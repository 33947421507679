/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planet': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 1.333A6.655 6.655 0 002.68 4h-.013v.018A6.657 6.657 0 108 1.333zm2 1.723a5.333 5.333 0 011.934 8.544 1.328 1.328 0 00-1.267-.933H10v-2A.667.667 0 009.333 8H6V6.667h.667A.667.667 0 007.333 6V4.667h1.334A1.333 1.333 0 0010 3.333v-.277zM2.805 6.805L4 8l2 2v.667A1.333 1.333 0 007.333 12v1.288a5.32 5.32 0 01-4.528-6.483z" _fill="#12143B" opacity=".2"/>'
  }
})
