import {LoadableListModel, MarketModel, CreateMarketRequest, UpdateMarketRequest} from 'src/models'
import {BACKEND, PLASMA, withAuthorization} from 'src/remotes'

export const MARKETS_LOADING = 'markets/loading'
export const MARKETS_LOADED = 'markets/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      list: new LoadableListModel(MarketModel)
    }
  },
  mutations: {
    [MARKETS_LOADING]: (state) => {
      state.list = state.list.loading()
    },
    [MARKETS_LOADED]: (state, { markets }) => {
      state.list = state.list.loaded(...markets)
    }
  },
  getters: {
    markets: state => state.list,
    getMarketBySymbol: state => symbol => state.list.isLoaded && state.list.value.find(
      market => market.symbol.toLowerCase() === symbol.toLowerCase()
    ),
    isLoaded: state => state.list.isLoaded
  },
  actions: {
    async fetchMarkets () {
      const { data } = await PLASMA.get('public/markets')
      const markets = data.map(MarketModel.fromJson)
      markets.sort((a, b) => a.sortOrder - b.sortOrder)
      return markets
    },
    async loadMarkets ({ state, commit, dispatch }) {
      commit(MARKETS_LOADING)
      const markets = await dispatch('fetchMarkets')
      commit(MARKETS_LOADED, { markets })
    },
    async createMarket ({ state, commit, dispatch }, { ...params }) {
      const request = new CreateMarketRequest({
        ...params
      })

      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post('markets/', request, withAuthorization(token))
      await dispatch('loadMarkets')
    },
    async editMarket ({ state, commit, dispatch }, { symbol, ...params }) {
      const request = new UpdateMarketRequest({
        ...params
      })

      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`markets/${symbol}`, request, withAuthorization(token))
      await dispatch('loadMarkets')
    },
    async deleteMarket ({ state, commit, dispatch }, { symbol }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post(`markets/${symbol}/remove`, null, withAuthorization(token))
      await dispatch('loadMarkets')
    }
  }
})
