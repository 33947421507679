
export default {
  props: {
    wallets: Array,
    fullWidth: Boolean,
    light: Boolean,
    small: Boolean,
    flat: Boolean,
    singleLine: Boolean,
    hideDetails: Boolean,
    color: String,
    filled: Boolean,
    rounded: Boolean,
    clearable: Boolean
  },
  computed: {
    currentWallet () {
      return this.wallets[0]
    },
    walletInput () {
      return this.currentWallet ? {
        key: this.currentWallet.address,
        name: this.currentWallet.name,
        address: this.currentWallet.address
      } : null
    },
    availableWalletItems () {
      return this.wallets.map(e => ({
        key: e.address,
        name: e.name,
        address: e.address,
        active: e.default
      }))
    }
  },
  methods: {
    changeWallet (key) {
      this.$emit('changeWallet', key)
    }
  }
}
