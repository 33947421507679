var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-atom-deposit-state"},[_c('div',{staticClass:"atom-deposit-state",class:{
    'yellow darken-3': _vm.state === 'STARTED',
    'lime darken-3': _vm.state === 'PENDING',
    'deep-orange darken-1': _vm.state === 'CANCELLED',
    'teal darken-1': _vm.state === 'ACCEPTED',
    'pink darken-1': _vm.state === 'REJECTED',
    'green darken-1': _vm.state === 'CONFIRMED',
    'blue darken-1': _vm.state === 'COMPLETE',
    'red darken-1': _vm.state === 'FAILED',
  }},[_vm._v(_vm._s(_vm.state))])])}
var staticRenderFns = []

export { render, staticRenderFns }