import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import * as micros from 'src/micros'

export default {
  components: {
    ...micros
  },
  props: {
  },
  async created () {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      profile: 'myProfileKyc/profile',
      isLoaded: 'myProfileKyc/isLoaded',
      email: 'myProfileKyc/email',
      levels: 'myProfileKyc/levels'
    })
  },
  watch: {
  },
  methods: {
    moment,
    ...mapActions({
      updateEmail: 'myProfileKyc/updateEmail',
      validateEmail: 'myProfileKyc/validateEmail',
      emailResendConfirm: 'myProfileKyc/resendEmailConfirmation'
    }),
    handleTxFlow () {
      console.log('emit txFlow')
      this.$emit('txFlow')
    }
  },
  mounted () {
  }
}
