import { render, staticRenderFns } from "./ReferralDetails.htm?vue&type=template&id=2c21ede0&scoped=true&external"
import script from "./ReferralDetails.js?vue&type=script&lang=js&external"
export * from "./ReferralDetails.js?vue&type=script&lang=js&external"
import style0 from "./ReferralDetails.scss?vue&type=style&index=0&id=2c21ede0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c21ede0",
  null
  
)

export default component.exports