import { mapState, mapGetters, mapActions } from 'vuex'
import { OperationCanceledError } from 'src/errors'

export default {
  metaInfo: {
    title: 'Customers'
  },
  data () {
    return {
      deleteFlag: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrencyBySymbol: 'currencies/getCurrencyBySymbol'
    }),
    ...mapState({
      symbol: state => state.route.params.currency
    })
  },
  methods: {
    ...mapActions({
      deleteCurrency: 'currencies/deleteCurrency',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      handleConfirmation: 'interaction/confirmation'
    }),
    async handleDelete () {
      await this.handleConfirmation({
        handler: async () => {
          try {
            await this.deleteCurrency({
              symbol: this.symbol
            })
            await this.handleToast({
              message: 'Currency deleted'
            })
            this.$router.push('/private/system/currencies')
          } catch (e) {
            if (!(e instanceof OperationCanceledError)) {
              await this.handleError({
                error: e,
                message: 'Cannot delete currency'
              })
            }
          }
        }
      })
    }
  }
}
