import { render, staticRenderFns } from "./CircleTextBlock.htm?vue&type=template&id=eb2148ce&scoped=true&external"
import script from "./CircleTextBlock.js?vue&type=script&lang=js&external"
export * from "./CircleTextBlock.js?vue&type=script&lang=js&external"
import style0 from "./CircleTextBlock.scss?vue&type=style&index=0&id=eb2148ce&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb2148ce",
  null
  
)

export default component.exports