import { mapState, mapGetters } from 'vuex'
import uniqid from 'uniqid'
import set from 'lodash/set'
import BigNumber from 'bignumber.js'
import { WithdrawalNetwork, DepositAddressType } from 'src/dictionaries'

const DEPOSIT_TYPES = [
  'GeoPayDepositPlugin',
  'ManualDepositPlugin',
  'FaucetDepositPlugin',
  'CryptoDepositPlugin',
  'RaidoDepositPlugin',
  'AdvCashDepositPlugin',
  'MonoovaDepositPlugin',
  'ItezDepositPlugin'
]

const COMMISSION_TYPES = [
  'DefaultCommission',
  'PercentCommission',
  'FixedCommission'
]

export default {
  props: {
    currentCurrency: Object,
    depositPlugins: Array,
    readonly: Boolean
  },
  data () {
    return {
      DEPOSIT_TYPES,
      COMMISSION_TYPES
    }
  },
  created () {
    for (let plugin of this.depositPlugins) {
      if (plugin.minAmount) {
        plugin.minAmount = this.currentCurrency.dao.removeDecimals(new BigNumber(plugin.minAmount))
      }

      if (plugin.maxAmount) {
        plugin.maxAmount = this.currentCurrency.dao.removeDecimals(new BigNumber(plugin.maxAmount))
      }
    }
  },
  computed: {
    ...mapState({
      fiatCurrencies: state => state.currencies.fiatCurrencylist
    }),
    ...mapGetters({
      getFiatCurrencyBySymbol: 'currencies/getFiatCurrencyBySymbol',
      getFiatCurrencyById: 'currencies/getFiatCurrencyById'
    }),
    fiatValues () {
      return this.fiatCurrencies.value.map(e => e.symbol)
    },
    networkValues () {
      return WithdrawalNetwork.values()
    },
    depositAddressTypeValues () {
      return DepositAddressType.values()
    }
  },
  methods: {
    getFiatCurrency (plugin) {
      if (plugin.fiatCurrency) {
        return this.getFiatCurrencyById(plugin.fiatCurrency).symbol
      }
      return null
    },
    handlePluginAdd () {
      this.$emit('pluginAdd', {
        id: uniqid(),
        type: null,
        minKycLevel: null,
        minAmount: null,
        maxAmount: null,
        commission: {},
        bank: null
      })
    },
    handlePluginRemove (plugin) {
      this.$emit('pluginRemove', plugin.id)
    },
    updatePluginField (plugin, field, value) {
      let fiatCurrency = plugin.fiatCurrency
      let requiredPhonePrefixes = plugin.requiredPhonePrefixes
      if (field === 'fiatCurrency') {
        fiatCurrency = this.getFiatCurrencyBySymbol(value).id
      }
      if (field === 'requiredPhonePrefixes') {
        requiredPhonePrefixes = value
          .split(',')
          .map(e => e.trim())
          .filter(e => e !== '')
      }

      plugin = {
        ...plugin,
        commission: {
          ...plugin.commission
        },
        fiatCurrency,
        requiredPhonePrefixes
      }

      if (field !== 'fiatCurrency' && field !== 'requiredPhonePrefixes') {
        set(plugin, field, value)
      }

      this.$emit('updatePlugin', plugin)
    }
  }
}
