import * as components from 'src/components'
import SectionFormLayout from '../SectionFormLayout/SectionFormLayout.vue'
import * as directives from 'src/directives'

export default {
  components: {
    ...components,
    SectionFormLayout
  },
  directives: {
    ...directives
  }
}
