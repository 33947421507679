import BigNumber from 'bignumber.js'
import uniqid from 'uniqid'
import {mapActions, mapGetters} from 'vuex'
import {required, numeric} from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'
import {isGreaterThan, isGreaterThanOrEqual, isLessThanOrEqual} from 'src/validations'
import DepositPartial from './DepositPartial/DepositPartial.vue'
import WithdrawPartial from './WithdrawPartial/WithdrawPartial.vue'

export default {
  components: {
    ...components,
    DepositPartial,
    WithdrawPartial
  },
  props: {
    readonly: Boolean,
    currencySymbol: String
  },
  data () {
    return {
      isAddInProgress: false,
      symbolsArr: [],
      form: {
        symbol: '',
        name: '',
        address: '',
        icon: '',
        coloredIcon: null,
        background: 'transparent',
        fiatSymbol: '',
        decimals: '',
        displayDecimals: '',
        tradeDecimals: '',
        crypto: true,
        depositEnabled: false,
        withdrawalEnabled: false,
        transferEnabled: false,
        showOnLanding: true,
        showOnCoinPages: false,
        active: true,
        isSepa: false,
        sortOrder: null,
        link: ''
      },
      depositPlugins: [],
      withdrawPlugins: []
    }
  },
  validations: {
    form: {
      symbol: {
        required,
        symbolCheck (value) {
          return this.currentCurrency || !this.currencies.value.map(e => e.currency.symbol).includes(value)
        }
      },
      name: {
        required
      },
      sortOrder: {
        required,
        numeric
      },
      address: {
        required,
        addressCheck (value) {
          return this.currentCurrency || !this.currencies.value.map(e => e.currency.address).includes(value.toLowerCase())
        }
      },
      icon: {
        startCheck (val) {
          return val.startsWith('data:image/svg+xml;base64,') || ''
        }
      },
      coloredIcon: {
        startCheck (val) {
          if (val === '' || val == null) {
            return true
          }
          return val.startsWith('data:image/svg+xml;base64,')
        }
      },
      decimals: {
        required,
        numeric,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      tradeDecimals: {
        required,
        numeric,
        tradeDecimals (value) {
          return isGreaterThanOrEqual(value, this.form.decimals)
        }
      },
      displayDecimals: {
        required,
        numeric,
        displayDecimals (value) {
          return isLessThanOrEqual(value, this.form.decimals)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      getCurrencyBySymbol: 'currencies/getCurrencyBySymbol'
    }),
    currentCurrency () {
      return this.currencySymbol
        ? this.getCurrencyBySymbol(this.currencySymbol)
        : null
    },
    symbolErrors () {
      if (this.$v.form.symbol.$dirty) {
        if (!this.$v.form.symbol.required) {
          return [
            'Symbol is a required field'
          ]
        }
        if (!this.$v.form.symbol.symbolCheck) {
          return [
            'This symbol already exists'
          ]
        }
      }
      return []
    },
    addressErrors () {
      if (this.$v.form.address.$dirty) {
        if (!this.$v.form.address.required) {
          return [
            'Address is a required field'
          ]
        }
        if (!this.$v.form.address.addressCheck) {
          return [
            'This address already exists'
          ]
        }
      }
    },
    decimalsErrors () {
      if (this.$v.form.decimals.$dirty) {
        if (!this.$v.form.decimals.required) {
          return [
            'Decimals is a required field'
          ]
        }
        if (!this.$v.form.decimals.numeric) {
          return [
            'Decimals can be integer only'
          ]
        }
        if (!this.$v.form.decimals.numberCheck) {
          return [
            'Decimals must be number and more than 0'
          ]
        }
      }
    },
    tradeDecimalsErrors () {
      if (this.$v.form.tradeDecimals.$dirty) {
        if (!this.$v.form.tradeDecimals.required) {
          return [
            'tradeDecimals is a required field'
          ]
        }
        if (!this.$v.form.tradeDecimals.numeric) {
          return [
            'Trade Decimals can be integer only'
          ]
        }
        if (!this.$v.form.tradeDecimals.tradeDecimals) {
          return [
            'Trade Decimals must be greater or equal than Decimals'
          ]
        }
      }
    },
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
    },
    iconErrors () {
      if (this.$v.form.icon.$dirty) {
        if (!this.$v.form.icon.startCheck) {
          return [
            'Icon text should start with data:image/svg+xml;base64,'
          ]
        }
      }
    },
    coloredIconErrors () {
      if (this.$v.form.coloredIcon.$dirty) {
        if (!this.$v.form.coloredIcon.startCheck) {
          return [
            'colored icon text should start with data:image/svg+xml;base64,'
          ]
        }
      }
    },
    displayDecimalsErrors () {
      if (this.$v.form.displayDecimals.$dirty) {
        if (!this.$v.form.displayDecimals.required) {
          return [
            'Display Decimals is a required field'
          ]
        }
        if (!this.$v.form.displayDecimals.numeric) {
          return [
            'Display Decimals can be integer only'
          ]
        }
        if (!this.$v.form.displayDecimals.displayDecimals) {
          return [
            'Display Decimals  must be smaller than Decimals'
          ]
        }
      }
    },
    sortOrderErrors () {
      if (this.$v.form.sortOrder.$dirty) {
        if (!this.$v.form.sortOrder.required) {
          return [
            'Sort order is a required field'
          ]
        }
        if (!this.$v.form.sortOrder.numeric) {
          return [
            'Sort order can be integer only'
          ]
        }
      }
    }
  },
  watch: {
    currentCurrency: {
      handler () {
        if (this.currentCurrency && this.currentCurrency.currency) {
          const currency = this.currentCurrency.currency
          this.form = {
            symbol: currency.symbol,
            name: currency.name,
            address: currency.address,
            icon: currency.icon,
            coloredIcon: currency.coloredIcon,
            background: currency.background,
            fiatSymbol: currency.fiatSymbol,
            decimals: currency.decimals,
            displayDecimals: currency.displayDecimals,
            tradeDecimals: currency.tradeDecimals,
            crypto: currency.crypto,
            depositEnabled: currency.depositEnabled,
            withdrawalEnabled: currency.withdrawalEnabled,
            transferEnabled: currency.transferEnabled,
            showOnLanding: currency.showOnLanding,
            showOnCoinPages: currency.showOnCoinPages,
            active: currency.active,
            isSepa: currency.isSepa,
            sortOrder: currency.sortOrder,
            link: currency.link
          }
          this.depositPlugins = currency.depositPlugins.slice(0).map(plugin => ({
            ...plugin,
            id: uniqid(),
            bank: plugin.bank ? plugin.bank.id : null,
            commission: {
              ...plugin.commission,
              min: plugin.commission && plugin.commission.min ? this.removeCommissionDecimal(plugin.commission.min) : null,
              max: plugin.commission && plugin.commission.max ? this.removeCommissionDecimal(plugin.commission.max) : null,
              value: plugin.commission && plugin.commission.value ? this.removeCommissionDecimal(plugin.commission.value) : null
            }
          }))
          this.withdrawPlugins = currency.withdrawPlugins.slice(0).map(plugin => ({
            ...plugin,
            id: uniqid(),
            commission: {
              ...plugin.commission,
              min: plugin.commission && plugin.commission.min ? this.removeCommissionDecimal(plugin.commission.min) : null,
              max: plugin.commission && plugin.commission.max ? this.removeCommissionDecimal(plugin.commission.max) : null,
              value: plugin.commission && plugin.commission.value ? this.removeCommissionDecimal(plugin.commission.value) : null
            }
          }))
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createCurrency: 'currencies/createCurrency',
      editCurrency: 'currencies/editCurrency'
    }),
    async handleSubmit () {
      if (!this.currentCurrency) {
        await this.create()
      } else {
        await this.edit()
      }
    },
    async edit () {
      this.isAddInProgress = true
      try {
        await this.editCurrency({
          currency: this.currentCurrency,
          ...this.form,
          depositPlugins: this.depositPlugins.map(plugin => ({
            ...plugin,
            minAmount: plugin.minAmount && this.currentCurrency.dao.addDecimals(new BigNumber(plugin.minAmount)).toString(),
            maxAmount: plugin.maxAmount && this.currentCurrency.dao.addDecimals(new BigNumber(plugin.maxAmount)).toString(),
            commission: {
              ...plugin.commission,
              min: plugin.commission && plugin.commission.min ? this.addCommissionDecimal(plugin.commission.min) : null,
              max: plugin.commission && plugin.commission.max ? this.addCommissionDecimal(plugin.commission.max) : null,
              value: plugin.commission && plugin.commission.value ? this.addCommissionDecimal(plugin.commission.value) : null
            }
          })),
          withdrawPlugins: this.withdrawPlugins.map(plugin => ({
            ...plugin,
            minAmount: plugin.minAmount && this.currentCurrency.dao.addDecimals(new BigNumber(plugin.minAmount)).toString(),
            maxAmount: plugin.maxAmount && this.currentCurrency.dao.addDecimals(new BigNumber(plugin.maxAmount)).toString(),
            commission: {
              ...plugin.commission,
              min: plugin.commission && plugin.commission.min ? this.addCommissionDecimal(plugin.commission.min) : null,
              max: plugin.commission && plugin.commission.max ? this.addCommissionDecimal(plugin.commission.max) : null,
              value: plugin.commission && plugin.commission.value ? this.addCommissionDecimal(plugin.commission.value) : null
            }
          })),
          address: (this.form.address).toLowerCase()
        })
        await this.handleToast({
          message: 'Currency edited'
        })
        this.$router.push('/private/system/currencies')
      } catch (e) {
        console.log('e?', e)
        if (!(e instanceof OperationCanceledError)) {
          await this.handleError({
            error: e
          })
        }
      } finally {
        this.isAddInProgress = false
      }
    },
    async create () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createCurrency({
            ...this.form,
            depositPlugins: this.depositPlugins.map(plugin => ({
              ...plugin,
              commission: {
                ...plugin.commission,
                minAmount: plugin.minAmount ? this.currentCurrency.dao.addDecimals(new BigNumber(plugin.minAmount)).toString() : null,
                min: plugin.commission && plugin.commission.min ? this.addCommissionDecimal(plugin.commission.min) : null,
                max: plugin.commission && plugin.commission.max ? this.addCommissionDecimal(plugin.commission.max) : null,
                value: plugin.commission && plugin.commission.value ? this.addCommissionDecimal(plugin.commission.value) : null
              }
            })),
            withdrawPlugins: this.withdrawPlugins.map(plugin => ({
              ...plugin,
              commission: {
                ...plugin.commission,
                minAmount: plugin.minAmount ? this.currentCurrency.dao.addDecimals(new BigNumber(plugin.minAmount)).toString() : null,
                min: plugin.commission && plugin.commission.min ? this.addCommissionDecimal(plugin.commission.min) : null,
                max: plugin.commission && plugin.commission.max ? this.addCommissionDecimal(plugin.commission.max) : null,
                value: plugin.commission && plugin.commission.value ? this.addCommissionDecimal(plugin.commission.value) : null
              }
            })),
            address: (this.form.address).toLowerCase()
          })
          await this.handleToast({
            message: 'Currency added'
          })
          this.$router.push('/private/system/currencies')
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot add currency'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    },
    addCommissionDecimal (value) {
      const multiplier = new BigNumber(10).pow(this.form.tradeDecimals)
      return new BigNumber(value).times(multiplier).toString()
    },
    removeCommissionDecimal (value) {
      const divider = new BigNumber(10).pow(this.currentCurrency.currency.tradeDecimals)
      return new BigNumber(value).div(divider).toString()
    },
    addDepositPlugin (plugin) {
      this.depositPlugins.push(plugin)
    },
    addWithdrawPlugin (plugin) {
      this.withdrawPlugins.push(plugin)
    },
    updateDepositPlugin (plugin) {
      const index = this.depositPlugins.findIndex(pl => pl.id === plugin.id)
      this.depositPlugins.splice(index, 1, plugin)
    },
    updateWithdrawPlugin (plugin) {
      const index = this.withdrawPlugins.findIndex(pl => pl.id === plugin.id)
      this.withdrawPlugins.splice(index, 1, plugin)
    },
    removeWithdrawPlugin (id) {
      const index = this.withdrawPlugins.findIndex(pl => pl.id === id)
      this.withdrawPlugins.splice(index, 1)
    },
    removeDepositPlugin (id) {
      const index = this.depositPlugins.findIndex(pl => pl.id === id)
      this.depositPlugins.splice(index, 1)
    }
  }
}
