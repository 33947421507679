import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.number().required(),
  bsb: Joi.string().allow(['', null]),
  accountName: Joi.string().allow(['', null]),
  accountNumber: Joi.string().allow(['', null]),
  enabled: Joi.boolean()
})

export default class MonoovaWhitelistModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new MonoovaWhitelistModel(
      { ...data },
      { stripUnknown: true }
    )
  }
}
