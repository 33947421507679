import * as components from 'src/components'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    ...components
  },
  data () {
    return {
      filters: {
        from: moment().subtract(14, 'days').startOf('day').toDate(),
        to: moment().subtract(1, 'days').endOf('day').toDate()
      }
    }
  },
  computed: {
    ...mapGetters({
      rewardsSummary: 'stats/rewardsSummary'
    }),
    rewardsSummaryItem () {
      return this.rewardsSummary.value
    }
  },
  async created () {
    await this.reloadData()
  },
  methods: {
    ...mapActions({
      loadRewardsSummary: 'stats/loadRewardsSummary'
    }),
    handleFrom (value) {
      this.filters.from = moment(value, 'YYYY-MM-DD').startOf('day').toDate()
      this.reloadData()
    },
    handleTo (value) {
      this.filters.to = moment(value, 'YYYY-MM-DD').endOf('day').toDate()
      this.reloadData()
    },
    async reloadData () {
      this.loadRewardsSummary({
        from: this.filters.from,
        to: this.filters.to
      })
    }
  }
}
