import * as components from 'src/components'
import OptionImportTrezor from './OptionImportTrezor/OptionImportTrezor.vue'
import OptionImportLedger from './OptionImportLedger/OptionImportLedger.vue'

export default {
  components: {
    ...components,
    OptionImportTrezor,
    OptionImportLedger
  },
  data () {
    return {
      type: 'trezor',
      options: [
        { key: 'trezor', title: 'Trezor' },
        { key: 'ledger', title: 'Ledger Nano S' }
      ]
    }
  }
}
