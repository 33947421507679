import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import { stringToColour } from 'src/utils/lib/stringToColour'
import { copyToClipboard } from 'src/utils/lib/common'

export default {
  metaInfo: {
    title: 'Addresses'
  },
  components: {
    ...components
  },
  async created () {
    await this.loadAddresses()
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  computed: {
    ...mapGetters({
      isLoaded: 'customAddresses/isLoaded',
      isLoading: 'customAddresses/isLoading',
      addresses: 'customAddresses/items',
      getScoutAddressReference: 'scout/getScoutAddressReference'
    }),
    filteredItems () {
      let result = this.addresses
      if (this.searchName) {
        result = result.filter(item => item.name.toLowerCase().includes(this.searchName.toLowerCase()))
      }
      if (this.searchAddress) {
        result = result.filter(item => item.address.toLowerCase().includes(this.searchAddress.toLowerCase()))
      }
      return result
    }
  },
  data () {
    return {
      deleteIdle: false,
      isMobile: window.innerWidth <= 1024,
      searchName: '',
      searchAddress: ''
    }
  },
  methods: {
    ...mapActions({
      loadAddresses: 'customAddresses/loadAddresses'
    }),
    resizeEventListener () {
      this.isMobile = innerWidth <= 1024
    },
    copyClipboard (path) {
      copyToClipboard(path)
    },
    colorize (value) {
      return stringToColour(value)
    }
  }
}
