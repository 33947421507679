import { omitBy, isUndefined } from 'lodash'
import { REGISTRY } from 'src/services'

export const NETWORK_PROVIDER_UPDATE = 'network/provider/update'
export const NETWORK_INITIALIZED = 'network/initialized'
export const NETWORK_SESSION_STATUS = 'network/session/status'

export default () => ({
  namespaced: true,
  state () {
    return {
      provider: null,
      services: {
        web3SessionId: null,
        plasmaSessionId: null,
        profileSessionId: null,
        exchangeSessionId: null,
        ratesSessionId: null
      }
    }
  },
  mutations: {
    [NETWORK_SESSION_STATUS] (state, { web3SessionId, profileSessionId, plasmaSessionId, exchangeSessionId, ratesSessionId }) {
      // eslint-disable-next-line
      Object.assign(
        state.services,
        omitBy({ web3SessionId, profileSessionId, plasmaSessionId, exchangeSessionId, ratesSessionId }, isUndefined)
      )
    }
  },
  getters: {
    web3SessionId (state) {
      return state.services.web3SessionId
    },
    profileSessionId (state) {
      return state.services.profileSessionId
    },
    exchangeSessionId (state) {
      return state.services.exchangeSessionId
    },
    plasmaSessionId (state) {
      return state.services.plasmaSessionId
    },
    ratesSessionId (state) {
      return state.services.ratesSessionId
    }
  },
  actions: {
    async initialize ({ commit, dispatch }) {
      // const web3Client = REGISTRY.getService('web3Client')
      // web3Client
      //   .on('close', () => commit(NETWORK_SESSION_STATUS, { web3SessionId: null }))
      //   .on('error', console.warn)
      //   .on('open', ({ sessionId }) => {
      //     commit(NETWORK_SESSION_STATUS, { web3SessionId: sessionId })
      //     dispatch('reconnectWeb3')
      //   })

      const plasmaSocketClient = REGISTRY.getService('plasmaSocketClient')
      plasmaSocketClient
        .on('close', () => commit(NETWORK_SESSION_STATUS, { plasmaSessionId: null }))
        .on('open', ({ sessionId }) => {
          commit(NETWORK_SESSION_STATUS, { plasmaSessionId: sessionId })
          dispatch('reconnectPlasma')
        })

      const profileSocketClient = REGISTRY.getService('profileSocketClient')
      profileSocketClient
        .on('close', () => commit(NETWORK_SESSION_STATUS, { profileSessionId: null }))
        .on('open', ({ sessionId }) => {
          commit(NETWORK_SESSION_STATUS, { profileSessionId: sessionId })
          dispatch('reconnectProfile')
        })

      const exchangeSocketClient = REGISTRY.getService('exchangeSocketClient')
      exchangeSocketClient
        .on('close', () => commit(NETWORK_SESSION_STATUS, { exchangeSessionId: null }))
        .on('open', ({ sessionId }) => {
          commit(NETWORK_SESSION_STATUS, { exchangeSessionId: sessionId })
          dispatch('reconnectExchange')
        })

      const ratesSocketClient = REGISTRY.getService('ratesSocketClient')
      ratesSocketClient
        .on('close', () => commit(NETWORK_SESSION_STATUS, { ratesSessionId: null }))
        .on('open', ({ sessionId }) => {
          commit(NETWORK_SESSION_STATUS, { ratesSessionId: sessionId })
          dispatch('reconnectRates')
        })

      commit(NETWORK_SESSION_STATUS, {
        // web3SessionId: web3Client.sessionId,
        plasmaSessionId: plasmaSocketClient.sessionId,
        profileSessionId: profileSocketClient.sessionId,
        exchangeSessionId: exchangeSocketClient.sessionId,
        ratesSessionId: ratesSocketClient.sessionId
      })
    },
    async reconnectWeb3 ({ state, dispatch }) {
      await dispatch('daos/reconnectDaos', null, { root: true })
      await dispatch('tokens/reconnectTokens', null, { root: true })
      // eslint-disable-next-line no-console
      console.warn('[network] Web3 Reconnected')
    },
    async reconnectPlasma ({ dispatch }) {
      await dispatch('currencies/reconnectCurrencies', null, { root: true })
      // await dispatch('markets/reconnectMarkets', null, { root: true })
      // eslint-disable-next-line no-console
      console.warn('[network] Plasma Reconnected')
    },
    async reconnectProfile ({ dispatch }) {
      // eslint-disable-next-line no-console
      console.warn('[network] Profile Reconnected')
    },
    async reconnectExchange ({ dispatch }) {
      // eslint-disable-next-line no-console
      console.warn('[network] Exchange Reconnected')
    },
    async reconnectRates ({ dispatch }) {
      // eslint-disable-next-line no-console
      console.warn('[network] Rates Reconnected')
    }
  }
})
