import Joi from 'joi'
import AbstractModel from './AbstractModel'

export const schemaFactory = () => ({
  name: Joi.string(),
  domain: Joi.string(),
  provider: Joi.string().lowercase(),
  value: Joi.boolean()
})

export default class NotificationItem extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  static fromJs (data) {
    return new NotificationItem(data, { stripUnknown: true })
  }
}
