import {
  REGISTRY,
  socketClientFactory,
  plasmaSocketClientFactory,
  profileSocketClientFactory,
  exchangeSocketClientFactory,
  ratesSocketClientFactory,
  passportClientFactory,
  memoryDeviceFactory,
  trezorDeviceFactory,
  ledgerDeviceFactory,
  metamaskPluginFactory,
  serverPluginFactory
  // web3ClientFactory
} from 'src/services'

export default async ({ store }) => {
  try {
    if (process.client) {
      const config = store.getters['config/config']

      REGISTRY
        // .registerService('web3Client', web3ClientFactory(config))
        .registerService('socketClient', socketClientFactory(config))
        .registerService('plasmaSocketClient', plasmaSocketClientFactory(config))
        .registerService('profileSocketClient', profileSocketClientFactory(config))
        .registerService('exchangeSocketClient', exchangeSocketClientFactory(config))
        .registerService('ratesSocketClient', ratesSocketClientFactory(config))
        .registerService('passportClient', passportClientFactory(config))
        .registerDevice('memory', memoryDeviceFactory())
        .registerDevice('trezor', trezorDeviceFactory(config))
        .registerDevice('ledger', ledgerDeviceFactory())
        .registerPlugin('metamask', metamaskPluginFactory())
        .registerPlugin('server', serverPluginFactory())

      window.addEventListener('message', message => {
        return store.dispatch('messages/handleMessage', message)
      }, false)
    }
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
    throw e
  }
}
