import { omitBy, isNil } from 'lodash'
import {
  CustomerModel,
  CustomerData,
  BankAccountModel,
  RiskRequestModel,
  LoadableModel,
  LoadableMapModel,
  GetRapidIDResponse,
  LoadableListModel, RapidIDModel
} from 'src/models'
import { PASSPORT, PROFILE, withAuthorization } from 'src/remotes'

export const CUSTOMERS_LOADING = 'customers/loading'
export const CUSTOMERS_LOADED = 'customers/loaded'
export const CLEAR_CUSTOMERS = 'customers/clear'

export const CUSTOMER_KYC_DATA_LOADED = 'customer/data/kyc/loaded'
export const CUSTOMER_KYC_DATA_LOADING = 'customer/data/kyc/loading'
export const CUSTOMERS_TABLE_DATA_LOADED = 'customer/table/data/loaded'
export const TEMPLATES_LOADED = 'customer/data/templates/loaded'
export const TEMPLATES_LOADING = 'customer/data/templates/loading'
export const FOUR_STOP_LOADED = 'customer/data/4stop/loading'
export const CHANGE_ACTIVE_PAGE = 'customer/data/activePage/change'
export const SET_HISTORY_STATE = 'customer/historyState/set'

export const RAPIDID_LOADING = 'rapidid/loading'
export const RAPIDID_LOADED = 'rapidid/loaded'

export const DEFAULT_PAGE_SIZE = 30

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      templatesIsLoading: false,
      actualDate: null,
      activePage: 1,
      numberOfElements: 30, // page size
      limit: 100,
      templates: [],
      items: [],
      tableData: [],
      fourStop: [],
      count: null,
      historyState: null,
      filters: {
        level: null,
        q: null
      },
      tableKYC: new LoadableMapModel(LoadableModel),
      rapidIdItems: new LoadableListModel(RapidIDModel),
      page: 0,
      rapidTotalPages: 0
    }
  },
  mutations: {
    [CHANGE_ACTIVE_PAGE]: (state, page) => {
      state.activePage = page
    },
    [FOUR_STOP_LOADED]: (state, data) => {
      state.fourStop = data
    },
    [CUSTOMERS_LOADING]: (state, flag) => {
      state.isLoading = flag
    },
    [CUSTOMERS_TABLE_DATA_LOADED]: (state, data) => {
      state.tableData = data
      state.count = data.count
    },
    [CUSTOMERS_LOADED]: (state, { items }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        items
      }, isNil))
    },
    [CLEAR_CUSTOMERS]: (state) => {
      state.items = []
      state.isLoaded = false
    },
    [CUSTOMER_KYC_DATA_LOADING]: (state, { id }) => {
      const model = state.tableKYC.get(id) || new LoadableModel(CustomerData)
      state.tableKYC = state.tableKYC.put(id, model.loading())
    },
    [CUSTOMER_KYC_DATA_LOADED]: (state, { id, value }) => {
      const model = state.tableKYC.get(id) || new LoadableModel(CustomerData, { value })
      state.tableKYC = state.tableKYC.put(id, model.loaded(value))
    },
    [TEMPLATES_LOADED]: (state, { templates }) => {
      state.templates = templates
    },
    [TEMPLATES_LOADING]: (state, flag) => {
      state.templatesIsLoading = flag
    },
    [SET_HISTORY_STATE]: (state, data) => {
      state.historyState = data
    },
    [RAPIDID_LOADING]: (state) => {
      state.rapidIdItems = state.rapidIdItems.loading()
    },
    [RAPIDID_LOADED]: (state, { rapidIdItems, page, rapidTotalPages, count }) => {
      state.rapidIdItems = state.rapidIdItems.loaded(...rapidIdItems)
      state.page = page
      state.rapidTotalPages = rapidTotalPages
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    actualDate: state => state.actualDate,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    items: state => state.items,
    totalPages (state) {
      const count = state.count
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    },
    tableData: state => state.tableData,
    getKYCProfileData: state => id => state.tableKYC.get(id) || new LoadableModel(CustomerData),
    getCustomerTemplates: state => state.templates,
    fourStop: state => state.fourStop,
    rapidIdItems: state => state.rapidIdItems
  },
  actions: {
    async fetchRapidIdItems ({ dispatch, state }, { page, size }) {
      const request = {
        limit: size,
        offset: page * size
      }
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('/kyc/manager/rapidid/pending', withAuthorization(token, {
        params: request
      }))
      return GetRapidIDResponse.fromJson(data)
    },
    async loadRapidIdItems ({ state, commit, dispatch }, { page = 0, size = DEFAULT_PAGE_SIZE }) {
      commit(RAPIDID_LOADING)
      const {
        items,
        offset,
        count
      } = await dispatch('fetchRapidIdItems', { page, size })
      commit(RAPIDID_LOADED, {
        rapidIdItems: items,
        page: Math.floor(offset / size),
        rapidTotalPages: Math.ceil(count / size)
      })
    },
    async refreshRapidId ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post(`/kyc/manager/rapidid/${id}/refresh`, null, withAuthorization(token))
      await dispatch('loadRapidIdItems', { page: state.page, size: DEFAULT_PAGE_SIZE })
    },
    async fetch ({ dispatch }, { filters = {} } = { filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('kyc/manager/customers/v2', withAuthorization(token, {
        params: {
          ...filters
        }
      }))
      return data
    },
    async loadMore ({ state, commit, dispatch }, { filters }) {
      if (!state.isLoading) {
        commit(CUSTOMERS_LOADING, true)
        const { items } = await dispatch('fetch', {
          filters
        })
        commit(CUSTOMERS_LOADED, {
          items: items.map(CustomerModel.fromJson)
        })
      }
    },
    async clearCustomers ({ commit }) {
      commit(CLEAR_CUSTOMERS)
    },
    async fetchTyped ({ dispatch }, options) {
      const { items } = await dispatch('fetch', options)
      return items.map(CustomerModel.fromJson)
    },
    async fetchKYCData ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get(`kyc/manager/customers/i/${id}/data`, withAuthorization(token))
      return data
    },
    async loadKYCProfileData ({ commit, dispatch }, { id }) {
      commit(CUSTOMER_KYC_DATA_LOADING, { id })
      const value = await dispatch('fetchKYCData', { id })
      commit(CUSTOMER_KYC_DATA_LOADED, { id, value: CustomerData.fromJson(value) })
    },
    async loadMoreKYCUpdates (
      { state, commit, dispatch },
      {
        key,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        },
        sort,
        emailCredential,
        emailKyc
      } = {
        key: 'address',
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        },
        sort: 'asc',
        emailCredential: null,
        emailKyc: null
      }
    ) {
      if (!state.isLoading) {
        commit(CUSTOMERS_LOADING, true)
        await dispatch('getUsersKYCUpdates', {
          offset: (page.activePage - 1) * page.numberOfElements,
          limit: page.numberOfElements,
          key: key,
          sort: sort,
          emailCredential: emailCredential,
          emailKyc: emailKyc
        })
        commit(CHANGE_ACTIVE_PAGE, page.activePage)
        commit(CUSTOMERS_LOADING, false)
      }
    },
    async fetchBankAccounts ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data: bankAccounts } = await PROFILE.get(`manager/profiles/i/${id}/bank-accounts`, withAuthorization(token))
      return bankAccounts.map(BankAccountModel.fromJson)
    },
    async fetchRiskRequests ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data: bankAccounts } = await PROFILE.get(`manager/profiles/i/${id}/risk-requests`, withAuthorization(token))
      return bankAccounts.map(RiskRequestModel.fromJson)
    },
    async acceptBankAccount ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`manager/bank-accounts/i/${id}/accept`, null, withAuthorization(token))
      const bankAccount = BankAccountModel.fromJson(data)
      return bankAccount
    },
    async rejectBankAccount ({ dispatch }, { id, validationComment }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`manager/bank-accounts/i/${id}/reject`, {
        validationComment
      }, withAuthorization(token))
      const bankAccount = BankAccountModel.fromJson(data)
      return bankAccount
    },
    async check ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`manager/profiles/i/${id}/check`, null, withAuthorization(token))
      const riskRequest = RiskRequestModel.fromJson(data)
      return riskRequest
    },
    async getStatistics ({ dispatch }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get(`kyc/manager/customers/statistics`, withAuthorization(token))
      return data
    },
    setTableData ({ commit }, data) {
      commit(CUSTOMERS_TABLE_DATA_LOADED, data)
    },
    async getUsersKYCUpdates ({ commit, dispatch }, { offset, limit, key, sort, emailCredential, emailKyc }) {
      commit(CUSTOMERS_LOADING, true)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get(`kyc/manager/customers/${key}`, withAuthorization(token, {
        params: {
          offset,
          limit,
          sort,
          emailCredential,
          emailKyc
        }
      }))
      commit(CUSTOMERS_TABLE_DATA_LOADED, data)
      commit(CUSTOMERS_LOADING, false)
      return data
    },
    async getCustomerHistory ({ commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get(`kyc/manager/customers/i/${id}/history`, withAuthorization(token))
      return data
    },
    async getTemplates ({ commit, dispatch }) {
      commit(TEMPLATES_LOADING, false)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get(`kyc/manager/templates`, withAuthorization(token))
      commit(TEMPLATES_LOADED, { templates: data })
      commit(TEMPLATES_LOADING, true)
      return data
    },
    async createTemplate ({ state, commit, dispatch }, { title, text }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`kyc/manager/templates`, {
        name: title,
        text: text
      }, withAuthorization(token))
      await dispatch('getTemplates')
      return data
    },
    async editTemplate ({ state, commit, dispatch }, { id, title, text }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.put(`kyc/manager/templates/i/${id}`, {
        name: title,
        text: text
      }, withAuthorization(token))
      await dispatch('getTemplates')
      return data
    },
    async deleteTemplate ({ state, commit, dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.delete(`kyc/manager/templates/i/${id}`, withAuthorization(token))
      await dispatch('getTemplates')
      return data
    },
    async submitCustomer ({ state, commit, dispatch }, { userId, type }) {
      commit(CUSTOMERS_LOADING, true)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`kyc/manager/customers/i/${userId}/accept`, {
        userId: userId,
        type: type
      }, withAuthorization(token))
      commit(CUSTOMERS_LOADING, false)
      return data
    },
    async resetCustomer ({ state, commit, dispatch }, { userId, level, type }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`kyc/manager/customers/i/${userId}/reset`, {
        level: level
      }, withAuthorization(token))
      return data
    },
    async complicateCustomer ({ state, commit, dispatch }, { userId, type, complicateComment }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`kyc/manager/customers/i/${userId}/complicate`, {
        complicateComment: complicateComment,
        type: type
      }, withAuthorization(token))
      return data
    },
    async rejectCustomer ({ state, commit, dispatch }, { userId, type, rejectComment }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.post(`kyc/manager/customers/i/${userId}/reject`, {
        userId: userId,
        type: type,
        rejectComment: rejectComment
      }, withAuthorization(token))
      return data
    },
    async get4stop ({ state, commit, dispatch }, { kycRequestId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get(`kyc/manager/4stop`, withAuthorization(token, {
        params: {
          kycRequestId: kycRequestId
        }
      }))
      commit(FOUR_STOP_LOADED, data)
      return data
    },
    setHistoryState ({ commit }, state) {
      commit(SET_HISTORY_STATE, state)
    },
    async addUserComment ({ dispatch }, { id, comment }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post(`/kyc/manager/customers/i/${id}/comment`, { comment }, withAuthorization(token))
    },
    async reSand2faEmail ({ dispatch }, { ssoUserId, verificator, email }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PASSPORT.post(`/api/v1/manager/users/i/${ssoUserId}/verificators/i/${verificator}/recover`, { email }, withAuthorization(token))
    }
  }
})
