const GET_EXCHANGE_CURRENCY = 'general/getCurrency'

export default () => ({
  namespaced: true,
  state () {
    // TODO Alexey Gerasimov: after server will have functionality to change 'to' value for rates, add new logic here
    return {
      exchangeCurrency: 'USD'
    }
  },
  mutations: {
    [GET_EXCHANGE_CURRENCY] (state, {currencyName}) {
      state.exchangeCurrency = currencyName
    }
  },
  getters: {
    exchangeCurrency: state => state.exchangeCurrency
  },
  actions: {
    getExchangeCurrency ({ commit }, { currencyName }) {
      commit(GET_EXCHANGE_CURRENCY, {currencyName})
    }
  }
})
