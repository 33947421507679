import Joi from 'joi'
import { AbstractModel, AttachmentModel } from 'src/models/core'
import CustomerModel from './CustomerModel'

export const schemaFactory = () => ({
  id: Joi.string().allow([null, '']),
  profile: Joi.object().type(CustomerModel),
  level: Joi.string().required(),
  state: Joi.string().required(),
  validationComment: Joi.string().allow([null, '']),
  diffs: Joi.array().items(
    Joi.object().keys({
      name: Joi.string(),
      title: Joi.string(),
      type: Joi.string(),
      approved: Joi.string().allow([null, '']),
      submitted: Joi.string().allow([null, ''])
    }).optional()
  ),
  attachments: Joi.object().keys({
    approved: Joi.array().items(
      Joi.object().type(AttachmentModel)
    ),
    submitted: Joi.array().items(
      Joi.object().type(AttachmentModel)
    )
  }).allow(null)
})

export default class CustomerUpdateModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new CustomerUpdateModel(
      {
        ...data,
        profile: CustomerModel.fromJson(data.profile),
        attachments: data.attachments == null
          ? null
          : ({
            approved: data.attachments.approved == null
              ? null
              : data.attachments.approved.map(AttachmentModel.fromJson),
            submitted: data.attachments.submitted == null
              ? null
              : data.attachments.submitted.map(AttachmentModel.fromJson)
          })
      },
      { stripUnknown: true }
    )
  }
}
