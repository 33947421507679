import { mapGetters } from 'vuex'
import { ProfileIdentityModel } from 'src/models'

export default {
  props: {
    identity: ProfileIdentityModel
  },
  computed: {
    ...mapGetters({
      getIdentityDetails: 'identities/getDetails'
    }),
    details () {
      return this.getIdentityDetails(this.identity)
    },
    customer () {
      const details = this.details

      return details == null || !details.isLoaded || details.customer == null
        ? null
        : details.customer
    }
  }
}
