import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  id: Joi.string().required(),
  ssoUserId: Joi.string().allow([null, '']).optional(),
  phone: Joi.string(),
  email: Joi.string(),
  firstName: Joi.string(),
  lastName: Joi.string()
})

export default class CustomerModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new CustomerModel(
      {
        ...data
      },
      { stripUnknown: true }
    )
  }
}
