import { PROFILE, withAuthorization } from 'src/remotes'
import { omitBy, isNil } from 'lodash'

const CHAT_LOADED = 'chat/loaded'
const CHAT_LOADING = 'chat/loading'
const CHANGE_USER = 'chat/user/change'

export default () => ({
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    activePage: 1,
    numberOfElements: 10, // page size
    limit: 100,
    items: [],
    total: null,
    users: [],
    filters: {
      name: null
    }
  },
  mutations: {
    [CHAT_LOADING] (state, users) {
      state.isLoading = true
    },
    [CHAT_LOADED]: (state, { activePage, numberOfElements, total, items }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        activePage,
        total,
        numberOfElements,
        items
      }, isNil))
    },
    [CHANGE_USER] (state, id) {
      const userId = state.items.findIndex(e => e.chatId === id)
      state.items[userId].isBlocked = !state.items[userId].isBlocked
      state.isLoading = false
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    activePage: state => state.activePage,
    numberOfElements: state => state.numberOfElements,
    limit: state => state.limit,
    items: state => state.items,
    totalPages (state) {
      const count = state.total
      const pageSize = state.numberOfElements
      return count == null
        ? null
        : parseInt(count / pageSize) + (count % pageSize === 0 ? 0 : 1)
    }
  },
  actions: {
    async ban ({ commit, dispatch }, user) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post(`/chat/manager/users/i/${user.chatId}/block`, null, withAuthorization(token))
      commit(CHANGE_USER, user.chatId)
    },
    async unban ({ commit, dispatch }, user) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      await PROFILE.post(`/chat/manager/users/i/${user.chatId}/unblock`, null, withAuthorization(token))
      commit(CHANGE_USER, user.chatId)
    },
    async loadChatUsers ({ state, commit, dispatch }, { offset, limit, filters = {} } = { filters: {} }) {
      commit(CHAT_LOADING)
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PROFILE.get('/chat/manager/users', withAuthorization(token, {
        params: {
          ...filters,
          offset,
          limit
        }
      }))
      commit(CHAT_LOADED, data)
      return data
    },
    async loadMore (
      { state, commit, dispatch },
      {
        reset = false,
        filters,
        page = {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      } = {
        reset: false,
        page: {
          activePage: 1,
          numberOfElements: state.numberOfElements
        }
      }
    ) {
      if (!state.isLoading) {
        commit(CHAT_LOADING)
        const { items, total } = await dispatch('loadChatUsers', {
          offset: (page.activePage - 1) * page.numberOfElements,
          limit: page.numberOfElements,
          filters
        })
        commit(CHAT_LOADED, {
          activePage: page.activePage,
          numberOfElements: page.numberOfElements,
          total: total,
          items: items
        })
      }
    }
  }
})
