import { render, staticRenderFns } from "./SectionCredentials.htm?vue&type=template&id=1c1e21f1&scoped=true&external"
import script from "./SectionCredentials.js?vue&type=script&lang=js&external"
export * from "./SectionCredentials.js?vue&type=script&lang=js&external"
import style0 from "./SectionCredentials.scss?vue&type=style&index=0&id=1c1e21f1&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1e21f1",
  null
  
)

export default component.exports