import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'
import * as partials from 'src/partials'
import * as components from 'src/components'
import {compact, get} from 'lodash'
import RejectVerificationModal from 'src/modals/lib/RejectVerificationModal/RejectVerificationModal.vue'
import ComplicateVerificationModal from '@/modals/lib/ComplicateVerificationModal/ComplicateVerificationModal.vue'
import { AddCommentBlockUserModal } from 'src/modals'

export default {
  metaInfo: {
    title: 'KYC'
  },
  props: {
  },
  components: {
    ...components,
    ...partials
  },
  data () {
    return {
      isBusy: false,
      history: null,
      customer: null,
      attachments: null,
      historyHeaders: [
        {
          text: 'Created At',
          align: 'start',
          sortable: false,
          value: 'createdAt'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Level',
          align: 'start',
          sortable: false,
          value: 'level'
        }
      ],
      acceptBusy: false,
      rejectBusy: false,
      complicateBusy: false,
      googlePlaceLoaded: false,
      type: ''
    }
  },
  async mounted () {
    await this.getCustomer()

    const address = this.customer.profile.address
    if (address && (address.status === 'COMPLICATED' || address.status === 'SUBMITTED')) {
      this.type = 'ADDRESS'
    }
    const documents = this.customer.profile.documents
    if (documents && (documents.status === 'COMPLICATED' || documents.status === 'SUBMITTED')) {
      this.type = 'DOCUMENTS'
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      isLoading: 'customers/isLoading',
      getProfileData: 'customers/getKYCProfileData',
      hasKycActionsAccess: 'grants/hasKycActionsAccess',
      hasAccountOperationsAccess: 'grants/hasAccountOperationsAccess'
    }),
    ...mapState({
      templatesItems: state => state.customers.templates
    }),
    isGooglePlacesEnabled () {
      return this.config.googlePlaces.kyc.enable
    },
    isShowGooglePlace () {
      return this.customer.profile.address && this.customer.profile.address.status === 'SUBMITTED' &&
        (!this.customer.profile.documents || this.customer.profile.documents.status === 'RESET')
    },
    hasDocuments () {
      return this.customer.profile.documents && this.customer.profile.documents.status !== 'RESET'
    },
    clientHistory () {
      let address = this.history.address.map(el => ({
        createdAt: el.createdAt,
        status: el.status,
        comment: el.complicateComment || el.rejectComment,
        level: 'address'
      }))
      let doc = this.history.documents.map(el => ({
        createdAt: el.createdAt,
        status: el.status,
        comment: el.rejectComment || el.complicateComment,
        level: 'documents'
      }))
      return address.concat(doc).sort((a, b) => {
        return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
      })
    },
    key () {
      return this.getKey(this.$route.hash)
    },
    customerId () {
      return this.$route.params.id
    },
    profileData () {
      return this.getProfileData(this.customerId).value
    },
    isLocked () {
      return this.profileData.isLocked
    },
    details () {
      return this.profileData.profile
    },
    info () {
      return {
        ssoUserId: get(this.details, 'ssoUserId', '-'),
        id: get(this.details, 'id', '-'),
        comment: get(this.details, 'customComment', '')
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      getTemplates: 'customers/getTemplates',
      getCustomerHistory: 'customers/getCustomerHistory',
      loadKYCProfileData: 'customers/loadKYCProfileData',
      findPlace: 'googlePlaces/findPlace',
      openModal: 'modals/open',
      submitCustomer: 'customers/submitCustomer',
      complicateCustomer: 'customers/complicateCustomer',
      rejectCustomer: 'customers/rejectCustomer',
      openFile: 'media/openFile',
      lockedUser: 'users/lockedUser',
      handleToast: 'interaction/toast'
    }),
    async handleLockUser () {
      if (this.isLocked) {
        await this.lockedUser({
          id: this.info.id,
          ssoUserId: this.info.ssoUserId,
          isLocked: false
        })
        await this.loadKYCProfileData({ id: this.info.id })
        await this.handleToast({
          message: 'User updated'
        })
      } else {
        await this.openModal({
          factory: () => AddCommentBlockUserModal,
          data: {
            ssoUserId: this.info.ssoUserId,
            handler: async () => {
              await this.loadKYCProfileData({ id: this.info.id })
            }
          }
        })
      }
    },
    getKey (hash) {
      switch (hash) {
        case '#address':
          return 'address'
        case '#documents':
          return 'documents'
        case '#resubmitted':
          return 'submitted'
        case '#complicated':
          return 'complicated'
      }
    },
    async getCustomer () {
      this.history = await this.getCustomerHistory({ id: this.customerId })
      await this.loadKYCProfileData({ id: this.customerId })

      this.customer = this.getProfileData(this.customerId).value

      if (this.isGooglePlacesEnabled) {
        await this.loadGooglePlaces()
      }

      if (this.history.documents.length > 0) {
        const actualDocumentLevel = this.history.documents[0]
        const prevDocumentLevel = this.history.documents.length > 1
          ? this.history.documents[1]
          : null

        this.attachments = actualDocumentLevel.attachments.map(e => ({
          id: e.file.id,
          kind: e.type,
          name: e.file.name,
          type: e.file.mimetype,
          new: prevDocumentLevel != null && !prevDocumentLevel.attachments.find(prevEl => {
            return prevEl.file.id === e.file.id
          })
        }))
        if (actualDocumentLevel.selfie != null) {
          const prevSelfieId = get(prevDocumentLevel, 'selfie.id')
          const actualSelfie = actualDocumentLevel.selfie

          this.attachments.push({
            id: actualSelfie.id,
            kind: 'SELFIE',
            name: actualSelfie.name,
            type: actualSelfie.mimetype,
            new: prevSelfieId !== actualSelfie.id
          })
        }
      }
    },
    toCustomer (id) {
      this.$router.push({ path: `/private/admin/customers/i/${id}` })
    },
    viewState (item) {
      const customerId = this.$route.params.id
      let routeData = this.$router.resolve({
        path: `/private/admin/kyc/kyc-history/i/${customerId}`,
        query: { createdAt: item.createdAt, type: item.level.toUpperCase() }
      })
      window.open(routeData.href, '_blank')
    },
    async loadGooglePlaceForAddressLine (addressLine) {
      const address = [
        this.customer.profile.address.zip,
        this.customer.profile.address.country,
        this.customer.profile.address.state,
        this.customer.profile.address.city,
        addressLine
      ]
      const text = compact(address).join(', ')

      const result = await this.findPlace({
        text
      })

      return { result, text } // { candidates, noResult } or null
    },
    async loadGooglePlaces () {
      try {
        const addressLines = [this.customer.profile.address.addressLine1]

        if (this.customer.profile.address.addressLine2) {
          addressLines.push(this.customer.profile.address.addressLine2)
        }

        const results = await Promise.all(addressLines.map(async e => {
          return this.loadGooglePlaceForAddressLine(e)
        }))

        if (results.length > 0) {
          this.customer = Object.freeze({
            ...this.customer,
            addressLine1GooglePlacesInfo: {
              text: results[0].text,
              result: results[0].result
            }
          })

          if (results.length > 1) {
            this.customer = Object.freeze({
              ...this.customer,
              addressLine2GooglePlacesInfo: {
                text: results[1].text,
                result: results[1].result
              }
            })
          }
          this.googlePlaceLoaded = true
        }
      } catch (e) {
        console.warn('cannot load places', e)
        // do nothing
      }
    },
    async handleComplicated () {
      this.complicateBusy = true
      this.openModal({
        factory: () => ComplicateVerificationModal,
        data: {
          title: 'Complicate',
          handler: async value => {
            await this.complicateCustomer({
              userId: this.customer.profile.id,
              type: this.type,
              complicateComment: value.complicateComment
            })
            this.customer = null
            await this.$router.push({ path: `/private/admin/kyc/${this.key}` })
            this.complicateBusy = false
          },
          handlerClose: () => {
            this.complicateBusy = false
          }
        }
      })
    },
    async handleSubmit () {
      this.acceptBusy = true
      await this.submitCustomer({
        userId: this.customer.profile.id,
        type: this.type
      })
      this.customer = null
      await this.$router.push({ path: `/private/admin/kyc/${this.key}` })
      this.acceptBusy = false
    },
    async handleReject () {
      this.rejectBusy = true
      await this.getTemplates()
      this.openModal({
        factory: () => RejectVerificationModal,
        data: {
          title: 'Reject',
          templates: this.templatesItems,
          handler: async value => {
            await this.rejectCustomer({
              userId: this.customer.profile.id,
              type: this.type,
              rejectComment: value.text
            })
            this.customer = null
            await this.$router.push({ path: '/private/admin/kyc/address' })
            this.rejectBusy = false
          }
        }
      })
      this.rejectBusy = false
    }
  }
}
