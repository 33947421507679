import Joi from 'joi'
import AbstractModel from './AbstractModel'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  url: Joi.string().required()
})

export default class ImageModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }
    return new ImageModel(data)
  }
}
