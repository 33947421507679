import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import { ProfileIdentityModel } from 'src/models'
import { LogsModal } from 'src/modals'
import { NumberUtils } from 'src/utils'

export default {
  metaInfo: {
    title: 'Redemptions'
  },
  components: {
    ...components,
    ...micros
  },
  computed: {
    ...mapGetters({
      withdrawals: 'redemptions/items',
      summary: 'redemptions/summary',
      isLoaded: 'redemptions/isLoaded',
      isLoading: 'redemptions/isLoading',
      actualDate: 'redemptions/actualDate',
      updatesCount: 'redemptions/updatesCount',
      activePage: 'redemptions/activePage',
      numberOfElements: 'redemptions/numberOfElements',
      totalPages: 'redemptions/totalPages',
      currencies: 'currencies/currencies',
      getScoutTransactionReference: 'scout/getScoutTransactionReference',
      hasWithdrawalsViewAccess: 'grants/hasWithdrawalsViewAccess',
      hasProfilesViewAccess: 'grants/hasProfilesViewAccess',
      hasWithdrawalsActionAccess: 'grants/hasWithdrawalsActionAccess'
    }),
    stateOptions () {
      return [
        { value: 'PENDING', label: 'Pending' }, // This state have several sub-states in some inheritors
        { value: 'FAILED', label: 'Failed' },
        { value: 'ACCEPTED', label: 'Accepted' },
        { value: 'CANCELLED', label: 'Cancelled' },
        { value: 'REJECTED', label: 'Rejected' },
        { value: 'CONFIRMED', label: 'Confirmed' }, // After reducing plasma balance
        { value: 'COMPLETE', label: 'Complete' }
      ]
    },
    typeOptions () {
      return [
        { value: 'WithdrawalRequest', label: 'Crypto' },
        { value: 'WithdrawalManual', label: 'Manual' },
        { value: 'GeoPayWithdrawRequest', label: 'GeoPay' },
        { value: 'AdvCashWithdrawRequest', label: 'AdvCash' },
        { value: 'MonoovaWithdrawOperation', label: 'Monoova' },
        { value: 'PlasmaTransferOutgoingOperation', label: 'Internal withdrawal' }
      ]
    },
    symbolOptions () {
      const currencies = this.currencies
      return !currencies.isLoaded
        ? []
        : this.currencies.value.map(currency => ({
          value: currency.currency.symbol,
          label: currency.currency.name
        }))
    },
    items () {
      return this.withdrawals.map((withdrawal, index) => ({
        index,
        withdrawal,
        title: this.calcTitle(withdrawal),
        moment: moment(withdrawal.createdAt),
        txLink: this.getScoutTransactionReference(withdrawal.token, withdrawal.tx, withdrawal.network),
        identities: (withdrawal.identities || []).map(
          id => new ProfileIdentityModel({
            type: 'passport',
            id
          })
        )
      }))
    },
    passportIds () {
      const set = new Set()
      const items = this.items || []
      for (const { withdrawal } of items) {
        for (const passportId of (withdrawal.identities || [])) {
          set.add(passportId)
        }
      }
      return [...set.values()]
    },
    totalSum () {
      return NumberUtils.getNumber(this.summary.total)
    }
  },
  watch: {
    passportIds: {
      immediate: true,
      handler () {
        if (!this.hasProfilesViewAccess) {
          return
        }

        this.reloadIdentities({
          identities: this.passportIds.map(
            id => new ProfileIdentityModel({
              type: 'passport',
              id
            })
          )
        })
      }
    }
  },
  data () {
    return {
      filters: {
        state: arrayOfQueryParam(this.$route.query.state) || [],
        type: arrayOfQueryParam(this.$route.query.type) || [],
        symbol: arrayOfQueryParam(this.$route.query.symbol) || []
      },
      visibleCount: 6,
      isTablet: window.innerWidth <= 1000
    }
  },
  async created () {
    if (!this.hasWithdrawalsViewAccess) {
      return
    }
    await this.init()
    await this.handleRefresh()
  },
  async beforeRouteUpdate (to, from, next) {
    if (!this.hasWithdrawalsViewAccess) {
      return
    }

    Object.assign(this.filters, {
      state: arrayOfQueryParam(to.query.state) || [],
      type: arrayOfQueryParam(to.query.type) || [],
      symbol: arrayOfQueryParam(to.query.symbol) || []
    })
    await this.handleRefresh() // just trigger, do not await
    next()
  },
  methods: {
    moment,
    ...mapActions({
      loadMoreRedemptions: 'redemptions/loadMore',
      reloadIdentities: 'identities/reload',
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast',
      loadSummary: 'redemptions/loadSummary'
    }),
    async init () {
      await this.handleState('PENDING')
      this.filters = {
        state: ['PENDING'] || arrayOfQueryParam(this.$route.query.state)
      }
    },
    async loadSum () {
      await this.loadSummary()
    },
    calcTitle (withdrawal) {
      switch (withdrawal.type || '') {
        case 'WITHDRAWAL_GEO_PAY': return 'GeoPay'
        case 'WITHDRAWAL_REQUEST': return 'Crypto'
        case 'WITHDRAWAL_ADV_CASH': return 'AdvCash'
        case 'WITHDRAWAL_MONOOVA': return 'Monoova'
        case 'WITHDRAWAL_MANUAL': return 'Manual'
        case 'INTERNAL_WITHDRAWAL': return 'Internal transfer'
        default: return withdrawal.type
      }
    },
    async handleState (state) {
      this.filters.state = state
      this.$router.replace({ // triggers update on this component (see beforeRouteUpdate)
        query: {
          state: state === '*'
            ? undefined
            : state,
          type: this.filters.type,
          symbol: this.filters.symbol
        }
      })
    },
    async handleType (type) {
      this.filters.type = type
      this.$router.replace({ // triggers update on this component (see beforeRouteUpdate)
        query: {
          state: this.filters.state,
          type: type === '*'
            ? undefined
            : type,
          symbol: this.filters.symbol
        }
      })
    },
    async handleSymbol (symbol) {
      this.filters.symbol = symbol
      this.$router.replace({ // triggers update on this component (see beforeRouteUpdate)
        query: {
          state: this.filters.state,
          type: this.filters.type,
          symbol: symbol === '*'
            ? undefined
            : symbol
        }
      })
    },
    async handleRefresh () {
      try {
        await this.loadMoreRedemptions({
          reset: true,
          filters: this.filters
        })
      } catch (e) {
        this.$router.push('/private/error/error')
      }
    },
    async handleLoadMore () {
      await this.loadMoreRedemptions({
        filters: this.filters
      })
    },
    async handleActivePage (value) {
      this.loadMoreRedemptions({
        filters: this.filters,
        page: {
          activePage: value,
          numberOfElements: this.numberOfElements
        }
      })
    },
    getAmount (operation) {
      return NumberUtils.getNumber(operation.realAmount || operation.amount)
    },
    getFee (operation) {
      return NumberUtils.getNumber(operation.realCommissionAmount || operation.commissionAmount)
    },
    showLogs (item) {
      const rows = item.withdrawal.result.split('\n')
      this.openModal({
        factory: () => LogsModal,
        data: {
          rows: rows
        }
      })
    },
    showCrystalData (item) {
      const rows = JSON.stringify(item.withdrawal.crystalData, null, 2).split('\n')
      this.openModal({
        factory: () => LogsModal,
        data: {
          rows: rows
        }
      })
    }
  }
}

function arrayOfQueryParam (value) {
  return value == null
    ? null
    : (Array.isArray(value) ? value : [value])
}
