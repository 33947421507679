import { render, staticRenderFns } from "./DepositPartial.htm?vue&type=template&id=484b8664&scoped=true&external"
import script from "./DepositPartial.js?vue&type=script&lang=js&external"
export * from "./DepositPartial.js?vue&type=script&lang=js&external"
import style0 from "./DepositPartial.scss?vue&type=style&index=0&id=484b8664&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484b8664",
  null
  
)

export default component.exports