import {mapActions, mapGetters} from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'
import {isGreaterThan, isGreaterThanOrEqual, isLessThanOrEqual} from 'src/validations'

export default {
  components: {
    ...components
  },
  data () {
    return {
      isAddInProgress: false,
      trade: [
        {
          name: 'unlocked',
          value: false,
          key: 0
        },
        {
          name: 'locked',
          value: true,
          key: 1
        }
      ],
      form: {
        baseTokenAddress: '',
        quoteTokenAddress: '',
        feeTokenAddress: '',
        symbol: '',
        name: '',
        makerFee: '',
        takerFee: '',
        quantityIncrement: '',
        tickSize: '',
        baseMinSize: '',
        quoteMinSize: '',
        locked: false
      }
    }
  },
  validations: {
    form: {
      symbol: {
        required,
        symbolCheck (val) {
          let pattern = new RegExp('[^A-Za-z0-9]+')
          return !val.match(pattern)
        }
      },
      name: {
        required
      },
      makerFee: {
        required,
        validCheck (value) {
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      takerFee: {
        required,
        validCheck (value) {
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      quantityIncrement: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      tickSize: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      baseMinSize: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      quoteMinSize: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies'
    }),
    isLocked () {
      return this.trade.find(e => e.value === this.form.locked) || this.trade[0]
    },
    availableFeeCurrency () {
      const result = []
      if (this.form.baseTokenAddress && this.form.quoteTokenAddress) {
        result.push(this.form.baseTokenAddress)
        result.push(this.form.quoteTokenAddress)
      }
      return result
    },
    symbolErrors () {
      if (this.$v.form.symbol.$dirty) {
        if (!this.$v.form.symbol.required) {
          return [
            'Symbol is a required field'
          ]
        }
        if (!this.$v.form.symbol.symbolCheck) {
          return [
            'Symbol name can contain only letters and symbols'
          ]
        }
      }
      return []
    },
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    makerFeeErrors () {
      if (this.$v.form.makerFee.$dirty) {
        if (!this.$v.form.makerFee.required) {
          return [
            'Maker Fee is a required field'
          ]
        }
        if (!this.$v.form.makerFee.validCheck) {
          return [
            'Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    },
    takerFeeErrors () {
      if (this.$v.form.takerFee.$dirty) {
        if (!this.$v.form.takerFee.required) {
          return [
            'Taker Fee is a required field'
          ]
        }
        if (!this.$v.form.takerFee.validCheck) {
          return [
            'Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    },
    quantityIncrementErrors () {
      if (this.$v.form.quantityIncrement.$dirty) {
        if (!this.$v.form.quantityIncrement.required) {
          return [
            'Quantity Increment is a required field'
          ]
        }
        if (!this.$v.form.quantityIncrement.numberCheck) {
          return [
            'Quantity Increment must be number and more than 0'
          ]
        }
      }
      return []
    },
    tickSizeErrors () {
      if (this.$v.form.tickSize.$dirty) {
        if (!this.$v.form.tickSize.required) {
          return [
            'Tick Size is a required field'
          ]
        }
        if (!this.$v.form.tickSize.numberCheck) {
          return [
            'TickSize must be number and more than 0'
          ]
        }
      }
      return []
    },
    baseMinSizeErrors () {
      if (this.$v.form.baseMinSize.$dirty) {
        if (!this.$v.form.baseMinSize.required) {
          return [
            'Base Min Size is a required field'
          ]
        }
        if (!this.$v.form.baseMinSize.numberCheck) {
          return [
            'BaseMinSize must be number and more than 0'
          ]
        }
      }
      return []
    },
    quoteMinSizeErrors () {
      if (this.$v.form.quoteMinSize.$dirty) {
        if (!this.$v.form.quoteMinSize.required) {
          return [
            'Quote Min Size is a required field'
          ]
        }
        if (!this.$v.form.quoteMinSize.numberCheck) {
          return [
            'QuoteMinSize must be number and more than 0'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      createMarket: 'markets/createMarket'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.createMarket({
            ...this.form,
            baseTokenAddress: this.form.baseTokenAddress.currency.address,
            quoteTokenAddress: this.form.quoteTokenAddress.currency.address,
            feeTokenAddress: this.form.feeTokenAddress.currency.address
          })
          await this.handleToast({
            message: 'Market added'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot add market'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    },
    handleLockedInput (key) {
      this.form.locked = this.trade.find(e => e.key === key).value
    },
    handleBaseTokenInput (key) {
      this.form.baseTokenAddress = key == null
        ? null
        : this.currencies.value.find(c => c.currency.key === key)
    },
    handleQuoteTokenInput (key) {
      this.form.quoteTokenAddress = key == null
        ? null
        : this.currencies.value.find(c => c.currency.key === key)
    },
    handleInputFeeToken (key) {
      this.form.feeTokenAddress = key == null
        ? null
        : this.currencies.value.find(c => c.currency.key === key)
    }
  }
}
