import { getCountry } from 'iso-3166-1-alpha-2'
import { VueMaskDirective } from 'v-mask'
// import { debounce } from 'lodash'
import examples from 'libphonenumber-js/examples.mobile.json'
import { AsYouType, parsePhoneNumber, getCountryCallingCode, getExampleNumber, getCountries } from 'libphonenumber-js'

const REGION_OPTIONS = getCountries()
  .map(key => Object.freeze({
    key,
    code: getCountryCallingCode(key),
    country: getCountry(key)
  }))
  .filter(({ country }) => country != null)
  .sort((a, b) => a.country.localeCompare(b.country))

const REGION_AU = REGION_OPTIONS.find(option => option.key === 'AU')

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    text: Boolean,
    filled: Boolean,
    solo: Boolean,
    label: String,
    placeholder: String,
    errorMessages: Array,
    prependIcon: String,
    appendIcon: String,
    hideDetails: Boolean,
    clearable: Boolean,
    rounded: Boolean
  },
  directives: {
    mask: VueMaskDirective
  },
  data () {
    try {
      const phoneNumber = parsePhoneNumber(this.value)
      return {
        region: REGION_OPTIONS.find(option => option.key === phoneNumber.country),
        fullNumber: this.value
      }
    } catch (e) {
      return {
        region: REGION_AU,
        fullNumber: ''
      }
    }
  },
  computed: {
    regionOptions () {
      return REGION_OPTIONS
    },
    example () {
      const region = this.region
      const exampleNumber = getExampleNumber(region.key, examples)
      const result = exampleNumber == null
        ? null
        : exampleNumber.format('INTERNATIONAL')
      return result == null
        ? null
        : `Example: ${result}`
    }
  },
  methods: {
    handleRegion (region) {
      this.region = region
      this.$emit('input', this.fullNumber)
    },
    handleInput (fullNumber) {
      // this.fullNumber = fullNumber
      try {
        const asYouType = new AsYouType()
        asYouType.input(fullNumber)
        const country = (asYouType.metadata && asYouType.metadata._country)
          ? asYouType.metadata._country
          : null
        if (country != null) {
          const region = REGION_OPTIONS.find(option => option.key === country)
          if (region != null) {
            if (this.region == null || region.key !== this.region.key) {
              this.region = region
            }
          }
        }
      } catch (e) {
        // ignore
      }

      this.$emit('input', this.fullNumber)
    }
  }
}
