import Joi from 'joi'
import BigNumber from 'bignumber.js'
import { AbstractModel } from 'src/models/core'
import PlasmaPocketModel from './PlasmaPocketModel'

export const schemaFactory = () => ({
  pocket: Joi.object().type(PlasmaPocketModel).required(),
  isLoading: Joi.boolean().required(),
  isLoaded: Joi.boolean().required(),
  balance: Joi.object().type(BigNumber).allow(null),
  lockedBalance: Joi.object().type(BigNumber).allow(null)
})

export default class PlasmaBalanceModel extends AbstractModel {
  constructor (data, options) {
    super(Object.assign({
      isLoading: true,
      isLoaded: false,
      balance: null,
      lockedBalance: null
    }, data), schemaFactory(), options)
  }

  get amount () {
    return this.pocket.currency.dao.removeDecimals(this.balance)
  }

  get lockedAmount () {
    return this.pocket.currency.dao.removeDecimals(this.lockedBalance)
  }

  get availableAmount () {
    return !(this.balance && this.lockedBalance)
      ? null
      : this.pocket.currency.dao.removeDecimals(
        this.balance.minus(this.lockedBalance)
      )
  }

  loaded ({ balance, lockedBalance }) {
    return Object.assign(this, {
      isLoaded: true,
      isLoading: false,
      balance,
      lockedBalance
    })
  }

  loading () {
    return Object.assign(this, {
      isLoading: true
    })
  }
}
