/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credentials-phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.023 15.488a15.084 15.084 0 01-1.924-.14c-.559-.11-1.11-.251-1.653-.425a1.017 1.017 0 00-1.019.253l-2.217 2.2a15.377 15.377 0 01-3.8-2.792 15.376 15.376 0 01-2.792-3.8l2.2-2.218a1.017 1.017 0 00.253-1.019A14.6 14.6 0 018.653 5.9a15.067 15.067 0 01-.141-1.924A1 1 0 007.51 3H4a.992.992 0 00-1 1 17.18 17.18 0 004.966 12.034A17.18 17.18 0 0020 21a.993.993 0 001-1v-3.51a1 1 0 00-.977-1.002z" _fill="#000"/>'
  }
})
