import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { pickBy, identity } from 'lodash'
import BigNumber from 'bignumber.js'
import * as components from 'src/components'
import { stringToColour } from 'src/utils/lib/stringToColour'

export default {
  metaInfo: {
    title: 'Deposits'
  },
  components: {
    ...components
  },
  data () {
    return {
      selectedItems: [],
      filters: {
        baseCurrencySymbol: null,
        quoteCurrencySymbol: null,
        address: null,
        isMaker: false,
        isTaker: false,
        from: moment().startOf('day').toDate(),
        till: null
      },
      isMobile: window.innerWidth <= 1200,
      selectedSumBaseAmount: 0,
      selectedSumQuoteAmount: 0,
      selectedSumPrice: 0
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      pageNumber: 'trades/pageNumber',
      tradesItems: 'trades/items',
      pageSize: 'trades/pageSize'
    }),
    size () {
      return this.pageSize
    },
    currentPage () {
      return this.pageNumber + 1
    },
    currencySymbol () {
      return this.currencies.value.map(c => c.currency.symbol)
    },
    items () {
      return this.tradesItems.value.map(e => ({
        ...e,
        market: `${e.baseCurrencySymbol}${e.quoteCurrencySymbol}`
      }))
    },
    params () {
      return pickBy({
        baseCurrencySymbol: this.filters.baseCurrencySymbol,
        quoteCurrencySymbol: this.filters.quoteCurrencySymbol,
        address: this.filters.address,
        isMaker: this.filters.isMaker,
        isTaker: this.filters.isTaker,
        from: moment(this.filters.from).toISOString(),
        till: moment(this.filters.till).toISOString()
      }, identity)
    },
    selectedItemsIds () {
      return this.selectedItems.map(e => e.id)
    }
  },
  async created () {
    addEventListener('resize', this.resizeEventListener)
  },
  beforeDestroy () {
    removeEventListener('resize', this.resizeEventListener)
  },
  methods: {
    moment,
    BigNumber,
    ...mapActions({
      loadTrades: 'trades/loadTrades'
    }),
    colorize (value) {
      return stringToColour(value)
    },
    clearSummary () {
      this.selectedItems = []
      this.selectedSumBaseAmount = 0
      this.selectedSumQuoteAmount = 0
      this.selectedSumPrice = null
    },
    resizeEventListener () {
      this.isMobile = innerWidth <= 1200
    },
    selectRow (item) {
      const index = this.selectedItems.findIndex(e => e.id === item.id)
      if (index === -1) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(index, 1)
      }

      const baseTokensCount = new Set(this.selectedItems.map(e => e.baseCurrencySymbol)).size
      const quoteTokensCount = new Set(this.selectedItems.map(e => e.quoteCurrencySymbol)).size

      const isSameMarket = baseTokensCount === 1 && quoteTokensCount === 1
      this.selectedSumBaseAmount = 0
      this.selectedSumQuoteAmount = 0

      if (this.selectedItems.length === 0) {
        this.selectedSumPrice = null
        return
      }

      if (!isSameMarket) {
        this.selectedSumPrice = 'n/a (should be only 1 market in selection)'
        return
      }
      for (const selectedItem of this.selectedItems) {
        if (selectedItem.makerAddress === selectedItem.takerAddress) {
          continue
        } else if (selectedItem.makerAddress === this.filters.address) {
          if (selectedItem.direction === 'BUY') {
            this.selectedSumBaseAmount = new BigNumber(this.selectedSumBaseAmount).minus(selectedItem.baseAmount).toString()
            this.selectedSumQuoteAmount = new BigNumber(this.selectedSumQuoteAmount).plus(selectedItem.quoteAmount).toString()
          } else {
            this.selectedSumBaseAmount = new BigNumber(this.selectedSumBaseAmount).plus(selectedItem.baseAmount).toString()
            this.selectedSumQuoteAmount = new BigNumber(this.selectedSumQuoteAmount).minus(selectedItem.quoteAmount).toString()
          }
        } else if (selectedItem.takerAddress === this.filters.address) {
          if (selectedItem.direction === 'BUY') {
            this.selectedSumBaseAmount = new BigNumber(this.selectedSumBaseAmount).plus(selectedItem.baseAmount).toString()
            this.selectedSumQuoteAmount = new BigNumber(this.selectedSumQuoteAmount).minus(selectedItem.quoteAmount).toString()
          } else {
            this.selectedSumBaseAmount = new BigNumber(this.selectedSumBaseAmount).minus(selectedItem.baseAmount).toString()
            this.selectedSumQuoteAmount = new BigNumber(this.selectedSumQuoteAmount).plus(selectedItem.quoteAmount).toString()
          }
        }
      }

      if (new BigNumber(this.selectedSumBaseAmount).isZero()) {
        this.selectedSumPrice = null
        return
      }

      this.selectedSumPrice = new BigNumber(this.selectedSumQuoteAmount).div(this.selectedSumBaseAmount).abs().toString()
    },
    async handleLoad () {
      await this.loadTrades({
        filters: this.params,
        pageNumber: 0
      })
    },
    async loadNext () {
      this.clearSummary()
      await this.loadTrades({
        filters: this.params,
        pageNumber: this.pageNumber + 1
      })
    },
    async loadPrev () {
      this.clearSummary()
      await this.loadTrades({
        filters: this.params,
        pageNumber: this.pageNumber - 1
      })
    },
    async handleClearBaseCurrencySymbol () {
      this.filters.baseCurrencySymbol = null
    },
    async handleClearQuoteCurrencySymbol () {
      this.filters.quoteCurrencySymbol = null
    },
    async handleClearAddress () {
      this.filters.address = null
      this.filters.isMaker = false
      this.filters.isTaker = false
    }
  }
}
