import {mapActions, mapGetters} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'
import {BlogCategoryModel} from 'src/models/domain'

export default {
  components: {
    ...components
  },
  props: {
    category: BlogCategoryModel
  },
  data () {
    return {
      isAddInProgress: false,
      form: {
        name: this.category.name,
        showOnLanding: this.category.showOnLanding
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      categories: 'blog/categories'
    }),
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      editCategory: 'blog/editCategory'
    }),
    async handleSubmit () {
      this.isAddInProgress = true
      setImmediate(async () => {
        try {
          await this.editCategory({
            id: this.category.id,
            name: this.form.name,
            showOnLanding: this.form.showOnLanding
          })
          await this.handleToast({
            message: 'Category changed'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e,
              message: 'Cannot change category'
            })
          }
        } finally {
          this.isAddInProgress = false
        }
      })
    }
  },
  async mounted () {
  }
}
