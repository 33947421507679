import { mapActions, mapGetters } from 'vuex'
import * as components from 'src/components'

export default {
  props: {
    id: String
  },
  components: {
    ...components
  },
  computed: {
    ...mapGetters({
      getDetailData: 'partnersPrograms/getDetailData'
    }),
    detailData () {
      return this.getDetailData(this.id)
    }
  },
  methods: {
    ...mapActions({
      loadDetails: 'partnersPrograms/loadDetails'
    }),
    loadedDetails () {
      this.loadDetails({ id: this.id })
    }
  },
  async mounted () {
    await this.loadedDetails()
  }
}
