import EventEmitter from 'events'

export default class SignerModel {
  constructor () {
    this.emitter = new EventEmitter()
  }

  getAddress () {
    throw new Error('Not implemented')
  }

  isChangePasswordSupport () {
    throw new Error('Not implemented')
  }

  // eslint-disable-next-line no-unused-vars
  async signTransaction (tx) { // tx object
    throw new Error('Not implemented')
  }

  // eslint-disable-next-line no-unused-vars
  async signData (data) { // data object
    throw new Error('Not implemented')
  }

  // eslint-disable-next-line no-unused-vars
  async encrypt (password) { // password argument is required only for the in-memory wallet
    throw new Error('Not implemented')
  }
}
