import * as components from 'src/components'
import OptionImportMetamask from './OptionImportMetamask/OptionImportMetamask.vue'

export default {
  components: {
    ...components,
    OptionImportMetamask
  },
  data () {
    return {
      type: 'metamask',
      options: [
        { key: 'metamask', title: 'Metamask' }
      ]
    }
  }
}
