import { mapActions, mapGetters } from 'vuex'
import { required, decimal } from 'vuelidate/lib/validators'
import * as components from 'src/components'
import BigNumber from 'bignumber.js'
import { CurrencyUtils } from 'src/utils'
import { formatNumber } from 'accounting'

export default {
  components: {
    ...components
  },
  props: {
    item: Object
  },
  data () {
    return {
      form: {
        amount: this.getAmount(this.item.purchase).toString() // use simple data types in forms
      },
      feePercentage: new BigNumber(0.01), // Get this value from the REST edpoint
      exchangeRate: null
    }
  },
  validations: {
    form: {
      amount: {
        required,
        decimal,
        positive (value) {
          return new BigNumber(value).gt(0)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      getCurrency: 'currencies/getCurrency',
      getFiatCurrencyById: 'currencies/getFiatCurrencyById'
    }),
    currencyOptions () {
      return this.currencies.isLoaded
        ? this.currencies.value.map(c => c.currency)
        : []
    },
    fiatCurrency () {
      const purchase = this.item.purchase
      if (purchase.fiatCurrencyId) {
        const fiatCurrency = this.getFiatCurrencyById(purchase.fiatCurrencyId)
        if (fiatCurrency) {
          return fiatCurrency.title
        }
      }
      return 'AUD'
    },
    amount () {
      return new BigNumber(this.form.amount)
    },
    feeAmount () {
      const currency = this.getCurrency(this.item.purchase.token)

      const plugin = CurrencyUtils.getPlugin({
        currency: currency.currency,
        type: 'BankTransferDepositPlugin'
      })

      let bankFee = {
        percent: 0,
        min: 0,
        max: 0
      }

      if (plugin && plugin.commission) {
        const { percent, min: min0, max: max0 } = plugin.commission
        bankFee = {
          percent,
          min: currency.dao.removeDecimals(min0),
          max: currency.dao.removeDecimals(max0)
        }
      }

      const percentCommissionValue = this.amount.div(new BigNumber(bankFee.percent).plus(100)).times(100)
      let result = new BigNumber(this.amount.minus(percentCommissionValue))
      if (result.lt(bankFee.min)) {
        result = bankFee.min
      } else if (result.gt(bankFee.max)) {
        result = bankFee.max
      }
      return new BigNumber(formatNumber(result, 2))
    },
    depositAmount () {
      return this.amount.minus(this.feeAmount)
    },
    amountErrors () {
      if (this.$v.form.amount.$dirty) {
        if (!this.$v.form.amount.required) {
          return [
            'Amount is a required field'
          ]
        }
        if (!this.$v.form.amount.decimal) {
          return [
            'Amount should have a decimal value'
          ]
        }
        if (!this.$v.form.amount.positive) {
          return [
            'Amount should be a positive number'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      acceptPurchase: 'purchases/acceptPurchase',
      rejectPurchase: 'purchases/rejectPurchase',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast'
    }),
    async handleAccept ({ purchase }) {
      await this.handleConfirmation({
        handler: async () => {
          await this.acceptPurchase({
            id: purchase.id,
            fiatAmount: this.form.amount,
            exchangeRate: this.form.exchangeRate
          })
          this.handleClose()
          await this.handleToast({
            message: 'Purchase accepted'
          })
        }
      })
    },
    async handleReject ({ purchase }) {
      await this.handleConfirmation({
        handler: async () => {
          await this.rejectPurchase({
            id: purchase.id
          })
          this.handleClose()
          await this.handleToast({
            message: 'Purchase rejected'
          })
        }
      })
    },
    getAmount (operation) {
      if (operation.realAmount) {
        return new BigNumber(operation.realAmount)
          .plus(operation.realCommissionAmount)
      } else {
        return new BigNumber(operation.amount)
          .plus(operation.commissionAmount)
      }
    }
  }
}
