import { Grants } from './models'

class TreeNode {
  constructor ({ key, title, to, icon, iconImage, grants, children, notShowInMenu }) {
    this.key = key
    this.title = title
    this.to = to
    this.icon = icon
    this.iconImage = iconImage
    this.grants = grants || []
    this.children = children || []
    this.notShowInMenu = notShowInMenu
  }

  addChild (treeNode) {
    this.children.push(treeNode)
    return this
  }

  build () {
    return this
  }

  getTreeGrants () {
    if (this.children.length === 0) {
      return this.grants
    }

    const result = new Set()

    for (const child of this.children) {
      child.getTreeGrants().forEach(grant => result.add(grant))
    }

    return Array.from(result)
  }

  clone ({ children } = {}) {
    return new TreeNode({
      key: this.key,
      title: this.title,
      to: this.to,
      icon: this.icon,
      iconImage: this.iconImage,
      grants: this.grants,
      children: (children || this.children).map(e => e.clone()),
      notShowInMenu: this.notShowInMenu
    })
  }

  getGrantedTree ({ hasGrant }) {
    if (!hasGrant(...this.grants)) {
      return null
    }

    if (this.children.length === 0) {
      return this.clone()
    }

    const grantedChildren = []

    for (const child of this.children) {
      const grantedChild = child.getGrantedTree({ hasGrant })
      if (grantedChild != null) {
        grantedChildren.push(grantedChild)
      }
    }

    return this.clone({
      children: grantedChildren
    })
  }
}

const TREE_CUSTOMER_BUILDER = new TreeNode({
  key: 'customer',
  title: 'Customer',
  icon: 'account_circle'
}).addChild(new TreeNode({
  key: 'wallets',
  title: 'Wallet',
  to: '/private/customer/setup/wallet',
  icon: 'account_balance_wallet'
})).addChild(new TreeNode({
  key: 'history',
  title: 'History',
  to: '/private/customer/history',
  icon: 'history'
})).addChild(new TreeNode({
  key: 'profile',
  title: 'Verification',
  to: '/private/customer/setup/verification',
  icon: 'verified_user'
})).addChild(new TreeNode({
  key: '2fa',
  title: '2FA',
  to: '/private/customer/setup/2fa',
  icon: 'security'
}))

const TREE_ADMIN_BUILDER = new TreeNode({
  key: 'admin',
  title: 'Admin',
  icon: 'supervised_user_circle'
}).addChild(new TreeNode({
  key: 'orders',
  title: 'Orders',
  to: '/private/admin/orders',
  icon: 'credit_card',
  grants: [
    ...Grants.root,
    ...Grants.depositsAction,
    ...Grants.depositsView,
    ...Grants.withdrawalsAction,
    ...Grants.withdrawalsView
  ]
})).addChild(new TreeNode({
  key: 'customers',
  title: 'Customers',
  to: '/private/admin/customers',
  icon: 'group',
  grants: [
    ...Grants.root,
    ...Grants.profilesView
  ]
})).addChild(new TreeNode({
  key: 'balances',
  title: 'Balances',
  to: '/private/admin/balances/custom',
  icon: 'event',
  grants: [
    ...Grants.root,
    ...Grants.withdrawalsAction
  ]
})).addChild(new TreeNode({
  key: 'KYC',
  title: 'KYC',
  to: '/private/admin/kyc',
  icon: 'group',
  grants: [
    ...Grants.root,
    ...Grants.kycView,
    ...Grants.kycActions
  ]
})).addChild(new TreeNode({
  key: 'rapidID',
  title: 'Rapid ID',
  to: '/private/admin/rapidid',
  icon: 'group',
  grants: [
    ...Grants.root,
    ...Grants.kycView,
    ...Grants.kycActions
  ]
})).addChild(new TreeNode({
  key: 'monoova',
  title: 'Monoova',
  to: '/private/admin/monoova',
  icon: 'format_bold',
  grants: [
    ...Grants.root,
    ...Grants.monoovaSettingsView,
    ...Grants.monoovaSettingsActions
  ]
})).addChild(new TreeNode({
  key: 'trades',
  title: 'Trades',
  to: '/private/admin/trades',
  icon: 'credit_card',
  grants: [
    ...Grants.root,
    ...Grants.trades
  ]
}))

const TREE_SYSTEM_BUILDER = new TreeNode({
  key: 'system',
  icon: 'settings',
  title: 'System'
}).addChild(new TreeNode({
  key: 'currencies',
  title: 'Currencies',
  to: '/private/system/currencies',
  icon: 'attach_money',
  grants: [
    ...Grants.root,
    ...Grants.currencies
  ]
})).addChild(new TreeNode({
  key: 'currency-create',
  title: 'currency-create',
  to: '/private/system/currency-create',
  icon: 'attach_money',
  notShowInMenu: true,
  grants: [
    ...Grants.root,
    ...Grants.currencies
  ]
})).addChild(new TreeNode({
  key: 'currency-edit',
  title: 'currency-edit',
  to: '/private/system/currency-edit',
  icon: 'attach_money',
  notShowInMenu: true,
  grants: [
    ...Grants.root,
    ...Grants.currencies
  ]
})).addChild(new TreeNode({
  key: 'markets',
  title: 'Markets',
  to: '/private/system/markets',
  icon: 'attach_money',
  grants: [
    ...Grants.root,
    ...Grants.markets
  ]
})).addChild(new TreeNode({
  key: 'stats',
  title: 'Market Stats',
  to: '/private/system/stats',
  icon: 'event',
  grants: [
    ...Grants.root,
    ...Grants.stats
  ]
})).addChild(new TreeNode({
  key: 'partners-programs',
  title: 'Partners Programs',
  to: '/private/system/partners-programs',
  icon: 'event',
  grants: [
    ...Grants.root,
    ...Grants.referral
  ]
})).addChild(new TreeNode({
  key: 'custom-fee',
  title: 'Custom fee',
  to: '/private/system/custom-fee',
  icon: 'event',
  grants: [
    ...Grants.root,
    ...Grants.customFee
  ]
})).addChild(new TreeNode({
  key: 'blog',
  title: 'Blog',
  to: '/private/system/blog',
  icon: 'format_bold',
  grants: [
    ...Grants.root,
    ...Grants.blog
  ]
})).addChild(new TreeNode({
  key: 'chat',
  title: 'Chat',
  to: '/private/system/chat',
  icon: 'format_bold',
  grants: [
    ...Grants.root,
    ...Grants.chat
  ]
})).addChild(new TreeNode({
  key: 'deposit-addresses',
  title: 'Deposit Addresses',
  to: '/private/system/deposit-addresses',
  icon: 'settings',
  grants: [
    ...Grants.root,
    ...Grants.depositAddressesView
  ]
})).addChild(new TreeNode({
  key: 'bundles',
  title: 'Bundles',
  to: '/private/system/bundles',
  icon: 'group',
  grants: [
    ...Grants.root,
    ...Grants.markets
  ]
})).addChild(new TreeNode({
  key: 'system-page',
  title: 'System',
  to: '/private/system/system',
  icon: 'settings',
  grants: [
    ...Grants.root,
    ...Grants.developer
  ]
}))

const TREE_MODE_BUILDER = new TreeNode({
  key: 'mode',
  title: 'Mode'
}).addChild(new TreeNode({
  key: 'customer',
  title: 'Customer Mode',
  to: '/private/customer',
  icon: 'account_circle',
  iconImage: '/assets/icons/user2.svg'
})).addChild(new TreeNode({
  key: 'admin',
  title: 'Admin Mode',
  to: '/private/admin',
  icon: 'supervised_user_circle',
  iconImage: '/assets/icons/admin.svg',
  grants: TREE_ADMIN_BUILDER.getTreeGrants()
})).addChild(new TreeNode({
  key: 'system',
  title: 'System Mode',
  to: '/private/system',
  icon: 'settings',
  iconImage: '/assets/icons/eye.svg',
  grants: TREE_SYSTEM_BUILDER.getTreeGrants()
}))

const TREE_CUSTOMER = TREE_CUSTOMER_BUILDER.build()
const TREE_SYSTEM = TREE_SYSTEM_BUILDER.build()
const TREE_ADMIN = TREE_ADMIN_BUILDER.build()
const TREE_MODE = TREE_MODE_BUILDER.build()

export {
  TREE_CUSTOMER,
  TREE_SYSTEM,
  TREE_ADMIN,
  TREE_MODE
}
