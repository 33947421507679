import Joi from 'joi'
import BigNumber from 'bignumber.js'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object({
  symbol: Joi.string().required(),
  name: Joi.string().required(),
  baseCurrency: Joi.string().required(),
  baseTokenAddress: Joi.string().required(),
  quoteCurrency: Joi.string().required(),
  quoteTokenAddress: Joi.string().required(),
  feeCurrency: Joi.string().allow([null]),
  feeTokenAddress: Joi.string().required(),
  alternativeCurrency: Joi.string().allow([null]),
  alternativeTokenAddress: Joi.string().allow([null]),
  quantityIncrement: Joi.string().required(),
  tickSize: Joi.string().required(),
  makerFee: Joi.string().required(),
  takerFee: Joi.string().required(),
  makerAltFee: Joi.string().required().allow([null]),
  takerAltFee: Joi.string().required().allow([null]),
  baseMinSize: Joi.string().required(),
  quoteMinSize: Joi.string().required(),
  locked: Joi.boolean(),
  promotional: Joi.boolean(),
  showOnLanding: Joi.boolean(),
  sortOrder: Joi.number().required(),
  defaultSlippage: Joi.number().allow([null])
})

export default class MarketModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  get key () {
    return this.symbol
  }

  get tickSizeDP () {
    return new BigNumber(this.tickSize).dp()
  }

  get quantityIncrementDP () {
    return new BigNumber(this.quantityIncrement).dp()
  }

  get tickSizeQuantityIncrementCompositionDP () {
    return new BigNumber(this.tickSize).times(this.quantityIncrement).dp()
  }

  static fromJson (data, context, options) {
    return new MarketModel({
      ...data
    }, { stripUnknown: true })
  }
}
