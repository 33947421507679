import Joi from 'joi'
import PluginModel from './PluginModel'
import FixedCommissionModel from './FixedCommissionModel'
import PercentCommissionModel from './PercentCommissionModel'
import DefaultCommissionModel from './DefaultCommissionModel'

const schemaFactory = () => ({
  id: Joi.string().required(),
  type: Joi.string().valid('MonoovaWithdrawPlugin'),
  minKycLevel: Joi.number().min(0).max(4).required(),
  minAmount: Joi.string().allow([null, '']),
  maxAmount: Joi.string().allow([null, '']),
  commission: Joi.alternatives().try(
    Joi.object().type(FixedCommissionModel),
    Joi.object().type(PercentCommissionModel),
    Joi.object().type(DefaultCommissionModel)
  ).allow(null),
  requiredPhonePrefixes: Joi.array().items(Joi.string()).allow(null).default([])
})

export default class MonoovaWithdrawPluginModel extends PluginModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data, context, options) {
    if (data == null) {
      return null
    }
    return new MonoovaWithdrawPluginModel({
      id: data.id,
      type: 'MonoovaWithdrawPlugin',
      minKycLevel: data.minKycLevel,
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      commission: this.prepareCommission(data.commission),
      requiredPhonePrefixes: data.requiredPhonePrefixes
    })
  }
}
