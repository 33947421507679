import { mapActions } from 'vuex'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    handler: Function,
    handlerClose: Function
  },
  data () {
    return {
      template: null,
      message: null
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    complicate () {
      if (this.message) {
        this.handler({
          complicateComment: this.message
        })
      }
      this.handleClose()
    },
    close () {
      this.handlerClose()
      this.handleClose()
    }
  }
}
