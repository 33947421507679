import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import CurrencyDAOModel from './CurrencyDAOModel'

export const schemaFactory = () => ({
  currency: Joi.object().type(CurrencyDAOModel).required(),
  address: Joi.string().required()
})

export default class PlasmaPocketModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  get key () {
    return `[currency:${this.currency.currency.key}]-[address:${this.address.toLowerCase()}]`
  }
}
