class WithdrawalNetwork {
  static NONE = new WithdrawalNetwork('NONE', 'NONE')
  static ERC20 = new WithdrawalNetwork('ERC20', 'ERC20')
  static BEP20 = new WithdrawalNetwork('BEP20', 'BEP20')
  static POLYGON = new WithdrawalNetwork('POLYGON', 'POLYGON')
  static BTC = new WithdrawalNetwork('BTC', 'BTC')
  static LTC = new WithdrawalNetwork('LTC', 'LTC')
  static EOS = new WithdrawalNetwork('EOS', 'EOS')
  static XRP = new WithdrawalNetwork('XRP', 'XRP')
  static RON_ERC20 = new WithdrawalNetwork('RON_ERC20', 'RON_ERC20')
  static WAVES = new WithdrawalNetwork('WAVES', 'WAVES')
  static TRON = new WithdrawalNetwork('TRON', 'TRON')

  static values () {
    return Object.values(WithdrawalNetwork).map(e => e.name)
  }

  constructor (name, label) {
    this.name = name
    this.label = label
  }
}

export default WithdrawalNetwork
