/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g opacity=".2"><path pid="0" d="M8 .667L2 3.333v4a8.28 8.28 0 006 8 8.28 8.28 0 006-8v-4L8 .667z" _fill="#12143C"/><path pid="1" d="M8 8.632c-1.111 0-3.333.582-3.333 1.736v.965h6.666v-.965c0-1.154-2.222-1.736-3.333-1.736zM9.852 5.93c0-.382-.109-.755-.312-1.072a1.87 1.87 0 00-.831-.711 1.782 1.782 0 00-1.07-.11 1.83 1.83 0 00-.948.528c-.26.27-.436.614-.507.988-.072.375-.035.763.105 1.115.14.353.378.654.682.866a1.8 1.8 0 001.738.179c.224-.097.428-.24.6-.419s.309-.392.402-.626a2 2 0 00.14-.738z" _fill="#E5E5E5"/></g>'
  }
})
