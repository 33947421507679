// eslint-disable-next-line no-undef
const config = global.__APP_CONFIG__
// eslint-disable-next-line no-undef
const jwtConfig = global.__APP_CONFIG_JWT__

export default () => ({
  namespaced: true,
  getters: {
    config: () => config,
    jwtConfig: () => jwtConfig,
    jwtPubKey: () => jwtConfig.pubKey
  }
})
