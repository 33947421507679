import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  symbol: Joi.string().required(),
  name: Joi.string().required(),
  baseTokenAddress: Joi.string().required(),
  quoteTokenAddress: Joi.string().required(),
  feeTokenAddress: Joi.string().required(),
  makerFee: Joi.string().required(),
  takerFee: Joi.string().required(),
  quantityIncrement: Joi.string().required(),
  tickSize: Joi.string().required(),
  baseMinSize: Joi.string().required(),
  quoteMinSize: Joi.string().required(),
  locked: Joi.boolean().default(false)
})

export default class CreateMarketRequest extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory(), options)
    Object.freeze(this)
  }

  static fromJs (data) {
    return new CreateMarketRequest(data, { stripUnknown: true })
  }
}
