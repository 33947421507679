import {
  DefaultLayout,
  DefaultPage,
  TransparencyPage,
  FeesPage,
  AboutPage,
  ContactPage,
  BOPage
} from 'src/pages'

export default () => [
  {
    path: '',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: DefaultPage
      },
      {
        path: 'transparency',
        component: TransparencyPage
      },
      {
        path: 'fees',
        component: FeesPage
      },
      {
        path: 'about-us',
        component: AboutPage
      },
      {
        path: 'contact-us',
        component: ContactPage
      }
    ]
  },
  {
    path: '/bo',
    component: BOPage
  }
]
