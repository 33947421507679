import {LoadableMapModel, LoadableModel, withdrawalDiscountModel} from 'src/models'
import { BACKEND, withAuthorization } from 'src/remotes'

export const WITHDRAWAL_DISCOUNT_LOADING = 'withdrawalDiscount/loading'
export const WITHDRAWAL_DISCOUNT_LOADED = 'withdrawalDiscount/loaded'

export default (url, config) => ({
  namespaced: true,
  state () {
    return {
      withdrawalDiscount: new LoadableMapModel(LoadableModel)
    }
  },
  mutations: {
    [WITHDRAWAL_DISCOUNT_LOADING]: (state, { ssoUserId }) => {
      const model = state.withdrawalDiscount.get(ssoUserId) || new LoadableModel(withdrawalDiscountModel)
      state.withdrawalDiscount = state.withdrawalDiscount.put(ssoUserId, model.loading())
    },
    [WITHDRAWAL_DISCOUNT_LOADED]: (state, { ssoUserId, withdrawalDiscount }) => {
      const model = state.withdrawalDiscount.get(ssoUserId) || new LoadableModel(withdrawalDiscountModel, { withdrawalDiscount })
      state.withdrawalDiscount = state.withdrawalDiscount.put(ssoUserId, model.loaded(withdrawalDiscount))
    }
  },
  getters: {
    getWithdrawalDiscount: state => ssoUserId => state.withdrawalDiscount.get(ssoUserId) || new LoadableModel(withdrawalDiscountModel)
  },
  actions: {
    async loadWithdrawalDiscount ({ state, commit, dispatch }, { ssoUserId }) {
      commit(WITHDRAWAL_DISCOUNT_LOADING, { ssoUserId })
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.get('manager/withdrawal-fee/discount', withAuthorization(token, {
        params: {
          ssoUserId
        }
      }))
      commit(WITHDRAWAL_DISCOUNT_LOADED, { ssoUserId, withdrawalDiscount: withdrawalDiscountModel.fromJson(data) })
    },
    async addWithdrawalDiscount ({ state, commit, dispatch }, { discount, ssoUserId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await BACKEND.post('manager/withdrawal-fee/discount', { discount, ssoUserId }, withAuthorization(token))
      commit(WITHDRAWAL_DISCOUNT_LOADED, { ssoUserId, withdrawalDiscount: withdrawalDiscountModel.fromJson(data) })
    },
    async removeDepositAddress ({ state, commit, dispatch }, { ssoUserId }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      await BACKEND.post('manager/withdrawal-fee/discount/delete', { ssoUserId }, withAuthorization(token))
      commit(WITHDRAWAL_DISCOUNT_LOADED, { ssoUserId, withdrawalDiscount: null })
    }
  }
})
