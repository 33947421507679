/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 35c-7.5 0-22.5 3.77-22.5 11.25v6.25h45v-6.25C52.5 38.77 37.5 35 30 35zm12.5-17.5a12.5 12.5 0 10-25 0 12.5 12.5 0 0025 0z" _fill="#E5E7EF"/>'
  }
})
