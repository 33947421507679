/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fb': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.022 21.357v-7.623h2.575l.385-2.972h-2.96V8.865c0-.86.241-1.447 1.482-1.447l1.582-.002V4.76c-.766-.081-1.535-.12-2.306-.117-2.281 0-3.841 1.385-3.841 3.928v2.191H8.357v2.971h2.582v7.624h3.083z" _fill="#B8B8B8"/>'
  }
})
