import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import * as micros from 'src/micros'
import { WalletAddModal } from 'src/modals'
import { PlasmaPocketModel } from 'src/models'
import SelectWalletPartial from '../SelectWalletPartial/SelectWalletPartial.vue'

export default {
  metaInfo: {
    title: 'Wallet'
  },
  components: {
    ...micros,
    ...components,
    SelectWalletPartial
  },
  computed: {
    ...mapGetters({
      wallets: 'wallets/entries',
      currencies: 'currencies/currencies',
      getFiatCurrencyBySymbol: 'currencies/getFiatCurrencyBySymbol'
    }),
    pockets () {
      const wallet = this.wallet
      const currencies = this.currencies
      return (!currencies.isLoaded || !wallet)
        ? []
        : this.currencies.value.map(
          currency => new PlasmaPocketModel({
            currency,
            address: this.wallet.address
          })
        ).filter(item => {
          return item.currency.currency.symbol === 'AUDT'
        })
    }
  },
  data () {
    return {
      wallet: null,
      isRemoveInProgress: false
    }
  },
  watch: {
    wallets: {
      immediate: true,
      handler () {
        const wallet = this.wallet
        const wallets = this.wallets
        if (wallet != null) {
          this.wallet = wallets.find(w => w.key === wallet.key) || null
        }
        if (this.wallet == null) {
          this.wallet = wallets.length
            ? wallets[0]
            : null
        }
      }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      handleError: 'interaction/error',
      handleConfirmation: 'interaction/confirmation',
      handleToast: 'interaction/toast'
    }),
    async handleAdd () {
      await this.openModal({
        factory: () => WalletAddModal
      })
    }
  }
}
