import {mapActions, mapGetters} from 'vuex'
import {required, numeric, decimal} from 'vuelidate/lib/validators'
import {OperationCanceledError} from 'src/errors'
import * as components from 'src/components'
import {MarketModel} from 'src/models'
import {isGreaterThan, isGreaterThanOrEqual, isLessThanOrEqual} from 'src/validations'

export default {
  components: {
    ...components
  },
  props: {
    market: MarketModel
  },
  data () {
    return {
      isSaveInProgress: false,
      trade: [
        {
          name: 'unlocked',
          value: false,
          key: 0
        },
        {
          name: 'locked',
          value: true,
          key: 1
        }
      ],
      promotionalValues: [
        {
          name: 'no',
          value: false,
          key: 0
        },
        {
          name: 'yes',
          value: true,
          key: 1
        }
      ],
      showOnLandingValues: [
        {
          name: 'no',
          value: false,
          key: 0
        },
        {
          name: 'yes',
          value: true,
          key: 1
        }
      ],
      form: {
        name: this.market.name,
        makerFee: this.market.makerFee,
        takerFee: this.market.takerFee,
        quantityIncrement: this.market.quantityIncrement,
        tickSize: this.market.tickSize,
        baseMinSize: this.market.baseMinSize,
        quoteMinSize: this.market.quoteMinSize,
        locked: this.market.locked,
        promotional: this.market.promotional,
        showOnLanding: this.market.showOnLanding,
        sortOrder: this.market.sortOrder,
        feeTokenAddress: this.market.feeTokenAddress,
        alternativeFeeTokenAddress: this.market.alternativeTokenAddress,
        makerAltFee: this.market.makerAltFee,
        takerAltFee: this.market.takerAltFee,
        defaultSlippage: this.market.defaultSlippage
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      makerFee: {
        required,
        validCheck (value) {
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      takerFee: {
        required,
        validCheck (value) {
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      quantityIncrement: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      tickSize: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      baseMinSize: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      quoteMinSize: {
        required,
        numberCheck (value) {
          return isGreaterThan(value, 0)
        }
      },
      sortOrder: {
        required,
        numeric
      },
      feeTokenAddress: {
        required
      },
      makerAltFee: {
        validCheck (value) {
          if (value === '' || value == null) {
            return true
          }
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      takerAltFee: {
        validCheck (value) {
          if (value === '' || value == null) {
            return true
          }
          if (value >= 0) {
            return isLessThanOrEqual(value, 1, 'positive')
          } else {
            return isGreaterThanOrEqual(value, -1, 'negative')
          }
        }
      },
      defaultSlippage: {
        decimal
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/currencies',
      getCurrency: 'currencies/getCurrency'
    }),
    feeToken () {
      return this.getCurrency(this.form.feeTokenAddress)
    },
    alternativeFeeToken () {
      return this.form.alternativeFeeTokenAddress == null
        ? null
        : this.getCurrency(this.form.alternativeFeeTokenAddress)
    },
    availableFeeCurrency () {
      const result = []
      if (this.market.baseTokenAddress && this.market.quoteTokenAddress) {
        result.push(this.getCurrency(this.market.baseTokenAddress))
        result.push(this.getCurrency(this.market.quoteTokenAddress))
      }
      return result
    },
    isLocked () {
      return this.trade.find(e => e.value === this.form.locked)
    },
    isPromotional () {
      return this.promotionalValues.find(e => e.value === this.form.promotional)
    },
    isShowOnLanding () {
      return this.showOnLandingValues.find(e => e.value === this.form.showOnLanding)
    },
    nameErrors () {
      if (this.$v.form.name.$dirty) {
        if (!this.$v.form.name.required) {
          return [
            'Name is a required field'
          ]
        }
      }
      return []
    },
    makerFeeErrors () {
      if (this.$v.form.makerFee.$dirty) {
        if (!this.$v.form.makerFee.required) {
          return [
            'Maker Fee is a required field'
          ]
        }
        if (!this.$v.form.makerFee.validCheck) {
          return [
            'Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    },
    takerFeeErrors () {
      if (this.$v.form.takerFee.$dirty) {
        if (!this.$v.form.takerFee.required) {
          return [
            'Taker Fee is a required field'
          ]
        }
        if (!this.$v.form.takerFee.validCheck) {
          return [
            'Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    },
    quantityIncrementErrors () {
      if (this.$v.form.quantityIncrement.$dirty) {
        if (!this.$v.form.quantityIncrement.required) {
          return [
            'Quantity Increment is a required field'
          ]
        }
        if (!this.$v.form.quantityIncrement.numberCheck) {
          return [
            'Quantity Increment must be number and more than 0'
          ]
        }
      }
      return []
    },
    tickSizeErrors () {
      if (this.$v.form.tickSize.$dirty) {
        if (!this.$v.form.tickSize.required) {
          return [
            'Tick Size is a required field'
          ]
        }
        if (!this.$v.form.tickSize.numberCheck) {
          return [
            'TickSize must be number and more than 0'
          ]
        }
      }
      return []
    },
    baseMinSizeErrors () {
      if (this.$v.form.baseMinSize.$dirty) {
        if (!this.$v.form.baseMinSize.required) {
          return [
            'Base Min Size is a required field'
          ]
        }
        if (!this.$v.form.baseMinSize.numberCheck) {
          return [
            'BaseMinSize must be number and more than 0'
          ]
        }
      }
      return []
    },
    quoteMinSizeErrors () {
      if (this.$v.form.quoteMinSize.$dirty) {
        if (!this.$v.form.quoteMinSize.required) {
          return [
            'Quote Min Size is a required field'
          ]
        }
        if (!this.$v.form.quoteMinSize.numberCheck) {
          return [
            'QuoteMinSize must be number and more than 0'
          ]
        }
      }
      return []
    },
    sortOrderErrors () {
      if (this.$v.form.sortOrder.$dirty) {
        if (!this.$v.form.sortOrder.required) {
          return [
            'Sort order is a required field'
          ]
        }
        if (!this.$v.form.sortOrder.numeric) {
          return [
            'Sort order can be integer only'
          ]
        }
      }
    },
    defaultSlippageErrors () {
      if (this.$v.form.defaultSlippage.$dirty) {
        if (!this.$v.form.defaultSlippage.decimal) {
          return [
            'Default slippage can be decimal only'
          ]
        }
      }
    },
    makerAltFeeErrors () {
      if (this.form.makerAltFee && this.$v.form.makerAltFee.$dirty) {
        if (!this.$v.form.makerAltFee.validCheck) {
          return [
            'Alternative Maker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    },
    alternativeTakerFeeErrors () {
      if (this.form.takerAltFee && this.$v.form.takerAltFee.$dirty) {
        if (!this.$v.form.takerAltFee.validCheck) {
          return [
            'Alternative Taker Fee should be more than -1 and less than 1'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      handleError: 'interaction/error',
      editMarket: 'markets/editMarket'
    }),
    async handleSubmit () {
      this.isSaveInProgress = true
      setImmediate(async () => {
        try {
          await this.editMarket({
            symbol: encodeURIComponent(this.market.symbol),
            ...this.form,
            makerAltFee: this.form.makerAltFee === '' ? null : this.form.makerAltFee,
            takerAltFee: this.form.takerAltFee === '' ? null : this.form.takerAltFee,
            defaultSlippage: this.form.defaultSlippage === '' ? null : this.form.defaultSlippage
          })
          await this.handleToast({
            message: 'Market edited'
          })
          this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isSaveInProgress = false
        }
      })
    },
    handleLockedInput (key) {
      this.form.locked = this.trade.find(e => e.key === key).value
    },
    handlePromotionalInput (key) {
      this.form.promotional = this.promotionalValues.find(e => e.key === key).value
    },
    handleShowOnLandingInput (key) {
      this.form.showOnLanding = this.showOnLandingValues.find(e => e.key === key).value
    },
    handleInputFeeToken (key) {
      this.form.feeTokenAddress = key == null
        ? null
        : this.currencies.value.find(c => c.currency.key === key).currency.address
    },
    handleInputAltertativeFeeToken (key) {
      this.form.alternativeFeeTokenAddress = key == null
        ? null
        : this.currencies.value.find(c => c.currency.key === key).currency.address
    }
  },
  async mounted () {
  }
}
