import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

const schemaFactory = () => ({
  withdrawalsFee: Joi.array().items(Joi.object().keys({
    currency: Joi.string().required(),
    sum: Joi.number()
  })),
  fiatDepositsAmount: Joi.array().items(Joi.object().keys({
    currency: Joi.string().required(),
    type: Joi.string().required(),
    sum: Joi.number()
  })),
  fiatDepositsCount: Joi.array().items(Joi.object().keys({
    currency: Joi.string().required(),
    type: Joi.string().required(),
    count: Joi.number()
  }))
})

export default class ExchangeFinancialStatsModel extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new ExchangeFinancialStatsModel(data, { stripUnknown: true })
  }
}
