import {isNil, omitBy} from 'lodash'
import {PLASMA, withAuthorization} from 'src/remotes'
export const CUSTOM_FEE_LOADING = 'items/loading'
export const CUSTOM_FEE_LOADED = 'items/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      isLoading: false,
      isLoaded: false,
      activePage: 1,
      size: 30, // elements per page
      totalPages: null,
      items: []
    }
  },
  mutations: {
    [CUSTOM_FEE_LOADING]: (state) => {
      state.isLoading = true
    },
    [CUSTOM_FEE_LOADED]: (state, { activePage, size, items, totalPages }) => {
      Object.assign(state, omitBy({
        isLoading: false,
        isLoaded: true,
        activePage,
        size,
        items,
        totalPages
      }, isNil))
    }
  },
  getters: {
    isLoaded: state => state.isLoaded,
    isLoading: state => state.isLoading,
    activePage: state => state.activePage,
    size: state => state.size,
    items: state => state.items,
    totalPages: state => state.totalPages
  },
  actions: {
    async fetchCustomFee ({ dispatch, state }, { page, totalPages, size, filters = {} } = { totalPages: state.totalPages, size: state.size, filters: {} }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.get('/trading/custom_fee/manager/', withAuthorization(token, {
        params: {
          ...filters,
          page,
          totalPages,
          size
        }
      }))
      return data
    },
    async loadCustomFee ({ state, commit, dispatch }, {
      reset = false,
      filters,
      itemsPage = {
        activePage: 1,
        size: state.size
      }
    } = {
      reset: false,
      itemsPage: {
        activePage: 1,
        size: state.size
      }
    }) {
      if (!state.isLoading) {
        commit(CUSTOM_FEE_LOADING)
        const { page, totalPages } = await dispatch('fetchCustomFee', {
          filters: filters,
          page: parseInt(itemsPage.activePage) - 1,
          size: state.size
        })
        commit(CUSTOM_FEE_LOADED, {
          activePage: parseInt(itemsPage.activePage),
          items: page,
          totalPages
        })
      }
    },
    async createCustomFee ({ dispatch }, { address, marketSymbol, feeCurrencySymbol, makerFee, takerFee, expiredAt }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const request = {
        address,
        marketSymbol,
        feeCurrencySymbol,
        makerFee,
        takerFee,
        expiredAt
      }
      const { data } = await PLASMA.post('/trading/custom_fee', request, withAuthorization(token))
      return data
    },
    async removeCustomFee ({ dispatch }, { id }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      const { data } = await PLASMA.post(`/trading/custom_fee/delete/${id}`, null, withAuthorization(token))
      return data
    }
  }
})
