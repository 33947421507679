import { mapActions } from 'vuex'
import * as partials from 'src/partials'
import * as components from 'src/components'

export default {
  metaInfo: {
    title: 'Customers'
  },
  components: {
    ...components,
    ...partials
  },
  data () {
    return {
      stats: {}
    }
  },
  async created () {
    this.stats = await this.getStatistics()
  },
  computed: {
    navigations () {
      return [
        {
          id: 1,
          title: 'First check address:',
          path: 'address',
          counter: this.stats.firstSubmitAddresses || '0'
        },
        {
          id: 2,
          title: 'First check documents:',
          path: 'documents',
          counter: this.stats.firstSubmitDocuments || '0'
        },
        {
          id: 3,
          title: 'Resubmited:',
          path: 'submitted',
          counter: this.stats.reSubmittedLevels || '0'
        },
        {
          id: 4,
          title: 'Complicated:',
          path: 'complicated',
          counter: this.stats.complicatedLevels || '0'
        },
        {
          id: 5,
          title: 'Templates:',
          path: 'templates',
          counter: ''
        }
      ]
    }
  },
  watch: {
    async $route (to, from) {
      this.stats = await this.getStatistics()
    }
  },
  methods: {
    ...mapActions({
      getStatistics: 'customers/getStatistics'
    })
  }
}
