import {mapActions, mapGetters} from 'vuex'
import {BlogCategoryModel} from 'src/models'
import {BlogCategoryEditModal} from 'src/modals'

export default {
  props: {
    category: BlogCategoryModel
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleToast: 'interaction/toast',
      openModal: 'modals/open',
      handleConfirmation: 'interaction/confirmation',
      handleError: 'interaction/error',
      deleteCategory: 'blog/deleteCategory'
    }),
    async handleEdit () {
      this.openModal({
        factory: () => BlogCategoryEditModal,
        data: {
          category: this.category
        }
      })
    },
    async handleDelete (id) {
      await this.handleConfirmation({
        message: 'Do you really want to delete this category?',
        handler: async () => {
          await this.deleteCategory({
            id: id
          })
          this.handleClose()
          await this.handleToast({
            message: 'Category deleted'
          })
        }
      })
    }
  }
}
