import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { OperationCanceledError } from 'src/errors'
import * as components from 'src/components'

export default {
  components: {
    ...components
  },
  props: {
    title: String,
    label: String,
    message: String,
    submitTitle: String,
    resetTitle: String,
    handler: Function
  },
  data () {
    return {
      isBusy: false,
      form: {
        value: ''
      }
    }
  },
  validations: {
    form: {
      value: {
        required
      }
    }
  },
  computed: {
    valueErrors () {
      if (this.$v.form.value.$dirty) {
        if (!this.$v.form.value.required) {
          return [
            'Value is a required field'
          ]
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close',
      handleError: 'interaction/error'
    }),
    async handleSubmit () {
      this.isBusy = true
      setImmediate(async () => {
        try {
          if (this.handler) {
            await this.handler(
              this.form.value
            )
          }
          await this.handleClose()
        } catch (e) {
          if (!(e instanceof OperationCanceledError)) {
            await this.handleError({
              error: e
            })
          }
        } finally {
          this.isBusy = false
        }
      })
    }
  }
}
