import { mapGetters, mapActions } from 'vuex'
import * as components from 'src/components'
import * as directives from 'src/directives'
import SectionLayout from '../SectionLayout/SectionLayout.vue'
import { LaxMixinFactory } from 'src/mixins'

export default {
  components: {
    ...components,
    SectionLayout
  },
  directives: {
    ...directives
  },
  mixins: [
    LaxMixinFactory(function () {
      const { top } = this.$refs.blocks.getBoundingClientRect()
      const { scrollY } = window
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    })
  ],
  data () {
    return {
      startScrollOffset: 0,
      endScrollOffset: 0,
      feature: [
        {
          id: 1,
          img: '/assets/img/main/time.svg',
          title: 'Quick redemption of tokens',
          text: 'We ensure that you can convert your tokens back to Australian dollars',
          offset: 15,
          mobileOffset: 100,
          alt: 'convert your tokens back to Australian dollars'
        },
        {
          id: 2,
          img: '/assets/img/main/checklist.svg',
          title: 'Regular compliance audits',
          text: 'AUSTRAC-registered third-party company audits us regularly and publish the results here',
          offset: 30,
          mobileOffset: 200,
          alt: 'AUSTRAC-registered third-party company audits '
        },
        {
          id: 3,
          img: '/assets/img/main/safe.svg',
          title: 'Licensed banking procedure',
          text: 'Your funds are safe and protected by licensed bank',
          offset: 45,
          mobileOffset: 300,
          alt: 'licensed banking'
        },
        {
          id: 4,
          img: '/assets/img/main/currency.svg',
          title: 'Ethereum',
          text: 'Our platform is hosted on best-in-class technology',
          offset: 60,
          mobileOffset: 400,
          alt: 'our platform is hosted on Ethereum'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    items () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      return this.feature.map(
        (item, index) => ({
          ...item,
          dataLaxOpacity: `${start + item.offset + index * 10} 0, ${end + item.offset + index * 30} 1`,
          dataLaxTranslateY: `${start + item.offset + index * 100} 100px, ${end + item.offset + index * 30} 0px`,
          dataLaxOpacitySmall: `${start + item.mobileOffset + index * 150} 0, ${end + item.mobileOffset + index * 130} 1`,
          dataLaxTranslateYSmall: `${start + item.mobileOffset + index * 150} 150px, ${end + item.mobileOffset + index * 130} 0px`
        })
      )
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open'
    }),
    handleEnter () {
      this.$router.push('/private')
    }
    // async handleSignIn () {
    //   this.openModal({
    //     onClose: callback => setTimeout(callback, 500),
    //     factory: () => SignInModal
    //   })
    // }
  }
}
