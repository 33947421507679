export default {
  props: {
    title: String,
    text: String,
    icon: String,
    color: {
      type: String,
      default: 'rgba(18, 20, 60, .2)'
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
