/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.213 2.395a6.474 6.474 0 01-1.935.56A3.538 3.538 0 0016.759.991a6.55 6.55 0 01-2.141.861A3.324 3.324 0 0012.705.773a3.223 3.223 0 00-2.141.373 3.484 3.484 0 00-1.482 1.67 3.725 3.725 0 00-.209 2.275 9.195 9.195 0 01-3.848-1.077A9.683 9.683 0 011.926 1.38a3.657 3.657 0 00-.456 1.785c0 1.23.593 2.317 1.499 2.954a3.233 3.233 0 01-1.528-.444v.045c0 .82.27 1.614.763 2.249a3.349 3.349 0 001.941 1.232 3.227 3.227 0 01-1.522.06 3.555 3.555 0 001.2 1.765 3.265 3.265 0 001.95.701c-.7.579-1.5 1.007-2.357 1.26a6.434 6.434 0 01-2.635.211 9.195 9.195 0 005.167 1.595c6.202 0 9.593-5.41 9.593-10.101 0-.154-.003-.308-.01-.46a7.068 7.068 0 001.682-1.837v-.001z" _fill="#F7F9FD"/>'
  }
})
