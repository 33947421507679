import moment from 'moment'
import {mapActions, mapGetters, mapState} from 'vuex'
import * as components from 'src/components'
import { get } from 'lodash'
import { AddCommentBlockUserModal } from 'src/modals'

export default {
  components: {
    ...components
  },
  props: {
    details: Object
  },
  data () {
    return {
      commentDisabled: true
    }
  },
  computed: {
    ...mapGetters({
      getProfileData: 'customers/getKYCProfileData',
      hasAccountOperationsAccess: 'grants/hasAccountOperationsAccess'
    }),
    ...mapState({
      profileId: state => state.route.params.id
    }),
    profileData () {
      return this.getProfileData(this.profileId).value
    },
    isLocked () {
      return this.profileData.isLocked
    },
    info () {
      return {
        ssoUserId: get(this.details, 'ssoUserId', '-'),
        id: get(this.details, 'id', '-'),
        comment: get(this.details, 'customComment', '')
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      loadKYCProfileData: 'customers/loadKYCProfileData',
      addUserComment: 'customers/addUserComment',
      lockedUser: 'users/lockedUser',
      handleToast: 'interaction/toast',
      openModal: 'modals/open'
    }),
    async handleLockUser () {
      if (this.isLocked) {
        await this.lockedUser({
          id: this.info.id,
          ssoUserId: this.info.ssoUserId,
          isLocked: false
        })
        await this.handleToast({
          message: 'User updated'
        })
      } else {
        await this.openModal({
          factory: () => AddCommentBlockUserModal,
          data: {
            ssoUserId: this.info.ssoUserId,
            handler: async () => {
              await this.loadKYCProfileData({ id: this.info.id })
            }
          }
        })
      }
      this.$emit('update')
    },
    commentEdit () {
      this.commentDisabled = false
    },
    async addComment () {
      try {
        const id = this.info.id
        const comment = this.info.comment
        await this.addUserComment({ id, comment })
      } finally {
        this.commentDisabled = true
      }
    }
  }
}
