import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => ({
  id: Joi.string().required(),
  symbol: Joi.string().required(),
  title: Joi.string().required(),
  rateServiceSymbol: Joi.string().required(),
  icon: Joi.string().allow([null, ''])
})

// fiat currency used in deposit/withdraw plugins
export default class FiatCurrency extends AbstractModel {
  constructor (data, options) {
    super(data, schemaFactory, options)
    Object.freeze(this)
  }

  static fromJson (data) {
    return new FiatCurrency(data, { stripUnknown: true })
  }
}
