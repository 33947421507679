import * as components from 'src/components'
import SectionLayout from '../SectionLayout/SectionLayout.vue'

export default {
  components: {
    ...components,
    SectionLayout
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/'
      },
      {
        text: 'About Us',
        disabled: true,
        href: ''
      }
    ],
    data: [
      {
        id: 1,
        title: 'Mission',
        text: 'We are transforming the HR sector with blockchain, using the best of both centralised and decentralised technologies within a clear and robust regulatory framework. AUDT is closely integrated with these tools and services.'
      },
      {
        id: 2,
        title: 'Vision',
        text: 'In a sector that still lacks clarity and protections, we are building trust and reducing risk for those who want to take the next step and extend their field of work and practices using blockchain.'
      },
      {
        id: 3,
        title: 'Principle',
        text: 'We develop products that make a real difference. In order to have a meaningful impact on the world, we have to see beyond the idealism and hype of blockchain and build solutions people truly need.'
      }
    ]
  })
}
